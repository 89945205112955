import * as turf from "@turf/turf";
import Mapbox from "mapbox-gl";
import moment from "moment";


export const setlayersMixin = {
    methods: {
        async setLayers(){
            this.sources.forEach((source)=>{
                var centroid = turf.centroid(source.data.geometry);
                // this.centers.push(centroid)
                let getSource = this.$refs.map.map.getSource('limit-areas-'+source.data.properties.id)
                if(getSource){
                    this.$refs.map.map.removeLayer(source.data.properties.id)
                    this.$refs.map.map.removeSource('limit-areas-'+source.data.properties.id)
                }
                this.$refs.map.map.addSource('limit-areas-'+source.data.properties.id, source);
                this.$refs.map.map.addLayer(
                    {   
                        id: source.data.properties.id, type: 'fill', source: 'limit-areas-'+source.data.properties.id, layout: {}, 
                        paint: { 'fill-color': source.data.properties.color, 'fill-opacity': 0.3}
                    },
                );
                this.$refs.map.map.on('click', source.data.properties.id, ()=>{
                    new Mapbox.Popup()
                        .setLngLat(centroid.geometry.coordinates)
                        .setHTML(`
                        <div>
                            <h5>Detalles de la zonificación</h5>
                            <ul class="mb-0 p-0 size-14 list-unstyled">
                                <li><span class="weight-600">Nombre:</span> ${source.data.properties.name}</li>
                                <li><span class="weight-600">Kilométros:</span> ${source.data.properties.square_kilometers} Km</li>
                                <li><span class="weight-600">Clientes:</span> ${source.data.properties.customer_count}</li>
                                <li><span class="weight-600">Creada:</span> ${moment(source.data.properties.createdAt).format('DD-MM-YYYY HH:MM:SS A')}</li>
                                <li><span class="weight-600">Tipo de zona:</span> ${source.data.properties.type.description}</li>
                            </ul>
                        </div>
                    `)
                        .addTo(this.$refs.map.map)
                })
            })
            await this.getCustomerZones()
        }
    }
}
<template>
    <icon-base :color="color" :size="size">
        <g>
            <path class="st0" d="M125.295,101.488c-13.576,0-24.594,11.018-24.594,24.594s11.018,24.594,24.594,24.594
                            c13.576,0,24.594-11.018,24.594-24.594S138.872,101.488,125.295,101.488z"/>
            <path class="st0" d="M155.989,61.744H94.012c-17.905,0-32.267,14.363-32.267,32.267v61.977c0,17.905,14.363,32.267,32.267,32.267
                            h61.977c17.904,0,32.267-14.363,32.267-32.267V94.012C188.256,76.107,173.893,61.744,155.989,61.744z M125.295,164.646
                            c-21.25,0-38.563-17.314-38.563-38.563c0-21.249,17.314-38.563,38.563-38.563c21.249,0,38.563,17.314,38.563,38.563
                            C163.859,147.331,146.544,164.646,125.295,164.646z M166.023,94.405c-5.115,0-9.247-4.132-9.247-9.247
                            c0-5.115,4.132-9.247,9.247-9.247c5.116,0,9.247,3.935,9.247,9.247C175.27,90.273,171.139,94.405,166.023,94.405z"/>
            <path class="st0" d="M125,0.259C56.108,0.259,0.259,56.108,0.259,125S56.108,249.741,125,249.741
                            c68.893,0,124.741-55.848,124.741-124.741S193.893,0.259,125,0.259z M203.013,155.988c0,25.971-21.053,47.024-47.024,47.024H94.012
                            c-25.971,0-47.024-21.052-47.024-47.024V94.012c0-25.971,21.053-47.024,47.024-47.024h61.977c25.971,0,47.024,21.052,47.024,47.024
                            V155.988z"/>
        </g>
    </icon-base>
</template>
<script>
import IconBase from './IconBase.vue'
export default {
    components: { IconBase },
        name: 'IconInstagram',
        props: {
            size: {
                type: Number,
                default: 20,
            },
            color: {
                type: String,
                default: '#000'
            }
        }
    }
</script>

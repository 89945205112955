<script>
import router from "@/router";
import { layoutComputed } from "@/modules/template/state/helpers";
import {mapGetters} from 'vuex';
import Topbar from "@/modules/layout/components/topbar";
import Sidebar from "@/modules/layout/components/sidebar";
import Footer from "@/modules/layout/components/footer";
import RightBar from "@/modules/layout/components/right-bar";

/**
 * Vertical layout
 */
export default {
  data() {
    return {
      type: this.$store
        ? this.$store.state.layout.leftSidebarType
        : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      userAccess: []
    };
  },
  components: {
    Topbar,
    Sidebar,
    Footer,
    RightBar
  },
  computed: {
    ...layoutComputed,
    ...mapGetters({
      toggleValue: "layout/toggleMenu",
      iframe: "documentsFollowup/iframe",
      permissions: "auth/permissions"
    })
  },
  watch:{
    'permissions'(){
        this.userAccess = this.permissions?.modules
    }
  },
  created() {
    const localData = JSON.parse(localStorage.getItem('userAcess'));
    this.userAccess = localData?.modules
    document.body.setAttribute("data-sidebar", "dark");
    document.body.removeAttribute("data-topbar", "light");
    document.body.removeAttribute("data-layout-size", "boxed");
    document.body.removeAttribute("data-layout", "horizontal");
  },
  methods: {
    toggleMenu() {
      this.$store.commit('layout/TOGGLE_MENU', !this.toggleValue)
      document.body.classList.toggle("sidebar-enable");
      document.body.classList.toggle("vertical-collpsed");

      if (window.screen.width <= 768) {
        document.body.classList.remove("vertical-collpsed");
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <Topbar v-if="!iframe"/>
    <Sidebar :userAccess="userAccess"  :type="leftSidebarType" :width="layoutWidth" v-if="!iframe"/>
    <div :class="[iframe ? 'main-content-iframe' :'main-content']">
      <div :class="[iframe ? '' : 'page-content']">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
      <Footer v-if="!iframe"/>
    </div>
    <RightBar />
  </div>
</template>

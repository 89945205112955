const Module = () => import("./Module.vue");
const DocumentsFollowup = () => import("./views/DocumentsFollowup.vue");
const DocumentFollowupIFrame = () => import("./views/DocumentFollowupIFrame.vue");
const Maintenance = () => import("./views/Maintenance.vue");
// import i18n from "../../i18n";


const localData = JSON.parse(localStorage.getItem('userAcess'));
const modules = localData?.modules?.find(data => data.name == 'Rastreo de estados')

const trackingDocument = modules?.subModules?.find(data => data.name === 'Seguimiento de documentos')
const maintnance = modules?.subModules?.find(data => data.name === 'Mantenimiento')

const moduleRoute = {
    path: "/documents-followup",
    component: Module,
    name: "Track",
    children: [
        {
            path: "/",
            name: 'Seguimiento de documentos',
            component: DocumentsFollowup,
            meta:{
              icon: "icon-track",
              search: trackingDocument?.active,
              requiresAuth: true,
              active: trackingDocument?.active
            }
        },
        {
          path: "maintenance",
          name: 'Mantenimiento',
          component: Maintenance,
          meta:{
            icon: "icon-track",
            search: maintnance?.active,
            requiresAuth: true,
            active: maintnance?.active
          }
      },
      {
        path: "iframe",
        name: 'Seguimiento de documentos iframe',
        component: DocumentFollowupIFrame,
        meta:{
          icon: "icon-track",
          search: false,
          requiresAuth: true
        }
    },
    ]
};

export default router => {
    router.addRoutes([moduleRoute]);
};

import { mapGetters } from "vuex";
import { auth } from "@/firebase-config";

export const globalMixin = {
  computed:{
    ...mapGetters({
      iframe: "documentsFollowup/iframe"
    })
  },
  async mounted(){
    auth.signInAnonymously()
    .then(userCredential => {
      console.log('Signed in as anonymous user:', userCredential.user.uid);
    })
    .catch(error => {
      console.error('Error signing in anonymously:', error.code, error.message);
    });
    const {iframe} = this.$route.query
    if(iframe == '1'){
      this.$store.commit('documentsFollowup/VERIFY_IS_IFRAME', true)
    }
    try {
      await this.$store.dispatch("ecommerce/getConfiguration")
      await this.$store.dispatch("ecommerce/getStores")
    } catch (error) {
      console.log(error);
    }
  }
}

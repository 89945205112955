<template>
    <layout>
        <b-tabs class="mt-2" pills card active-tab-class="p-2" v-model="tabIndex">
            <b-tab :title="$t('tracking.follow_up')">
                <document-followup-table/>
            </b-tab>
            <b-tab :title="$t('tracking.statistics')">
              <document-followup-charts :tabIndex="tabIndex"/>
            </b-tab>
        </b-tabs>

        <!-- <order-details v-if="showDetails" @closeModal="showDetails = false" :details="documentDetails" :detailsLocal="selectedDocument" :types="types"/> -->
    </layout>
</template>
<script>
import Layout from "@/modules/template/layouts/main";

// import {documentMixin} from '../helpers/mixins/document-mixin'
import DocumentFollowupTable from '../components/DocumentFollowupTable.vue';
import DocumentFollowupCharts from '../components/DocumentFollowupCharts.vue';

export default {
    name: "Dashboard",
    components:{ 
        Layout, DocumentFollowupTable,
        DocumentFollowupCharts, 
        // WidgetStat ,
        //  Draggable, IconPlus, IconMinus, Help,
        // IconCheckCircle
    },
    // mixins: [documentMixin],
    data() {
        return {
            showCharts: false,
            totalPages: 0,
            perPage: 10,
            selectedDocument: null,
            interval: null,
            showHelp: false,
            showActive: true,
            chartsInterval: null,
            tabIndex: 0
        }
    },
}
</script>

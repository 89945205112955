export default () => ({
  employees: [],
  taskType: [],
  incidentType: [],
  tasks: [],
  taskIncidents: [],
  singleTaskIncidents: [],
  selectTask: [],
  status: [],
  methods: [],
  agents: [],
  salesEmployee: [],
  locationAssignMap: null,
  taskDetails: {},
  incidentDetails: {}
});

<template>
    <el-dialog title="Delivery details" :visible.sync="open" :beforeClose="handleClose">
        <div v-if="delivery">
            <el-row :gutter="20">
                <el-col :span="12">
                    <label for="code" class="mb-1">Code</label>
                    <el-input placeholder="Code" id="code" size="small" :value="delivery.code" disabled/>
                </el-col>
                <el-col :span="12">
                    <label for="last-online" class="mb-1">Last online</label>
                    <el-input placeholder="29-03-2022" id="last-online" size="small" :value="delivery.lastOnlineDateTime" disabled/>
                </el-col>
                <el-col :span="12" class="mt-2">
                    <label for="name" class="mb-1">Name</label>
                    <el-input placeholder="Name" id="name" size="small" v-model="delivery.name" :disabled="!edit"/>
                </el-col>
                <el-col :span="12" class="mt-2">
                    <label for="name" class="mb-1">Lastname</label>
                    <el-input placeholder="Name" id="name" size="small" v-model="delivery.lastName" :disabled="!edit"/>
                </el-col>
                <el-col :span="6" class="mt-2 d-flex align-items-end">
                    <div class="d-flex flex-column">
                        <label for="name" class="mb-1">Favorite</label>
                        <el-switch v-model="delivery.favorite" active-color="#13ce66" :disabled="!edit"/>
                    </div>
                    <div class="d-flex options">
                        <icon-moto :color="delivery.vehicleType == 1 ? '#409EFF' : '#DCDFE6'" @click.native="setVehicleType(1)"/>
                        <icon-cart :color="delivery.vehicleType == 2 ? '#409EFF' : '#DCDFE6'" class="ms-2" @click.native="setVehicleType(2)"/>
                        <icon-truck :color="delivery.vehicleType == 3 ? '#409EFF' : '#DCDFE6'" class="ms-2" @click.native="setVehicleType(3)"/>
                    </div>
                </el-col>
            </el-row>
            <div class="d-flex" v-if="edit">
                <el-button class="ms-auto" size="small" style="width: 100px;" @click="updateDeliveryDialog = false;">Cancel</el-button>
                <el-popconfirm title="Are you sure to save this?" @confirm="updateData">
                    <el-button slot="reference" type="primary" class="ms-2" size="small" style="width: 100px;">Save</el-button>
                </el-popconfirm>
            </div>
            <div class="d-flex" v-else>
                <el-button class="ms-auto" size="small" style="width: 100px;" @click="$emit('close')">Close</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props:{
            show:{
                type: Boolean
            },
            edit:{
                type: Boolean,
                default: false,
            },
            delivery:{
                type: Object
            }
        },
        data(){
            return {
                open: false
            }
        },
        watch:{
            show(val){
                this.open = val;
                // if(!val) this.$emit('close');
            }
        },
        methods: {
            handleClose(done) {
                done();
                this.$emit("close");
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <layout>
        <!-- <el-button @click="testing">testing</el-button> -->
        <div class="d-flex position-relative">
            <div class="side-bar px-3" :class="[toogle ? 'open-side-bar' : 'close-side-bar', {'d-grid' : !trackingDelivery}]" style="grid-template-rows: auto 1fr;">
                <template v-if="!trackingDelivery">
                    <div>
                        <label for="search" class="mb-1">Search</label>
                        <el-input id="search" placeholder="Search delivery" suffix-icon="el-icon-search" size="small" v-model="search" clearable/>
                        <div class="mt-2 d-flex justify-content-between">
                            <div class="d-flex align-items-center" style="gap: 10px;">
                                <el-button size="mini" round @click="filters.searchType = 1" :type="filters.searchType == 1 ? 'primary' : ''">All</el-button>
                                <el-button size="mini" round class="m-0" @click="filters.searchType = 2" :type="filters.searchType == 2 ? 'primary' : ''">With packages</el-button>
                                <el-button size="mini" round class="m-0" @click="filters.searchType = 3" :type="filters.searchType == 3 ? 'primary' : ''">
                                    On route
                                </el-button>
                                <eva-icon name="heart" width="18" :fill="'var(--bs-danger)'" class="cursor-pointer" @click="filters.searchType = 4"/>
                            </div>
                            <el-button @click="getDeliveries" type="text" class="p-0">
                                <i class="el-icon-refresh size-20"></i>
                            </el-button>
                        </div>
                    </div>
                    <el-aside v-if="(search ? result : deliveries).length > 0" class="mt-3">
                        <el-card class="box-card deliveries cursor-pointer" v-for="(delivery, i) in (search ? result : deliveries)" 
                        :key="i" :class="{'mt-3': i > 0}" @click.native="select(delivery)">
                            <div class="size-14 d-flex align-items-center w-100">
                                <div class="icon-container me-2 p-2 rounded-circle d-flex justify-content-center" :class="[delivery.onRoute ? 'bg-success' : 'bg-danger']">
                                    <icon-moto :color="'white'" v-if="delivery.vehicleType == 1"/>
                                    <icon-cart :color="'white'" v-else-if="delivery.vehicleType == 2"/>
                                    <icon-truck :color="'white'" v-else/>
                                </div>
                                <div class="w-100">
                                    <!-- d-flex justify-content-between align-items-center -->
                                    <div class="d-grid" v-if="delivery.name" style="grid-auto-flow:column;">
                                        <b class="size-13 text-capitalize text-limited me-2">{{ delivery.name.toLowerCase() }} {{ delivery.lastName.toLowerCase() }}</b>
                                        <span class="size-12 text-end">{{ delivery.speedKilometerPerHour.toFixed(2) }} Km/h</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="size-12">{{ moment(delivery.lastOnlineDateTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        <div class="options d-flex">
                                            <el-tooltip effect="dark" content="Add to favorite" placement="bottom">
                                                <eva-icon name="heart" width="18" class="me-2" :fill="delivery.favorite ? 'var(--bs-danger)' : ''" @click.native="setFavorite(delivery)"/>
                                            </el-tooltip>
                                            <el-tooltip effect="dark" content="Update" placement="bottom">
                                                <eva-icon name="edit" width="18" class="me-2" @click.native.stop="editDelivery(delivery)"/>
                                            </el-tooltip>
                                            <el-tooltip effect="dark" content="Track delivery" placement="bottom">
                                                <eva-icon name="activity" width="18" @click.native.stop="trackingDelivery = delivery"/>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <el-badge :value="delivery.packages.length" class="mt-2" v-if="delivery.packages && delivery.packages.length" >
                                    <el-popover placement="right" width="400" trigger="click">
                                    <el-table :data="delivery.packages" style="width: 100%" size="small" stripe cell-class-name="p-0" v-loading="loadingDetails">
                                        <el-table-column prop="taskID" label="Task ID" width="70" class-name="text-center"/>
                                        <el-table-column prop="documentID" label="Document ID" width="100" class-name="text-center"/>
                                        <el-table-column prop="statusID" label="State ID" width="70" class-name="text-center"/>
                                        <el-table-column prop="state" label="State" width="100" class-name="text-center"/>
                                            <el-table-column label="Actions" width="70" class-name="text-center" >
                                                <template slot-scope="scope">
                                                    <div class="d-flex justify-content-center">
                                                        <el-button type="text" icon="el-icon-more" size="small" class="p-1" @click="showDetails(scope.row.taskID)"></el-button>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                    </el-table>
                                        <el-button slot="reference" size="small">See Packages</el-button>
                                    </el-popover>
                                </el-badge>
                            </div>
                        </el-card>
                    </el-aside>
                    <el-empty description="NO DATA" v-else/>
                </template>
                <template v-else>
                    <el-page-header @back="back" class="mb-4">
                        <span slot="content" class="size-14">Track delivery</span>
                    </el-page-header>
                    <div class="d-flex flex-column">
                        <label for="datetime" class="mb-1">Delivery</label>
                        <el-select v-model="trackingDelivery" placeholder="Select" filterable no-match-text="No data" value-key="code" class="w-100" size="small">
                            <el-option v-for="item in deliveries" :key="item.code" :label="`(${item.code}) ${item.name} ${item.lastName}`" :value="item"/>
                        </el-select>
                    </div>
                    <div class="mt-3">
                        <label for="datetime" class="mb-1">Position Time</label>
                            <el-date-picker v-model="simpleRange" type="datetimerange" align="right" start-placeholder="Start Date" end-placeholder="End Date" 
                            :default-time="['00:00:00', '23:59:59']" class="w-100 mb-2" size="small"/>
                        <el-select v-model="range" placeholder="Select" filterable no-match-text="No data" value-key="id" class="w-100" size="small">
                            <el-option v-for="item in ranges" :key="item.id" :label="`${item.title}`" :value="item"/>
                        </el-select>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <el-button type="primary" size="small" @click="trackDelivery" class="w-100">Search</el-button>
                        <el-button type="text" style="font-weight: 400;" @click="back">Reset</el-button>
                    </div>
                    <div class="d-flex">
                        <el-checkbox v-model="showCustomers">Customers</el-checkbox>
                        <el-checkbox v-model="showLogs">Logs</el-checkbox>
                        <!-- <el-checkbox v-model="showRoute" @change="onShowRoute">Route</el-checkbox> -->
                    </div>
                    <el-card class="box-card mt-3" v-loading="loading">
                        <template v-if="trackDetails">
                            <div slot="header" class="size-13">
                                <span>{{ trackDetails.distanceInKilometres.toFixed(2) }}Km <b class="ms-2">-</b></span>
                                <span class="ms-2">{{ trackDetails.speedKilometerPerHour.toFixed(2) }}Km/h</span>
                                <el-button style="float: right; padding: 3px 0" type="text">Details</el-button>
                            </div>
                            <el-timeline class="p-0 ps-1" style="max-height: 40vh;">
                                <el-timeline-item timestamp="Start" placement="top" :color="'var(--bs-success)'">
                                    <el-card>
                                        <div class="d-flex align-items-center">
                                            <img src="https://us.tracksolidpro.com/resource/dev/img/start@2x.f7de0fcf.png" alt="s" width="20px" class="me-2">
                                            <span class="size-13">{{ moment(trackDetails.details.start.timestamp).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        </div>
                                        <!-- <el-popover placement="bottom" width="300" trigger="hover" :content="trackDetails.details.start.address" >
                                            <span class="size-12 desc-address" slot="reference">{{ trackDetails.details.start.address }}</span>
                                        </el-popover> -->
                                    </el-card>
                                </el-timeline-item>
                                <el-timeline-item :timestamp="`Total ${stops.length} trips`" placement="top" :color="'var(--bs-warning)'" v-if="stops.length">
                                    <el-card :body-style="{ display: 'flex', 'flex-direction': 'column'}" v-loading="loadingStopsAddress">
                                        <el-button size="small" class="m-auto mb-1" @click="getStopDetails">{{showStops ? 'More less' : 'More details'}}</el-button>
                                        <hr>
                                        <template v-if="showStops">
                                            <div  v-for="stop in stops" :key="stop.id">
                                                <el-timeline-item :timestamp="`Start at ${moment(stop.timestamp).format('YYYY-MM-DD HH:mm:ss')}`" placement="top" :color="'var(--bs-success)'">
                                                    <el-card>
                                                        <!-- <div class="d-flex align-items-center">
                                                            <span class="size-13">{{ moment(stop.timestamp).format(' HH:MM:SS') }}</span>
                                                        </div> -->
                                                        <el-popover placement="bottom" width="300" trigger="hover" :content="stop.address" >
                                                            <span class="size-12 desc-address" slot="reference">{{ stop.address }}</span>
                                                        </el-popover>
                                                    </el-card>
                                                </el-timeline-item>
                                                <el-timeline-item :timestamp="`Finish at ${moment(locations[stop.index - 1].timestamp).format('YYYY-MM-DD HH:mm:ss')}`" placement="top" :color="'var(--bs-success)'">
                                                    <el-card>
                                                        <!-- <div class="d-flex align-items-center">
                                                            <span class="size-13">{{ moment(deliveryLocations[stop.index - 1]).format(' HH:MM:SS') }}</span>
                                                        </div> -->
                                                        <el-popover placement="bottom" width="300" trigger="hover" :content="stop.address" >
                                                            <span class="size-12 desc-address" slot="reference">{{ stop.address }}</span>
                                                        </el-popover>
                                                    </el-card>
                                                </el-timeline-item>
                                                <!-- <el-card>
                                                    <div class="d-flex align-items-center">
                                                        <span class="size-13">{{ moment(stop.timestamp).format(' HH:MM:SS') }}</span>
                                                    </div>
                                                    <el-popover placement="bottom" width="300" trigger="hover" :content="stop.address" >
                                                        <span class="size-12 desc-address" slot="reference">{{ stop.address }}</span>
                                                    </el-popover>
                                                </el-card> -->
                                            </div>
                                        </template>
                                        <!-- </el-timeline-item> -->
                                    </el-card>
                                </el-timeline-item>
                                <el-timeline-item timestamp="Finish" placement="top" :color="'var(--bs-danger)'">
                                    <el-card>
                                        <div>
                                            <img src="https://us.tracksolidpro.com/resource/dev/img/finish@2x.1c3a11ad.png" alt="s" width="20px" class="me-2">
                                            <span class="size-13">{{ moment(trackDetails.finish.timestamp).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        </div>
                                        <!-- <el-popover placement="bottom" width="300" trigger="hover" :content="route.details.finish.address" >
                                            <span class="size-12 desc-address" slot="reference">{{ route.details.finish.address }}</span>
                                        </el-popover> -->
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                        </template>
                        <el-empty description="" v-else/>
                    </el-card>
                </template>
            </div>
            <div class="map w-100 position-relative">
                <div class="position-absolute icon-toogle d-flex align-items-center cursor-pointer" @click="toogle = !toogle" :class="{'icon-toogle-open-side-bar' : toogle}">
                    <icon-arrow style="margin-top: -2px;" :style="{transform: toogle ? 'rotate(180deg)': 'none'}"/>
                </div>
                    
                <!-- * -------------------- PLAYER --------------------->
                <el-card class="box-card player" v-if="locations.length" :class="{'player-open-side-bar' : toogle}" :body-style="{ padding: '10px' }">
                        <div class="d-flex align-items-center justify-content-end">
                            <span class="size-13">Speed: {{locations[validateCurrentIndex ? currentIndex : locations.length - 1].speedKilometerPerHour.toFixed(0)}}km/h</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <el-button type="primary" circle size="small" class="me-3 p-0">
                                <eva-icon :name="animation ? 'pause-circle' : 'play-circle'" @click.native="startAnimation" fill="#fff"></eva-icon>
                            </el-button>
                            <el-slider v-model.number="progress" :min="0" :max="100" @change="onProgressChange" :step="step" class="w-100" :show-tooltip="false"/>
                        </div>
                        <div class="d-flex mt-2">
                            <el-dropdown class="me-3">
                                <span>
                                    <i class="el-icon-timer size-18"></i>
                                    <span style="font-size:7px;" class="me-2">{{animationSpeed.title}}</span>
                                    <span class="el-dropdown-link">Speed</span>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-for="speed in speeds" :key="speed.value" @click.native="animationSpeed = speed">{{ speed.title }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button type="text" class="p-0" icon="el-icon-refresh-right" @click="currentIndex = 0;progress=0;">Replay</el-button>
                            <span class="ms-auto size-13">{{ moment(locations[validateCurrentIndex ? currentIndex : locations.length - 1].timestamp).format('YYYY-MM-DD HH:mm:ss')}}</span>
                        </div>
                </el-card>
                <!-- * -------------------- PLAYER --------------------->
                <div class="select-sync d-flex align-items-center" :class="{'select-sync-open-side-bar' : toogle}">
                    <el-select v-model="syncTime" placeholder="Time" size="small">
                        <el-option v-for="time in syncTimes" :key="time.value" :label="time.label" :value="time.value"/>
                    </el-select>
                    <el-progress type="circle" :percentage="(count / syncTime) * 100" :width="30" :stroke-width="3" class="ms-4" :format="formatPercentage"/>
                </div>
                <mgl-map :key="styleSelectMap.id" :center="center" :accessToken="accessToken" :zoom="6" @load="onMapLoaded" :mapStyle="styleSelectMap.value" ref="map" v-loading="loadingDetails">
                    <mgl-geolocate-control :trackUserLocation="true" position="top-right" />
                    <mgl-navigation-control position="top-right"/>
                    <div class="icon-map">
                        <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                        <el-popover placement="right" width="210" trigger="click">
                            <b-row class="mt-2" style="width: 205">
                                <b-col>
                                    <div class="d-flex">
                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                            <span class="icon" role="img" aria-label="Mapa">
                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                                <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                            </span>
                                            <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                        </button>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div class="d-flex" style="margin-left: -7px;">
                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                            <span class="icon" role="img" aria-label="Mapa">
                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                                <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                            </span>
                                            <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>
                        <button slot="reference" class="gmaps-button">
                            <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i> <!-- Puedes reemplazar esto con un icono real, como un SVG o un icono de FontAwesome -->
                        </button>
                        </el-popover>
                    </div>
                    <mgl-marker :coordinates="[delivery.coordinates.longitude, delivery.coordinates.latitude]" v-for="(delivery, i) in trackingDelivery ? deliveries.filter(d=>d.code == trackingDelivery.code) : deliveries" :key="`marker-${i}`">
                        <div slot="marker">
                            <img :src="delivery.vehicleType == 1 ? 'https://static.vecteezy.com/system/resources/previews/010/871/769/original/3d-deliveryman-going-to-deliver-parcel-png.png' 
                            : delivery.vehicleType == 2 ? 'https://www.uber-assets.com/image/upload/f_auto,q_auto:eco,c_fill,w_956,h_637/v1555367349/assets/d7/3d4b80-1a5f-4a8b-ac2b-bf6c0810f050/original/Final_XL.png':
                            'https://static.vecteezy.com/system/resources/previews/010/872/418/original/3d-delivery-truck-icon-png.png'" 
                            alt="con-motorcycle" width="60">
                        </div>
                        <mgl-popup :ref="`popup-${delivery.code}`">
                            <div class="mt-2">
                                <div class="d-flex">
                                    <el-avatar icon="el-icon-user-solid"></el-avatar>
                                    <div class="ms-2">
                                        <h6 class="mb-0 text-capitalize">{{delivery.name.toLowerCase()}} {{delivery.lastName.toLowerCase()}}</h6>
                                        <span :class="delivery.onRoute ? 'text-success':'text-danger'">
                                            {{ delivery.onRoute ? 'Active':'Inactive' }}
                                        </span>
                                    </div>
                                </div>
                                <el-table :data="delivery.packages" style="width: 100%" size="small" stripe cell-class-name="p-0" v-loading="loadingDetails">
                                    <el-table-column prop="taskID" label="Task ID" width="70" class-name="text-center"/>
                                    <el-table-column prop="documentID" label="Document ID" width="100" class-name="text-center"/>
                                    <el-table-column prop="statusID" label="State ID" width="70" class-name="text-center"/>
                                    <el-table-column prop="state" label="State" width="100" class-name="text-center"/>
                                        <el-table-column label="Actions" width="70" class-name="text-center" >
                                            <template slot-scope="scope">
                                                <div class="d-flex justify-content-center">
                                                    <el-button type="text" icon="el-icon-more" size="small" class="p-1" @click="showDetails(scope.row.taskID)"></el-button>
                                                </div>
                                                <!-- slot-scope="scope" -->
                                            </template>
                                        </el-table-column>
                                </el-table>
                            </div>
                        </mgl-popup>
                    </mgl-marker>
                    <mgl-marker :coordinates="navigator" v-if="navigator">
                        <div slot="marker">
                            <img src="https://us.tracksolidpro.com/resource/dev/img/dot.a897c116.png" alt="con-motorcycle" width="60px" :style="`transform: rotate(${bearing - 100}deg);`">
                        </div>    
                    </mgl-marker>
                    <mgl-marker :coordinates="statusPosition.coordinates" v-if="statusPosition">
                        <div slot="marker">
                            <img src="https://cdn-icons-png.flaticon.com/512/5610/5610944.png" alt="warning" width="40" v-if="statusPosition.active">
                            <img src="https://static.vecteezy.com/system/resources/previews/012/042/292/original/warning-sign-icon-transparent-background-free-png.png" alt="warning" width="40" v-else>
                        </div>
                    </mgl-marker>
                    <!-- * -------------------- CREATE STOPS --------------------->
                    <template v-if="stops.length">
                        <mgl-marker :coordinates="[stop.coordinates.longitude, stop.coordinates.latitude]" v-for="stop in stops" :key="`stop-${stop.id}`">
                            <div slot="marker">
                                <icon-stop :size="25" :color="'#f8b425'"/>
                            </div>
                            <mgl-popup>
                                <div class="mt-2">
                                    <span v-if="locations[stop.index - 1]">Start time: {{ locations[stop.index - 1].timestamp }}</span> <br>
                                    <span>End time: {{ stop.timestamp }}</span> <br>
                                    <span>Duration: {{ elapsedTime(stop.differenceMilliseconds) }}</span>
                                </div>
                            </mgl-popup>
                        </mgl-marker>
                    </template>
                    <!-- * -------------------- CREATE STOPS --------------------->
                    <template v-if="trackDetails">
                        <mgl-marker :coordinates="trackDetails.start">
                            <div slot="marker">
                                <img src="https://us.tracksolidpro.com/resource/dev/img/start@2x.f7de0fcf.png" alt="s" width="40px">
                            </div>
                        </mgl-marker>
                        <mgl-marker :coordinates="trackDetails.finish" color="var(--bs-danger)">
                            <div slot="marker">
                                <img src="https://us.tracksolidpro.com/resource/dev/img/finish@2x.1c3a11ad.png" alt="s" width="40px">
                            </div>
                        </mgl-marker>
                    </template>
                    <!-- * -------------------- PACKAGE DELIVERED --------------------->
                    <mgl-marker :coordinates="[pack.coordinates.longitude, pack.coordinates.latitude]" v-for="(pack, i) in packageDelivered" :key="`marker-${i}-${pack.id}`" 
                    @click="showDetails(pack.package.taskID)">
                        <div slot="marker">
                            <div style="margin-top: -45px;border-radius: 30px">
                                <img src="../assets/images/icon-package-map.png" alt="icon-package-map" width="50">
                            </div>
                        </div>
                    </mgl-marker>
                    <!-- * -------------------- PACKAGE DELIVERED --------------------->
                    
                    <!-- * -------------------- CUSTOMERS --------------------->
                    <!-- <template v-if="showCustomers">
                        <mgl-marker style="cursor: pointer" :coordinates="[customer.geometry.coordinates[0], customer.geometry.coordinates[1]]" :draggable="false" 
                        v-for="(customer, index) in unclusteredCustomers" :key="customer.properties.address_id + index">
                            <div slot="marker">
                                <icon-pin :color="customer.properties.verified ? '#348547' : '#c40316'" :size="25"/>
                            </div>
                            <mgl-popup>
                                <div>
                                    <h6>{{customer.properties.first_name}} {{customer.properties.last_name}}</h6>
                                    <ul class="p-0">
                                        <li> <b>{{$t('ecommerce.code')}}</b>  {{customer.properties.code}}</li>
                                        <li> <b>ID</b>  {{customer.properties.address_id}}</li>
                                        <li> <b>{{$t('ecommerce.verified_buyer')}}</b>  {{customer.properties.verified}}</li>
                                    </ul>
                                </div> 
                            </mgl-popup>
                        </mgl-marker>
                    </template>
                    <mgl-geojson-layer :layer="clusters" :sourceId="'customers'" :layerId="clusters.id" :source.sync="sourceCustomers" @click="clickZoom" @mouseenter="putPointer" @mouseleave="removePointer"/>
                    <mgl-geojson-layer :layer.sync="clusterCount" :sourceId="'customers'" :layerId="clusterCount.id"/>
                    <mgl-geojson-layer :layer.sync="unclustered" :sourceId="'customers'" :layerId="unclustered.id" :clearSource="true" @mouseenter="putPointer" @mouseleave="removePointer"/> -->
                    <!-- * -------------------- CUSTOMERS --------------------->


                </mgl-map>

                <!-- * -------------------- SHOW STATUS LOGS --------------------->
                <el-card v-if="statusLogs.length && showLogs" class="status-log-container">
                    <h5>Status</h5>
                    <el-table :data="statusLogs" height="250" size="small" stripe cell-class-name="p-0">
                        <el-table-column label="Created at" width="200" class-name="text-center">
                            <template slot-scope="scope">
                                {{ moment(scope.row.createdAt ? scope.row.createdAt : scope.row.time).format('MMMM Do YYYY, h:mm:ss a') }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Status" width="85" class-name="text-center">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.active ? 'success': 'danger'" size="mini" class="text-center" style="width: 65px;">
                                    {{ scope.row.active ? 'Active' : 'Desactive' }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="Location" width="70" class-name="text-center" >
                            <template slot-scope="scope" v-if="scope.row.coordinates.longitude && scope.row.coordinates.latitude">
                                <i class="el-icon-location text-primary size-16 cursor-pointer" 
                                @click="showMapPin({latitude: scope.row.coordinates.latitude,  longitude: scope.row.coordinates.longitude, active:scope.row.active})"/>
                            </template>
                        </el-table-column>
                        </el-table>
                </el-card>
                <!-- * -------------------- SHOW STATUS LOGS --------------------->
            </div>
        </div>
        <el-dialog title="Update delivery" :visible.sync="updateDeliveryDialog">
            <div v-if="updateDelivery">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <label for="code" class="mb-1">Code</label>
                        <el-input placeholder="Code" id="code" size="small" :value="updateDelivery.code" disabled/>
                    </el-col>
                    <el-col :span="12">
                        <label for="last-online" class="mb-1">Last online</label>
                        <el-input placeholder="29-03-2022" id="last-online" size="small" :value="updateDelivery.lastOnlineDateTime" disabled/>
                    </el-col>
                    <el-col :span="12" class="mt-2">
                        <label for="name" class="mb-1">Name</label>
                        <el-input placeholder="Name" id="name" size="small" v-model="updateDelivery.name"/>
                    </el-col>
                    <el-col :span="12" class="mt-2">
                        <label for="name" class="mb-1">Lastname</label>
                        <el-input placeholder="Name" id="name" size="small" v-model="updateDelivery.lastName"/>
                    </el-col>
                    <el-col :span="6" class="mt-2 d-flex align-items-end">
                        <div class="d-flex flex-column">
                            <label for="name" class="mb-1">Favorite</label>
                            <el-switch v-model="updateDelivery.favorite" active-color="#13ce66"/>
                        </div>
                        <div class="d-flex options">
                            <icon-moto :color="updateDelivery.vehicleType == 1 ? '#409EFF' : '#DCDFE6'" @click.native="setVehicleType(1)"/>
                            <icon-cart :color="updateDelivery.vehicleType == 2 ? '#409EFF' : '#DCDFE6'" class="ms-2" @click.native="setVehicleType(2)"/>
                            <icon-truck :color="updateDelivery.vehicleType == 3 ? '#409EFF' : '#DCDFE6'" class="ms-2" @click.native="setVehicleType(3)"/>
                        </div>
                    </el-col>
                </el-row>
                <div class="d-flex">
                    <el-button class="ms-auto" size="small" style="width: 100px;" @click="updateDeliveryDialog = false;">Cancel</el-button>
                    <el-popconfirm title="Are you sure to save this?" @confirm="updateData">
                        <el-button slot="reference" type="primary" class="ms-2" size="small" style="width: 100px;">Save</el-button>
                    </el-popconfirm>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="Task details" :visible.sync="taskDetailsDialog">
            <el-row :gutter="20" v-if="taskDetails.id">
                <el-col :span="12">
                    <label for="customer" class="mb-1">Customer</label>
                    <el-input placeholder="customer" id="customer" size="small" :value="`${taskDetails.company_name} ${taskDetails.company_name ? '/' : ''} ${taskDetails.customer}`" disabled/>
                </el-col>
                <el-col :span="12">
                    <label for="description" class="mb-1">Description</label>
                    <el-input placeholder="description" id="description" size="small" :value="taskDetails.description" disabled/>
                </el-col>
                <el-col :span="24" class="mt-2">
                    <label for="address" class="mb-1">Address</label>
                    <el-input placeholder="address" id="address" size="small" :value="taskDetails.address" disabled/>
                </el-col>
                <el-col :span="6" class="mt-2">
                    <label for="date" class="mb-1">Create</label>
                    <el-input placeholder="date" id="date" size="small" :value="taskDetails.created_at" disabled/>
                </el-col>
                <el-col :span="6" class="d-flex flex-column mt-2">
                    <label for="date" class="mb-1">State</label>
                    <el-tag>{{taskDetails.status.description}}</el-tag>
                </el-col>
                <el-col :span="12" class="mt-2">
                    <label for="delivery" class="mb-1">Delivery</label>
                    <el-input placeholder="delivery" id="delivery" size="small" :value="`${taskDetails.employee.first_name} ${taskDetails.employee.last_name}`" disabled/>
                </el-col>
                <el-col :span="6" class="d-flex flex-column mt-2">
                    <label for="method" class="mb-1">Method</label>
                    <el-input placeholder="method" id="method" size="small" :value="taskDetails.method.description" disabled/>
                </el-col>
                <el-col :span="6" class="d-flex flex-column mt-2">
                    <label for="type" class="mb-1">State</label>
                    <el-input placeholder="type" id="type" size="small" :value="taskDetails.type.description" disabled/>
                </el-col>
                <el-col :span="12" class="d-flex flex-column mt-2">
                    <label for="branch" class="mb-1">Branch</label>
                    <el-input placeholder="branch" id="branch" size="small" :value="taskDetails.sales_location.description" disabled/>
                </el-col>
                <el-col :span="24" class="mt-4">
                    <el-steps :active="taskDetails.status_log.length" align-center>
                        <el-step v-for="state in taskDetails.status_log" :key="state.id">
                            <template slot="title">
                                <h5 :style="`color: ${state.new_status.background_color}`">{{ state.new_status.description }}</h5>
                            </template>
                            <template slot="description">
                                <span class="text-dark">{{$d(new Date(state.created_at))}}</span>
                                <h6 class="text-dark">{{getHour(state.created_at)}}</h6>
                            </template>
                            <template slot="icon">
                                <eva-icon name="checkmark-circle-2" :fill="state.new_status.background_color"></eva-icon>
                            </template>
                        </el-step>
                    </el-steps>
                </el-col>
            </el-row>
        </el-dialog>
    </layout>
</template>

<script>
    import IconMoto from '../assets/svg/delivery-types/IconMoto.vue'
    import IconTruck from '../assets/svg/delivery-types/IconTruck.vue'
    import IconCart from '../assets/svg/delivery-types/IconCart.vue'
    import { MglMap, MglGeolocateControl, MglNavigationControl, MglMarker, MglPopup } from 'v-mapbox'
    import Mapbox from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import 'v-mapbox/dist/v-mapbox.css';
    import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
    
    import { db } from "@/firebase-config";
    import moment from "moment";
    
    import Layout from "@/modules/template/layouts/main";
    import { mapGetters } from 'vuex';
    import IconStop from '../../../common/components/svg/IconStop.vue';
    import IconArrow from '../../../common/components/svg/IconArrow.vue';
    // import IconPin from '../../../common/components/svg/IconPin.vue';

    import http from 'axios';
    import { http as backend } from "@/common/services/web-backend.js";
    
    // import { customersMixin } from '../../ecommerce/helpers/mixins/customers-mixin'

    export default {
        components: {
            MglMap,
            MglGeolocateControl,
            MglNavigationControl,
            MglMarker,
            MglPopup,
            // MglGeojsonLayer,
            Layout,
            IconStop,
            IconArrow,
            IconMoto,
            IconTruck,
            IconCart,
            // IconPin
        },
        // mixins: [customersMixin],
        data() {
                return {
                    deliveries: [],
                    customers: [],
                    // unclusteredCustomers: [],
                    result: [],
                    search: null,
                    moment: moment,
                    trackingDelivery: null,
                    progress: 0,
                    route: null,
                    navigator: null,
                    counter: 0,
                    currentIndex:0,
                    animationSpeed: {title:'0.1X', value: 100,},
                    animation: null,
                    deliveryPackages: [],
                    // MAP
                    center: [-70.70238447994983, 19.45556310283655],
                    accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps

                    bearing: 0,
                    range: {id: 1, title: 'Today', date: [moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'), moment().add(24, 'hours')]},
                    ranges: [
                        {
                            id: 1, title: 'Today',
                            date: [moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'), moment().add(24, 'hours')]
                        },
                        {
                            id: 2, title: 'Yesterday',
                            date: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
                        },
                        {
                            id: 3, title: 'Last 3 days',
                            date: [moment().subtract(3, 'days'), moment().subtract(3, 'days')]
                        },
                        {
                            id: 4, title: 'This week',
                            date: [moment().subtract(7, 'days'), moment().subtract(7, 'days')]
                        },
                        {
                            id: 5, title: 'Last week',
                            date: [moment().subtract(14, 'days'), moment().subtract(14, 'days')]
                        },
                    ],
                    toogle: true,
                    toogleRight: false,
                    showCustomers: false,
                    simpleRange: [moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'), moment().add(24, 'hours').endOf('day').format('YYYY-MM-DDTHH:mm:ss')],
                    loading: false,
                    loadingStopsAddress: false,
                    loadingDetails: false,
                    updateDeliveryDialog: false,
                    updateDelivery: null,
                    taskDetailsDialog: false,
                    showStops: false,
                    speeds: [
                        {title:'0.1X', value: 100,},
                        {title:'0.5X', value: 50,},
                        {title:'1X', value: 25,},
                        {title:'2X', value: 10,},
                    ],
                    localDeliveries: [],
                    filters: {
                        // Options ->  1 All | 2 With packages | 3 Activos | 4 Favorite | 5 Inactivos
                        searchType: 1
                    },
                    syncTime: 20,
                    syncTimes:[
                        {value:10, label: '10 seconds'},
                        {value:20, label: '20 seconds'},
                        {value:30, label: '30 seconds'},
                        {value:60, label: '60 seconds'},
                    ],
                    countUp: null,
                    count: 0,
                    statusPosition: null,
                    styleMap: [
                        {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                        {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
                    ],
                    styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
                    showLogs: false,
                    maxDistance: 50000,
                    markers: [],  // Array para almacenar todos tus marcadores
                    visibleMarkers: [],  // Array para almacenar los marcadores visibles
                    nonVisibleMarkers: [],  // Array para almacenar los marcadores no visibles
                    maxVisibleMarkers: 20,  // Límite para la cantidad de marcadores visibles

                    // showRoute: true,
                }
        },
        watch: {
            'permissions'() {
                const modules = this.permissions?.modules?.find(data => data.name == 'Delivery')
                const tracking = modules?.subModules?.find(data => data.name === 'Rastreo')
                // Verifica si se han cambiado los permisos de administrador
                if (!tracking.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
            },
            search(val) {
                if (val) {
                    this.result = this.deliveries.filter(d => (`${d.code.toLowerCase()} ${d.name.toLowerCase()} ${d.lastName.toLowerCase()}`).includes(val.toLowerCase()))
                }
            },
            range(val) {
                this.simpleRange = [
                    val.date[0].startOf('day').format('YYYY-MM-DDTHH:mm:ss'), 
                    val.date[1].endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                ];
            },
            filters: {
                deep: true,
                handler(val) {
                    switch (val.searchType) {
                        case 1:
                            this.deliveries = this.localDeliveries;
                            break;
                        case 2:
                            this.deliveries = this.localDeliveries.filter(d => d.packages && d.packages.length > 0);
                            break;
                        case 3:
                            this.deliveries = this.localDeliveries.filter(d => d.onRoute);
                            break;
                        case 4:
                            this.deliveries = this.localDeliveries.filter(d => d.favorite);
                            break;
                        default:
                            break;
                    }
                }
            },
            'trackingDelivery.code'(){
                this.trackDelivery['stops'] = [];
                // if(val){
                    // this.deliveries = this.localDeliveries.filter(d=> d.code == val);
                // }
            },
            showCustomers(val){
                if(val){
                    this.markers.forEach(m=> m.addTo());
                }else{
                    this.markers.forEach(m=> m.remove());
                }
            }
        },
        computed: {
            map() {
                return this.$refs.map.map;
            },
            step() {
                return 100 / (this.trackingDelivery?.route?.length - 1);
            },
            validateCurrentIndex() {
                return this.currentIndex < this.trackingDelivery?.route?.length;
            },
            ...mapGetters({
                mapStyle: 'layout/mapStyle',
                taskDetails: "delivery/taskDetails",
                userData: "auth/user",
                permissions: "auth/permissions"
            }),
            statusLogs(){
                // let logs = [];
                if(this.trackingDelivery?.logs){
                    return this.trackingDelivery.logs
                }
                // if(this.trackingDelivery?.statusLog){
                //     const array = Object.entries(this.trackingDelivery.statusLog).map(([key, value]) => ({ id: key, ...value, ...(value.time ? {createdAt:value.time} : {}) }));
                //     const sortedArray = array.sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt));
                    
                //     const startDate = moment(this.simpleRange[0]);
                //     const endDate = moment(this.simpleRange[1]);

                //     const filteredArray = sortedArray.filter(obj => {
                //         const createdAt = new Date(obj.createdAt);
                //         return createdAt >= startDate && createdAt <= endDate;
                //     });
                    
                //     logs = filteredArray;
                // }
                // return logs;
                return [];
            },
            packageDelivered(){
                if(this.trackingDelivery?.packageDelivered){
                    return this.trackingDelivery.packageDelivered;
                }
                return [];
            },
            stops(){
                if(this.trackingDelivery?.stops){
                    return this.trackingDelivery.stops;
                }
                return [];
            },
            locations(){
                if(this.trackingDelivery?.locations){
                    return this.trackingDelivery.locations;
                }
                return [];
            },
            trackDetails(){
                if(this.trackingDelivery?.details){
                    return this.trackingDelivery.details;
                }
                return null;
            },
        },
        methods: {
            onMapLoaded(e) {
                this.mapbox = Mapbox;
                const map = e.map;
                // Luego puedes llamar a updateVisibleMarkers en un evento de movimiento del mapa para actualizar los marcadores visibles
                map.on('moveend', () => {
                    if(this.showCustomers) this.updateVisibleMarkers();
                });
            },
            getDeliveries() {
                const deliveriesRef = db.ref(`deliveries`)
                deliveriesRef.once('value', (snapshot) => {
                    const deliveries = snapshot.val();
                    const deliveriesFromObj = Object.values(deliveries);
                    this.deliveries = deliveriesFromObj.filter(e=> e.name && e.lastName && e.coordinates);
                    this.localDeliveries = deliveriesFromObj.filter(e=> e.name && e.lastName && e.coordinates);
                })  
                if(this.search){
                    this.result = this.deliveries.filter(d => (`${d.code.toLowerCase()} ${d.name.toLowerCase()} ${d.lastName.toLowerCase()}`).includes(this.search.toLowerCase()));
                }
            },
            select(delivery) {
                this.map.flyTo({
                    center: [delivery.coordinates.longitude, delivery.coordinates.latitude],
                    zoom: 16,
                    essential: true,
                });
            },
            async trackDelivery() {
                const locationsRef = db.ref(`deliveryDetails/${this.trackingDelivery.code}/locations`);
                const statusRef = db.ref(`deliveryDetails/${this.trackingDelivery.code}/onRouteLog`);
                const packageDeliveredRef = db.ref(`deliveryDetails/${this.trackingDelivery.code}/packageDelivered`);
                try {
                    this.loading = true;
                    await locationsRef
                    .orderByChild('timestamp')
                        .startAt(moment(this.simpleRange[0]).format())
                        .endAt(moment(this.simpleRange[1]).format())
                        .once("value")
                        .then(async (snapshot) => {
                            const data = snapshot.val();
                            if(!data){
                                this.$notify.error({
                                    title: 'No data found',
                                    message: 'We did not find data in our systems, contact the administrator.',
                                });
                                return;
                            }
                            const locations = Object.entries(data || {}).map(([key, value]) => {
                                return { id: key, ...value };
                            });
                            const stops = locations.reduce((list, item, i) => {
                                let hasPackageWithStatus5 = false;
                                if(item.packages){
                                    hasPackageWithStatus5 = item.packages.some(p => p.statusID === 5);
                                }
                                const validateTime = (item.differenceMilliseconds == 0 && i != 0 && i != (locations.length - 1)) || item.differenceMilliseconds > 60000;
                                if ( validateTime && !hasPackageWithStatus5) {
                                    list.push({ ...item, index: i })
                                }
                                return list;
                            }, []);

                            // this.trackingDelivery['stops'] = stops;
        
                            let coordinates = [];
                            let distanceInMeters = 0;
                            let speedKilometerPerHour = 0;
                            locations.forEach(l => {
                                if (l.coordinates.longitude && l.coordinates.latitude) coordinates.push([l.coordinates.longitude, l.coordinates.latitude]);
                                if (l.distanceInMeters) distanceInMeters += l.distanceInMeters;
                                if (l.speedKilometerPerHour) speedKilometerPerHour += l.speedKilometerPerHour;
                            });
                            const obj = {
                                coordinates: coordinates,
                                type: 'LineString'
                            }
                            const details = {
                                start: coordinates[0],
                                finish: coordinates[coordinates.length - 1],
                                distanceInKilometres: distanceInMeters / 1000,
                                speedKilometerPerHour: speedKilometerPerHour / locations.length,
                                details: {
                                    start: {
                                        ...locations[0],
                                        // address: await this.getAddress(locations[0]?.latitude, locations[0]?.longitude)
                                    },
                                    finish: {
                                        ...locations[locations.length - 1],
                                        // address: await this.getAddress(locations[locations.length - 1]?.latitude, locations[locations.length - 1]?.longitude)
                                    },
                                }
                            }

                            this.$set(this.trackingDelivery, 'route', coordinates);
                            this.$set(this.trackingDelivery, 'locations', locations);
                            this.$set(this.trackingDelivery, 'details', details);
                            this.$set(this.trackingDelivery, 'stops', stops);

                            this.navigator = coordinates[0];

                            this.addRoute(obj)
                            if(locations.length > 0){
                                this.map.flyTo({
                                    center: [
                                        locations[0].coordinates.longitude, 
                                        locations[0].coordinates.latitude,
                                    ],
                                    zoom: 16,
                                    essential: true,
                                });
                            }
                        });
                        await statusRef.orderByChild('createdAt')
                        .startAt(moment(this.simpleRange[0]).format())
                        .endAt(moment(this.simpleRange[1]).format())
                        .once("value")
                        .then(async (snapshot) => {
                            const data = snapshot.val();
                            if(!data){
                                this.$notify.error({
                                    title: 'No data found',
                                    message: 'We did not find data in our systems, contact the administrator.',
                                });
                                return;
                            }
                            const logs = Object.entries(data || {}).map(([key, value]) => {
                                return { id: key, ...value };
                            });
                            this.$set(this.trackingDelivery, 'logs', logs);
                        });
                        await packageDeliveredRef.orderByChild('timestamp')
                        .startAt(moment(this.simpleRange[0]).format())
                        .endAt(moment(this.simpleRange[1]).format())
                        .once("value")
                        .then(async (snapshot) => {
                            const data = snapshot.val();
                            if(!data){
                                this.route = null;
                                this.$notify.error({
                                    title: 'No data found',
                                    message: 'We did not find data in our systems, contact the administrator.',
                                });
                                return;
                            }
                            const packageDelivered = Object.entries(data || {}).map(([key, value]) => {
                                return { id: key, ...value };
                            });
                            this.$set(this.trackingDelivery, 'packageDelivered', packageDelivered);
                        });
                } catch (error) {
                    console.error(error);
                    throw error;
                } finally {
                    this.loading = false;
                }
            },
            addRoute(coords) {

                try {
                    if (this.map.getSource(`route-${this.trackingDelivery.code}`)) {
                        this.map.removeLayer(`route-${this.trackingDelivery.code}`);
                        this.map.removeSource(`route-${this.trackingDelivery.code}`)
                    }
                    this.map.addLayer({
                        id: `route-${this.trackingDelivery.code}`,
                        type: "line",
                        source: {
                            type: "geojson",
                            data: { type: "Feature",properties: {}, geometry: coords }
                        },
                        layout: { "line-join": "round","line-cap": "round" },
                        paint: {
                            "line-color": "#65ddef",
                            "line-width": 5,
                            "line-opacity": 0.8
                        }
                    });
                } catch (error) {
                    console.error(error);
                }

            },
            setLiveRoute(coords) {
                const lastCoordinate = coords[coords.length - 1];
                let nextCoordinate = lastCoordinate;
                if (coords.length + 1 < this.trackingDelivery.route.length) {
                    nextCoordinate = this.trackingDelivery.route[coords.length + 1];
                }
                this.map.flyTo({
                    center: lastCoordinate,
                    speed: 0.8,
                });
                const bearing = this.calculateBearing(lastCoordinate, nextCoordinate);
                this.bearing = bearing;

                this.progress = (coords.length + 1) / (this.trackingDelivery.route.length - 1) * 100;

                if (this.map.getSource(`live-route-${this.trackingDelivery.code}`)) {
                    const routeSource = this.map.getSource(`live-route-${this.trackingDelivery.code}`);
                    routeSource.setData({
                    type: 'Feature',
                    properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: coords,
                        },
                    });
                    return;
                }
                this.map.addSource(`live-route-${this.trackingDelivery.code}`, {
                type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                        type: 'LineString',
                            coordinates: coords
                        },
                    },
                });
                this.map.addLayer({
                    id: `live-route-${this.trackingDelivery.code}`,
                    type: 'line',
                    source: `live-route-${this.trackingDelivery.code}`,
                    paint: {
                        'line-color': '#439eff',
                        'line-width': 4,
                    },
                });
            },
            startAnimation() {
                if (this.animation) {
                    clearInterval(this.animation);
                    this.animation = null;
                    return;
                }

                this.animation = setInterval(() => {
                    this.currentIndex++;
                    if (this.currentIndex < this.trackingDelivery.route.length) {
                        this.navigator = this.trackingDelivery.route[this.currentIndex];
                        this.setLiveRoute(this.trackingDelivery.route.slice(0, this.currentIndex))
                    } else {
                        clearInterval(this.animation);
                    }
                }, this.animationSpeed.value);
            },
            calculateBearing(start, end) {
                const startLat = start[1] * Math.PI / 180;
                const startLng = start[0] * Math.PI / 180;
                const endLat = end[1] * Math.PI / 180;
                const endLng = end[0] * Math.PI / 180;
            
                const dLng = endLng - startLng;
            
                const y = Math.sin(dLng) * Math.cos(endLat);
                const x = Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLng);
            
                const bearing = Math.atan2(y, x);
            
                return (bearing * 180 / Math.PI + 360) % 360;
            },
            pauseRoute() {
                clearInterval(this.animation);
                this.animation = null;
            },
            onProgressChange() {
                this.pauseRoute(); // Pausa la ruta cuando el usuario cambia el progreso
                const index = Math.round(this.progress / this.step);
                this.currentIndex = index;
            },
            setChange() {
                this.startAnimation();
            },
            back() {
                try {
                    if (this.map.getSource(`live-route-${this.trackingDelivery.code}`))
                        this.map.removeLayer(`live-route-${this.trackingDelivery.code}`);
                    if (this.map.getSource(`route-${this.trackingDelivery.code}`))
                        this.map.removeLayer(`route-${this.trackingDelivery.code}`);
                    this.trackingDelivery = null;
                    this.currentIndex = 0;
                    this.progress = 0;
                    this.navigator = null;
                    this.statusPosition =  null;
                    this.toogleRight = false;
                    this.pauseRoute();
                    
                } catch (error) {
                    console.error(error);
                }
            },
            elapsedTime(milliseconds) {
                const duration = moment.duration(milliseconds);
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                if (hours > 0) {
                    return `${hours}h ${minutes}m`;
                } else {
                    return `${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
                }
            },
            setFavorite(delivery) {
                const deliveryRef = db.ref(`deliveries/${delivery.code}`);
                deliveryRef.update({ favorite: !delivery.favorite })

                this.getDeliveries();
                
            },
            async getAddress(latitude, longitude) {
                try {
                    const address = await http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latitude + '&lon=' + longitude);
                    return address.data.display_name;
                } catch (error) {
                    throw error;
                }
            },
            async getStopDetails() {
                this.showStops = !this.showStops;
                try {
                    this.loadingStopsAddress = true;
                    let stopsWithAddress = [];
                    for (const stop of this.trackingDelivery.stops) {
                        const address = await this.getAddress(stop.coordinates.latitude, stop.coordinates.longitude);
                        stopsWithAddress.push({...stop, address});
                    }
                    this.$set(this.trackingDelivery, 'stops', stopsWithAddress);
                } catch (error) {
                    throw error;
                } finally {
                    this.loadingStopsAddress = false;
                }
            },
            editDelivery(d) {
                this.updateDeliveryDialog = true;
                this.updateDelivery = {...d}
            },
            setVehicleType(type) {
                this.updateDelivery.vehicleType = type;
            },
            updateData() {
                const deliveryRef = db.ref(`deliveries/${this.updateDelivery.code}`);
                deliveryRef.update(this.updateDelivery);
                this.getDeliveries();
                this.updateDeliveryDialog = false;
            },
            async showDetails(taskID) {
                try {
                    this.loadingDetails = true;
                    await this.$store.dispatch('delivery/getTaskDetails', taskID)
                    this.taskDetailsDialog = true;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loadingDetails = false;   
                }
            },
            getHour(data){
                const date = new Date(data);
                const hour = date.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });
                return hour
            },
            startCountUp() {
                if(this.countUp){
                    clearInterval(this.countUp);
                }
                const interval = setInterval(() => {
                    if(this.syncTime == this.count){
                        this.count = 0;
                        this.getDeliveries();
                    }
                    this.count++;
                    // You can perform other operations here based on the updated count

                }, 1000); // Interval of 1 second (1000 milliseconds)

                this.countUp = interval;
            },
            formatPercentage() {
                return `${this.count}s`;
            },
            showMapPin(payload){
                this.map.flyTo({
                    center: [payload.longitude, payload.latitude],
                    speed: 0.8,
                    zoom: 18
                });
                this.statusPosition = {
                    coordinates: [payload.longitude, payload.latitude],
                    active: payload.active
                };
            },
            async getPointsFromERP(){
                try {
                    this.loading = true
                    const {data} = await backend.get(`${process.env.VUE_APP_BACKEND_ERP}geography/map`);
                    this.customers = data.data;
                    this.customers.forEach(c => {
                        // console.log(c.geometry.coordinates[0]);
                        this.addMarker(c.properties.address_id,c.geometry.coordinates[0], c.geometry.coordinates[1], c.properties)
                    });
                } catch (e) {
                    console.log(e)
                }finally{
                    this.loading = false
                }
            },
            clickZoom(e) {
                var features = this.map.queryRenderedFeatures(e.mapboxEvent.point, {
                    layers: ['clusters']
                });
                var clusterId = features[0].properties.cluster_id;
                this.map.getSource('customers').getClusterExpansionZoom(
                    clusterId,
                    (err, zoom) => {
                        if (err) return;
                        this.map.easeTo({
                            center: features[0].geometry.payload,
                            zoom: zoom
                        });
                    }
                );
            },
            putPointer() {
                this.map.getCanvas().style.cursor = 'pointer';
            },
            removePointer() {
                this.map.getCanvas().style.cursor = '';
            },
            getMarkers(){
                this.unclusteredCustomers = []
                const clusters = this.map.queryRenderedFeatures({ layers: ["unclustered-point"] });
                clusters.forEach(item => {
                    let index = this.unclusteredCustomers.findIndex(index => index.properties.address_id == item.properties.address_id)
                    if(index == -1){
                    this.unclusteredCustomers.push(item)
                    }
                });
            },
            setMarkers(){
                const center = this.map.getCenter();
                // const maxDistance = 10000; // Máxima distancia en metros para mostrar un marcador
                // let markers = []; // Array para almacenar los marcadores

                if(!this.trackingDelivery?.packageDelivered) return;
                console.log(this.trackingDelivery?.packageDelivered)
                this.trackingDelivery.packageDelivered.forEach(p => {

                    // let el = document.createElement('div');
                    // el.className = 'marker';
                    // el.style.backgroundImage = 'url(https://img.uxwing.com/wp-content/themes/uxwing/download/location-travel-map/map-pin-icon.png)';
                    // el.style.width = '150px';
                    // el.style.height = '150px';
                    
                    // Check if a marker with the same id already exists
                    const index = this.markers.findIndex(m => m.id == p.id);
                    // console.log(index);
                    if(index != -1) return;
                    // for (let i = 0; i < markers.length; i++) {
                    //     console.log(markers[i].id, p.code)
                    //     if (markers[i].id == p.code) {
                    //         console.log("verdad que se hizo")
                    //         return;  // Don't add the marker if it already exists
                    //     }
                    // }
                    // Crea un nuevo marcador
                    const marker = new Mapbox.Marker()
                    .setLngLat([p.coordinates.longitude, p.coordinates.latitude])
                    // Assign the id to the marker
                    marker.id = p.id;
                    this.markers.push(marker);
                });

                console.log(this.markers);
                // Filtra los marcadores según la distancia al centro del mapa
                for (let marker of this.markers) {
                    let markerLatLng = marker.getLngLat();
                    let distance = center.distanceTo(markerLatLng);

                    if (distance < this.maxDistance) {
                        marker.addTo(this.map);
                    } else {
                        marker.remove();
                    }
                }

                this.removeMarkersOutsideView(this.markers);
            },
            removeMarkersOutsideView(markers) {
                let bounds = this.map.getBounds();
                for (let i = markers.length - 1; i >= 0; i--) {
                    let lngLat = markers[i].getLngLat();
                    if (!bounds.contains([lngLat.lng, lngLat.lat])) {
                        markers[i].remove();
                        markers.splice(i, 1);
                    }
                }
                console.log(markers.length)
            },

            testing(){
                const deliveryRef = db.ref(`deliveryDetails/10360`);
                deliveryRef.update({onRouteLog: "hola"});
            },

            // Función para agregar un marcador al mapa y al array
            addMarker(id, lng, lat, properties) {
                
                const htmlContent = `
                    <div>
                        <h6>${properties.first_name} ${properties.last_name}</h6>
                        <ul class="p-0">
                            <li> <b>${this.$t('ecommerce.code')}</b>  ${properties.code}</li>
                            <li> <b>ID</b>  ${properties.address_id}</li>
                            <li> <b>${this.$t('ecommerce.verified_buyer')}</b>  ${properties.verified}</li>
                        </ul>
                    </div>`;
                const popup = new Mapbox.Popup({ offset: 25 }) // crea un popup y establece el offset
                .setHTML(htmlContent); // establece el contenido HTML que se mostrará en el popup

                let el = document.createElement('div');
                el.className = 'marker';
                el.style.backgroundImage = `url(${require(`../assets/svg/${properties.verified ? 'active' : 'inactive'}.svg`)})`;
                const marker = new Mapbox.Marker({element: el})
                    .setLngLat([lng, lat])
                    .setPopup(popup) // agrega el popup al marcador
                    // .addTo(this.map);


                marker.id = id;
                this.markers.push(marker);

                // Al principio, todos los marcadores son no visibles
                this.nonVisibleMarkers.push(marker);
            },

            // Función para actualizar los marcadores visibles
            updateVisibleMarkers() {
                let bounds = this.map.getBounds();

                // Filtra los marcadores no visibles para encontrar los que están en la vista
                const newlyVisibleMarkers = this.nonVisibleMarkers.filter(marker => bounds.contains(marker.getLngLat()));

                // Agrega los marcadores recién visibles a la lista de marcadores visibles, hasta el límite
                for (let i = 0; i < newlyVisibleMarkers.length && this.visibleMarkers.length < this.maxVisibleMarkers; i++) {
                    const marker = newlyVisibleMarkers[i];
                    marker.addTo(this.map);
                    this.visibleMarkers.push(marker);
                    this.nonVisibleMarkers = this.nonVisibleMarkers.filter(m => m.id !== marker.id);
                }

                // Filtra los marcadores visibles para encontrar los que están fuera de la vista
                let newlyNonVisibleMarkers = this.visibleMarkers.filter(marker => !bounds.contains(marker.getLngLat()));

                // Elimina los marcadores recién no visibles de la lista de marcadores visibles y añádelos a los no visibles
                for (let i = 0; i < newlyNonVisibleMarkers.length; i++) {
                    let marker = newlyNonVisibleMarkers[i];
                    marker.remove();
                    this.visibleMarkers = this.visibleMarkers.filter(m => m.id !== marker.id);
                    this.nonVisibleMarkers.push(marker);
                }
            }
            // onShowRoute(){
            //     console.log(this.showRoute);
            //     if (!this.showRoute && this.map.getSource(`route-${this.trackingDelivery.code}`)) {
            //         this.map.removeLayer(`route-${this.trackingDelivery.code}`);
            //         this.map.removeSource(`route-${this.trackingDelivery.code}`)
            //     }
            // }
        },
        mounted() {
            const content = document.querySelector(".page-content");
            const container = document.querySelector(".container-fluid");
            content.classList.add('ps-0')
            content.classList.add('pe-0')
            container.classList.add('ps-0')
            container.classList.add('pe-0')

            this.startCountUp();
        },
        created() {
            this.getPermissions()
            this.$store.commit('layout/SET_IS_MAP', true);
            this.getDeliveries();
            this.getPointsFromERP();
        },
        beforeDestroy() {
            this.$store.commit('layout/SET_IS_MAP', false);
            const content = document.querySelector(".page-content");
            const container = document.querySelector(".container-fluid");
            content.classList.remove('ps-0')
            content.classList.remove('pe-0')
            container.classList.remove('ps-0')
            container.classList.remove('pe-0')

            if(this.countUp){
                clearInterval(this.countUp);
            }
        }
    }
</script>

<style scoped>
.side-bar{
    height: 86.25vh;
    background-color: #fff;
    position: absolute;
    left: -350px; /* Oculta el sidebar inicialmente */
    top: 0;
    width: 350px;
    padding: 1rem;
    transition: left 0.3s; /* Animación de apertura/cierre del sidebar */
    z-index: 3;
}
.map{
    height: 86.25vh;
}
.options i {
    fill: #ccc;
}
.options i:hover, .options > svg:hover{
    cursor: pointer;
    fill: var(--bs-primary) !important;
}
.empty-state{
    min-height: 40vh;
}
ul{
    max-height: 80vh;
    overflow-y: auto;
}
ul::-webkit-scrollbar{
    width: 5px;
}
ul::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
}
ul::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Color del thumb */
  border-radius: 10px; /* Bordes redondeados del thumb */
}
ul.deliveries li {
    border-radius: 6px;
    border: 1px solid #e1e4ee;
}
.deliveries:hover{
    background: #f6f8fc
}
/* MULTISELECT */
.multiselect::v-deep .multiselect__select:before{
    top: 55%;
}
.select-sync{
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 10px;  
    transition: left 0.3s;
}

.player{
    width: 400px;
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 10px;
    /* border-radius: 10px; */
    transition: left 0.3s;
}
.container-fluid{
    padding: 0;
}

.timeline-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #eee;
    outline: none;
    border-radius: 5px;
    transition: background 0.2s;
}

.timeline-slider:hover {
    background: #ccc;
}

.timeline-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: #3B7AFF;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.timeline-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #3B7AFF;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.timeline-slider::-ms-thumb {
    width: 15px;
    height: 15px;
    background: #3B7AFF;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.icon-toogle{
    transition: left 0.3s;
    left: 0;
    border-radius: 0px 8px 8px 0px;
    background-color: #fff;
    width: 15px;
    height: 50px;
    z-index: 1;
    top: 50%;
}
.icon-toogle-open-side-bar{
    left: 350px;
}
.open-side-bar{
    left: 0;
    overflow: auto;
}

.player-open-side-bar, .select-sync-open-side-bar{
    left: 360px;
}
.form-control::placeholder {
    font-size: 13px !important;
    font-weight: 100;
    color: #8b95ae;
}

.desc-address{
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    overflow: hidden;
    color: #9ba1b6;
}
.icon-container{
    height: 40px;
    width: 40px;
    flex-shrink: 0;
}
.mgl-map-wrapper::v-deep .mapboxgl-map .mapboxgl-popup {
    max-width: none !important;
}
.el-steps::v-deep .el-step__head.is-finish {
    color: #292929;
    border-color: #292929;
}
.el-steps::v-deep .el-step__icon.is-text {
    border: none;
}
.el-badge::v-deep .el-badge__content{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.el-progress::v-deep > .el-progress__text{
    font-size: 10px !important;
}

.loading-package-delivered{
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: absolute;
    top: -34px;
    left: 10px;
    overflow: hidden;
}

.status-log-container{
    position: absolute;
    z-index: 3;
    bottom: 10px;
    right: 10px;
}

.mgl-map-wrapper::v-deep .marker{
    width: 32px;
    height: 40px;
    background-repeat: no-repeat;
}
.icon-map{
        position: absolute;
        right: 7px;
        top: 22%;
        padding: 3px;
    }
.gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }
    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }
</style>
<template>
    <layout>
        <b-table :items="agents" :fields="fields" responsive hover striped thead-class="green-bg bg-primary text-white" class="mt-4">
            <template #cell(is_active)=data>
                <icon-check-circle v-if="data.item.is_active" :color="'#02a499'"/>
                <icon-danger-circle v-else :color="'#ec4561'"/>
            </template>
            <template #cell(is_available)=data>
                <icon-check-circle v-if="data.item.is_available" :color="'#02a499'"/>
                <icon-danger-circle v-else :color="'#ec4561'"/>
            </template>
            <template #cell(battery_level)=data>
                <div :class="[data.item.battery_level > 70 ? 'text-success' : data.item.battery_level > 30 ? 'text-warning' : 'text-danger']">
                    {{data.item.battery_level}} %
                </div>
            </template>
            <template #cell(actions)=data>
                <div>
                    <eva-icon name="pin" fill="#02a499" @click.native="seeLocation(data.item.longitude, data.item.latitude)"></eva-icon>
                </div>
            </template>
        </b-table>
        <mgl-map :center="center" :accessToken="accessToken" :zoom="7" :dragRotate="false" @load="onMapLoaded" :mapStyle="mapStyle" ref="agentsMap" style="height: 55vh">      
        <mgl-marker :coordinates="[agent.longitude, agent.latitude]" :draggable="false" color="#c40316" v-for="agent in agents" :key="agent.fleet_id">
            <!-- <div slot="marker"> -->
                <!-- <icon-delivery :size="40" :color="calculateTime(task)" :class="[calculateTime(task) == '#E53935'? 'blink' : '']" v-if="task.messenger_service_type_id == 1 && task.method_id != 3"/>
                <icon-bus :size="40" :color="calculateTime(task)" :class="[calculateTime(task) == '#E53935'? 'blink' : '']" v-else-if="task.messenger_service_type_id == 2 && task.method_id != 3"/>
                <icon-check-list :class="[calculateTime(task) == '#E53935'? 'blink' : '']" :color="calculateTime(task)" v-else-if="task.method_id == 3"/>
                <icon-delivery :size="40" :color="calculateTime(task)" :class="[calculateTime(task) == '#E53935'? 'blink' : '']" v-else/> -->
            <!-- </div> -->
            <div slot="marker">
                <svg width="30px" height="30px" viewBox="0 0 24 24" fill="#ec4561" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM14.248 9.75C14.248 10.9926 13.2416 12 12 12C10.7584 12 9.75197 10.9926 9.75197 9.75C9.75197 8.50736 10.7584 7.5 12 7.5C13.2416 7.5 14.248 8.50736 14.248 9.75ZM16.8057 16.9195C17.9737 15.7453 16.6528 14.25 14.9974 14.25H9.00262C7.34722 14.25 6.02632 15.7453 7.19428 16.9195C8.29702 18.0281 10.0569 18.75 12 18.75C13.9431 18.75 15.703 18.0281 16.8057 16.9195Z"/>
                </svg>
            </div>
            <mgl-popup>
                <div>
                    <h4>{{agent.name}}</h4>
                </div> 
            </mgl-popup>
        </mgl-marker>
            <mgl-geolocate-control :trackUserLocation="true" position="top-right" />
            <mgl-navigation-control position="top-right"/>
        </mgl-map>
    </layout>
</template>


<script>
import Layout from "@/modules/template/layouts/main";
import http from "axios"
import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup} from 'v-mapbox'
import Mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import IconCheckCircle from '../../../common/components/svg/IconCheckCircle.vue';
import IconDangerCircle from '../../../common/components/svg/IconDangerCircle.vue';

export default {
    name: "Agents",
    components: {Layout, MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup, IconCheckCircle, IconDangerCircle, },
    data() {
        return {
            agents: [],
            center: [-70.70238447994983, 19.45556310283655],
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/mundomovil28/ckgwp0y0g06r219pb8oqihafv',
            autocompleteOptions: {
                componentRestrictions: {country: ['do']},
            },
            coordinates: [-70.70238447994983, 19.45556310283655]
        }
    },
    computed:{
        fields(){
            return [
                {label: 'ID Tookan', key: 'fleet_id', sortable: true},
                {label: 'Nombre', key: 'name', sortable: true},
                {label: 'Activo', key: 'is_active', sortable: true},
                {label: 'Disponible', key: 'is_available', sortable: true},
                {label: 'Numero', key: 'phone', sortable: true},
                {label: 'Email', key: 'email', sortable: true},
                {label: 'Nivel de Bateria', key: 'battery_level', sortable: true},
                {label: 'Device', key: 'device_type', sortable: true},
                {label: 'Device', key: 'transport_type', sortable: true},
                {label: 'Actions', key: 'actions', sortable: true},

            ]
        },
    },
    methods:{
        async getAgents(){
            try {
                this.loading = true;
                const response = await http.post('https://api.tookanapp.com/v2/get_all_fleets',
                {
                    api_key: "556a6585f64a4e055d457967495b25401cedc1f92edf7d3b5a1c09",
                }   
                )
                this.agents = response.data.data
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false;
            }
        },
        seeLocation(long, lat){
            this.$refs.agentsMap.map.easeTo({
                center: [long, lat],
                zoom: 15
            });
        },
        async onMapLoaded(){
            this.mapbox = Mapbox;
        },
    },
    async created() {
        await this.getAgents()
    },
}
</script>
<style scoped>
    .table-responsive::v-deep > table{
        vertical-align: middle;
        text-align: center;
    }
</style>

<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 512 512">
        <path fill="#000" d="M288.3 13.4c-12.3-.01-23 6.49-27.3 15.31l-2.9 5.95-6.6-1.01c-5.5-.85-11.3-1.32-17.1-1.32-18.8 0-35.7 4.5-47.3 11.14-11.5 6.64-16.9 14.59-16.9 22 0 7.42 5.4 15.37 16.9 22 11.6 6.64 28.5 11.13 47.3 11.13 7.5-.02 14.9-.74 21.8-2.13l6.3-1.23 3.2 5.46c5.6 9.2 23.7 18.2 44.7 18.2 13.9 0 26.4-3.6 34.8-8.8 8.4-5.2 12.2-11.23 12.2-16.9 0-5.09-3-10.48-10-15.44-6.9-4.97-17.6-8.87-30-9.95l-18.3-1.59 12.5-13.49c4.1-4.41 6.1-9.6 6.1-14.87 0-12.88-12.4-24.46-29.4-24.46zM152.2 65.46C84.8 102.3 39 173.9 39 256c0 32.1 7 62.6 19.54 90 5.95-7.9 10.48-13.4 12.67-16C62.05 307.2 57 282.2 57 256c0-74 40.27-138.5 100.1-172.78-3.1-5.39-4.9-11.34-4.9-17.74v-.02zm210.7 1.75c7.5 6.93 12.5 15.9 12.5 25.99 0 1.14-.1 2.27-.2 3.38C423.7 132.9 455 190.7 455 256c0 23.7-4.2 46.5-11.8 67.6l18.3 2.2c7.4-21.9 11.5-45.4 11.5-69.8 0-80.8-44.4-151.5-110.1-188.79zm58.6 270.49c-11.1.2-23 1.1-35.9 3.1l-.5.1-.5.1c-25.8 7.3-37.1 15.2-46.4 24.7l-2.2 2.2-.3 3.1c-3.4 29.6-5.8 60 0 91.8l1.3 6.8 6.8.6c30.6 2.6 58.7 1.4 86.2 0l1-.1.9-.2c18.5-5.2 34.4-12.8 46.4-24.6l1.7-1.6.6-2.2c10-33.4 3.4-63.8.4-92.7l-.8-7.4-7.3-.7c-16.2-1.5-32.8-3.2-51.4-3zM79.62 348.2c-4.94 6.1-11.43 14.5-18.58 25.6-12.29 18.9-22.53 42.4-20.51 54.9 1.43 8.8 6.97 19.6 14.51 27.6 7.54 7.9 16.69 12.8 24.58 12.8 7.87 0 17.03-4.9 24.58-12.8 7.5-8 13.1-18.8 14.5-27.6 2-12.5-8.2-36-20.5-54.9-7.15-11-13.64-19.5-18.58-25.6zM427 355.7c1.7 0 3.3 0 5 .1 5.4.1 10.7.4 16 .8-5.9 3.4-12.1 6.8-19.5 9.9l-2.4.2c-19.5 1.4-37.7.3-55.4-2 4.7-2 10.5-4 18.3-6.2 13.8-2.2 26.3-2.9 38-2.8zm38 11.6c2.8 22.9 5 44.5-1 66.6-7 6.3-16 11.4-27 15.4 3-22.5 2-44.8-.5-66.6 11.3-5 20.2-10.2 28.5-15.4zm-112.2 13.1c20.7 3.2 42.3 5.4 65.8 4.5 2.5 23 3.5 45.6-.4 67.8-21.5 1-43.1 1.5-65.8-.1-3.5-24-2.2-47.9.4-72.2zm-216.4 34.7c.9 5.5 1 11 .1 16.4-.3 1.6-.6 3.3-1 4.9C170 459.5 211.4 473 256 473c21.6 0 42.5-3.2 62.3-9.1l-2-18.2c-19 6-39.3 9.3-60.3 9.3-44.9 0-86.3-14.8-119.6-39.9z"/>
    </icon-base>
</template>

<script>
export default {
name: 'IconTrack',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


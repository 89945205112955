<template>
    <layout>
        <b-row class="mt-5">
            <b-col cols="6">
                <correct-words @wordSelected="(data)=>{ wordSelected = data }" @wordSelectedNull="test()"/>
            </b-col>
            <b-col cols="6">
                <bad-words :wordSelected="wordSelected"/>
            </b-col>
        </b-row>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import CorrectWords from '../components/dictionary/CorrectWords.vue';
import BadWords from '../components/dictionary/BadWords.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'Dictionary',
    components:{
        Layout,
        CorrectWords,
        BadWords
    },
    computed: {
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        })
    },
    watch:{
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const dictionary = modules?.subModules?.find(data => data.name === 'Diccionario')
                // Verifica si se han cambiado los permisos de administrador
                if (!dictionary.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
        },
    },
    data(){
        return{
            wordSelected: null,
        }
    },
    methods: {
        test(){
            this.wordSelected = null
        }
    },
    created(){
        this.getPermissions()
    }
}
</script>

const Module = () => import("./Module.vue");
const Limitions = () => import("../ecommerce/view/Limitions.vue");
const Routes = () => import("./view/Routes.vue");

// import i18n from "../../i18n";

const localData = JSON.parse(localStorage.getItem('userAcess'));
const modules = localData?.modules?.find(data => data.name == 'Zonificacion')

const limitions = modules?.subModules?.find(data => data.name === 'Zonas de cobertura')
const route = modules?.subModules?.find(data => data.name === 'Rutas')

const moduleRoute = {
  path: "/zones",
  component: Module,
  name: "Delivery",
  // meta: { requiresAuth: true, role: '1' },
  children: [
    {
      path: "limits",
      name: "Limites",
      component: Limitions,
      meta: {
        icon: "icon-assing-task",
        search: limitions?.active,
        requiresAuth: true, 
        active: limitions?.active
      },
    },
    {
      path: "routes",
      name: "Routas",
      component: Routes,
      meta: {
        icon: "icon-assing-task",
        search: route?.active,
        requiresAuth: true, 
        active: route?.active
      },
    },
  ],
};


// // Middleware de navegación para verificar el nivel de acceso
// moduleRoute.beforeEach((to, from, next) => {
//   const isLoggedIn = true;
//   const userRole = '1'

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!isLoggedIn) {
//       // El usuario no está autenticado, redirigirlo a la página de inicio de sesión
//       next({ name: 'home' });
//     } else if (to.matched.some(record => record.meta.role !== userRole)) {
//       // El usuario no tiene el rol adecuado para acceder a esta ruta
//       next({ name: 'home' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default (router) => {
  router.addRoutes([moduleRoute]);
};

<template>
    <b-row>
        <b-row class="setting-manager-container mt-8">
            <b-col sm="12" xl="9" class="mx-auto">
                <div class="d-flex mb-3 justify-content-end gap-10">
                    <b-button @click="() => {showCreateTypeModal = true}" class="d-block" variant="primary">
                        <eva-icon name="plus-circle-outline" fill="white" width="20" class="me-1"/>
                        {{ $t('delivery.created_type') }}
                    </b-button>

                    <b-button @click="() => { showCreateActionModal = true }" class="d-block" variant="primary">
                        <eva-icon name="plus-circle-outline" fill="white" width="20" class="me-1"/>
                        {{ $t('delivery.create_action') }}
                    </b-button>
                </div>
                <b-overlay :show="loading">
                        <b-table striped hover :fields="fields" :items="records">
                            <template #cell(setting.fields)="fields"> <!-- A virtual composite column -->
                                <span v-for="(fieldset, index) in fields.item.setting.fields" :key="index">
                                    <vue-custom-tooltip :label="fieldset.field_type === 'text-field' ? 'Campo de texto' : fieldset.field_type === 'photo' ? 'Campo de fotos':fieldset.field_type === 'multiselect'? 'Selección múltiple': fieldset.field_type === 'validate-location' ? 'Validar ubicación':  'Firma'" position="is-top">
                                        <eva-icon fill="#5b626b" :name="fieldset.field_type === 'text-field' ? 'text':fieldset.field_type === 'photo' ? 'image-outline':fieldset.field_type === 'multiselect'? 'arrow-down': fieldset.field_type === 'validate-location' ? 'pin' : 'brush'" />
                                    </vue-custom-tooltip>
                                </span>
                            </template>
                            <!-- <template #cell(updated_at)="updated_at">
                                {{ format(updated_at.item.updated_at) }}
                            </template> -->
                            <template #cell(actions)="data">
                                <div class="d-flex justify-content-center">
                                    <vue-custom-tooltip :label="Object.keys(data.item.setting).length == 0 ? $t('delivery.add_setting') : $t('delivery.update_setting')">
                                        <b-button v-if="Object.keys(data.item.setting).length == 0" variant="primary" @click="selectRecord(data.item)" class="me-2">
                                            <eva-icon name="plus-outline" fill="white" />
                                        </b-button>
                                        <b-button v-else variant="primary" @click="selectRecord(data.item)" class="me-2">
                                            <eva-icon name="edit-2" fill="white" />
                                        </b-button>
                                    </vue-custom-tooltip>

                                    <vue-custom-tooltip v-if="Object.keys(data.item.setting).length > 0" :label="$t('delivery.delete_setting')">                                        
                                        <b-button class="me-2" variant="danger" @click="removeRecord(data.item.setting)">
                                            <eva-icon name="trash-2" fill="white" />
                                        </b-button>
                                    </vue-custom-tooltip>

                                    <vue-custom-tooltip :label="$t('delivery.assign_actions')">
                                        <b-button variant="primary" @click="showTypeActions(data.item)" class="me-2">
                                            <eva-icon name="flag-outline" fill="white" />
                                        </b-button>
                                    </vue-custom-tooltip>
                                    <vue-custom-tooltip :label="$t('delivery.assign_incident_types')">
                                        <b-button v-if="type == 'task-setting'" variant="primary" @click="showIncidentTypes(data.item)" class="me-2">
                                            <eva-icon name="alert-triangle-outline" fill="white" />
                                        </b-button>
                                    </vue-custom-tooltip>
                                </div>
                            </template>
                        </b-table>
                </b-overlay>
            </b-col>
        </b-row>
        <!-- <task-setting-modal ref="TaskSettingModal" @formCompleted="formCompleted" :updateRecord="recordSelected" @cleanRecordSelected="recordSelected = null"/> -->
        <setting-modal :type="type" ref="SettingModal" @formCompleted="formCompleted" :selectedRecord="selectedRecord" @cleanRecordSelected="selectedRecord = {}"/>
        <create-task-type-modal v-if="showCreateTypeModal && type == 'task-setting'" @closeModal="closeCreateTypeModal" @getRecords="getRecords" />
        <create-incident-type-modal v-if="showCreateTypeModal && type == 'incident-setting'" @closeModal="closeCreateTypeModal" @getRecords="getRecords" />
        <create-action-modal v-if="showCreateActionModal" @closeModal="() => { showCreateActionModal = false;}" />

        <show-type-actions v-if="showTypeActionsModal" :type="type" :typeData="selectedRecord" @closeModal = "() => {showTypeActionsModal = false;}" />
        <incident-types-modal v-if="showIncidentTypesModal" :type="type" :typeData="selectedRecord" @closeModal="() => {showIncidentTypesModal = false;}"></incident-types-modal>
    </b-row>
</template>

<script>
import { http as backend } from "@/common/services/web-backend.js";
// import TaskSettingModal from "../tasks/modals/TaskSettingModal.vue";
import SettingModal from "../tasks/modals/SettingModal.vue"
import CreateTaskTypeModal from "../tasks/modals/CreateTaskTypeModal.vue"
import CreateIncidentTypeModal from "./modals/CreateIncidentTypeModal.vue";
import CreateActionModal from "./modals/CreateActionModal.vue";
import ShowTypeActions from "./modals/ShowTypeActions.vue";
import IncidentTypesModal from "./modals/IncidentTypesModal.vue";
import { mapGetters } from 'vuex';

export default {
    components: { SettingModal, CreateTaskTypeModal, CreateIncidentTypeModal, ShowTypeActions, CreateActionModal, IncidentTypesModal },
    name: "SettingManager",
    props: {
        type: {
            type: String,
            required: true
        }
    },
    watch:{
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Delivery')
            const configTask = modules?.subModules?.find(data => data.name === 'Config. de tarea')
            // Verifica si se han cambiado los permisos de administrador
            if (!configTask.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    },
    computed:{
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        })
    },
    data() {
        return {
            fields: [
                // { key: "name", label: "Nombre de la incidencia" },
                { key: "id", label: "id" },
                { key: "description", label: this.$t('delivery.type')},
                { key: "setting.fields", label: this.$t('delivery.field') },
                // { key: "updated_at", label: "Actualizado última vez" },
                { key: "actions", label: "" }, // A virtual column made up from two fields
            ],
            selectedRecord: {},
            records: [],
            typeActions: [],
            loading: false,
            typeActionsModalLoading: false,
            showCreateTypeModal: false,
            showTypeActionsModal: false,
            showCreateActionModal: false,
            showIncidentTypesModal: false,
        };
    },
    methods: {
        closeCreateTypeModal() {
            this.showCreateTypeModal = false
        },
        format(val) {
            const date = new Date(val).toLocaleDateString("es-ES", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            });
            return date.charAt(0).toUpperCase() + date.slice(1).toLowerCase();
        },
        cl(value) {
            console.log(value);
        },
        openModal() {
            this.$refs.SettingModal.openModal();
        },
        async getRecords() {
            this.loading = true;
            //   let req = await backend.get("incidents");
            let req;

            switch (this.type) {
                case 'task-setting':
                    // req = await backend.get("task-settings");
                    req = await backend.get("task-types");
                    break;
                case "incident-setting":
                    req = await backend.get("incidents/types");
                    break;
            }
            // let req = await backend.get("task-settings");
            this.records = req.data.data;
            
            this.loading = false;
        },
        selectRecord(record) {
            // console.log(record);
            this.selectedRecord = record;
            this.openModal();
        },
        showTypeActions(record) {
            this.selectedRecord = record;
            this.showTypeActionsModal = true;
        },
        showIncidentTypes(record) {
            this.selectedRecord = record;
            this.showIncidentTypesModal = true;
        },
        formCompleted() {
            this.selectedRecord = {};
            this.getRecords();
        },
        /**
         * Remove a setting from a type
         * @param {*} setting 
         */
        async removeRecord(setting) {
            if (!Object.keys(setting).length > 0 || !setting.id) {
                return;
            }

            const settingId = setting.id;

            this.$swal({
                title: this.$t('delivery.question_delete_task_setting'),
                confirmButtonText: this.$t('delivery.yes'),
                denyButtonText: this.$t('delivery.no'),
                showDenyButton: true,
                customClass: {
                    title: "modal-title",
                    cancelButton: "order-1 right-gap",
                    confirmButton: "order-2",
                    denyButton: "order-3",
                },
                }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    switch (this.type) {
                        case 'task-setting':
                            await backend.delete("task-settings/" + settingId);
                            break;
                        case "incident-setting":
                            await backend.delete("incident-settings/" + settingId);
                            break;
                        default: 
                            await backend.delete("task-settings/" + settingId);
                            break;
                    }
                    await this.getRecords();
                    this.loading = false;
                }
            });
        },
    },
    created() {
        this.getPermissions()
        this.getRecords();
    },
};
</script>

<style scoped>
    .gap-10 {
        gap: 10px;
    }
    .setting-manager-container {
        margin-top: 20px;
    }
    .b-table::v-deep thead {
        background-color: #323547;
        color: white;
    }
    .b-table::v-deep t-body {
        background-color: white;
    }
    .modal-mask::v-deep .modal-container {
        width: 980px;
        /* height: 880px; */
    }
</style>
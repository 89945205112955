<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 24 24" >
        <g>
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-6h2v6z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconHome',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


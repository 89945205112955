<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 24 24">
        <g id="exclamation-circle-Filled">
            <path id="exclamation-circle-Filled-2" data-name="exclamation-circle-Filled" class="cls-1" d="M12,2A10,10,0,1,0,22,12,10.016,10.016,0,0,0,12,2Zm0,15a1,1,0,1,1,1-1A1,1,0,0,1,12,17Zm1-5a1,1,0,0,1-2,0V8a1,1,0,0,1,2,0Z"/>
        </g>
    </icon-base>
</template>

<script>
import IconBase from './IconBase.vue'
export default {
    components: { IconBase },
    name: "IconDangerCircle",
    props:{
        size:{
            type: Number,
            default: 20
        },
        color:{
            type: String,
        }
    }
}
</script>

<template>
    <icon-base viewBox="0 0 512 512" :size="size" :fill="color">
        <g>
            <g>
                <path d="M269.55,33.07h-31.626c-4.716-17.625-20.038-31.25-39.266-32.903c-24.536-2.109-46.137,16.071-48.247,40.607
                    c-2.109,24.536,16.072,46.137,40.608,48.247c21.887,1.881,41.424-12.386,46.889-32.903h31.642c6.364,0,11.524-5.159,11.524-11.524
                    S275.914,33.07,269.55,33.07z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M410.765,127.275h-40.379c0,6.562,0,59.448,0,71.587l-8.564-8.564l-8.564,8.564l-8.564-8.564l-8.564,8.564l-8.564-8.564
                    l-8.564,8.564c0-7.154,0-61.942,0-71.587h-40.379c-8.91,0-16.133,7.224-16.133,16.133v49.474l28.877,7.207
                    c20.056,5.006,32.3,25.395,27.295,45.451c-5.011,20.078-25.378,32.307-45.451,27.295l-10.72-2.675v5.392
                    c0,8.91,7.224,16.133,16.133,16.133h132.142c8.91,0,16.133-7.224,16.133-16.133V143.41
                    C426.898,134.499,419.676,127.275,410.765,127.275z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M287.181,216.863l-67.803-16.922l-22.963-64.518l35.762,49.897l3.957-46.027c1.425-16.575-10.857-31.167-27.432-32.592
                    l-53.521-4.601c-16.575-1.425-31.167,10.857-32.592,27.432c-12.487,162.255-11.934,153.927-11.76,155.039l14.473,92.19
                    L86.667,479.195c-4.725,12.527,1.601,26.514,14.128,31.239c12.529,4.724,26.514-1.602,31.239-14.128l40.894-108.425
                    c1.481-3.925,1.917-8.17,1.266-12.315c-2.788-17.755-10.934-69.65-13.762-87.663l12.786,1.099l38.682,85.594l26.948,118.528
                    c2.968,13.053,15.957,21.234,29.016,18.265c13.056-2.969,21.232-15.959,18.265-29.016l-27.49-120.91
                    c-0.36-1.584-0.879-3.129-1.548-4.61l-33.323-73.736l1.904-22.147l-30.775-7.681c-16.633-4.151-26.13-18.696-27.135-27.679
                    l-8.178-73.166l25.357,71.246c2.349,6.597,7.84,11.255,14.141,12.828l78.313,19.545c10.885,2.717,21.813-3.964,24.494-14.709
                    C304.586,230.557,298.038,219.572,287.181,216.863z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
import IconBase from "../../../../common/components/svg/IconBase.vue"

export default {
    name: "IconDeliveryMainGreen",
    components: { IconBase },
    props:{
        color:{
            type: String
        },
        size:{
            type: Number,
            default: 20
        }
    }
}
</script>

<template>
    <div class="modal-mask" id="modal-mask" @click="close" :class="[close ? 'closed-container' : '']">
        <div class="modal-container">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h4 class="mb-0">{{$t(title)}}</h4> <i class="fas fa-times fs-4 cursor-pointer hover-main-gray" @click="$emit('closeModal')"></i>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainModal",
    props: {
        title: {
            type: String
        }
    },
    methods:{
        close: function (event) {
            if(event.target.id === "modal-mask"){
                this.$emit('closeModal')
                return
            }
        },
    }
}
</script>

<style scoped>
    .modal-mask{
        background: #2f35444d;
        position: fixed;
        left: 0px;
        top: 0px;
        /*3000*/
        z-index: 1002;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: open-modal-container .3s;
    }
    .modal-container{
        background: white;
        border-radius: 20px;
        padding: 20px;
        min-width: 300px;
        /* min-height: 300px; */
        margin: 30px;
        max-width: 1600px;
    }
    @keyframes open-modal-container {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
</style>

<template>
    <div class="row">
    <div class="col-sm-8">
        <!-- <div class="form-group-feedback form-group-feedback-left mb-2">
            <input type="text" v-model="search" class="form-control form-control-lg alpha-grey" placeholder="Search" />
            <div class="form-control-feedback form-control-feedback-lg">
                <i class="icon-search4 text-muted"></i>
            </div>
        </div> -->
        <div style="max-height: 500px; overflow-x: auto">
        <div class="row">
            <div class="col-sm-4 text-center">
            <h3>{{ $t('ecommerce.category') }}</h3>
            </div>
            <div class="col-sm-4 text-center">
            <h3>{{$t('ecommerce.min')}}</h3>
            </div>
            <div class="col-sm-4 text-center">
            <h3>{{ $t('ecommerce.max') }}</h3>
            </div>
        </div>
        <div v-for="category in categories" style="margin: 0; padding: 5px" :key="category.id">
            <div class="row">
            <div class="col-sm-4">
                {{ category.description }}
            </div>
            <div class="col-sm-4">
                <input value="0" min="0" required type="number" @input="change(category)" v-model="category.limit" class="form-control font-weight-semibold" />
            </div>
            <div class="col-sm-4">
                <input value="0" min="0" required type="number" @input="change(category)" v-model="category.max" class="form-control font-weight-semibold" />
            </div>
            </div>
        </div>
        </div>
    </div>
    <div class="col-sm-4">
        <h2>{{ $t('ecommerce.subcategory') }}</h2>
        <b-list-group>
            <b-list-group-item style="width: 95%;">
                <b-form-checkbox id="local-courier" v-model="minPercentage" name="local-courier"> 
                    {{ $t('ecommerce.min_how') }}
                </b-form-checkbox>
            </b-list-group-item>
            <b-list-group-item style="width: 95%;">
                <b-form-checkbox id="local-courier" v-model="maxPercentage" name="local-courier"> 
                    {{ $t('ecommerce.max_how') }}
                </b-form-checkbox>
            </b-list-group-item>
        </b-list-group>
    </div>
    </div>
</template>

<script>
export default {
    name: "Categories",
    data() {
        return {
            items: [],
            minPercentage: false,
            maxPercentage: false,
            // search: "",

        }
    },
    props:{
        categories:{
            type: Array
        }
    },
    created(){
        for (let index = 0; index < this.categories.length; index++) {
            const element = this.categories[index];
            this.items.push({
                description: element.description,
                min: element.limit,
                max: element.max
            })
        }
    }
}
</script>

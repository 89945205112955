<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 569.848 569.848" >
        <g>
            <path d="M517.79,0H259.354c-21.238,0-38.422,17.212-38.422,38.441v64.977h-20.712c-5.288,0-9.562,4.284-9.562,9.562v7.593
                c-9.648,3.614-19.374,8.893-27.607,16.696c-14.277,13.531-21.525,31.891-21.525,54.602c0,17.604,3.108,42.61,6.713,71.566
                c2.754,22.146,5.795,46.742,7.812,71.355c-0.411,3.623-8.616,61.41-98.245,60.32c-1.721-10.738-7.028-18.637-13.397-18.637
                c-3.318,0-6.321,2.217-8.731,5.775c-1.683-0.564-3.442-0.947-5.316-0.947c-9.247,0-16.734,7.496-16.734,16.744v8.846
                c0,9.246,7.487,16.744,16.734,16.744c1.865,0,3.634-0.383,5.316-0.947c2.41,3.557,5.413,5.775,8.731,5.775
                c6.416,0,11.762-8.014,13.445-18.895c0.918,0.008,1.874,0.057,2.783,0.057c56.552,0,84.044-22.137,97.375-42.229
                c1.578,46.092-2.773,88.406-22.434,110.199c-7.861,8.711-17.873,13.463-30.141,14.859c-2.305-8.51-7.009-14.4-12.518-14.4
                c-3.318,0-6.321,2.219-8.73,5.775c-1.683-0.564-3.442-0.947-5.317-0.947c-9.247,0-16.734,7.498-16.734,16.744v8.846
                c0,9.246,7.488,16.744,16.734,16.744c1.865,0,3.634-0.383,5.317-0.947c2.41,3.557,5.413,5.775,8.73,5.775
                c7.239,0,13.12-10.154,13.885-23.17c15.931-1.777,29.194-8.338,39.417-19.68c27.77-30.791,29.309-91.035,24.241-152.637l0.125-1.51
                l-0.239-0.02c-2.046-24.309-5.049-48.788-7.908-71.738c-3.519-28.353-6.56-52.833-6.56-69.453c0-18.58,5.728-33.44,17.002-44.16
                c6.512-6.177,14.315-10.356,22.175-13.378c1.511,0.995,3.213,1.722,5.164,1.722h20.712v395.381
                c0,21.238,17.184,38.439,38.422,38.439H517.78c21.229,0,38.441-17.201,38.441-38.439V38.441C556.222,17.203,539.019,0,517.79,0z
                M387.94,546.383c-13.379,0-24.203-10.816-24.203-24.184c0-13.359,10.824-24.184,24.203-24.184
                c13.32,0,24.184,10.824,24.184,24.184C412.124,535.566,401.261,546.383,387.94,546.383z M504.345,453.512
                c0,5.842-4.742,10.586-10.586,10.586H283.384c-5.842,0-10.576-4.744-10.576-10.586V73.995c0-5.843,4.733-10.576,10.576-10.576
                h210.375c5.844,0,10.586,4.733,10.586,10.576V453.512z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconProducts',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


<template>
    <div>
        <h4 class="mt-4">{{$t('delivery.maintenance_of_states')}}</h4>
        <b-row>
            <b-col xl="3" lg="4" md="5">
                <b-table sticky-header responsive thead-class="green-bg bg-primary text-white" class="bg-white text-center" striped hover :items="status" 
                selectable select-mode="single" :fields="fields" @row-selected="onRowSelected" selected-variant="success" tbody-class="cursor-pointer align-middle">
                    <template #cell(color)="data">
                        <div class="d-flex justify-content-center">
                            <div class="container-color" :style="{backgroundColor: data.item.background_color}"></div>
                        </div>
                    </template>
                </b-table>
            </b-col>
            <b-col class="d-flex flex-column justify-content-between" xl="4" lg="5" md="6" v-if="form.id">
                <div>
                    <h5 v-if="form.description">{{form.id}} - {{form.description}}</h5>
                    <div class="d-flex align-items-end">
                        <div class="me-3 w-100">
                            <label for="description">Description</label>
                            <b-form-input v-model="form.description" placeholder="Enter your name"></b-form-input>
                        </div>
                        <color-picker v-model="form.background_color" :position="{right: 0, top: '40px'}" class="mb-1"></color-picker>
                    </div>
                </div>
                <div class="d-flex">
                    <b-button class="me-3 ms-auto"  variant="primary" @click="save"> {{$t('common.save')}} <eva-icon name="save" fill="#fff" width="20"></eva-icon></b-button>
                    <b-button variant="danger" class="ml-1" @click="cancel">{{$t('common.cancel')}}<eva-icon name="alert-circle" fill="#fff" width="20"></eva-icon></b-button>   
                </div>
            </b-col>
            <b-col xl="12">
                <!-- <time-line2 :status="cashActions" v-if="cashActions.length > 0" @delete="deleteToCash" :groupName="'cash-actions'" :key="cashComponent"/> -->
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { http } from "@/common/services/web-backend.js";

    export default {
        name: "StateMaintenance",
        data(){
            return{
                form:{
                    description: '',
                    background_color: '',
                    id: null
                }
            }
        },
        computed:{
            ...mapGetters({
                status: "delivery/status"
            }),
            fields(){
                return [
                    {label: 'ID', key: 'id', sortable: true},
                    {label: 'Description', key: 'description', sortable: false},
                    {label: 'Color', key: 'color', sortable: false},
                ]
            },
        },
        methods:{
            onRowSelected(item){
                if(item.length > 0){
                    this.form.description = item[0].description
                    this.form.background_color = item[0].background_color
                    this.form.id = item[0].id
                }else{
                    this.form = {
                        description: '',
                        color: '',
                        id: null
                    }
                }
            },
            async save(){
                try {
                    this.$swal.fire({ 
                        title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                        icon: "info", showCancelButton: true, confirmButtonText: 'Confirmar', 
                        cancelButtonText: 'Cancelar',
                    }).then(async (result) => {
                        if(result.isConfirmed){
                            await http.put(`/task/status/${this.form.id}`, this.form)
                            await this.$store.dispatch('delivery/getTaskStatus')
                            this.cancel()
                        }
                    })
                } catch (error) {
                    console.log(error);
                }
            },
            cancel(){
                this.form = {
                    description: '',
                    color: '',
                    id: null
                }
            }
        }
    }
</script>

<style scoped>
    .container-color{
        width: 30px;
        height: 30px;
    }
</style>

<template>
    <form @submit.prevent="update" autocomplete="off" >
        <b-form-input v-model="description" placeholder="Sucursal" class="mb-3"></b-form-input>
        <b-form-input v-model="address" placeholder="Direccion" class="mb-3"></b-form-input>
        <b-button variant="primary" class="me-3" type="submit">Actualizar</b-button>
        <b-button variant="danger" @click="$emit('cancel')">Cancelar</b-button>
    </form>
</template>

<script>
    export default {
        name: "StoreForm",
        props: {
            descripcion: {
                type: String,
                required: true,
            },
            direccion:{
                type: String,
                required: true,
            }
        },
        watch: {
            descripcion: {
                immediate: true,
                handler(val){
                    this.description = val;
                }
            },
            direccion: {
                immediate: true,
                handler(val){
                    this.address = val;
                }
            }
        },

        data(){
            return{
                address: "",
                description: "",
                btnLabel: 'Actualizar'
            }
        },

        methods: {
            update(){
                const address = this.address;
                const description= this.description;
                this.$emit('update', { address,  description})
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <b-row>
        <b-col>
            <div class="d-flex flex-column align-items-start ms-3 pb-3">
                <h6 class="title-22 color-blue-700 mb-1">{{ $t('ecommerce.veltrix_settings') }}</h6>
                <h6 class="subtitle-16 mb-0">{{ $t('ecommerce.veltrix_settings_description') }}</h6>
            </div>
            
            <el-collapse class="w-75" style="margin-left: 15px;">
                <el-collapse-item title="Tasks" name="collapse-1">

                    <b-row :title="values.tasks.validate_task_assignation.description">
                        <b-col cols="3">
                            <b-form-checkbox 
                                id ="checkbox-validate-task-assignation"  
                                name="checkbox-validate-task-assignation"  
                                v-model="values.tasks.validate_task_assignation.value"
                                >
                                {{ $t('ecommerce.validate_task_assignation') }}
                                </b-form-checkbox>
                            </b-col>

                        <b-col cols="6">
                            <b-row>
                                <b-col cols="3">
                                    <label :title="values.tasks.days_without_completion.description">{{ $t('ecommerce.days_without_completion') }}</label>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-input v-model="values.tasks.days_without_completion.value" :title="values.tasks.days_without_completion.description"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row :title="values.tasks.validate_in_curso_task_status.description">
                        <b-form-checkbox 
                            id="checkbox-validate-in-curso-task-status"  
                            name="checkbox-validate-in-curso-task-status"  
                            v-model="values.tasks.validate_in_curso_task_status.value"
                        >
                            {{ $t('ecommerce.validate_in_curso_task_status') }}
                        </b-form-checkbox>
                    </b-row>

                    <b-row :title="values.tasks.validate_llegue_task_status.description" class="mt-2">
                        <b-form-checkbox 
                            id="checkbox-validate-llegue-task-status"  
                            name="checkbox-validate-llegue-task-status"  
                            v-model="values.tasks.validate_llegue_task_status.value"
                        >
                            {{ $t('ecommerce.validate_llegue_task_status') }}
                        </b-form-checkbox>
                    </b-row>

                    <b-row :title="values.tasks.validate_punch_location.description" class="mt-2">
                        <b-form-checkbox
                            id="checkbox-validate-punch-location"
                            name="checkbox-validate-punch-location"
                            v-model="values.tasks.validate_punch_location.value"
                        >
                        {{ $t('ecommerce.validate_punch_location') }}
                        </b-form-checkbox>
                    </b-row>

                    <!-- <b-row :title="values.tasks.validate_llegue_task_status.description">
                        <b-form-checkbox 
                            id="checkbox-validate-llegue-task-status"
                            name="checkbox-validate-llegue-task-status"
                            v-model="value.tasks.validate_llegue_task_status.value"
                        >
                            {{ $t('ecommerce.validate_llegue_task_status') }}
                        </b-form-checkbox>
                    </b-row> -->
                </el-collapse-item>

                <el-collapse-item title="Punchs" name="collapse-2">
                    <b-row :title="values.punchs.validate_location.description">
                        <b-form-checkbox 
                            id="checkbox-punchs-validate-location"  
                            name="checkbox-punchs-validate-location"  
                            v-model="values.punchs.validate_location.value"
                        >
                            validate_location
                        </b-form-checkbox>
                    </b-row>
                </el-collapse-item>

                <el-collapse-item title="Routes" name="collapse-3">
                    <b-row :title="values.punchs.validate_location.description">
                        <b-form-checkbox 
                            id="checkbox-routes-validate-filter"  
                            name="checkbox-routes-validate-filter"  
                            v-model="values.routes.filter_customers_using_intranet_api.value"
                        >
                            Filter customers using Intranet athenas api in routes?
                        </b-form-checkbox>
                    </b-row>
                </el-collapse-item>
            </el-collapse>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "VeltrixSettings",
        props: {
            settings: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                values: {}
            }
        },
        created() {
            this.values = this.settings
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <layout>
        <h1 class="mt-3">Products</h1>
        <b-row class="mb-4">
            <b-col lg="5">
                <label for="search-by-description">{{$t('ecommerce.search_by_description')}}</label>
                <b-form-input v-model="filters.q" debounce="1000" :placeholder="$t('ecommerce.search_category')"></b-form-input>
            </b-col>
            <b-col lg="2">
                <label for="search-by-description">{{$t('ecommerce.search_by_code')}}</label>
                <b-form-input v-model="code" :placeholder="$t('ecommerce.search_code')" @keypress.enter="getProductByCode"></b-form-input>
            </b-col>
            <b-col>
                <label for="search-by-description">{{$t('ecommerce.filter_by_category')}}</label>
                <div>
                    <b-form-select v-model="filters.category" :options="getCategories" class="form-control"></b-form-select>
                </div>
            </b-col>
            <b-col>
                <label for="search-by-description">{{$t('ecommerce.filter_by_price')}}</label>
                <vue-slider v-model="filters.prices" :min="0" :max="10000" :height="10" :enable-cross="false" :lazy="true"/>
            </b-col>
        </b-row>
        <div>
            <b-table :items="products" responsive striped thead-class="bg-primary text-white" details-td-class="bg-primary" :fields="fields" class="bg-white" :busy="loading">
                <template #table-busy>
                    <div class="skeleton" v-for="item in 15" :key="item"></div>
                </template>
                <template #cell(image)=data>
                    <div class="img-container">
                        <img v-lazy="data.item.imageGallery[0].picture_url" :alt="data.item.imageGallery[0].picture" width="50" height="50" class="img-product">
                    </div>
                </template>
                <template #cell(description)=data>
                    <h6 class="text-start">
                        {{data.item.description | textFormat}}
                    </h6>
                </template>
                <template #cell(category)=data>
                    {{data.item.subcategory.sub_category_name | textFormat}}
                </template>
                <template #cell(brand)=data>
                    {{data.item.brand | textFormat}}
                </template>
                <template #cell(price)=data>
                    <b>
                        {{data.item.price | moneyFormat}}
                    </b>
                </template>
                <template #cell(actions)=data>
                    <b-button variant="primary" size="sm" class="me-2" @click.prevent="selectProduct = data.item; showReviews = true">{{ $t('ecommerce.reviews') }}({{data.item.reviews.length}})</b-button>
                    <b-button variant="info" size="sm" class="me-2" @click.prevent="selectProduct = data.item; showQuestions = true">{{ $t('ecommerce.questions') }}({{data.item.questions.length}})</b-button>
                    <b-button variant="success" size="sm">{{ $t('ecommerce.details') }}</b-button>
                </template>
            </b-table>
            <!-- <vue-element-loading :active="loading" :is-full-screen="false"/> -->
        </div>
        <main-modal v-if="showReviews" @closeModal="showReviews = false">
            <reviews :product="selectProduct"/>
        </main-modal>
        <main-modal v-if="showQuestions" @closeModal="showQuestions = false">
            <questions :product="selectProduct"/>
        </main-modal>
        <b-pagination v-model="filters.page" :total-rows="totalPages" :per-page="perPage" class="justify-content-center mt-4 mb-4"></b-pagination>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { ProductsService} from "../service/products-service";
const productService = new ProductsService();
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import MainModal from '../../../common/components/modals/MainModal.vue';
import Reviews from '../components/products/Reviews.vue';
import Questions from '../components/products/Questions.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'Products',
    components:{
        Layout,
        VueSlider,
        MainModal,
        Reviews,
        Questions
    },
    data() {
        return {
            products: [],
            filters: {
                prices: [0, 100000],
                page: 1,
                category: null,
                place: 1,
                q: ''
            },
            totalPages: 0,
            perPage: 0,
            code: '',
            categories:[],
            loading: false,
            selectProduct: null,
            showReviews: false,
            showQuestions: false
        }
    },
    watch:{
        filters:{
            deep: true,
            async handler(){
                await this.getProducts()
            }
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const products = modules?.subModules?.find(data => data.name === 'Productos')
            // Verifica si se han cambiado los permisos de administrador
            if (!products.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    }
    ,
    computed:{
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        fields(){
            return [
                {label: this.$t('ecommerce.image'), key: 'image', sortable: true},
                {label: this.$t('ecommerce.description'), key: 'description', sortable: true},
                {label: this.$t('ecommerce.category'), key: 'category', sortable: true},
                {label: this.$t('ecommerce.brand'), key: 'brand', sortable: true},
                {label: this.$t('ecommerce.quantity'), key: 'existence', sortable: true},
                {label: this.$t('ecommerce.price'), key: 'price', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions'},
            ]
        },
        getCategories(){
            let obj = [{value: null, text: this.$t('ecommerce.select_option')}]
            this.categories.map((category)=>{
                obj.push({value: category.codcategory, text: category.description})
            })
            return obj
        }
    },
    methods:{
        async getProductByCode(){
            if(this.code){
                try {
                    const response = await productService.getProductCode(this.code)
                    this.products = [response.data.data.product]
                } catch (error) {
                    console.log(error);
                }
            }else{
                await this.getProducts()
            }
        },
        async getProducts(){
            try {
                this.loading = true
                const response = await productService.getProducts(this.filters)
                this.products = response.docs
                this.totalPages = response.totalPages
                this.perPage = response.limit
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        },
    },
    async created(){
        this.getPermissions()
        try {

            // // testing
            await this.getProducts()
            // <-------------->
            const categories = await productService.getCategories()
            this.categories = categories

        } catch (error) {
            console.log(error);
        }
    }
}
</script>

<style scoped>
.img-product{
    border: 1px solid #f8f9fa;
    border-radius: 5px;
}
.img-container{
    max-width: 50px;
    max-height: 50px;
}
.table-responsive::v-deep > table{
    vertical-align: middle;
}
.table-responsive::v-deep > table{
    text-align: center;
}
.table-responsive::v-deep > table > tbody > tr > td:nth-child(2){
    max-width: 300px;
}
</style>

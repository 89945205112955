<template>
    <main-modal @closeModal="$emit('closeLightBox')">
        <h5 class="mb-4 title-20">{{$t('ecommerce.image_gallery')}} ({{images.length}})</h5>
        <div class="d-flex">
            <swiper :options="swiperOption" class="swiper-product-list me-4">
                <swiper-slide :key="path_s3+index" v-for="({path_s3}, index) in images" @mouseenter.native="img = path_s3">
                    <img v-lazy="path_s3" class="img-product-details loaded" :class="[path_s3 === img ? 'active' :'']"/>
                </swiper-slide>
            </swiper>
            <div class="container-img-primary">
                <img v-lazy="img" :key="img"/>
            </div>
        </div>
    </main-modal>
</template>

<script>
import MainModal from './MainModal.vue'
export default {
    components: { MainModal },
    name: "LightBox",
    props:{
        active:{
            type:String
        },
        images:{
            type: Array
        },
    },
    data() {
        return {
            img:"",
            swiperOption: {
                slidesPerView: 6,
                slidesPerColumn: 1,
                direction: 'vertical',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
            },
        }
    },
    created(){
        this.img = this.active
    }
}
</script>
<style scoped>
    .img-primary{
        width: 100%;
        object-fit: cover;
        max-height: 800px;
    }
    .container-img-primary{
        border: 1px solid #e9ecef;
        border-radius: 15px;
        overflow: hidden;
        min-width: 400px;
        min-height: 400px;
        margin: auto;
    }
    .swiper-container::v-deep{
        height: 700px;
    }
    .active.img-product-details{
        border-bottom: 5px solid #ec4561;
        opacity: 1;
        transition: var(--transition-1);
    }
    .img-product-details{
        object-fit: contain;
        width: 90px;
        height: 90px;
        margin: auto;
        border: 1px solid #e9ecef;
        border-radius: 10px;
        overflow: hidden;
        opacity: 0.7;
        cursor: pointer;
    }
    @media (min-width: 1200px) {
        .container-img-primary > img{
            width: 800px !important;
            height: 700px;
            object-fit: contain;
        }
    }
</style>

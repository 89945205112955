<template>
    <div>
        <b-table striped hover responsive :items="items" :fields="fields" class="bg-white" thead-class="bg-primary text-white" style="max-height: 45vh;overflow-x: hidden;" :tbody-tr-class="rowClass">
            <template #cell(employee)="data">
                <vue-custom-tooltip :label="(`${data.item.employee.first_name} ${data.item.employee.last_name}`).toUpperCase()" :size="'is-small'" v-if="data.item.employee">
                    <h6 class="text-capitalize text-limited mb-0" style="max-width: 160px;">
                        {{ data.item.employee.first_name | textFormat}} {{data.item.employee.last_name | textFormat}}
                    </h6>
                </vue-custom-tooltip>
            </template>
            <template #cell(created_at)="data">
                {{moment(data.item.created_at).format("DD-MM-YYYY HH:mm:ss")}}
            </template>
            <template #cell(actions)="data" v-if="showActionButtons">
                <div class="d-flex">
                    <vue-custom-tooltip :label="$t('delivery.incident_detail')" :size="'is-small'" class="me-3" v-if="showIncidentDetailButton">
                        <eva-icon name="eye" width="30" height="30" :fill="'#02a499'" @click.native="$emit('selectIncident', data.item)"></eva-icon>
                    </vue-custom-tooltip>
                    <vue-custom-tooltip :label="'Detalles de la tarea'" :size="'is-small'" v-if="showTaskDetailButton">
                        <eva-icon name="car-outline" width="30" height="30" :fill="'#02a499'" @click.native="$emit('selectTask', data.item)"></eva-icon>
                    </vue-custom-tooltip>
                    <!-- <vue-custom-tooltip :label="$t('delivery.incident_detail')" :size="'is-small'" class="me-3">
                        <eva-icon name="eye" width="30" height="30" :fill="'#02a499'" @click.native="selectIncident(data.item)"></eva-icon>
                    </vue-custom-tooltip> -->
                    <!-- <vue-custom-tooltip :label="'Detalles de la tarea'" :size="'is-small'" class="me-3">
                        <eva-icon name="car-outline" width="30" height="30" :fill="'#02a499'" @click.native="selectTask(data.item)"></eva-icon>
                    </vue-custom-tooltip> -->
                </div>
            </template>
            <template #cell(status)="data">
                <div :style="{background : data.item.status.background_color}" class="time-sla mx-2">
                    {{ data.item.status.description }}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        data() {
            return {
                moment: moment,
            }
        },
        props: {
            items: {
                type: Array,  
            },
            showActionButtons: {
                type: Boolean,
                default: true
            },
            showTaskDetailButton: {
                type: Boolean,
                default: true
            },
            showIncidentDetailButton: {
                type: Boolean,
                default: true
            }
        },
        components: {

        },
        computed: {
            fields() {
                return [
                    {label: '# '+ this.$t('delivery.incident'), key: 'id', sortable: true},
                    {label: this.$t('delivery.name'), key: 'name', sortable: true},
                    {label: this.$t('delivery.incident_type'), key: 'incident_type', sortable: true},
                    {label: this.$t('delivery.task_id'), key: 'task_id', sortable: true},
                    {label: this.$t('delivery.task_type'), key: 'task_type', sortable: true},
                    {label: this.$t('delivery.delivery'), key: 'task_employee_name', sortable: true},
                    {label: this.$t('delivery.document_id'), key: 'task_document_id', sortable: true},
                    {label: this.$t('delivery.date'), key: 'created_at', sortable: true},
                    // {label: 'Fields', key: 'fields', sortable: true},
                    {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},
                ];
            },
        },
        methods: {
            rowClass(item, type) {
                if (!item || type !== "row") return;

                // Mark as green the incidents which task are completed
                if (item.task_status_id == 5) {
                    return "table-success";
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
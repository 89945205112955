<template>
    <main-modal @closeModal="$emit('closeModal')">
        <div class="position-relative" style="height: 190px;overflow: hidden;">
            <lottie-animation ref="anim" :animationData="require('../../assets/animations/clock.json')" :loop="true" :autoPlay="true" 
            class="position-absolute" style="width: 300px;left: 14%;top: -25%;"/>
        </div>
        <div  class="text-center">
            <h3 class="mb-0">¿Sigues aqui?</h3>
            <p class="size-16">Estuviste inactivo por mas de 20 min.</p>
        </div>
        <div class="d-flex justify-content-center">
            <b-button @click="$emit('closeModal')">
                Aceptar
            </b-button>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '../modals/MainModal.vue'
import LottieAnimation from 'lottie-web-vue'
    export default {
    components: { MainModal, LottieAnimation },
        name: "IdleSession"
    }
</script>

<style scoped>  
    .modal-mask::v-deep > .modal-container{
        min-width: 450px
    }
</style>

<template>
    <layout>
        <div style="height: 86vh;" class="position-relative">
                <div class="filter position-absolute" v-if="showCustomersList">
                    <div class="p-2">
                        <label for="search">{{$t('ecommerce.filter_by_name_or_code')}}</label>
                        <b-form-input v-model="search" type="search"></b-form-input>
                    </div>
                    <div class="customer-table mt-4">
                        <b-table striped hover :items="customers" :fields="fields" :stickyColumn="true" class="bg-white" @row-selected="selectAddress" ref="customersTable" selectable>
                            <template #cell(name)="data">
                                <span v-if="data.item.properties.company_name" class="text-capitalize">
                                    <b>
                                        {{data.item.properties.company_name | textFormat}} 
                                    </b>
                                    | 
                                </span>
                                {{data.item.properties.first_name | textFormat}} {{data.item.properties.last_name | textFormat}} 
                            </template>
                            <template #cell(code)="data">
                                    {{data.item.properties.code}}
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="controls position-absolute p-1" :class="{active: showCustomersList}">
                    <eva-icon name="funnel-outline" @click.native="showCustomersList = !showCustomersList;" fill="#5b626b" class="cursor-pointer"></eva-icon>
                </div>
                <mgl-map style="height: 100%" :zoom="mapOptions.defaultZoom" ref="map" :dragRotate="false" :accessToken="mapOptions.accessToken" :key="styleSelectMap.id || mapKey" :center="mapOptions.defaultCoordinates" :fadeDuration="300" @load="onMapLoaded" :mapStyle="styleSelectMap.value">
                    <vue-google-autocomplete id="vue-google-autocomplete" @focus="showMessage = true" :placeholder="$t('delivery.write_reference_to_locate_the_map')" 
                            class="form-control autocomplete" :class="'form-control-name'" :country="[generalSetting.general.web_page.country_default.text]" :types="['locality']" v-on:placechanged="setPlace"/>
                    <mgl-geojson-layer :layer="clusters" :sourceId="'customers'" :layerId="clusters.id" :source.sync="source" @click="clickZoom" @mouseenter="putPointer" @mouseleave="removePointer"/>
                    <mgl-geojson-layer :layer.sync="clusterCount" :sourceId="'customers'" :layerId="clusterCount.id"/>
                    <mgl-geojson-layer :layer.sync="unclustered" :sourceId="'customers'" :layerId="unclustered.id" :clearSource="true" @mouseenter="putPointer" @mouseleave="removePointer" />
                    <mgl-marker style="cursor: pointer" :coordinates="[customer.geometry.coordinates[0], customer.geometry.coordinates[1]]" :draggable="false" 
                    v-for="(customer, index) in unclusteredCustomers" :key="customer.properties.id + index" @click="getImages(customer.properties.code)">
                        <div slot="marker">
                            <icon-pin :color="customer.properties.verified ? '#348547' : '#c40316'" :size="25"/>
                        </div>
                        <mgl-popup>
                            <div>
                                <h6>{{customer.properties.first_name}} {{customer.properties.last_name}}</h6>
                                <ul class="p-0">
                                    <li> <b>Codigo</b>  {{customer.properties.code}}</li>
                                    <li> <b>Verificado</b>  {{customer.properties.verified}}</li>
                                </ul>
                            </div> 
                        </mgl-popup>
                    </mgl-marker>
                    <div class="icon-map">
                        <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                        <el-popover placement="right" width="210" trigger="click">
                            <b-row class="mt-2" style="width: 205">
                                <b-col>
                                    <div class="d-flex">
                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                            <span class="icon" role="img" aria-label="Mapa">
                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                                <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                            </span>
                                            <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                        </button>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div class="d-flex" style="margin-left: -7px;">
                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                            <span class="icon" role="img" aria-label="Mapa">
                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                                <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                            </span>
                                            <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>
                        <button slot="reference" class="gmaps-button">
                            <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i> <!-- Puedes reemplazar esto con un icono real, como un SVG o un icono de FontAwesome -->
                        </button>
                        </el-popover>
                    </div>
                </mgl-map>
                <right-menu v-if="showEntity" @closeMenu="showEntity = false" :entity="entity" :images="images"/>
        </div>
        <vue-element-loading :active="loading" :is-full-screen="false"/>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import "mapbox-gl/dist/mapbox-gl.css";
import 'v-mapbox/dist/v-mapbox.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import {MglMap, MglGeojsonLayer, MglMarker, MglPopup} from 'v-mapbox'
import Mapbox from "mapbox-gl";
import { http } from "@/common/services/web-backend.js";
import RightMenu from '../components/customers/RightMenu.vue';
import {mapGetters} from 'vuex';
import { CustomerService } from "../service/customer-service";
const customerService = new CustomerService()
import IconPin from '../../../common/components/svg/IconPin.vue';
export default {
    name: 'Customers',
    components:{
        Layout,
        MglMap,
        MglGeojsonLayer,
        RightMenu,
        MglMarker,
        VueGoogleAutocomplete,
        IconPin, 
        MglPopup
    },
    data() {
        return {
            mapOptions: {
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                defaultZoom: 7,
                defaultCoordinates: [-70.7260555, 19.0781721],
                defaultMarker: [-70.7260555, 19.0781721],
            }, // your map style
            data: [],
            showBy: null,
            search: '',
            searchByCode: '',
            customers: [],
            loading: false,
            styleMap: [
                {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
            ],
            styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
            showEntity: false,
            entity: {},
            showCustomersList: true,
            mapKey: 0,
            style: 0,
            images: [],
            unclusteredCustomers: []
        }
    },
    computed:{
        ...mapGetters({
            toggleMenu: "layout/toggleMenu",
            mapStyle: 'layout/mapStyle',
            generalSetting: "ecommerce/generalSetting",
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        clusters() {
        return {
                id: 'clusters',
                type: 'circle',
                source: 'customers',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#51bbd6',
                        100,
                        '#f1f075',
                        750,
                        '#f28cb1'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        40
                    ]
                }
            }
        },
        source() {
            return {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    "features": this.customers
                },
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            }
        },
        clusterCount() {
            return {
                id: 'cluster-count',
                type: 'symbol',
                source: 'customers',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12
                }
            }
        },
        unclustered() {
            return {
                id: 'unclustered-point',
                type: 'circle',
                source: 'customers',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#fff',
                    'circle-radius': 6,
                    'circle-stroke-width': 0,
                    'circle-stroke-color': '#fff'
                }
            }
        },
        options(){
            let status = [
                {value: null, text:'Todos los estados'},
                {value: 'register', text:'Registrados'},
                {value: 'customers', text:'Clientes'}
            ]
            return status
        },
        fields(){
            return [
                {label: this.$t('ecommerce.name'), key: 'name', sortable: true},
                {label: this.$t('ecommerce.code'), key: 'code', sortable: true},
                // {label: this.$t('ecommerce.phone'), key: 'phone', sortable: true},
                // {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},

            ]
        }
    } , 
    methods:{
        setPlace(place){
            this.mapOptions.defaultCoordinates = [place.longitude, place.latitude];
            setTimeout(() => {
                this.$refs.map.map.flyTo({ 
                    center: this.mapOptions.defaultCoordinates, 
                    zoom: 12, 
                    bearing: 0, 
                    speed: 2, 
                    curve: 1, 
                    essential: true,
                    easing: (t) => t 
                });
            }, 500);
        },
        async onMapLoaded() {
            this.mapbox = Mapbox;
            await this.fetchPoints()
            setTimeout(() => {
                this.getMarkers()
            }, 500);
            this.$refs.map.map.on('moveend', () => {
                this.getMarkers()
            });
        },
        clickZoom(e) {
            var features = this.$refs.map.map.queryRenderedFeatures(e.mapboxEvent.point, {
                layers: ['clusters']
            });
            var clusterId = features[0].properties.cluster_id;
            this.$refs.map.map.getSource('customers').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    this.$refs.map.map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom
                    });
                }
            );
        },
        putPointer() {
            this.$refs.map.map.getCanvas().style.cursor = 'pointer';
        },
        removePointer() {
            this.$refs.map.map.getCanvas().style.cursor = '';
        },
        async getImages(code){
            try {
                const response = await customerService.getImages(code)
                this.images = response.data
                this.showEntity = true
            } catch (error) {
                console.log(error);
            }
        },
        async fetchPoints(type = 'registrados') {
            switch (type) {
                case "registrados":
                await this.getPointsFromERP()
                // console.log('registrados');
                break;
                case "solicitudes":
                await this.getPointsFromMM()
                break
                default:
                console.error('tipo no encontrado.');
            }
        },
        async getPointsFromERP(){
            try {
                this.loading = true
                const data = await http.get(`${process.env.VUE_APP_BACKEND_ERP}geography/map`);
                this.data = data.data.data;
                this.customers = this.data;
            } catch (e) {
                console.log(e)
            }finally{
                this.loading = false
            }
        },
        async selectAddress(address){
            if(address[0]){
                await this.getImages(address[0].properties.code)
                this.showEntity = true
                this.$refs.map.map.easeTo({
                    center: address[0].geometry.coordinates,
                    zoom: 14
                });
            }
            this.$refs.customersTable.clearSelected()
        },
        getMarkers(){
            const clusters = this.$refs.map.map.queryRenderedFeatures({ layers: ["unclustered-point"] });
            this.unclusteredCustomers = clusters
        }
    },
    watch:{
        search(val){
            if(!isNaN(val)){
                let result = this.data.filter(client => client.properties.code.toLowerCase().includes(val))
                this.customers = result
            }else{                    
                let result = this.data.filter(client => (client.properties.first_name.toLowerCase() + client.properties.last_name.toLowerCase() 
                + client.properties?.company_name?.toLowerCase()).includes(val))
                this.customers = result
            }
            setTimeout(() => {
                this.getMarkers()
            }, 100);
        },
        toggleMenu(){
            this.mapKey += 1
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const customers = modules?.subModules?.find(data => data.name === 'Clientes')
            // Verifica si se han cambiado los permisos de administrador
            if (!customers.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    },
    async created(){
        this.getPermissions()
        this.mapOptions.defaultCoordinates = this.defaultCountry()
        this.mapOptions.defaultZoom = this.defaultSize()
        setTimeout(() => {
            this.$store.commit('layout/SET_IS_MAP', true)
        }, 100);
    },
    destroyed(){
        this.$store.commit('layout/SET_IS_MAP', false)
    }
}
</script>

<style scoped>
    .customer-table{
        max-height: 700px;
        overflow: auto;
    }
    .table::v-deep > tbody > tr > td:first-child{
        max-width: 170px;
        overflow: hidden;
    }
    .table::v-deep > tbody > tr{
        cursor: pointer;
    }
    .controls{
        background: #fff;
        left: 25px;
        top: 12px;
        z-index: 1;
        border-radius: 5px;
    }
    .controls.active{
        left: 320px;
    }
    .autocomplete{
        position: absolute;
        width: 310px;
        top: 10px;
        border: 0;
        right: 10px;
        z-index: 1;
    }

    .icon-map{
        position: absolute;
        right: 7px;
        top: 9%;
        padding: 3px;
    }
    .gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }
    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }
    .filter{
        left: 0px;
        top: 0px;
        z-index: 2;
        background: #fff;
        height: 100%;
        width: 300px;
        animation: 0.5s show-filter;
    }
    @keyframes show-filter {
        from{
            opacity: 0;
            transform: translateX(-50px);
        }to{
            opacity: 1;
            transform: translateX(0px);
        }
    }
</style>

<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 24 24" >
        <path fill="none" stroke="#000" stroke-width="2" d="M12,20 L24,20 M12,12 L24,12 M12,4 L24,4 M1,19 L4,22 L9,17 M1,11 L4,14 L9,9 M9,1 L4,6 L1,3"/>
    </icon-base>
</template>

<script>
export default {
name: 'IconAssingTask',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


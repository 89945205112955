import { http } from "../../../common/services/node-service";
export class DeliveryService {
    async deliveries(codplace, task_method_id){
        try{
          const response = await http.get('/deliveries/'+codplace, 
            { 
              params: {
                task_method_id: task_method_id
              } 
            } 
          );
          return response.data;
        }catch (e) {
          throw e
        }
    }

    async store(codplace, delivery){
        try{
          const response = await http.post('/deliveries/'+codplace, { delivery });
          return response.data;
        }catch (e) {
          throw e
        }

    }
}

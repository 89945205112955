<template>
    <b-row class="h-100">
        <b-col xl="5" class="d-flex flex-column h-100">
            <el-card>
                <div slot="header" class="clearfix">
                    <h4 class="mb-0">{{$t('delivery.create_tasks')}}</h4>
                </div>
                <create-task-form :coordinates="coordinates" @add="(data) => {items.push(data); clear = !clear}" 
                @edit="editFunction" :editData="editData" :address="address" @selectBranch="(data)=>{branch = data}"/>
            </el-card>
            <el-table :data="items" class="mt-4" :header-cell-style="{'font-weight':400}" size="mini" height="100%" stripe>
                <el-table-column label="#" :width="30" class-name="text-center">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.date')">
                    <template slot-scope="scope">
                        <span>{{$d(new Date(scope.row.date), 'alt_short')}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.employee')">
                    <template slot-scope="scope">
                        <div class="text-capitalize text-limited">
                            <span v-if="scope.row.employee" class="text-capitalize text-limited">
                                {{ `${scope.row.employee.entity.code} - ${scope.row.employee.entity.first_name} ${scope.row.employee.entity.last_name}` }}
                            </span>
                            <el-tag type="danger" size="small" v-else>No empleado</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.task_type')" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.taskType.description }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.description')">
                    <template slot-scope="scope">
                        <p class="m-0" style="max-width: 300px;">
                            {{scope.row.description}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.actions')" width="150" class-name="text-center">
                    <template slot-scope="scope">
                        <div class="d-flex">
                            <el-button size="mini" @click="edit(scope)">Edit</el-button>
                            <el-button size="mini" type="danger" @click="deleteTask(scope.$index)">Delete</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </b-col>
        <b-col xl="7">
            <div class="position-relative overflow-hidden h-100" style="border-radius: 5px;">
                <div class="position-abdolute side-bar h-100 overflow-auto" :class="[toogle ? 'open-side-bar' : 'close-side-bar']">
                    <hr style="margin-top: 4em;">
                    <label for="search" class="mb-2">Buscar direcciones de cliente</label>
                    <el-input placeholder="Please input" id="search" v-model="search" clearable size="small" @input="searchAddresses"/>
                    <el-table :data="search ? result : customers" size="mini" height="37.5vh" :header-cell-style="{'font-weight':400}" v-loading="loading" @row-click="selectCustomerAddress" ref="addressTable">
                        <el-table-column label="Code" class-name="text-start cursor-pointer p-0" width="70">
                            <template slot-scope="scope">
                                <el-tag :type="selectedCustomerAddress  && selectedCustomerAddress.entity.address_id == scope.row.properties.address_id ? 'success' :'info'"  class="text-center" size="mini" style="width: 50px;">
                                    {{ scope.row.properties.code }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="Nombre" class-name="text-start cursor-pointer" >
                            <template slot-scope="scope">
                                <span class="text-capitalize">
                                    <span v-if="scope.row.properties.company_name">{{ scope.row.properties.company_name.toLowerCase() }} /</span>
                                    {{ scope.row.properties.first_name.toLowerCase() }}
                                    {{ scope.row.properties.last_name.toLowerCase() }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-if="selectedCustomerAddress" class="mt-3">
                        <el-card shadow="hover" :body-style="{ padding: '5px 15px' }" class="cursor-pointer" @click.native="selectedCustomerAddress = null">
                            <div class="d-flex justify-content-between aling-items-center">
                                <h6 class="mb-0">Direccion seleccionada</h6>
                                <el-tag size="mini" type="danger">Delete</el-tag>
                            </div>
                            <hr class="my-2">
                            <div class="d-flex">
                                <el-tag type="success"  class="text-center me-2" size="mini" style="width: 50px;">
                                    {{ selectedCustomerAddress.entity.code }}
                                </el-tag>
                                <span class="text-capitalize size-12 text-limited">
                                    <span v-if="selectedCustomerAddress.entity.company_name">{{ selectedCustomerAddress.entity.company_name.toLowerCase() }} /</span>
                                    {{ selectedCustomerAddress.entity.first_name.toLowerCase() }}
                                    {{ selectedCustomerAddress.entity.last_name.toLowerCase() }}
                                </span>
                            </div>
                        </el-card>
                    </div>
                </div>
                <div class="position-absolute icon-toogle d-flex align-items-center cursor-pointer" @click="toogle = !toogle" :class="{'icon-toogle-open-side-bar' : toogle}">
                    <icon-arrow style="margin-top: -2px;" :style="{transform: toogle ? 'rotate(180deg)': 'none'}"/>
                </div>
                <create-task-map ref="map" @setCoordinates="(data)=>{coordinates = data}" @setAddress="(data)=>{address = data}" :clear="clear" @onMapLoaded="onMapLoaded" :selected="selectedAddress"/>
            </div>
        </b-col>
    </b-row>
</template>

<script>
import CreateTaskForm from '../components/TaskForm.vue';
import CreateTaskMap from '../components/TaskMap.vue';
import { mapGetters } from "vuex"
import { http } from "@/common/services/web-backend.js";
import IconArrow from '../../../common/components/svg/IconArrow.vue';
import {mapCustomer} from "../helpers/mixins/map-customer"

export default {
    name: "CreateTask",
    components:{
        CreateTaskForm,
        CreateTaskMap,
        IconArrow,
    },
    data() {
        return {
            coordinates: [],
            items: [],
            editData: null,
            clear: false,
            address: null,
            branch: null,
            toogle: false,
            addresses: [],
            loading: false,
            search:'',
            result:[],
            showCustomers: false,
            debounceTimer: null,
            selectedCustomerAddress: null,
        }
    },
    props:{
        save: {
            type: Boolean
        },
        cancel: {
            type: Boolean
        }
    },
    mixins: [mapCustomer],
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
            taskType: "delivery/taskType",
            stores: "ecommerce/stores",
        }),
        fields(){
            return [
                { key: "index", label: "#", sortable: true,},
                { key: "date", label: this.$t('delivery.date'), sortable: true,},
                { key: "name_employee", label: this.$t('delivery.employee'), sortable: true,},
                { key: "task_description", label: this.$t('delivery.task_type'), sortable: true,},
                { key: "description", label: this.$t('delivery.description'), sortable: true,},
                { key: "actions", label: this.$t('delivery.accions'), sortable: false,},
            ];
        },
        map() {
            return this.$refs.map.$refs.map.map;
        },
        selectedAddress(){
            let coordinates = null;
            if(this.selectedCustomerAddress){
                const coord = this.selectedCustomerAddress.coordinates;
                coordinates = [coord.latitude, coord.longitude];
            }
            return coordinates;
        }
    },
    watch:{
        save(){
            this.createTask()
        },
        cancel(){
            this.cancelCreateTask()
        },
        toogle(val){
            if(val && !this.customers.length){
                this.getPointsFromERP();
            }
        }
    },
    methods:{
        deleteTask(index) {
            this.items.splice(index, 1);
        },
        edit(row){
            this.editData = {form: row.row, index: row.$index}
        },
        async createTask(){
            try {
                if(!this.branch?.codplace){
                    this.$swal.fire({ 
                        title: 'Advertencia', text: 'El campo de sucursal es requerido.', 
                        icon: "warning",
                    })
                    return
                }
                let tasks = []
                this.items.map((task)=>{
                    let obj = {
                        date: task.date.toISOString().split('T')[0],
                        description: task.description, 
                        latitude: task.latitude, 
                        longitude: task.longitude, 
                        address: task.address, 
                        method_id: task.deliveryType.id, 
                        type_id:  task.taskType.id,
                    }
                    if(task.employee)
                    obj.employee_code = task.employee.entity.code;
                    tasks.push(obj)
                })
                const params = {
                    sales_location_id: this.branch.codplace,
                    tasks: tasks
                }
                this.$swal.fire({ 
                    title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                    icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            await http.post('task', params)
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                            this.items = [];
                            this.selectedCustomerAddress = null;
                        } catch (error) {
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: error.response.data.message, 
                                icon: "error",
                            })
                        }
                    }
                })
            } catch (error) {
                console.log(error);  
            }
        },
        cancelCreateTask(){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "Realmente desea cancelar esta operacion?", 
                icon: "warning", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        this.items = [];
                        this.selectedCustomerAddress = null;
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                            icon: "error",
                        })
                    }
                }
            })
        },
        editFunction(payload){
            this.$set(this.items, payload.index, {...payload.form});
            this.editData = null
        },
        searchAddresses(){
            this.showCustomers = this.search && !this.selectedCustomerAddress;
            clearTimeout(this.debounceTimer);
            if(!this.search){
                for (const marker of this.markers) {
                    marker.remove();
                }
                return;
            }

            this.debounceTimer = setTimeout(() => {
                this.result = this.customers.filter((address) => {
                    const entity = address.properties;
                    const value =`${entity.code.toLowerCase()} ${entity.first_name.toLowerCase()} ${entity.last_name.toLowerCase()} ${entity.company_name?.toLowerCase()}`;
                    return value.includes(this.search.toLowerCase());
                });
                this.updateMarkers(this.result);
                setTimeout(() => {
                    this.map.flyTo({
                        zoom: 6,
                        essential: true,
                    });
                }, 200);
                // Aquí puedes realizar las acciones que deseas cuando se actualice el valor debounced
            }, 300); // Cambia 300 por el tiempo de espera en milisegundos que deseas para el debounce
        },
        selectCustomerAddress(row){
            const obj = {
                coordinates:{
                    latitude:  row.geometry.coordinates[0],
                    longitude:  row.geometry.coordinates[1],
                },
                entity: row.properties
            }
            if(this.selectedCustomerAddress?.entity.address_id == obj.entity.address_id){
                this.selectedCustomerAddress = null;
                return;
            }
            for (const marker of this.markers) {
                marker.remove();
            }
            this.showCustomers = false;

            this.selectedCustomerAddress = obj;

            setTimeout(() => {
                this.map.flyTo({
                    center: row.geometry.coordinates,
                    zoom: 16,
                    essential: true,
                });
                
            }, 600);
        }
    },
    async created(){
        await this.$store.dispatch("delivery/getTaskType")
        await this.$store.dispatch('delivery/getEmployees')
        // this.getPointsFromERP();
    }
}
</script>

<style scoped>
    .side-bar{
        background-color: #fff;
        position: absolute;
        left: -350px; /* Oculta el sidebar inicialmente */
        top: 0;
        width: 350px;
        padding: 1rem;
        transition: left 0.3s; /* Animación de apertura/cierre del sidebar */
        z-index: 3;
    }
    .open-side-bar{
        left: 0px;
    }
    .icon-toogle{
        transition: left 0.3s;
        left: 0;
        border-radius: 0px 8px 8px 0px;
        background-color: #fff;
        width: 15px;
        height: 50px;
        z-index: 1;
        top: 50%;
    }
    .icon-toogle-open-side-bar{
        left: 350px;
    }
</style>

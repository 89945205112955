// import each language file

//delivery
import common_en from '@/common/lang/en.json'
import common_es from '@/common/lang/es.json'

//ecommerce
import ecommerce_en from '@/modules/ecommerce/lang/en.json'
import ecommerce_es from '@/modules/ecommerce/lang/es.json'

//delivery
import delivery_en from '@/modules/delivery/lang/en.json'
import delivery_es from '@/modules/delivery/lang/es.json'

//layout
import layout_en from '@/modules/layout/lang/en.json'
import layout_es from '@/modules/layout/lang/es.json'

//auth
import auth_en from '@/modules/auth/lang/en.json'
import auth_es from '@/modules/auth/lang/es.json'

//document follow
import tracking_en from '@/modules/documents-followup/lang/en.json'
import tracking_es from '@/modules/documents-followup/lang/es.json'

// zones
import zones_en from '@/modules/zones/lang/en.json'
import zones_es from '@/modules/zones/lang/es.json'

export default {
    en: {
      ecommerce: ecommerce_en,
      delivery: delivery_en,
      layout: layout_en,
      common: common_en,
      auth: auth_en,
      tracking: tracking_en,
      zones: zones_en
    } ,
    es:{
      ecommerce: ecommerce_es,
      delivery: delivery_es,
      layout: layout_es,
      common: common_es,
      auth: auth_es,
      tracking: tracking_es,
      zones: zones_es
    }
}

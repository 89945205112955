<template>
    <icon-base viewBox="0 0 512 512" :size="size" :fill="color">
        <g id="Checklist_2_">
            <path d="m361 302c-57.891 0-105 47.109-105 105 0 56.834 45.935 105 105 105 57.891 0 105-47.109 105-105s-47.109-105-105-105zm40.605 93.105-45 45c-5.859 5.859-15.351 5.86-21.211 0l-22.5-22.5c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0l11.895 11.895 34.395-34.395c5.859-5.859 15.352-5.859 21.211 0s5.859 15.352-.001 21.211z"/>
            <path d="m361 0h-300c-8.291 0-15 6.709-15 15v422c0 8.291 6.709 15 15 15h173.018c-.055-.157-.141-.297-.196-.454-5.039-12.891-7.822-28.286-7.822-44.546 0-74.443 60.557-135 135-135 5.136 0 10.01.954 15 1.516v-258.516c0-8.291-6.709-15-15-15zm-240 302c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm120 180h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15zm60-60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"/>
        </g>
    </icon-base>
</template>

<script>
import IconBase from "../../../../common/components/svg/IconBase.vue"

export default {
    name: "IconCheckList",
    components: { IconBase },
    props:{
        color:{
            type: String
        },
        size:{
            type: Number,
            default: 20
        }
    }
}
</script>

<template>
    <div>
        <div class="d-flex flex-column align-items-start ms-3">
            <h6 class="title-22 color-blue-700 mb-1">{{ $t('ecommerce.optional_payment') }}</h6>
        </div>
        <b-row class="p-4">
            <b-col xl="2" lg="3">
                <b-form-checkbox id="local-courier" v-model="values.local_delivery.active" name="local-courier" class="me-2"> 
                    <span class="size-15 text-dark">Delivery local</span> 
                </b-form-checkbox>
                <b-form-input id="input-local" v-model="values.local_delivery.code" type="number" placeholder="Code" :disabled="!values.local_delivery.active"/>
            </b-col>
            <b-col xl="2" lg="3">
                <b-form-checkbox id="nacional-delivery" v-model="values.nacional_delivery.active" name="nacional-delivery" class="me-2"> 
                    <span class="size-15 text-dark">{{ $t('ecommerce.delivery_national') }}</span>
                </b-form-checkbox>
                <b-form-input id="input-national" v-model="values.nacional_delivery.code" type="number" placeholder="Code" :disabled="!values.nacional_delivery.active"/>
            </b-col>
            <b-col xl="2" lg="3">
                <b-form-checkbox id="pick-up" v-model="values.pick_up.active" name="pick-up" class="me-2"> 
                    <span class="size-15 text-dark">Pick up</span> 
                </b-form-checkbox>
                <b-form-input id="input-pick-up" v-model="values.pick_up.code" type="number" placeholder="Code" :disabled="!values.pick_up.active"/>
            </b-col>
            <b-col xl="2" lg="3">
                <b-form-checkbox id="presale" v-model="values.presale.active" name="presale" class="me-2"> 
                    <span class="size-15 text-dark">Presale</span> 
                </b-form-checkbox>
                <b-form-input id="input-presale" v-model="values.presale.code" type="number" placeholder="Code" :disabled="!values.presale.active"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "BuyingOptions",
    props: {
        settings:{
            type: Object
        }
    },
    data() {
        return {
            values: {}
        }
    },
    created(){
        this.values = this.settings
    }
}
</script>

<style scoped>
    .form-control {
        margin-left: 29px;
        width: calc(100% - 29px);
    }
    .form-control:disabled{
        background-color: #ccc;
        cursor: no-drop;
    }
</style>
<template>
    <layout>
        <vue-element-loading :active="loading" :is-full-screen="false" />

        <b-row class="mt-2">
            <b-col>
                <b-row class="mt-3">
                    <b-col>
                        <b-form-input v-model="username" @keyup.enter="click" :placeholder="$t('ecommerce.enter_code_or_email')"></b-form-input>
                    </b-col>
                    <b-col>
                        <button class="btn btn-primary" @click="click">{{ $t("ecommerce.mark_email_as_verified") }}</button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { http } from "../../../common/services/web-backend";

export default {
    data() {
        return {
            username: "",
            loading: false
        }
    },
    components:{
        Layout
    },
    methods: {
        click() {
            if (!this.username) {
                this.$swal.fire({
                    title: "Error",
                    text: this.$t("ecommerce.you_must_enter_an_email_or_a_code"),
                    icon: "error",
                    confirmButtonText: "OK",
                });

                return;
            }

            this.$swal.fire({
                title: this.$t("ecommerce.confirm"),
                text: this.$t("ecommerce.are_you_sure_do_you_want_to_mark_this_email_as_verified"),
                icon: "info",
                confirmButtonText: this.$t("ecommerce.confirm"),
                cancelButtonText: this.$t("ecommerce.cancel"),
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    await this.setCognitoEmailAsVerified();
                    this.loading = false;
                } 
            });
        },
        async setCognitoEmailAsVerified() {
            try {
                const response = await http.put('/entities/set-cognito-email-as-validated', {
                    username: this.username
                });

                const data = response.data.data;

                if (data.is_error) {
                    this.$swal.fire({
                        title: "Error",
                        text: data.message,
                        icon: "error",
                        confirmButtonText: "OK",
                    });

                    return;
                }

                this.$swal.fire({
                    title: this.$t("ecommerce.success"),
                    text: this.$t("ecommerce.email_marked_as_verified_succesfully"),
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } catch (e) {
                let errorMessage = this.$t("ecommerce.something_went_wrong");

                if (e.response) {
                    errorMessage = e.response.data.message;
                }

                this.$swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
import { http } from "@/common/services/web-backend.js";

export class OrderService {
    async getOrders(filters){
        try{
            const data = await http.get("ecommerce/admin/orders", {   
                params: filters
            });
            return data.data.data
        }catch (e) {
            if (e.response){
                return e.response
            }else{
                throw e
            }
        }
    }
    async getTotalOrders(filters) {
    try{
        this.busy = true;
        const data = await http.get('ecommerce/admin/orders/total', {
            params: {
                ...filters
            }
        });
        return data.data.data;
    }catch (e) {
        console.log(e)
    }finally {
        this.busy = false;
    }
}
async getCurrentProcessed(filters){
    try{
        this.busy = true;
        const data = await http.get('ecommerce/admin/orders/processed', {
            params: {
                ...filters
            }
        });
        return data.data.data ? data.data.data+'%' : '0%';
    }catch (e) {
        console.log(e)

    }finally {
        this.busy = false;
    }
}
async getRevenue(filters){
    try{
        const data = await http.get('ecommerce/admin/orders/revenue', {
            params: {
                ...filters
            }
        });
        return data.data.data

    }catch (e) {
        console.log(e)
    }finally {
        this.busy = false;
    }
}
async getStatus(){
  try{
      const data = await http.get("ecommerce/admin/orders/status");
      return data.data.data
  }catch (e) {
      if (e.response){
        return e.response
      }else{
          throw e
      }
  }
}
}

<template>
    <main-modal @closeModal="$emit('closeModal')">
        <h3 class="mb-0">Confirma tu email.</h3>
        <h5>{{$t('auth.description_warning_verify_email')}}.</h5>
        <p>
            {{$t("auth.enter_the_code_sent_to_the")}} 
                <b>
                <!-- {{userAttributes.challengeParam.email}} -->
                </b> 
            {{$t("auth.before_they_pass")}} <b class="text-info"><count-down/></b>min.
        </p>
        <form @submit.prevent="verify">
            <div>
                <label for="code">{{$t('auth.code')}}</label>
                <b-form-input v-model="code" required> </b-form-input>
            </div>
            <div class="d-flex">
                <b-button variant="primary" class="mt-3 ms-auto" type="submit">{{$t('auth.confirm')}}</b-button>
            </div>
        </form>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import { Auth } from 'aws-amplify';
import CountDown from '../../../../common/components/clock/CountDown.vue';

export default {
    components: { MainModal, CountDown },
    name: "VerifyEmail",
    props:{
        userAttributes:{
            type: Object
        },
        user:{
            type: Object
        }
    },
    data() {
        return {
            code: null
        }
    },
    methods:{
        async verify(){
            try {
                this.loading = true
                await Auth.sendCustomChallengeAnswer(this.userAttributes, JSON.stringify({otp_email : this.code}));
                this.$swal.fire({
                    icon: 'success',
                    title: 'Usuario Verificado',
                    text: 'Email verificado con éxito.',
                    showConfirmButton: false,
                    timer: 2000
                })
                this.$emit("closeModal")
                await this.$store.dispatch('auth/login', {username: this.user.username, password: this.user.password});
            } catch (error) {
                this.$emit("incorrectCode")
                this.error = error.message
            }finally{
                this.loading = false
            }
        }
    }
}
</script>

<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 24 24" >
        <g id="minus-square-Filled">
            <path id="minus-square-Filled-2" data-name="minus-square-Filled" class="cls-1" d="M15,2.5H9A6.513,6.513,0,0,0,2.5,9v6A6.513,6.513,0,0,0,9,21.5h6A6.513,6.513,0,0,0,21.5,15V9A6.513,6.513,0,0,0,15,2.5ZM16,13H8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconMinus',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


<template>
    <div class="position-relative d-flex flex-column">
        <div class="trash-button d-flex justify-content-center align-items-center" @click="resetSelect">
            <eva-icon name="trash-2" width="18"></eva-icon>
        </div>
        <mgl-map :key="styleSelectMap.id"  class="assign-task-map" :center="mapOptions.center" :accessToken="mapOptions.accessToken" :zoom="mapOptions.defaultZoom"
        :dragRotate="false" ref="assignMap" :mapStyle="styleSelectMap.value" @load="onMapLoaded">
        <vue-google-autocomplete id="google-autocomplete" @focus="showMessage = true" :placeholder="$t('delivery.write_reference_to_locate_the_map')" 
            class="form-control autocomplete" :country="[generalSetting.general.web_page.country_default.text]" :types="['locality']" v-on:placechanged="setPlaceTask"/>
            <vue-element-loading :active="loading" :is-full-screen="false"/>
            <template v-if="filters.showBranches && filterBranches.length > 0">
                <mgl-marker style="cursor: pointer" :coordinates="[store.long, store.lat]" :draggable="false" color="#c40316" v-for="store in filterBranches" :key="store.codplace">
                    <div slot="marker">
                        <div class="icon-container icon-container-store bg-danger">
                            <icon-store :size="25" :color="'#fff'"/>
                        </div>
                    </div>
                    <mgl-popup>
                        <div>
                            <h3>{{store.description}}</h3>
                            <sub>{{store.long}}, {{store.lat}}</sub>
                        </div> 
                    </mgl-popup>
                </mgl-marker>
            </template>
            <mgl-marker style="cursor: pointer" :coordinates="[task.longitude, task.latitude]" :draggable="false" color="#c40316" v-for="task in tasks" :key="task.id">
                <div slot="marker" style="z-index: 2;">
                    <div class="icon-container">
                        <div class="total" v-if="getTotal(task) > 1">{{getTotal(task)}}</div>
                        <component :is="getIcon(task)" :color="calculateTime(task)" :class="[calculateTime(task) == '#E53935'? 'blink' : '']" :size="25"/>
                    </div>
                </div>
                <mgl-popup>
                    <div>
                        <h4>{{$t('delivery.task_summary')}}</h4>
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="mb-0 me-3 size-15">{{task.type.description | textFormat}} / {{task.method.description | textFormat}} <span style="color: #909baa;" class="ms-1">{{task.document.document}}</span></h5>
                            <h5 :id="`task-map-time-${task.id}`" class="mb-0 size-15 text-end" style="min-width: 90px;"></h5>
                        </div>
                        <ul class="p-0 mt-4 details-task-list">
                            <li v-if="task.document.type_id">
                                <i class="dripicons-store size-15"></i>
                                <span class="text-muted weight-600 text-capitalize" v-if="task.document.type_id == 3">{{task.document.branch_name | textFormat}}→{{task.customer | textFormat}}</span>
                                <span class="text-muted weight-600 text-capitalize" v-else-if="task.customer">
                                    <span v-if="task.company_name">{{task.company_name | textFormat}} /</span> {{task.customer | textFormat}}
                                </span>
                            </li>
                            <li v-if="task.document.employeee_first_name" class="text-capitalize"><i class="dripicons-user-id size-15"></i> 
                                {{task.document.employeee_first_name.toLowerCase()}} {{task.document.employeee_last_name.toLowerCase()}}
                            </li>
                            <li class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <i class="dripicons-calendar size-15 d-flex me-1"></i>
                                    {{$d(new Date(task.created_at), 'alt_short')}}
                                </div>
                                <div class="text-white px-2" :style="{backgroundColor: task.status.background_color}" style="border-radius: 15px">
                                    {{task.status.description}}
                                </div>
                            </li>
                        </ul>
                        <p>
                            {{task.description}}
                        </p>
                        <div class="d-flex justify-content-center align-items-center">
                            <a href="" @click.prevent="selectTask(task.id)" class="size-14">
                                {{$t('delivery.see_more')}} 
                                <icon-arrow :size="13" class="ms-2" style="transform: rotate(90deg);" color="#626ed4"/>
                            </a>
                        </div>
                    </div>
                </mgl-popup>
            </mgl-marker>
            <!-- <template v-if="filters.showDeliveries && deliveries.length > 0 &&  false">
                <mgl-marker :coordinates="[agent.tookan.longitude, agent.tookan.latitude]" :draggable="false" color="#c40316" v-for="(agent, index) in deliveries" :key="`agent-${index}`">
                    <div slot="marker" class="icon-container icon-container-store" :style="{backgroundColor: agent.tookan.is_available ? '#02a499' : '#f8b425'}">
                        <icon-motorcycle :color="'#fff'"/>
                    </div>
                    <mgl-popup>
                        <div>
                            <h4 class="text-capitalize">{{agent.tookan.name | textFormat}}</h4>
                        </div> 
                    </mgl-popup>
                </mgl-marker>
            </template> -->
            <mgl-geolocate-control :trackUserLocation="true" class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer"/>
            <mgl-navigation-control class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer"/>
            <div style="position: fixed; margin-top: 74px; margin-left: 10px;">
                <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                <el-popover placement="right" width="210" trigger="click">
                    <b-row class="mt-2" style="width: 205">
                        <b-col>
                            <div class="d-flex">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                    </span>
                                    <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                </button>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="d-flex" style="margin-left: -7px;">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                    </span>
                                    <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                <button slot="reference" class="gmaps-button">
                    <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i> <!-- Puedes reemplazar esto con un icono real, como un SVG o un icono de FontAwesome -->
                </button>
                </el-popover>
            </div>
            <eva-icon name="map" @click.native="showMapLegend = !showMapLegend" class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer"></eva-icon>
            <div class="map-legend p-2" v-if="showMapLegend">
                <ul class="list-legend mb-0 p-0">
                    <li v-for="item in legendList" :key="item.name">
                        <div class="legend-icon-container me-2">
                            <component :is="item.icon" :color="item.fill"/>
                        </div>
                        <h6 class="mb-0">{{item.name}}</h6> 
                        <span class="item-description ms-2">{{item.description}}</span>
                    </li>
                </ul>
            </div> 
        </mgl-map>
        <task-details v-if="showDetails" @closeModal="showDetails = false"/>
        <div class="mt-2">
            <strong>{{roundedArea}}</strong>
            <span class="ms-3">{{$t('delivery.square_kilometers')}}</span>
        </div>
        <!-- <ul class="list-unstyled mb-0 mt-2 d-flex align-items-center" style="gap: 30px;">
            <li>
                <strong>{{roundedArea}}</strong>
                <span class="ms-3">{{$t('delivery.square_kilometers')}}</span>
            </li>
            <li>
                <b-form-checkbox id="show-available-areas" v-model="showAvailableAreas" name="show-available-areas">{{$t('delivery.show_available_areas')}}</b-form-checkbox>
            </li>
        </ul> -->
    </div>
</template>

<script>
    // map
    import Mapbox from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import 'v-mapbox/dist/v-mapbox.css';
    import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
    import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
    import { mapGetters } from 'vuex'
    import {MglMap, MglMarker, MglPopup, MglGeolocateControl, MglNavigationControl} from 'v-mapbox'
    import IconDelivery from '../../../assets/svg/IconDelivery.vue';
    import IconBoxes from '../../../assets/svg/IconBoxes.vue';
    import IconCheckList from '../../../assets/svg/IconCheckList.vue';
    import VueGoogleAutocomplete from "vue-google-autocomplete";
    import IconBus from '../../../assets/svg/IconBus.vue';
    import MapboxDraw from "@mapbox/mapbox-gl-draw";
    import * as turf from "@turf/turf";
    import IconArrow from '../../../../../common/components/svg/IconArrow.vue';
    import IconStore from '../../../assets/svg/IconStore.vue';
    import moment from "moment";
    import IconCircle from '../../../../../common/components/svg/IconCircle.vue';
    import IconMotorcycle from '../../../assets/svg/IconMotorcycle.vue';
    import TaskDetails from '../modals/TaskDetails.vue';

    export default {
        name: 'AssignMap',
        props:{
            tasks:{
                type: Array
            },
            filters:{
                type: Object
            },
            selectedBranches:{
                type: Array
            },
            loading:{
                type: Boolean
            },
            showAvailableAreas:{
                type: Boolean
            },
            deliveries:{
                type: Array
            }
        },
        data(){
            return { 
                mapOptions: {
                    center: [-70.70238447994983, 19.45556310283655],
                    defaultZoom: 7,
                    accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                },
                points: [],
                legendList:[
                    {name: 'Local', fill: '#90a4ae', icon: 'icon-delivery', description: 'Delivery o recogida dentro de la ciudad.'},
                    {name: 'Parada', fill: '#90a4ae', icon: 'icon-bus', description: 'Delivery fuera de la ciudad.'},
                    {name: 'Mandado', fill: '#90a4ae', icon: 'icon-check-list', description: 'Asignaciones informales.'},
                    {name: 'Transferencia', fill: '#90a4ae', icon: 'icon-boxes', description: 'Mover mercancia entre sucursales.'},
                    {name: 'Mensajero', fill: '#90a4ae', icon: 'icon-motorcycle', description: 'Mensajeros'},
                    {name: 'Sucursal', fill: '#90a4ae', icon: 'icon-store', description: 'Sucursales'},
                    {name: 'Muy Tarde', fill: '#ec4561', icon: 'icon-circle', description: 'El tiempo limite ha sido excedido.'},
                    {name: 'Tarde', fill: '#f8b425', icon: 'icon-circle', description: 'El tiempo base ha sido excedido.'},
                    {name: 'A tiempo', fill: '#02a499', icon: 'icon-circle', description: 'Esta a tiempo.'},
                ],
                layers: [],
                tasksWithTime: [],
                styleMap: [
                    {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                    {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
                ],
                styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
                showDetails: false,
                showMapLegend: false,
                // showAvailableAreas: true,
                roundedArea: 0,
                interval: null,
            }
        },
        computed:{
            ...mapGetters({
                mapStyle: 'layout/mapStyle',
                stores: "ecommerce/stores",
                toggleMenu: "layout/toggleMenu",
                generalSetting: "ecommerce/generalSetting",
            }),
            filterBranches(){
                if(this.filters.branches > 0){
                    const filterB = this.filters.branches;
                    const stores = filterB.length > 0 ? this.stores.filter(item=> filterB.includes(item.id)) : this.stores;
                    return stores;
                    // return this.stores.this.selectedBranches
                }else{
                    return this.stores
                }
            },
            sources() {
                let sources = [];
                let limits = []
                this.filterBranches.forEach(branch => {
                    this.layers.forEach(layer => {
                        if(layer.properties.codplace == branch.codplace){
                            let index = limits.findIndex(item=> item.properties.id == layer.properties.id)
                            if(index == -1)
                            limits.push(layer)
                        }
                    });
                });
                for (let layer of limits){
                    sources.push({ type: 'geojson', data: layer})
                }
                return sources;
            },
        },
        components:{
            MglMap,
            MglMarker,
            MglPopup,
            IconDelivery,
            IconBoxes,
            IconCheckList,
            IconBus,
            IconArrow,
            IconStore,
            IconCircle,
            IconMotorcycle,
            MglGeolocateControl,
            MglNavigationControl,
            TaskDetails,
            VueGoogleAutocomplete
        },
        methods:{
            setPlaceTask(place){
                this.mapOptions.center = [place.longitude, place.latitude];
                setTimeout(() => {
                    this.$refs.assignMap.map.flyTo({ 
                        center: this.mapOptions.center, 
                        zoom: 12, 
                        bearing: 0, 
                        speed: 2, 
                        curve: 1, 
                        essential: true,
                        easing: (t) => t 
                    });
                }, 500);
            },
            async onMapLoaded(){
                this.mapbox = Mapbox;
                // agregar la funcion de dibujar lineas en el mapa
                this.control = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: { polygon: true, trash: true },
                    defaultMode: "draw_polygon",
                });
                this.$refs.assignMap.map.addControl(this.control, 'top-left');
                this.$refs.assignMap.map.on('draw.create', this.addTasks);
                this.$refs.assignMap.map.on('draw.update', this.addTasks);
                const filterB = this.filters.branches;
                const stores = filterB.length > 0 ? this.stores.filter(item=> filterB.includes(item.id)) : this.stores;


                setTimeout(() => {
                    if(this.filters.showZones) this.setLayers(stores)
                }, 100);
                // event.map.on('style.load', async () => {
                // });
                // this.setLayers()
            },
            // calcular el tiempo de las tares
            calculateTime(marker){
                const timeIcon = new Date(marker.created_at);
                let currentdate = new Date();
                let diff = (timeIcon.getTime() - currentdate.getTime()) / 1000;
                diff /= 60;
                let minutes = Math.abs(Math.round(diff))
                const deliveryManIcon = minutes <= 10 ? '#2E7D32' : minutes > 10 && minutes < 30 ? '#F9A825' : minutes > 30 ? '#E53935' : '#2E7D32';
                return deliveryManIcon
            },
            getTotal(task){
                let filter = this.tasks.filter((el) => el.latitude == task.latitude && el.longitude == task.longitude)
                return filter.length
            },
            getIcon(task){
                if(task.document.type_id == 3){
                    return 'icon-boxes' 
                }else if(task.type_id == 2){
                    return 'icon-check-list'
                }else if(task.type_id == 1 && task.method_id == 2){
                    return 'icon-bus'
                }
                else {
                    return 'icon-delivery'
                }
            },
            async createdPoints() {
                try {
                    this.points = []
                    this.tasks.forEach((task) => {
                        const lgt = parseFloat(task.longitude) || 0;
                        const ltd = parseFloat(task.latitude) || 0;
                        this.points.push([lgt, ltd]);
                    })
                } catch (error) {
                    console.log(error);
                }
            },
            async addTasks(event) {
                this.$store.commit('delivery/RESET_SELECT_TASK')
                try {
                    const response = await this.coordinatesMarkersPolygon(event)
                    for (let i = 0; i < response.features.length; i++) {
                    const element = response.features[i];
                    const check = this.existTasksInSelectedPolygon(element.geometry.coordinates)
                    const same =  this.tasks.filter((el) => el.latitude == check.latitude && el.longitude == check.longitude)

                    same.forEach((location) => {
                        if(location.status_id == 1)
                        this.$store.commit("delivery/SELECT_TASK", location)
                    })
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async coordinatesMarkersPolygon(event) {
                try {
                    if (event.features.length) {
                    // pass all coordinates
                    const points = turf.points(this.points);
                    // we obtain the coordinates of the polygon
                    const polygon = turf.polygon(event.features[0].geometry.coordinates);
                    // here we look for the points of the marker that is inside the polygon
                    const ptsWithin = turf.pointsWithinPolygon(points, polygon);
                    // get Info area
                    const area = turf.area(this.control.getAll());

                    
                    const roundedArea = Math.round((area * 100) / 1000000) / 100;
                    this.roundedArea = roundedArea
                    return ptsWithin;
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            existTasksInSelectedPolygon(coordinates = []) {
                try {
                    const [lgt, lat] = coordinates;
                    const found = this.tasks.filter(({ longitude, latitude }) => longitude == lgt && latitude == lat);
                    return found[0];
                } catch (e) {
                    console.log(e);
                }
            },
            resetSelect(){
                this.control.deleteAll()
                this.$store.commit('delivery/RESET_SELECT_TASK')
            },
            async selectTask(id){
                try {
                    await this.$store.dispatch('delivery/getTaskDetails', id)
                    this.showDetails = true
                } catch (error) {
                    console.log(error);
                }
            },
            getTimeOfTask(data){
                let startDate = moment(data.created_at)
                let endDate = data.completed ? moment(data.date_completed).format() : moment()
                let dtDiff = moment(endDate).diff(startDate);

                //express as a duration
                const diffDuration = moment.duration(dtDiff);
                let result = moment().startOf("day");
                result.add(diffDuration.hours(), 'hours')
                result.add(diffDuration.minutes(), 'minutes')
                result.add(diffDuration.seconds(), 'seconds')
                let days = diffDuration.days()
                this.tasksWithTime.push({id: data.id, time: result, days: days, completed: data.completed})
            },
            startTimer(){
                clearInterval(this.interval)
                this.interval = setInterval(() => {
                    this.tasksWithTime.forEach(task => {
                        if(!task.completed)
                        task.time.add(1, 'second');
                        let span = document.getElementById(`task-map-time-${task.id}`)
                        if(span){
                            span.innerHTML = `${task.days >= 1 ? `${task.days}D` : ''} ${task.time.format('HH:mm:ss')}`;
                        }
                    });
                }, 1000)
            },
            async setLayers(stores){
                this.layers = []
                await stores.forEach( (store) => {
                    store.limits.forEach(limit => {
                        let coords = []
                        limit.limits.forEach(el => {
                            coords.push([el.longitude, el.latitude])
                        });
                        if(coords.length > 0){
                            let poly = turf.polygon([coords]);
                            poly.properties['id'] = limit._id
                            poly.properties['codplace'] = store.codplace
                            poly.properties['description'] = store.description
                            poly.properties['color'] = limit.color
                            this.layers.push(poly);
                        }
                    })
                });
                const allLayers = this.$refs.assignMap.map.getStyle().layers
                const filterLayers = allLayers.filter(layer => layer.id.toString().includes('limits-layer-'))

                if(filterLayers.length > 0){
                    filterLayers.forEach(layer => {
                        const id = layer.id.replace('limits-layer-', '' )
                        let getSource = this.$refs.assignMap.map.getSource('limit-areas-'+id)
                        if(getSource){
                            this.$refs.assignMap.map.removeLayer(layer.id)
                            this.$refs.assignMap.map.removeSource('limit-areas-'+id)
                        }
                    })
                }
                this.sources.forEach(async (source)=>{
                    let getSource = this.$refs.assignMap.map.getSource('limit-areas-'+source.data.properties.id)
                    if(getSource){
                        this.$refs.assignMap.map.removeLayer('limits-layer-'+source.data.properties.id)
                        this.$refs.assignMap.map.removeSource('limit-areas-'+source.data.properties.id)
                    }
                    
                    this.$refs.assignMap.map.addSource('limit-areas-'+source.data.properties.id, source);
                    this.$refs.assignMap.map.addLayer(
                        {   
                            id: 'limits-layer-'+source.data.properties.id, type: 'fill', source: 'limit-areas-'+source.data.properties.id, layout: {}, 
                            paint: { 'fill-color': source.data.properties.color, 'fill-opacity': 0.3}
                        },
                    );
                })
            },
            hideZones(){
                let allLayers = this.$refs.assignMap.map.getStyle().layers
                let filterLayers = allLayers.filter(layer => layer.id.toString().includes('limits-layer-'))
                if(filterLayers.length > 0){
                    filterLayers.forEach(layer => {
                        const id = layer.id.replace('limits-layer-', '' )
                        let getSource = this.$refs.assignMap.map.getSource('limit-areas-'+id)
                        if(getSource){
                            this.$refs.assignMap.map.removeLayer(layer.id)
                            this.$refs.assignMap.map.removeSource('limit-areas-'+id)
                        }
                    })
                }
            }
        },
        watch:{
            tasks:{
                deep: true,
                handler(val){
                    clearInterval(this.interval)
                    this.createdPoints()

                    val.forEach(task => {
                        this.getTimeOfTask(task)
                    });
                    this.startTimer()
                }
            },
            'filters.branches':{
                deep: true,
                handler(val){
                    let stores = val.length > 0 ? val : this.stores
                    setTimeout(() => {
                        if(this.$refs.assignMap.map)
                        this.setLayers(stores)
                    }, 800);
                }
            },
            'filters.showZones'(val){
                if(val){
                    this.setLayers(this.filterBranches)
                }else{
                    this.hideZones()
                }
            },
            toggleMenu(){
                this.$refs.assignMap.map.resize()
            }
        },
        created(){
            this.mapOptions.center = this.defaultCountry()
            this.mapOptions.defaultZoom = this.defaultSize()
        },
        beforeDestroy(){
            clearInterval(this.interval)
        }
    }
</script>

<style scoped>
    .assign-task-map{
        /* height: 59vh; */
        border-radius: 8px;
        overflow: hidden;
    }
    .icon-container{
        background-color: #fff;
        border: 1px solid rgb(211, 191, 191);
        border-radius: 20px;
        padding: 5px;
    }
    .icon-container > .total{
        position: absolute;
        z-index: 2;
        background: #ec4561;
        font-size: 15px;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        left: 25px;
        top: 0px;
        color: #fff;
        font-weight: bold;
    }
    .blink {
        animation: blink 0.3s step-start infinite;
    }
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
    .trash-button:hover{
        background: #f2f2f2;
    }
    .trash-button{
        position: absolute;
        left: 10px;
        z-index: 3;
        top: 40px;
        cursor: pointer;
        background: #fff;
        width: 29px;
        height: 28px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .form-control {
        height: 31px !important;
    }
    .mgl-map-wrapper::v-deep > .mapboxgl-map > .mapboxgl-popup{
        max-width: 400px !important;
        z-index: 2;
    }
    .mapboxgl-ctrl-map {
        top: 38px !important;
    }
    .details-task-list > li{
        display: flex;
        align-items: center;
    }
    .details-task-list > li > i {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
    .icon-map{
        position: absolute;
        right: 10px;
        top: 42%;
        padding: 3px;
    }
    .map-legend{
        z-index: 1;
        background: #fff;
        position: absolute;
        border-radius: 17px;
        top: calc(15% + 3px);
        right: 60px;
        box-shadow: 3px 6px 20px #7c879329;
    }
    .map-legend::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 2px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
        transform: translate(50%, -50%) rotate(45deg);
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
    }
    .map-legend > ul > li > div{
        width: 30px;
    }
    .map-legend > ul > li{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .map-legend > ul > li:last-child{
        margin-bottom: 0;
    }
    .gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }
    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }
    .autocomplete{
        position: absolute;
        width: 310px;
        top: 8px;
        border: 0;
        right: 45px;
        z-index: 1;
    }

</style>
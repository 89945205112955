<template>
    <div>
        <h3>{{$t('ecommerce.answers')}}</h3>
        <b-table :items="answers" responsive striped hover thead-class="bg-primary text-white" :fields="fields" details-td-class="bg-primary" class="bg-white">
            <template #cell(date)="data">
                {{$d(new Date(data.item.created_at), 'alt_short')}}
            </template>
            <template #cell(customer)="data">
                <div class="d-flex align-items-center">
                    <avatar :name="data.item.customer.first_name" :lastname="data.item.customer.last_name" :width="30" :customerImg="data.item.customer.photo_url" :showStar="false" :customerVerified="data.item.customer_verified"/>
                </div>
            </template>
            <template #cell(verified_by_admin)="data">
                <div class="d-flex justify-content-center">
                    <icon-check-circle v-if="data.item.verified_by_admin" :size="17" :color="'#02a499'"/>
                    <icon-danger-circle :color="'#ff4560'" v-else/>     
                </div>
            </template>
            <template #cell(actions)=data>
                <div class="d-flex align-items-center">
                    <eva-icon name="checkmark-circle-2" width="22px" class="cursor-pointer me-2" fill="#02a499"></eva-icon>
                    <eva-icon name="trash-2" width="22px" class="cursor-pointer me-3" fill="#ff4560" @click.native="remove(data.item.id)"></eva-icon>
                    <!-- <b-button variant="success" size="sm" class="me-2">Verificar</b-button> -->
                    <b-button variant="warning" size="sm">Reportes({{data.item.total_reports}})</b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import IconCheckCircle from '../../../../common/components/svg/IconCheckCircle.vue'
import IconDangerCircle from '../../../../common/components/svg/IconDangerCircle.vue'
import { ProductsService} from "../../service/products-service";
import Avatar from '../avatar/Avatar.vue';
const productService = new ProductsService();
export default {
    components: { IconCheckCircle, IconDangerCircle, Avatar },
    name: "Answers",
    props:{
        answers:{
            type: Array
        },
    },
    computed:{
        fields(){
            return [
                {label: this.$t('ecommerce.user_code'), key: 'customer_id', sortable: true},
                {label: this.$t('ecommerce.date'), key: 'date', sortable: true},
                {label: this.$t('ecommerce.answer'), key: 'answer', sortable: true},
                {label: this.$t('ecommerce.admin'), key: 'verified_by_admin', sortable: true},
                {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                {label: this.$t('ecommerce.total_likes'), key: 'like.length', sortable: true},
                {label: this.$t('ecommerce.total_dislike'), key: 'dislike.length', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions'},
            ]
        },
    },
    methods:{
        async remove(id){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "¿Esta seguro de que eliminar esta respuesta?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        const response = await productService.deleteAnswer(id);
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: this.$options.filters.textFormat(response.message), 
                            icon: "success",
                        })
                        this.$emit('refresh')
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                            icon: "error",
                        })
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
.table-responsive::v-deep > table{
    vertical-align: middle;
    text-align: center;
}
.img-profile{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 1px solid #e2e1ee;
    border-radius: 50%;
}
</style>

<template>
    <main-modal @closeModal="() => this.$emit('closeModal')">
        <b-row>
            <h2 class="text-center">{{ $t('delivery.create_action') }}</h2>
        </b-row>
        <b-row>
            <b-col xs="12" sm="6" class="mx-auto">
                <b-form @submit="onSubmit" class="mt-4">
                    <b-form-group 
                        id="input-group-description" 
                        label="Description " 
                        label-for="input-description"
                    >
                        <b-form-input
                            id="input-description"
                            v-model="form.description"
                            placeholder="Enter Description"
                            required
                        >
                        </b-form-input>
                    </b-form-group>

                    <div class="mt-4 text-center">
                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger" class="close-button" @click="close">{{ $t('delivery.close') }}</b-button>
                    </div>
                </b-form>
            </b-col>
        </b-row>
    </main-modal>
</template>

<script>
    import MainModal from '@/common/components/modals/MainModal.vue';
    import { http } from '@/common/services/web-backend.js';

    export default {
        components: { MainModal },
        data() {
            return {
                form: {
                    description: ""
                }
            }
        },
        methods: {
            async create(payload) {
                try {
                    const response = await http.post("actions", {
                        description: payload.description
                    });

                    if (!response || !response.data) {
                        throw new Error(this.$t("delivery.invalid_backend_erp_response"));
                    }

                    this.$swal.fire({
                        title: this.$t('delivery.action_registered_successfully'),
                        icon: "success",
                    }).then(() => {
                        this.close();
                    });
                } catch (error) {
                    console.error(error);
                    this.$swal.fire({ 
                        title: this.$t("delivery.something_went_wrong"),
                        text: error, 
                        icon: "error",
                    })
                }
            },
            onSubmit(event) {
                event.preventDefault();
                this.create(this.form);
            },
            close() {
                this.$emit('closeModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
.close-button {
    margin-left: 10px;
}
</style>
export default {
  TOGGLE_MENU(state, value){
    state.toggleMenu = value
  },
  SET_MAP_STYLE(state, payload){
    state.mapStyle = payload
  },
  SET_IS_MAP(state, payload){
    state.isMap = payload
  }
};

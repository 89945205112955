<template>
    <main-modal :title="'ecommerce.order_details'" @closeModal="$emit('closeModal')">
        <b-row>
            <b-col cols="4">
                <b-row>
                    <b-col class="mt-4">
                        <div class="d-flex justify-content-between">
                            <div>
                                <a href="#" @click.prevent="seeInvoice"><eva-icon name="eye" width="18" class="me-2" fill="#626ed4"></eva-icon>{{$t('ecommerce.see_invoice')}}</a>
                                <span class="mx-2">|</span>
                                <a href="#" @click.prevent="print = true" v-print="'print-invoice'"><eva-icon name="printer" width="16" class="me-2" fill="#626ed4"></eva-icon>{{$t('ecommerce.print_invoice')}}</a>
                            </div>
                            <a href="#" class="ms-2" @click.prevent="cancel">{{$t('ecommerce.cancel')}}</a>
                        </div>
                        <ul class="order-details-list">
                            <li>
                                <b>{{$t('ecommerce.order')}} #</b> {{ order.order_token_id }}
                            </li>
                            <li>
                                <b>{{$t('ecommerce.customer')}}</b> {{ order.order_primary.entity.first_name | textFormat}} {{order.order_primary.entity.last_name | textFormat}}
                            </li>
                            <li>
                                <b>{{$t('ecommerce.service')}}</b> 
                                <div class="text-white px-2 d-inline-block" :class="[order.order_primary.option_name == 'local_delivery' ? 'bg-info': order.order_primary.option_name == 'pick_up' ? 'bg-success' : order.order_primary.option_name == 'nacional_delivery' ? 'bg-success' : '']" style="border-radius: 10px;">
                                    {{$t('ecommerce.'+order.order_primary.option_name)}}
                                </div>
                            </li>
                            <li>
                                <b>{{$t('ecommerce.created')}}</b> {{$d(new Date(order.created_at), 'alt_short')}}
                            </li>
                            <li>
                                <b>{{$t('ecommerce.state')}}</b> 
                                <div class="px-2 d-inline-block" :class="[order.order_status.name]" style="border-radius: 10px;">
                                    {{$t('ecommerce.' + order.order_status.name)}}
                                </div>
                            </li>
                            <li>
                                <b>{{$t('ecommerce.contact')}}</b> {{ order.order_primary.entity.phone}}
                            </li>
                            <li>
                                <b>{{$t('ecommerce.payment_method')}}</b>
                                <div :key="index" v-for="(payment, index) in order.order_primary.payments">
                                    {{payment.type}} 
                                </div>
                            </li>
                            <li>
                                <b>{{$t('ecommerce.branch')}}</b> {{stores | getDescriptionBranch(order.branch_code)}}
                            </li>
                        </ul>
                        <h5 v-if="order.delivery_detail">{{$t('ecommerce.delivery_details')}}</h5>
                        <ul class="order-details-list" v-if="order.delivery_detail">
                            <li>
                                <b>{{$t('Total')}}</b> {{ order.delivery_detail.amount | moneyFormat}}
                            </li>
                        </ul>
                        <a href="#" v-if="order.delivery_detail" @click.prevent="showDeliveryDetails = true">{{$t('ecommerce.see_details')}} ({{$t('ecommerce.route')}})</a>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="8">
                <h4>{{$t('ecommerce.products')}}</h4>
                <div class="product-table">
                    <b-table striped hover :items="order.order_details" :fields="fields">
                        <template #cell(description)="data">
                            {{ data.item.product.description | textFormat}}
                        </template>
                        <template #cell(price_sold)="data">
                            {{ data.item.price_sold | moneyFormat}}
                        </template>
                        <template #cell(amount)="data">
                            {{ data.item.amount | moneyFormat}}
                        </template>
                    </b-table>
                </div>
                <div class="d-flex justify-content-end">
                    <ul class="p-0" style="min-width: 160px;">
                        <li class="d-flex justify-content-between">Subtotal <b>{{order.amount_sub | moneyFormat}}</b></li>
                        <li class="d-flex justify-content-between">Tax <b>{{order.amount_tax | moneyFormat}}</b></li>
                        <li class="d-flex justify-content-between">Total: <b>{{order.amount | moneyFormat}}</b></li>
                    </ul>
                </div>
            </b-col>
        </b-row>
        <delivery-detail :delivery="getDeliveryDetails" :branch="stores | getBranch(order.branch_code)" 
        :lat="getDeliveryDetails.customer_latitud" :long="getDeliveryDetails.customer_longitud" v-if="showDeliveryDetails"
        @closeModal="showDeliveryDetails = false"/>
        <invoice :order="order" :class="[print ? 'visible': 'invisible']" style="height: 0"/>
    </main-modal>
</template>
<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import { mapGetters } from "vuex"
import DeliveryDetail from './DeliveryDetail.vue'
import Invoice from './Invoice.vue'

export default {
    components: { MainModal, DeliveryDetail, Invoice },
    name: "OrderDetails",
    props: {
        order: {
            type: Object
        },
    },
    data() {
        return {
            showDeliveryDetails: false,
            print: false
        }
    },
    watch: {
        print(val){
            if(val){
                setTimeout(() => {
                    this.print = false
                }, 0);
            }
        }
    },
    computed:{
        fields(){
            return [
                {label: this.$t('ecommerce.code'), key: 'product_code', sortable: true},
                {label: this.$t('ecommerce.description'), key: 'description', sortable: true},
                {label: this.$t('ecommerce.price'), key: 'price_sold', sortable: true},
                {label: this.$t('ecommerce.quantity'), key: 'quantity', sortable: true},
                {label: this.$t('ecommerce.amount'), key: 'amount', sortable: true},

            ]
        },
        ...mapGetters({
            stores: "ecommerce/stores",
        }),
        getDeliveryDetails(){
            let obj = {
                ...this.order.delivery_detail
            }
            obj.coords = JSON.parse(obj.coords)
            return obj
        }
    },
    methods:{
        seeInvoice(){
            localStorage.setItem('invoice', JSON.stringify(this.order))
            let route = this.$router.resolve({ path: "/ecommerce/invoice" });
            window.open(route.href);
        },
        cancel(){
                this.$swal.fire({ 
                    title: 'Confirmacion', text: `¿Seguro de eliminar la orden #${this.order.order_token_id}? esta accion no se puede desahaser.`, 
                    icon: "warning", showCancelButton: true, confirmButtonText: 'Confirma', 
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                        } catch (error) {
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: error.response.data.message, 
                                icon: "error",
                            })
                        }
                    }
                })
        }
    }
}
</script>

<style scoped>
    .order-details-list{
        list-style: none;
        padding: 0;
    }
    .order-details-list > li{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 15px;
    }
    .modal-mask::v-deep > .modal-container{
        width: 1200px;
    }
    .product-table{
        max-height: 700px;
        overflow: auto;
    }
    .table::v-deep > thead{
        position: sticky;
        top: 0px;
        background: #fff;
    }
</style>

import Vue from "vue";
var moment = require('moment');

Vue.filter('timer', function (date) {
  return moment(date).fromNow();
});

Vue.filter('textFormat', function (text) {
  if(text)
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
});

Vue.filter('moneyFormat', function (value) {
  return 'RD$ ' + parseFloat(value).toFixed(2)
});

Vue.filter('moneyFormatMultiply', function (value1, value2) {
  return 'RD$ ' + parseFloat( parseFloat(value1) * parseFloat(value2) ).toFixed(2)
});

Vue.filter('getBranch', function (branches, codplace) {
  if(codplace){
    const branch =  branches.filter((branch) =>{
        return branch.codplace === codplace
    })
    return branch[0]
  }
});

Vue.filter('getDescriptionBranch', function (branches, codplace) {
  const branch =  branches.filter((branch) =>{
      return branch.codplace === codplace
  })
  if(branch[0])
  return branch[0].description.charAt(0).toUpperCase() + branch[0].description.slice(1).toLowerCase()
});

Vue.filter('phoneFormat', function (phone) {
  return phone.replace(/[^0-9]/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2)-$3-$4');
});
Vue.filter('timeFormat', function (seconds) {
  let negative = false
  if(seconds < 0){
      negative = true
      seconds = Math.abs(seconds)
  }
  let minutes = Math.floor(seconds / 60)
  let hour = Math.floor(minutes / 60)
  let days = Math.floor(hour / 24)
  if(days >= 1){
      return `${negative ? '-' :''}${days} d`
  }else if(hour >= 1){
      return `${negative ? '-' :''}${hour} h`
  }else if(minutes >= 1){
      return `${negative ? '-' :''}${minutes} m`
  }else{
      return `${negative ? '-' :''}${seconds || 0} s`
  }
});

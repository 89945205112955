<script>
import appConfig from "@/app.config";
import IdleSession from './common/components/customs/IdleSession.vue';
import {globalMixin} from "./common/helpers/get-global-data"


export default {
  components: { IdleSession },
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    } 
  },
  mixins: [globalMixin],
  data(){
    return {
      idle: false
    }
  },
  methods: {
    onidle() {
      this.idle = true
      this.$store.commit('common/STOP_ALL_PROCESSES', true)
    },
    onremind() {
      // alert seconds remaining to 00:00
      // alert(time);
    },
    confirmIdle(){
      this.idle = false
      location.reload()
    }
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
    <!-- 1200 -->
    <v-idle @idle="onidle" @remind="onremind" :reminders="[10, 15]" :wait="5" :duration="1200" :events="['click']" v-show="false"/>
    <idle-session @closeModal="confirmIdle" v-if="idle"/>
  </div>
</template>

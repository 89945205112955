<template>
<!-- <ul class="timeline p-0" id="timeline"> -->
    <draggable class="timeline" tag="div" v-model="actionsLocal" v-bind="dragOptions" @start="drag = true" @end="drag = false" @change="changeFunction" ref="draggableTimeline">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div v-for="(action, index) in actionsLocal" :key="action.id" class="li complete">
            <!-- {{action.name}} -->
                <div class="status btn-action" v-if="action.name != 'add'" @click="$emit('delete', action.id)">
                    <div class="circle d-flex align-items-center justify-content-center text-white" :style="{background : action.background_color}">
                        <span class="d-block">{{index + 1}}</span>
                        <eva-icon name="close" class="d-none" fill="#fff"></eva-icon>
                    </div>
                    <div class="d-flex flex-column align-items-center mt-3">
                <!-- {{index}} -->
                        <h5 class="text-center" :style="{color : action.background_color}"> {{action.name}}</h5>
                    </div>
                </div>
                <div class="btn-add status cursor-pointer position-relative" v-else> 
                    <div class="circle d-flex align-items-center justify-content-center" :class="{'bg-danger active' : showSelect}" @click="showSelect = !showSelect">
                        <eva-icon name="plus" fill="white" width="20px"></eva-icon>
                    </div>
                    <div class="d-flex flex-column align-items-center mt-3">
                        <h5 class="mb-3">{{ $t('tracking.add') }}</h5>
                    </div>
                    <div class="select-action" v-if="showSelect">
                        <multiselect :id="'state'" :value="actionsLocal" :options="actions" :label="'name'" track-by="id" :multiple="true" @select="select" @remove="remove">
                            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('tracking.options_selected') }}</span></template>
                        </multiselect>
                    </div>
                </div>
            </div>
        </transition-group>
    </draggable>
    <!-- <li class="li complete" v-for="(action, index) in status" :key="action.id">
        <div class="status" v-if="index != 7">
            <div class="circle d-flex align-items-center justify-content-center" :style="{background : action.background_color}">
                <eva-icon name="checkmark" fill="white" width="18px"></eva-icon>
            </div>
            <div class="d-flex flex-column align-items-center mt-3">
                <h5 class="mb-3" :style="{color : action.background_color}"> {{action.name}}</h5>
            </div>
        </div>
        <div class="status cursor-pointer" v-if="index == 7">
            <div class="circle d-flex align-items-center justify-content-center bg-red">
                <eva-icon name="plus" fill="white" width="20px"></eva-icon>
            </div>
            <div class="d-flex flex-column align-items-center mt-3">
                <h5 class="mb-3"> ADD</h5>
            </div>
        </div>
    </li> -->
<!-- </ul>  -->
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex';

export default {
    nmae: "TimeLine2",
    props:{
        status:{
            type: Array
        },
        groupName:{
            type: String
        }
    },
    data(){
        return{
            drag: false,
            actionsLocal: [],
            showSelect: false
        }
    },
    computed:{
        dragOptions() {
            return {
                animation: 500,
                group: this.groupName,
                disabled: false,
                ghostClass: "ghost"
            };
        },
        ...mapGetters({
            actions: "documentsFollowup/actions",
        }),
    },
    components:{
        Draggable
    },
    methods:{
        changeFunction(){
            this.actionsLocal.forEach((action, index) => {
                action.order = index + 1
            });
            this.moveAddToLastPosition()
        },
        moveAddToLastPosition(){
            let findIndexAdd = this.actionsLocal.findIndex(item => item.id == 0)
            if(this.actionsLocal.length - 1 != findIndexAdd){
                this.actionsLocal.splice(findIndexAdd, 1);
                this.actionsLocal.splice(this.actionsLocal.length, 0, {name: 'add', $isDisabled: true, id: 0});
            }
        },
        select(e){
            this.actionsLocal.push({...e, order: this.actionsLocal.length})
            this.moveAddToLastPosition()
        },
        remove(e){
            let index = this.actionsLocal.findIndex(item => item.id == e.id)
            this.actionsLocal.splice(index, 1)
        }
    },
    mounted(){
        this.actionsLocal = this.status
    }
}
</script>

<style scoped>
.timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
}

.li {
    transition: all 200ms ease-in;
}

.timestamp {
    margin-bottom: 20px;
    /* padding: 0px 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
}

.status {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    border-top: 3px solid #e9ecef;
    position: relative;
    transition: all 200ms ease-in;
    min-width: 140px;
}
.status h4 {
    font-weight: 600;
}
.circle {
    content: "";
    width: 25px;
    height: 25px;
    background-color: #ced4da;
    border-radius: 25px;
    position: absolute;
    top: -14px;
    left: 44%;
    transition: all 200ms ease-in;
}

.li.complete .status {
    border-top: 3px solid #08213b;
}
.li.complete .status:before {
    background-color: #66DC71;
    border: none;
    transition: all 200ms ease-in;
}
.li.complete .status h4 {
    /* color: #66DC71; */
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
    .timeline {
        list-style-type: none;
        display: block;
    }

    .li {
        transition: all 200ms ease-in;
        display: flex;
        width: inherit;
    }

    .timestamp {
        width: 100px;
    }

    .status:before {
        left: -8%;
        top: 30%;
        transition: all 200ms ease-in;
    }
}
html, body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: "Titillium Web", sans serif;
    color: #758D96;
}

button {
    position: absolute;
    width: 100px;
    min-width: 100px;
    padding: 20px;
    margin: 20px;
    font-family: "Titillium Web", sans serif;
    border: none;
    color: white;
    font-size: 16px;
    text-align: center;
}

#toggleButton {
    position: absolute;
    left: 50px;
    top: 20px;
    background-color: #75C7F6;
}
.timeline > span{
    display: flex;
}
.select-action{
    position: absolute;
    top: 45px;
}
.multiselect::v-deep > .multiselect__tags{
    border: none !important;
    box-shadow: 0px 0px 4px 2px #f2f5fe;
    min-width: 180px;
}
.btn-add:hover > .circle{
    background: #626ed4;
}
.btn-add > .circle.active > i{
    transform: rotate(45deg);
    transition: 0.5s ease-in-out;
}
.btn-action > .circle:hover{
    background: #ec4561 !important;
    transform: rotate(45deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}
.btn-action > .circle:hover > i{
    display: block !important;
}
.btn-action > .circle:hover > span{
    display: none !important;
}
</style>

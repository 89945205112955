// import store from '@/state/store'

export default [{
        path: '/',
        meta: {
            authRequired: true,
            icon: "icon-home"
        },
        name: 'Home',
        component: () => import('../modules/template/views/home.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../modules/auth/view/Login.vue'),
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: () => import('../modules/auth/view/ForgotPassword.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import('../modules/template/views/pages/error-404.vue')
    },
    {
      path: '/permission',
      name: 'permission',
      component: () => import('../modules/template/views/pages/no-permission.vue')
    }
  ]

<script>
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";
import { layoutMethods } from "@/modules/template/state/helpers";
import { mapGetters } from 'vuex';
// import { menuItems } from "./menu";

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      modules: [
            {
                active: true,
                id: 1,
                name: "Ecommerce",
                route:'ecommerce',
                icon: "bx mdi mdi-shopping",
                subModules: [
                    { 
                        active: true,
                        id: 'ecommerce-1',
                        name: "Orders",
                        route:'orders'
                    }, 
                    { 
                        active: true,
                        id: 'ecommerce-2',
                        name: "Products",
                        route:'products'
                    }, 
                    { 
                        active: true,
                        id: 'ecommerce-3',
                        name: "Customers",
                        route:'customers'
                    }, 
                ]
            },
            {
                active: true,
                id: 2,
                name: "Delivery",
                route:'delivery',
                icon: "bx mdi mdi-truck-outline",
                subModules: [
                    { 
                        active: true,
                        id: 'delivery-1',
                        name: "Incident Settings",
                        route:'incident-settings'
                    }, 
                ]
            },
        ],
        showList: false,
      // menuItems: menuItems,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    userAccess: {
      type: Array,
    }
  },
  components: {
    simplebar,
  },
  computed: {
    ...mapGetters({
      userData: "auth/user"
    }),
    menuItems() {
      return [
        {
          id: 1,
          label: "Main",
          isTitle: true,
        },
        {
          id: 2,
          label: "Ecommerce",
          icon: "mdi mdi-shopping",
          subItems: [
            {
              id: 3,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: [
                {
                  id: 4,
                  label: this.$t("layout.orders"),
                  link: "/ecommerce/orders",
                },
                {
                  id: 10,
                  label: this.$t("layout.products"),
                  link: "/ecommerce/products",
                },
                {
                  id: 5,
                  label: this.$t("layout.customers"),
                  link: "/ecommerce/customers",
                },
                {
                  id: 5,
                  label: this.$t("layout.limitions"),
                  link: "/ecommerce/limitions",
                },
                {
                  id: 6,
                  label: this.$t("layout.delivery_price"),
                  link: "/ecommerce/delivery-price",
                },
                {
                  id: 7,
                  label: this.$t("layout.settings"),
                  link: "/ecommerce/settings/general",
                },
                {
                  id: 8,
                  label: this.$t("layout.requests"),
                  link: "/ecommerce/requests",
                },
                {
                  id: 9,
                  label: this.$t("layout.reviews"),
                  link: "/ecommerce/reviews",
                },
                {
                  id: 11,
                  label: this.$t("layout.questions"),
                  link: "/ecommerce/questions",
                },
                {
                  id: 12,
                  label: this.$t("layout.dictionary"),
                  link: "/ecommerce/dictionary",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          label: "Delivery",
          icon: "mdi mdi-truck-outline",
          subItems: [
            {
              id: 10,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: this.getItemsDelivery,
            },
          ],
        },
        {
          id: 10,
          label: this.$t('layout.zoning'),
          icon: "mdi mdi-router",
          subItems: [
            {
              id: 1,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: [
                {
                  id: 1,
                  label: this.$t('layout.coverage_areas'),
                  link: "/zones/limits",
                },
                {
                  id: 2,
                  label: this.$t('layout.routes'),
                  link: "/zones/routes",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          label: this.$t('layout.status_tracking'),
          icon: "mdi mdi-poll",
          subItems: [
            {
              id: 3,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: [
                {
                  id: 4,
                  label: this.$t("layout.document_followup"),
                  link: "/documents-followup",
                },
                {
                  id: 5,
                  label: this.$t('layout.maintenance'),
                  link: "/documents-followup/maintenance",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          label: 'App',
          icon: "mdi mdi-desktop-tower",
          subItems: [
            {
              id: 4,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: [
                {
                  id: 5,
                  label: 'Dashboard',
                  link: "/visit/dashboard",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          label: 'Admin',
          icon: "mdi mdi-security",
          subItems: [
            {
              id: 4,
              label: this.$t("layout.process"),
              link: "/email/inbox",
              subItems: [
                {
                  id: 5,
                  label: 'Administrar permisos',
                  link: "/admin/access",
                },
              ],
            },
          ],
        },
      ];
    },
    getItemsDelivery() {
      let subItems = [
        {
          id: 11,
          label: this.$t("layout.tasks"),
          link: "/delivery/tasks",
        },
        // {
        //   id: 12,
        //   label: this.$t("layout.assign_tasks"),
        //   link: "/delivery/assign-tasks",
        // },
        {
          id: 13,
          label: "Task Settings",
          link: "/delivery/task-settings",
        },
        {
          id: 14,
          label: "Incident Settings",
          link: "/delivery/incident-settings",
        },
        {
          id: 15,
          label: "Tracking",
          link: "/delivery/dashboard-tracking",
        },
        // {
        //   id: 15,
        //   label: 'Agentes' + ' ERP',
        //   link: "/delivery/agents-erp",
        // },
        // {
        //   id: 13,
        //   label: this.$t('layout.tasks') + ' tookan',
        //   link: "/delivery/all-tasks",
        //   color: '#ec4561'
        // },
        // {
        //   id: 14,
        //   label: 'Agentes' + ' tookan',
        //   link: "/delivery/agents",
        //   color: '#ec4561'
        // },
      ];
      subItems.sort((a, b) => a.label.localeCompare(b.label));
      return subItems;
    },
  },
  mounted: function () {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("active");
          }
        } else {
          parent.classList.add("mm-active");
        }
      }
    }
    document.body.setAttribute("data-theme", "dark");
  },
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    // toggleList() {
    //   // Toggle the visibility of the list
    //   this.showList = !this.showList;
    // },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subModules !== undefined ? item.subModules.length > 0 : false;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.setAttribute("data-topbar", "light");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-keep-enlarged");
              break;
            case "icon":
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.removeAttribute("data-sidebar-size");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
          <!-- <ul style="margin-left: 25px;" class="metismenu list-unstyled" id="side-menu" v-for="item in userAccess" :key="item.id">
              <li v-if="item.active">
                <i :class="`${item.icon}`" class="me-2"></i>{{item.name}}
                  <template v-for="submodule in item.subModules">
                      <li v-if="submodule.active" :key="submodule.id" class="ms-4">
                          <router-link :id="submodule.id" :to="`/${item.route}/${submodule.route}`" replace >
                              {{submodule.name}}
                          </router-link>
                      </li>
                  </template>
              </li>
          </ul> -->
          <ul class="metismenu list-unstyled" id="side-menu">
            <template v-for="item in userAccess">
              <li v-if="item.active" :key="item.id">
                <a
                  v-if="hasItems(item)"
                  href="javascript:void(0);"
                  class="is-parent"
                  :class="{
                    'has-arrow': !item.badge,
                    'has-dropdown': item.badge,
                  }"
                >
                  <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                  <span>{{ item.name }}</span>
                  <span
                    :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                    v-if="item.badge"
                    ></span
                  >
                </a>
                <router-link
                  :to="item.route"
                  v-if="!hasItems(item)"
                  class="side-nav-link"
                >
                  <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
                  <span>{{ item.name }}</span>
                  <span
                    :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
                    v-if="item.badge"
                    ></span
                  >
                </router-link>
                  <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                      <li v-for="(subitem, index) of item.subModules" :key="index">
                        <template v-if="subitem.active">
                          <router-link
                              :to="subitem.route"
                              v-if="!hasItems(subitem)"
                              class="side-nav-link"
                              >{{ subitem.name }}</router-link
                            >
                            <a
                              v-if="hasItems(subitem)"
                              class="side-nav-link-a has-arrow"
                              href="javascript:void(0);"
                              >{{ subitem.name }}</a
                            >
                            <ul
                              v-if="hasItems(subitem)"
                              class="sub-menu mm-collapse"
                              aria-expanded="false"
                            >
                            </ul>
                        </template>
                      </li>
                  </ul>
                </li>
            </template>
          </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

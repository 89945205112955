<template>
  <form class="row">
    <div class="col-md-3">
      <label for="input-live">{{ $t('ecommerce.price_based') }}:</label>
      <b-form-input id="input-live" v-model="basePrice" aria-describedby="input-live-help input-live-feedback" trim>
      </b-form-input>
    </div>
    <!-- <div class="col-md-3">
      <label for="input-live">Precio por kilometro:</label>
      <b-form-input id="input-live" v-model="kmBasePrice" aria-describedby="input-live-help input-live-feedback" trim>
      </b-form-input>
    </div>
    <div class="col-md-2">
      <label for="input-live">Precio por minuto:</label>
      <b-form-input id="input-live" v-model="minBasePrice" aria-describedby="input-live-help input-live-feedback" trim>
      </b-form-input>
    </div> -->
    <div class="col-md-3">
      <label for="input-live">{{ $t('ecommerce.price_quantity_low') }}:</label>
      <b-form-input id="input-live" v-model="quantityMinimum" aria-describedby="input-live-help input-live-feedback" trim>
      </b-form-input>
    </div>
    <div class="col-md-3">
      <label for="input-live">{{ $t('ecommerce.price_quantity') }}:</label>
      <b-form-input id="input-live" v-model="quantityPrice" aria-describedby="input-live-help input-live-feedback" trim>
      </b-form-input>
    </div>
    <div class="col-md-3">
      <label for="input-live">{{ $t("ecommerce.free_delivery_threshold") }}</label>
      <b-form-input 
        id="input-live" 
        v-model="freeDeliveryThreshold" 
        aria-describedby="input-live-help input-live-feedback"
        :title="$t('ecommerce.free_delivery_threshold_description')"
        trim
      >
      </b-form-input>
    </div>
      <b-table sticky-header thead-class="green-bg bg-primary text-white mt-5" hover style=" margin-top: 15px;" :items="condictionals" :fields="fields">
          <template #cell(actions)="row">
            <eva-icon name="trash-2" class="cursor-pointer" fill="#ec4561" @click.native="deleteCondiction(row.index)"></eva-icon>
          </template>
          <template #cell(km_minimo)="row">
            <b-input-group prepend=">">
              <b-form-input v-model="row.item.km_minimo" :title="$t('ecommerce.kilometers_min_value_description', {min_value: row.item.km_minimo, max_value: row.item.km_maximo})"></b-form-input>
            </b-input-group>
          </template>

          <template #cell(km_maximo)="row">
            <b-input-group prepend="<=">
              <b-form-input v-model="row.item.km_maximo" :title="$t('ecommerce.kilometers_max_value_description', {min_value: row.item.km_minimo, max_value: row.item.km_maximo})"></b-form-input>
            </b-input-group>
          </template>              
          <template #cell(km_price)="row">
              <b-form-input v-model="row.item.km_price"></b-form-input>
          </template>              
          <template #cell(min_price)="row">
              <b-form-input v-model="row.item.min_price"></b-form-input>
          </template>
          <template #cell(fixed_price)="row">
              <b-form-input v-model="row.item.fixed_price" :title="$t('ecommerce.fixed_price_value_description')"></b-form-input>
          </template>
      </b-table>
      <div class="d-flex justify-content-end">
        <b-button variant="success" @click="addCondiction" style="min-width: 150px" >{{ $t('ecommerce.adding_condition') }}</b-button>
        <b-button variant="primary" @click="save" style="min-width: 150px" class="ms-3">{{ $t('ecommerce.save') }}</b-button>
      </div>
  </form>
</template>

<script>
export default {
  name: "DeliveryForm",
  data() {
    return {
      basePrice: 0,
      kmBasePrice: 0,
      minBasePrice: 0,
      fixedPrice: 0,
      quantityMinimum: 0,
      quantityPrice: 0,
      freeDeliveryThreshold: 0,
      condictionals: []
    }
  },
  props:{
    delivery: {
      type: Object
    }
  },
  watch: {
    delivery: {
      immediate: true,
      handler(val) {
        this.basePrice = val.base_price;
        this.kmBasePrice = val.km_base_price;
        this.minBasePrice = val.min_base_price;
        this.fixedPrice = val.fixed_price;
        this.quantityMinimum = val.quantity_minimum;
        this.quantityPrice = val.quantity_price;
        this.freeDeliveryThreshold = val.free_delivery_threshold;
        this.condictionals = val.condictionals;
      },
    },
  },
  methods:{
    addCondiction() {
      const params = this.condictionals[this.condictionals.length - 1];
      if (params) {
        this.condictionals.push({
          km_minimo: parseFloat(params.km_minimo) + 1,
          km_maximo: parseFloat(params.km_maximo) + 1,
          km_price: 0,
          min_price: 0,
          fixed_price: 0
        });
      } else {
        this.condictionals.push({
          km_minimo: 0,
          km_maximo: 0,
          km_price: 0,
          min_price: 0,
          fixed_price: 0
        });
      }
    },
    save() {
      this.btnLabel = "Guardando...";
      const base_price = this.basePrice;
      const km_base_price = this.kmBasePrice;
      const min_base_price = this.minBasePrice;
      const fixed_price = this.fixedPrice;
      const quantity_minimum = parseInt(this.quantityMinimum);
      const quantity_price = this.quantityPrice;
      const free_delivery_threshold = this.freeDeliveryThreshold;
      const condictionals = this.condictionals;
      this.$emit("save", {
        base_price,
        km_base_price,
        min_base_price,
        fixed_price,
        quantity_minimum,
        quantity_price,
        free_delivery_threshold,
        condictionals,
      });
    },
    deleteCondiction(index) {
      this.condictionals.splice(index, 1);
    },  
  },
  computed:{
    fields(){
      return [
        {label: 'Km ' + this.$t('ecommerce.minimum'), key: 'km_minimo', sortable: true},
        {label: 'Km ' + this.$t('ecommerce.maximum'), key: 'km_maximo', sortable: true},
        {label: this.$t('ecommerce.prices_per_kilometer'), key: 'km_price', sortable: true},
        {label: this.$t('ecommerce.prices_per_minute'), key: 'min_price', sortable: true},
        {label: this.$t('ecommerce.fixed_price'), key: 'fixed_price', sortable: true},
        {label: this.$t('ecommerce.actions'), key: 'actions', sortable: true},
      ]
    }
  }
}
</script>

<template>
    <main-modal @closeModal="$emit('closeModal')">
        <h3>Reasignar tarea</h3>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <label for="">Nuevo Mensajero</label>
                <multiselect :id="'delivery'" v-model="$v.employee.$model" :options="deliveryOptions" :class="[$v.employee.$error ? 'invalid' : '']" :label="'text'" track-by="value"></multiselect>
            </div>
        </div>
        <span class="mt-3 d-block size-16" v-if="task.employee">
            <b>{{$t('delivery.delivery_courier')}}:</b> {{task.employee.first_name | textFormat}} {{task.employee.last_name | textFormat}}
        </span>
        <div class="d-flex justify-content-center mt-3">
            <b-button @click="unAssign" style="width: 100px;" variant="danger" class="me-3 mt-auto">Desasignar</b-button>
            <b-button @click="reassign" style="width: 100px;" variant="primary">{{$t('delivery.save')}}</b-button>
        </div>
        
<!-- {{task}} -->
    </main-modal>
</template>

<script>
import MainModal from '../../../../../common/components/modals/MainModal.vue'
import { mapGetters } from "vuex"
import { required } from "vuelidate/lib/validators"

export default {
    components: { MainModal },
    name: "Reassing",
    props:{
        task: {
            type: Object
        }
    },
    data() {
        return {
            employee: null,
        }
    },
    validations: {
        employee: {
            required,
        }
    },
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
        }),
        deliveryOptions(){
            let employees = [{value: null, text: this.$t('delivery.choose_an_option')}]
            for (let i = 0; i < this.employees.length; i++) {
                const employee = this.employees[i];
                employees.push({value: employee.entity.code, text: `${employee.entity.first_name}  ${employee.entity.last_name}`})
            }
            return employees
        },
    },
    methods: {
        async reassign(){
                this.$v.employee.$touch()
                if(!this.employee?.value){
                    this.employee = null
                }
                if(this.$v.employee.$error){
                    return
                }
                this.$swal.fire({ 
                    title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                    icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            await this.$store.dispatch('delivery/reassingTask', {id: this.task.id, employee: this.employee.value})
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                            this.$emit('closeModal')
                            this.$emit('refresh')
                        } catch (error) {
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: error.response.data.message, 
                                icon: "error",
                            })
                        }
                    }
                })
        },
        unAssign(){
                this.$swal.fire({ 
                    title: 'Confirmacion', text: "Esta seguro de que desea desasignar esta tarea ?", 
                    icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            await this.$store.dispatch('delivery/unAssign', this.task.id)
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                            this.$emit('closeModal')
                            this.$emit('refresh')
                        } catch (error) {
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: error.response.data.message, 
                                icon: "error",
                            })
                        }
                    }
                })
        }
    },
}
</script>

<style scoped>
    .multiselect::v-deep{
        min-height: 0;
        min-width: 300px;
    }
    .multiselect::v-deep.invalid > .multiselect__tags{
        border: 1px solid #ec4561 !important;
    }
</style>

<template>
    <layout>
        <!-- <document-followup-table class="mt-4"/> -->
        <TaskHistory class="mt-4" />
    </layout>
</template>

<script>
    import Layout from "@/modules/template/layouts/main";
    // import DocumentFollowupTable from '../components/DocumentFollowupTable.vue';
    import TaskHistory from "../../delivery/components/tasks/TaskHistory.vue";
    export default {
        name: "DocumentFollowupIFrame",
        components: { 
            Layout, 
            // DocumentFollowupTable, 
            TaskHistory 
        }
    }
</script>

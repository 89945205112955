<template>
    <div>
        <b-row class="m-0 p-0">
            <div class="d-flex flex-column align-items-start ms-1 mb-1">
                <h6 class="title-22 color-blue-700 mb-1">Ecommerce</h6>
                <h6 class="subtitle-16 mb-0">{{ $t('ecommerce.info_general') }}</h6>
            </div>
            <b-col class="p-4">
                <b-form-checkbox id="multiple-branches" v-model="values.multiple_branches.active" class="mb-2" name="multiple-branches"> 
                    {{$t('ecommerce.branch_mult') }}
                </b-form-checkbox>
                <b-form-checkbox id="surveys" v-model="values.survey.active" class="mb-2" name="surveys"> 
                    {{ $t('ecommerce.surveys') }}
                </b-form-checkbox>
                <b-form-checkbox id="deactivate-all-page" v-model="values.web_page.active" class="mb-2" name="deactivate-page"> 
                    {{ $t('ecommerce.disabled') }}
                </b-form-checkbox>
                <div class="ms-3">
                    <b-form-checkbox id="deactivate-for-customer-page" v-model="values.web_page.active_for_customer" class="mb-2" name="deactivate-page" :disabled="values.web_page.active"> 
                        {{ $t('ecommerce.customer') }}
                    </b-form-checkbox>
                    <b-form-checkbox id="deactivate-for-employee-page" v-model="values.web_page.active_for_employee" class="mb-2" name="deactivate-page" :disabled="values.web_page.active"> 
                        {{ $t('ecommerce.employee') }}
                    </b-form-checkbox>
                </div>
                <b-form-group id="input-group-1" :label="'Message'" label-for="input-1" class="size-input-grup">
                    <b-form-textarea class="custom-message-texarea" id="textarea" v-model="values.web_page.message" placeholder="Enter something..." rows="4">
                    </b-form-textarea>
                </b-form-group>
            </b-col>
            <b-col class="p-4">
                <el-collapse>
                    <el-collapse-item name="1">
                        <template slot="title">
                            {{ $t('ecommerce.footer') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.customize_page'">
                            <b-form-group id="input-group-7" :label="'Copyright'" label-for="input-7" class="size-input-grup ms-2 my-2 my-md-4">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.copyright" placeholder="Enter variable" rows="7">
                                </b-form-textarea>
                            </b-form-group>

                            <b-form-group id="input-group-7" :label="$t('ecommerce.head_title')" class="size-input-grup ms-2 my-2 my-md-4" label-for="input-8">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.title_head" placeholder="Enter variable" rows="7">
                                </b-form-textarea>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            {{ $t('ecommerce.api_keys') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.keys'">
                            <b-form-group id="input-group-6" :label="'Stripe client id'" label-for="input-6" class="size-input-grup ms-2 my-2 my-md-4">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.stripe_var" placeholder="Enter variable" rows="6">
                                </b-form-textarea>
                            </b-form-group>

                            <b-form-group id="input-group-6" :label="'Paypal client id'" label-for="input-11" class="size-input-grup ms-2 my-2 my-md-4">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.paypal_develop_var" placeholder="Enter variable" rows="6">
                                </b-form-textarea>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            {{ $t('ecommerce.country') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.select_country'">
                            <b-form-group id="multi-select-1" class="ms-2 my-2 my-md-4">
                                <multiselect class="custom-label" :id="'map-select'" v-model="values.web_page.country_default" :options="autoCompleteEcommerce" :label="'text'" :allowEmpty="false"/>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            {{ $t('ecommerce.default_lang') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.select_lang'"> 
                            <b-form-group id="multi-select-1" class="ms-2 my-2 my-md-4">
                                <multiselect class="custom-label" :id="'language-select'" v-model="values.web_page.statusLanguage" :options="types" :label="'text'" :allowEmpty="false"/>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="5">
                        <template slot="title">
                            {{ $t('ecommerce.view_products') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.select_view_product'">
                            <b-form-group id="multi-select-1" :label="$t('ecommerce.employee')" label-for="input-9"  class="ms-2 my-2 my-md-4">
                                <multiselect class="custom-label-view" :id="'view-select'" v-model="values.web_page.view_products" :options="typeView" :label="'name'" :allowEmpty="false"/>
                            </b-form-group>

                            <b-form-group id="multi-select-1" :label="$t('ecommerce.customer')" label-for="input-10" class="ms-2 my-2 my-md-4">
                                <multiselect class="custom-label-view" :id="'view-select'" v-model="values.web_page.view_client_products" :options="viewClient" :label="'name'" :allowEmpty="false"/>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="6">
                        <template slot="title">
                            {{ $t('ecommerce.permissions') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.permissions_to_edit'">
                            <b-form-checkbox id="deactivate-for-customer-page" v-model="values.web_page.active_address_employee" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px" name="deactivate-page" :disabled="values.web_page.active"> 
                                {{$t('ecommerce.active_employee') }}
                            </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="7">
                        <template slot="title">
                            {{ $t('ecommerce.social_media') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.url_social_media'">
                            <b-form-group v-show="true" id="input-group-2" class="size-input-grup w-100 ms-3 bg-gray-100" :label="'Facebook'" label-for="input-2">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.url_facebook" placeholder="Enter url" rows="4">
                                </b-form-textarea>
                                </b-form-group>

                                <b-form-group id="input-group-3" :label="'Twitter'" class="size-input-grup w-100 ms-3 bg-gray-100" label-for="input-3">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.url_twitter" placeholder="Enter url" rows="4">
                                </b-form-textarea>
                                </b-form-group>

                                <b-form-group id="input-group-4" :label="'Instagram'" class="size-input-grup w-100 ms-3 bg-gray-100" label-for="input-4">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.url_instagram" placeholder="Enter url" rows="4">
                                </b-form-textarea>
                                </b-form-group>

                                <b-form-group id="input-group-5" :label="'Youtube'"  class="size-input-grup w-100 ms-3 bg-gray-100" label-for="input-5">
                                <b-form-textarea  class="custom-texarea" id="textarea" v-model="values.web_page.url_youtube" placeholder="Enter url" rows="4">
                                </b-form-textarea>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <el-collapse-item name="8">
                        <template slot="title">
                            {{ $t('ecommerce.customer_service') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.service_url'">
                            <b-form-group id="input-group-2" :label="'Customer Service Intercom'" class="size-input-grup w-100 ms-3 bg-gray-100" label-for="input-2">
                                <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.url_intercom" placeholder="Enter url" rows="4">
                                </b-form-textarea>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <el-collapse-item name="9">
                        <template slot="title">
                            {{ $t('ecommerce.existence_products') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.view_existence'">
                                <b-form-checkbox id="deactivate-for-customer-page" v-model="values.web_page.active_exitence_product" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px" name="deactivate-page" :disabled="values.web_page.active"> 
                                    {{ $t('ecommerce.existence') }}
                                </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <el-collapse-item name="10">
                        <template slot="title">
                            {{ $t('ecommerce.id_when_billing') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Valor del seller id en el ecommerce'">
                            <b-form-group id="fieldset-default-seller-id" label="Default Seller ID" label-for="input-default-seller-id" class="'w-100 ms-3 bg-gray-100'">
                                <b-form-input class="" style="width: 119px" id="input-default-seller-id" v-model="values.default_seller_id" trim></b-form-input>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <el-collapse-item name="11">
                        <template slot="title">
                            {{ $t('ecommerce.customers') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.customers_info'">
                                <b-form-checkbox id="deactivate-order-for-customer-page" v-model="values.web_page.disable_cancel_order" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px" name="deactivate-page" :disabled="values.web_page.active"> 
                                    {{ $t('ecommerce.customer_disabled_info') }}
                                </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <el-collapse-item name="12">
                        <template slot="title">
                            {{ $t('ecommerce.search') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.search_configs_description'">
                                <b-form-checkbox id="activate-algolia-search" v-model="values.web_page.activate_algolia_search" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px"> 
                                    {{ $t('ecommerce.activate_algolia_search') }}
                                </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>

                    <!-- <el-collapse-item name="13">
                        <template slot="title">
                            {{ $t('Bank information') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Name account Bank'">
                            <b-form-group id="enter-message-rnc-id" :label="'Bank account in the name of'" label-for="enter-message-rnc" class="size-input-grup ms-2 my-2 my-md-4">
                                    <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.rnc" placeholder="Enter variable" rows="6">
                                    </b-form-textarea>
                            </b-form-group>

                            <b-form-group id="enter-rnc-id" :label="$t('Rnc')" class="size-input-grup ms-2 my-2 my-md-4" label-for="enter-rnc">
                                    <b-form-textarea class="custom-texarea" id="textarea" v-model="values.web_page.numberRnc" placeholder="Enter variable" rows="7">
                                </b-form-textarea>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item> -->

                    <el-collapse-item name="14">
                        <template slot="title">
                            {{ $t('ecommerce.summary_ecommerce') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'ecommerce.tax_receipt'">
                            <b-form-checkbox id="deactivate-for-tax_receipt" v-model="values.web_page.tax_receipt" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px" name="deactivate-page" :disabled="values.web_page.active"> 
                                {{$t('ecommerce.title_tax__receipt') }}
                            </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="15">
                        <template slot="title">
                            {{ $t('Category navigation bar') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Hide/show NavBar category'">
                            <b-form-checkbox id="nav-bar" v-model="values.web_page.nav_category" class="mb-2" :class="'w-100 ms-3 bg-gray-100'" style="margin-top: 10px" name="deactivate-page"> 
                                {{$t('Category bar') }}
                            </b-form-checkbox>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="16">
                        <template slot="title">
                            {{ $t('Address form') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Format of the form'">
                            <b-form-group id="multi-select-1" class="ms-2 my-2 my-md-4">
                                <el-select class="custom-label"  v-model="values.web_page.address_format" placeholder="Select">
                                    <el-option v-for="item in formatForm" :key="item.id" :label="item.text" :value="item.text">
                                    </el-option>
                                </el-select>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="17">
                        <template slot="title">
                            {{ $t('Validations') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Validation Number'">
                            <b-form-group id="multi-select-1" class="ms-2 my-2 my-md-4">
                                <el-select class="custom-label"  v-model="values.web_page.validate_phone_on_checkout" placeholder="Select">
                                    <el-option v-for="item in activeValidationNumber" :key="item.id" :label="item.text" :value="item.text">
                                    </el-option>
                                </el-select>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                    <el-collapse-item name="18">
                        <template slot="title">
                            {{ $t('Sucursales') }}
                        </template>
                        <GeneralCollapseSlots :subtitle="'Sucursal por defecto'">
                            <b-form-group id="multi-select-1" class="ms-2 my-2 my-md-4">
                                <el-select class="custom-label"  v-model="values.web_page.default_point_of_sale" placeholder="Select">
                                    <el-option v-for="item in store" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </b-form-group>
                        </GeneralCollapseSlots>
                    </el-collapse-item>
                </el-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import GeneralCollapseSlots from './GeneralCollapseSlots.vue';
export default {
    name: "General",
    props: {
        settings:{
            type: Object
        },
        store:{
            type: Object
        }
    },
    components:{
        GeneralCollapseSlots
    },
    data() {
        return {
            values: {},
            types: [
                {id: 1, text: 'EN'},
                {id: 2, text: 'ES'},
            ],
            statusLanguage: {id: 1, text: 'EN'},
            autoCompleteEcommerce: [
                {id: 1, text: 'DO'},
                {id: 2, text: 'US'},
            ],
            formatForm: [
                {id: 1, text: 'us'},
                {id: 2, text: 'do'},
            ],
            activeValidationNumber: [
                {id: 1, text: true},
                {id: 2, text: false},
            ],
            typeView: [
                {id: 1, text: 'grid', name: this.$t('ecommerce.cards')},
                {id: 2, text: 'list', name: this.$t('ecommerce.list')},
            ],
            viewClient: [
                {id: 1, text: 'grid', name: this.$t('ecommerce.cards')},
                {id: 2, text: 'list', name: this.$t('ecommerce.list')},
            ]
        }
    },
    // methods:{
    //     TestButton(){
    //     var x = document.getElementById("myDIV");
    //         if (x.style.display === "none") {
    //             x.style.display = "block";
    //         } else {
    //             x.style.display = "none";
    //         }
    //     }
    // },
    created(){
        // this.getPermissions()
        this.values = this.settings
    },
}
</script>

<style scoped>
.custom-texarea{
    height: 59px;
    width: 246px;
}
.custom-label{
    margin-top: 18px;
    height: 59px;
    width: 114px;
}

.size-input-grup{
    max-width: 250px;
}
.custom-label-view{
    height: 59px;
    width: 114px;
}
.custom-message-texarea{
    height:96px;
    width: 294px;
}
</style>
<template>
    <layout>
        <vue-element-loading :active="loading" :is-full-screen="false"/>
        <div class="row mt-3">
            <div class="col-lg-4">
                <orders-analytics :title="'ecommerce.total_revenues'" class="bg-success" :value="revenue"/>
            </div>
            <div class="col-lg-4">
                <orders-analytics :title="'ecommerce.processed_orders'" class="bg-primary" :value="processedOrders"/>
            </div>
            <div class="col-lg-4">
                <orders-analytics :title="'ecommerce.total_orders'" class="bg-info" :value="totalOrders"/>
            </div>
            <div class="col-lg-12 mb-3">
                <div class="row">
                    <div class="col-md-4 col-lg-3">
                        <label for="" class="me-2">{{$t('ecommerce.show_by_state')}}:</label>
                        <b-form-select v-model="stateSelected" :options="status" class="me-3"></b-form-select>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <label for="" class="me-2">{{$t("ecommerce.sort_by")}}:</label>
                        <b-button-group size="sm">
                            <b-button v-for="(btn, idx) in optionsOrderBy" :key="idx" :pressed.sync="btn.state" @click="filters.orderBy = btn.value" variant="secondary">
                                {{ btn.caption }} ({{btn.value}})
                            </b-button>
                        </b-button-group>
                    </div>
                    <div class="col-md-4 col-lg-4 d-flex align-items-center">
                        <label for="" class="mx-2">{{$t('ecommerce.show_by_date')}}:</label>
                        <date-picker v-model="filters.range" :input-class="'datePick'" range></date-picker>
                    </div>
                </div>
            </div>
        </div>
        <b-table striped hover thead-class="bg-primary text-white" class="bg-white" :items="orders" :fields="fields">
            <template #cell(time)="data">
                {{ data.item.created_at | timer}}
            </template>
            <template #cell(created_at)="data">
                {{$d(new Date(data.item.created_at), 'alt_short')}}
            </template>
            <template #cell(customer)="data">
                <div class="d-flex align-items-center">
                    <div>
                        <div class="avatar d-flex align-items-center justify-content-center me-3 bg-primary">
                            <abbr>{{data.item.order_primary.entity.first_name.slice(0,1) | textFormat}}</abbr>
                        </div>
                    </div>
                    {{ data.item.order_primary.entity.first_name | textFormat}} {{ data.item.order_primary.entity.last_name | textFormat}}
                </div>
            </template>
            <template #cell(amount)="data">
                <span class="font-weight-bold">
                    {{data.item.amount | moneyFormat}}
                </span>
            </template>
            <template #cell(order_status)="data">
                <div class="px-2 d-inline-block" :class="[data.item.order_status.name]" style="border-radius: 10px;">
                    {{$t('ecommerce.' + data.item.order_status.name)}}
                </div>
            </template>
            <template #cell(type)="data">
                <div class="text-white px-2 d-inline-block" :class="[data.item.order_primary.option_name == 'local_delivery' ? 'bg-info': data.item.order_primary.option_name == 'pick_up' ? 'bg-success' : '']" style="border-radius: 10px;">
                    {{$t('ecommerce.'+data.item.order_primary.option_name)}}
                </div>
            </template>
            <template #cell(actions)="data">
                <b-button variant="primary" size="sm" class="d-flex align-items-center" @click="orderSelected = data.item; showDetails = true">{{$t('ecommerce.see_details')}} <i class="fas fa-eye ms-2 fs-5"></i></b-button>
            </template>
        </b-table>
        <b-pagination v-model="filters.page" :total-rows="totalPages" :per-page="perPage" class="justify-content-center"></b-pagination>
        <order-details :order="orderSelected" @closeModal="showDetails = false" v-if="showDetails"/>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import OrdersAnalytics from '../components/orders/OrdersAnalytics.vue';
import { OrderService} from "../service/order-service";
import { mapGetters } from "vuex"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import OrderDetails from '../components/orders/OrderDetails.vue';
const service = new OrderService();
export default {
    name: "Orders",
    components: {
        Layout,
        OrdersAnalytics,
        DatePicker,
        OrderDetails
    },
    data() {
        return {
            filters: {
                name: null,
                cities: null,
                states: 1,
                range: null,
                orderBy:'desc',
                page: 1
            },
            orders: [],
            totalPages: 0,
            perPage: 10,
            revenue: 0,
            totalOrders: 0,
            processedOrders: 0,
            stateSelected: null,
            orderSelected: null,
            showDetails: false,
            loading: false
        }
    },
    computed:{
        fields() {
            // Accessing this.$i18n.locale in this computed prop tells Vue that
            // this computed prop should update whenever this.$i18n.locale
            // changes it value, even though we don't use the value.
            const lang = this.$i18n.locale
            // We add the following just so that minification optimizers
            // do not remove the above statement because the return value isn't used.
            if (!lang) { return [] }
            // Return the array of translated values
            return [
                {label: this.$t('ecommerce.order') + ' #', key: 'order_primary.global_token_id', sortable: true},
                {label: this.$t('ecommerce.time'), key: 'time', sortable: true},
                {label: this.$t('ecommerce.date'), key: 'created_at', sortable: true},
                {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                {label: this.$t('ecommerce.order_status'), key: 'order_status', sortable: true},
                {label: this.$t('ecommerce.type_of_purchase'), key: 'type', sortable: true},
                {label: this.$t('ecommerce.order_amount'), key: 'amount', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},
            ]
        },
        ...mapGetters({
            orderStatus: "ecommerce/orderStatus",
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        status(){
            let status = [{value: null, text: this.$t('ecommerce.all_state')}]
            this.orderStatus.map((state)=>{
                status.push({value: state.id, text: this.$t('ecommerce.'+ state.name)})
            })
            return status
        },
        optionsOrderBy(){
            return [
                { caption: this.$t('ecommerce.more_recent'), state: true, value: 'desc' },
                { caption: this.$t('ecommerce.older'), state: false, value: 'asc' },
            ]
        }
    }, 
    methods:{
        async getOrders(){
            try {
                this.loading = true
                const response = await this.$store.dispatch('ecommerce/getOrders', this.filters)
                this.orders = response.data
                this.totalPages = response.total
                this.perPage = response.per_page
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        },
        async getRevenue(){
            try {
                const response = await service.getRevenue(this.filters)
                this.revenue = parseFloat(response).toFixed(2)
            } catch (error) {
                console.log(error);
            }
        },
        async getTotalOrders(){
            try {
                const response = await service.getTotalOrders(this.filters)
                this.totalOrders = parseFloat(response)
            } catch (error) {
                console.log(error);
            }
        },
        async getCurrentProcessed(){
            try {
                const response = await service.getCurrentProcessed(this.filters)
                this.processedOrders = parseFloat(response)
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch:{
        filters:{
            deep: true,
            immediate: true,
            async handler(){
                await this.getOrders();
            }
        },
        stateSelected(val){
            this.filters.states = val
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const ordenes = modules?.subModules?.find(data => data.name === 'Ordenes')

            // Verifica si se han cambiado los permisos de administrador
            if (!ordenes.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    },
    async created() {
        this.getPermissions()
        // await this.getOrders()
        await this.$store.dispatch('ecommerce/getStatus')
        await this.getRevenue()
        await this.getTotalOrders()
        await this.getCurrentProcessed()
    },
}
</script>


<style scoped>
    .avatar{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
    }
    .table::v-deep > tbody > tr > td{
        vertical-align: middle;
        width: 12.5%;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input{
        width: 100%;
        border-radius: 6px;
        border: 1px solid rgb(225, 224, 240);
    }
    .custom-select{
        border-radius: 6px;
        border: 1px solid rgb(225, 224, 240);
        padding: 5px 0px;
    }
    .mx-datepicker-range{
        width: 210px;
    }
</style>

<template>
  <div style="min-height: 600px" class="card">
    <div class="card-header header-elements-inline">{{$t('ecommerce.misspelled_words')}}</div>
    <div class="card-body">
      <div v-if="wordSelected != null" class="input-group">
        <input v-model.trim="word" @keydown.enter="addBadWord" type="text" class="form-control" >
        <span class="input-group-append">
          <button class="btn btn-success" style="border-radius: 0;" @click="addBadWord" type="button">{{ $t('ecommerce.adding') }}</button>
        </span>
      </div>
      <div>
        <b-alert show variant="warning" v-if="wordSelected == null || (wordSelected != null && wordSelected.badWords.length ==0)" class="mt-4">
          <span v-if="wordSelected == null">{{ $t('ecommerce.no_well_spelled_word_selected') }}</span>
          <span v-else>{{$t('ecommerce.the_words')}} {{wordSelected.word }} {{ $t('ecommerce.no_corrected_words_yet') }}</span>
        </b-alert>
      </div>
      <template  v-if="wordSelected != null && wordSelected.badWords.length > 0">
        <div style="margin-top: 30px" class="card card-body border-top-warning">
          <div style="margin: 0; list-style: none" class="list-feed">
            <transition-group name="fade">
              <div :key="word"   v-for="(word, index) in wordSelected.badWords" class="list-feed-item" :class="[index > 0 ? 'TopMargin' : '']">
                {{ word }} <i class="fas fa-arrow-right mx-2"></i> {{ wordSelected.word }}
                <i class="fas fa-trash" style="float: right;font-size: 20px; color: #c62828;margin-right: 14px; cursor: pointer;" @click="deleteBadWord(wordSelected._id, word)"></i>
                <i @click="deleteBadWord(wordSelected._id, word)" style="cursor: pointer" class="icon-trash text-danger-800 float-right" ></i>
              </div>
            </transition-group>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { DictionaryService } from "../../service/dictionary-service";
  const service = new DictionaryService();
  import Swal from "sweetalert2";

  export default {
    name: "BadWords",
    props: ['wordSelected'],
    data(){
      return{
        word: ''
      }
    },
    methods: {
      async addBadWord(){
        try{
          if (this.word.trim().length === 0){
            return
          }
          await service.storeBadWord({ word: this.word, _id: this.wordSelected._id });
          this.wordSelected.badWords.push(this.word);
          this.word= "";
        }catch (e) {
        Swal.fire({
          title: 'Error',
          text: e.message,
          icon: "error",
        })
        }
      },
      async deleteBadWord(_id, word){
      const t = this;
      console.log('saliste')

      Swal.fire({
        title: this.$t('ecommerce.warning'),
        text: this.$t('ecommerce.are_you_sure'),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: this.$t('ecommerce.confirm'),
        cancelButtonText: this.$t('ecommerce.cancel'),
      }).then(async (result) => {
        const { isConfirmed } = await result;
        if (isConfirmed) {
          try {
          await service.destroy( _id, word );
          // const data = await  node.delete('api/dictionaries/badword', { data: { _id, word } });
          this.wordSelected.badWords = await this.wordSelected.badWords.filter(element => { return element !== word });
          // this.words = this.words.filter(element)
            t.setLoading();
            await t.post();
            t.$bvToast.toast(t.$t("TOAST.SAVE_DATA"), {
              title: "Success",
              variant: "success",
              toaster: "b-toaster-bottom-right",
              solid: true,
            });
            await Swal.fire({
              icon: "success",
              title: t.$t("lang.SWAL_ALERT.TRANSACTION.SUCCESFUL"),
              showConfirmButton: false,
              timer: 1500,
            });
            t.setLoading();
          } catch (e) {
            t.setLoading();
            t.$bvToast.toast(`${e.response.data.message}`, {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-bottom-right",
              solid: true,
            });

            await Swal.fire({
              icon: "error",
              title: t.$t("lang.SWAL_ALERT.TRANSACTION.FAILED"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.$notify({ title: 'Warning', text: e.message })
          }
        }
      });
      }
    }
  }
</script>

<style scoped>
  .TopMargin{
    margin-top:15px
  }
</style>

import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
// import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import router from "./router/index";
// import store from '@/state/store'
import store from "@/store";
import vco from "v-click-outside";

import { db } from "@/firebase-config";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
// languages
import i18n from "./i18n";

import "@/common/assets/styles/design/index.scss";

import Sparkline from "vue-sparklines";

import StarRating from "vue-star-rating";
Vue.component("StarRating", StarRating);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

// import { initFirebaseBackend } from './authUtils'
// import { configureFakeBackend } from './common/helpers/fake-backend';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_apiKey,
//   authDomain: process.env.VUE_APP_authDomain,
//   databaseURL: process.env.VUE_APP_databaseURL,
//   projectId: process.env.VUE_APP_projectId,
//   storageBucket: process.env.VUE_APP_storageBucket,
//   messagingSenderId: process.env.VUE_APP_messagingSenderId,
//   appId: process.env.VUE_APP_appId,
//   measurementId: process.env.VUE_APP_measurementId
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else {
//   configureFakeBackend();
// }

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMask);
import Print from "vue-print-nb";
// Global instruction
Vue.use(Print);

Vue.use(VueSweetalert2);
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
Vue.use(require("vue-chartist"));
Vue.use(vco);
Vue.use(Sparkline);
// Vue.use(VueGoogleMaps, {
//   load: {
//     region: "US",
//     language: "es",
//     key: process.env.VUE_APP_API_GOOGLE_MAPS_KEY,
//     libraries: "places",
//   },
//   installComponents: true,
// });
Vue.component("apexchart", VueApexCharts);

import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);

// icons
import EvaIcons from "vue-eva-icons";
Vue.use(EvaIcons);

// export to excel
import excel from "vue-excel-export";
Vue.use(excel);

// colors
import { ColorPicker, ColorPanel } from "one-colorpicker";
Vue.use(ColorPanel);
Vue.use(ColorPicker);

// lazyload
// or with options
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./common/assets/svg/no-available-icon.svg"),
  loading: require("./common/assets/images/loadings/loading.gif"),
  attempt: 1,
});

// Import the tooltip component before calling 'new Vue()'
import VueCustomTooltip from "@adamdehaven/vue-custom-tooltip";
// Install the plugin using ONE of the options below:
// --------------------------------------------------
// 1. Install with default options
Vue.use(VueCustomTooltip);

import Vidle from "v-idle";
Vue.use(Vidle);

import locale from "element-ui/lib/locale/lang/en";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { locale });

import { registerModules } from "@/common/helpers/register-modules.js";
import ecommerce from "@/modules/ecommerce";
import auth from "@/modules/auth";
import layout from "@/modules/layout";
import delivery from "@/modules/delivery";
import documentsFollowup from "@/modules/documents-followup";
import zones from "@/modules/zones";
import visit from "@/modules/visit";
import admin from "@/modules/admin";
import common from "@/common";


// filters
import "./common/helpers/filters";

registerModules({
  ecommerce: ecommerce,
  auth: auth,
  layout: layout,
  delivery: delivery,
  documentsFollowup: documentsFollowup,
  common: common,
  zones: zones,
  visit: visit,
  admin: admin,
});
//mixins for debug
Vue.mixin({
    methods: {
        cl: function (a) {
            console.log(a);
            return " P A P O "
        },
        defaultSize(){
          const sizeValue = this.generalSetting.general.web_page.country_default.text
          return sizeValue == 'US' ? 3 : 7;
        },
        defaultCountry(){
          const value = this.generalSetting.general.web_page.country_default.text
          return value == 'US' ? [-102.8720307149821, 42.31277964204047] : [-70.7260555, 19.0781721]
        },
        async getPermissions(){

          function mergeModulesWithExtraAccess(modules, extraAccess) {
            for (const module of modules) {
                const matchingExtraAccess = extraAccess.find(extraModule => extraModule.id === module.id);
      
                if (matchingExtraAccess) {
                if (matchingExtraAccess.active !== module.active) {
                    module.active = matchingExtraAccess.active;
                }
      
                if (module.subModules && matchingExtraAccess.subModules) {
                    module.subModules = mergeModulesWithExtraAccess(module.subModules, matchingExtraAccess.subModules);
                }
      
                // Verificar y actualizar 'active' en 'actions' y 'permissions'.
                const subObjectsToCheck = ['actions', 'permissions'];
                for (const key of subObjectsToCheck) {
                    if (module[key] && matchingExtraAccess[key]) {
                    for (let i = 0; i < module[key].length; i++) {
                        if (matchingExtraAccess[key][i] && module[key][i].active !== matchingExtraAccess[key][i].active) {
                        module[key][i].active = matchingExtraAccess[key][i].active;
                        }
                    }
                    }
                }
                }
            }
      
              return modules;
          }
      
          const accessEmployeesRef = db.ref(`admin/employees`);
          await accessEmployeesRef.once('value', (snapshot) => {
            const employeesRef = snapshot.val();
            const AllEmployees = []
            for (const id in employeesRef) {
              if (Object.hasOwnProperty.call(employeesRef, id)) {
                const object = {
                  id,
                  ...employeesRef[id]
                };
                AllEmployees.push(object);
              }
            }
            this.convertData = AllEmployees?.find((employee) => employee?.id === this.userData.entity.code)
            // console.log(this.convertData);
          })
          
          if(this.convertData){
            const getAccess = db.ref(`admin/accessLevels`);
            await getAccess.once('value', (snapshot) => {
              const getLevels = snapshot.val();
              const data = getLevels.filter(data => data?.id === this.convertData?.accessLevels)
              this.modules = data[0]?.modules
            })
      
            localStorage.removeItem('userAcess');
            this.result = mergeModulesWithExtraAccess(this.modules, this.convertData?.extraAccess);
      
              const mergedArray = [this.convertData]
              const data = []
              mergedArray.forEach(employee => {
                  data.push({
                      id: employee.id,
                      name: employee.name,
                      active: employee.active,
                      accessLevels: employee.accessLevels,
                      modules: this.result || this.modules
                  })
              });
      
            localStorage.setItem('userAcess', JSON.stringify(data[0]));
            this.$store.commit("auth/SET_PERMISSIONS", data[0])
          }


          if(!this.convertData){
            const getModules  = db.ref(`admin/modules`);
            await getModules.once('value', (snapshot) => {
                this.getModulesRef = snapshot.val();
            })

            const skeleton = [{ 
                id: this.userData.entity.code,
                name: this.userData.entity.first_name,
                active: this.userData.active,
                modules: this.getModulesRef,
            }]

            localStorage.setItem('userAcess', JSON.stringify(skeleton[0]));
            this.$store.commit("auth/SET_PERMISSIONS", skeleton[0])
          }
      
        }
    },
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

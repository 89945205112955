<script>
import appConfig from "@/app.config";
import { Auth } from 'aws-amplify';

/**
 * Recover password-1 component
 */
export default {
  name: "ForgotPassword",
  page: {
    title: "Recover password-1",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      email: '',
      next: false,
      form:{
        code: '',
        newPassword:'',
        newPasswordConfirm:''
      }
    }
  },
  methods: {
    async resetPassword(){
      try{
        await Auth.forgotPassword(this.email);
        this.next = true
      }catch(e){
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.message,
        })
      }
    },
    async onSubmit(){
      try {
        await Auth.forgotPasswordSubmit(this.email, this.form.code, this.form.newPasswordConfirm);
        // await this.$store.dispatch('auth/login', {username: this.email, password: this.newPasswordConfirm})
        this.$router.replace({path: '/login'})
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
        })
      }
    }
  },
};
</script>
<template>
  <div :style="{'background': 'url(' + require('@/common/assets/images/bg.jpg') + ')', 'background-size': 'cover', 'background-position': 'center'}" style="height: 100vh">
    <!-- <div class="bg-image" style="background: url('https://www.mundomovil.com.do/assets/img/mapa_mm.png')"></div> -->
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="fas fa-home h2 text-white"></i>
      </router-link>
    </div>
    <div class="account-pages pt-5 h-100 d-flex">
      <div class="container m-auto">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-4">
            <div class="card overflow-hidden" style="border-radius: 1.25rem;box-shadow: none;">
              <div class="bg-info">
                <div class="text-primary text-center p-4">
                  <h5 class="text-white font-size-20 p-2">Reset Password</h5>
                  <router-link to="/" class="logo logo-admin">
                    <img :src="require('../../../common/assets/images/mundo-movil/logo-sm.png')" height="74" alt="logo"/>
                  </router-link>
                </div>
              </div>

              <div class="card-body p-4">
                <div class="p-3" v-if="!next">
                  <div class="alert alert-success mt-4" role="alert" style="border-radius: 8px;border: none;">
                    Enter your Email and instructions will be sent to you!
                  </div>
                  <form class="form-horizontal mt-4" @submit.prevent="resetPassword">
                    <div class="mb-3">
                      <label class="form-label" for="useremail">Email</label>
                      <input type="email" class="form-control" id="useremail" placeholder="Enter email" v-model="email"/>
                    </div>

                    <div class="form-group row mb-0">
                      <div class="col-12 text-end">
                        <button class="btn btn-info w-md" type="submit">
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="p-3" v-else>
                  <b-form @submit.prevent="onSubmit">
                    <b-form-group id="input-group-1" label="Codigo:" label-for="input-1" class="mt-3">
                      <b-form-input id="input-1" v-model="form.code" placeholder="Codigo" required></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Nueva Contrasena:" label-for="input-2" class="mt-3">
                      <b-form-input id="input-2" v-model="form.newPassword" placeholder="Nueva Contrasena" type="password" required></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" label="Confirmar Nueva Contrasena:" label-for="input-3" class="mt-3">
                      <b-form-input id="input-3" v-model="form.newPasswordConfirm" placeholder="Confirmar Nueva Contrasena" type="password" required></b-form-input>
                    </b-form-group>
                    <div class="d-flex">
                      <b-button variant="primary" class="mt-3 ms-auto" type="submit">
                        Save
                      </b-button>
                    </div>
                  </b-form>
                </div>
                <div class="mt-2 text-center">
                  <p>
                    Remember It ?
                    <router-link to="/pages/login-1" class="fw-medium text-primary">Sign In here</router-link>
                  </p>
                  <p class="mb-0">
                    ©
                    {{ new Date().getFullYear() }}
                    Veltrix. Crafted with
                    <i class="mdi mdi-heart text-danger"></i> by Mundo Movil
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

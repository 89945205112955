<template>
    <layout>
        <h4 class="my-2">{{ $t('ecommerce.price_delivery') }}</h4>

        <b-row class="mt-3 mb-4">
            <b-col cols="1" class="d-flex align-items-center justify-content-center">
                <label for="">{{ $t('ecommerce.task_method') }}</label>
            </b-col>
            <b-col cols="2">
                <b-form-select class="w-75" v-model="selectedTaskMethod" :options="taskMethods" size="sm"></b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="3">
                <b-table sticky-header responsive thead-class="green-bg bg-primary text-white" class="bg-white" striped hover :items="stores" selectable select-mode="single" 
                :fields="fields" @row-selected="onRowSelected" selected-variant="success" tbody-class="cursor-pointer">
                </b-table>
            </b-col>
            <b-col>
                <h3>
                    {{storeSelected.description}}
                </h3>
                <delivery-form class="my-3" :delivery="delivery" @save="save"/>
            </b-col>
        </b-row>
        <vue-element-loading :active="loading" :is-full-screen="false"/>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { StoreService} from "../service/stores-services";
import DeliveryForm from '../components/delivery/DeliveryForm.vue';
const service = new StoreService();
import { DeliveryService} from "../service/delivery-service";
import { mapGetters } from 'vuex';
import { http } from "../../../common/services/web-backend";
const deliveryService = new DeliveryService();

export default {
    name: "DeliveryPrice",
    components:{
        Layout,
        DeliveryForm
    },
    data() {
        return {
            stores: [],
            delivery: {},
            storeSelected: {},
            loading: false,
            selectedTaskMethod: null,
            taskMethods: []
        }
    },
    computed:{
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        fields(){
            return [
                {label: 'ID', key: 'codplace', sortable: true},
                {label: 'Description', key: 'description', sortable: false},
            ]
        },
    },
    watch:{
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const deliveryPrice = modules?.subModules?.find(data => data.name === 'Precio de envio')
            // Verifica si se han cambiado los permisos de administrador
            if (!deliveryPrice.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
        selectedTaskMethod: {
            // immediate: true,
            handler: async function () {
                if (this?.storeSelected?.id) {
                    // fill the data again after changing task method
                    const response = await deliveryService.deliveries(this.storeSelected.id, this.selectedTaskMethod);
                    this.delivery = response.data
                }
            }
        }
    },
    methods: {
        async getTaskMethods() {
            try {
                const response = await http.get("/task-methods");
                const taskMethods = response.data.data;

                return taskMethods;
            } catch (error) {
                console.log(error);
            }
        },
        async getStores(){
            try{
                this.loading = true
                let response = await service.getStores();
                this.stores = response
            }catch (e) {
                console.log(e)

            }finally{
                this.loading = false

            }
        },
        async onRowSelected(item) {
            try {
                if(item.length > 0){
                    this.storeSelected = item[0]
                    const response = await deliveryService.deliveries(item[0].codplace, this.selectedTaskMethod);
                    this.delivery = response.data
                }else{
                    this.delivery = {}
                    this.storeSelected = {}
                }
            } catch (error) {
                this.isLoading = false 
                console.log(error)
            }finally{
                this.isLoading = false 
            }

        },
        async save(delivery){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try{
                        this.loading = true
                        delivery.task_method_id = this.selectedTaskMethod;
                        await deliveryService.store(this.storeSelected.codplace, delivery);
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                            icon: "success",
                        })
                    }catch (e) {
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                            icon: "error",
                        })
                    }finally{
                        this.loading = false
                    }
                }
            })
        },
    },
    async created(){
        this.getPermissions()
        await this.getStores()

        const taskMethods = await this.getTaskMethods();

        // format tasks methods for the select
        this.taskMethods = taskMethods.map((item) => {
            return {
                value: item.id,
                text: item.description,
            };
        });
        
        this.selectedTaskMethod = 1;
    }
}
</script>
<style scoped>
    .table-responsive::v-deep{
        max-height: 80vh;
    }

    .custom-select {
        border-radius: 6px;
        border: 1px solid rgb(225, 224, 240);
        padding: 5px 0px;
    }
</style>

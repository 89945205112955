<script>
import appConfig from "@/app.config";

export default {
    page: {
    title: "No tiene acceso",
    meta: [{ name: "description", content: appConfig.description }],
},
};
</script>
    <template>
    <!-- Begin page -->
    <div class="authentication-bg d-flex align-items-center pb-0 vh-100">
    <div class="content-center w-100">
        <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10">
            <div class="card">
                <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-lg-4 ms-auto">
                    <div class="ex-page-content">
                        <!-- <h1 class="text-dark display-1 mt-4">access</h1> -->
                        <h3 style="margin-top: 40px" class="mb-4">Sin permisos</h3>
                        <p class="mb-5">
                            Lo siento, no tienes los permisos necesarios para acceder a este módulo o submódulo. Si crees que esto es un error, por favor contacta al administrador del sistema para obtener acceso.
                        </p>
                        <router-link class="btn btn-primary mb-5" to="/">
                        <i class="mdi mdi-home"></i> volver al Dashboard
                        </router-link>
                    </div>
                    </div>
                    <div class="col-lg-5 mx-auto">
                    <img
                        src="@/common/assets/images/error.png"
                        alt
                        class="img-fluid mx-auto d-block"
                    />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!-- end card -->
        </div>
        <!-- end container -->
    </div>
    </div>
    <!-- end error page -->
    </template>
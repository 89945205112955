<template>
    <layout>
        <h3 class="mt-4 mb-3">{{$t('ecommerce.reviews')}} ({{totalDocs}})</h3>
        <b-row class="mb-4">
            <b-col>
                <label for="search-by-description">{{$t('ecommerce.search_by_product_description')}}</label>
                <b-form-input v-model="filters.q_product" debounce="1000" type="search"></b-form-input>
            </b-col>
            <b-col>
                <label for="search-by-description">{{$t('ecommerce.search_by_customer_name')}}</label>
                <b-form-input v-model="filters.q_customer" debounce="1000" type="search"></b-form-input>
            </b-col>
            <b-col>
                <label for="search-by-description">{{$t('ecommerce.search_by_user_email')}}</label>
                <b-form-input v-model="filters.q_customer_email" debounce="1000" type="search"></b-form-input>
            </b-col>            
            <b-col v-if="false">
                <label for="" class="mx-2">{{$t('ecommerce.show_by_date')}}:</label>
                <date-picker v-model="filters.range" :input-class="'datePick'" range></date-picker>
            </b-col>
        </b-row>
        <b-table :items="questions" responsive striped hover thead-class="bg-primary text-white" :fields="fields" details-td-class="bg-primary" table-class="m-0" class="bg-white"
        :busy="loading" show-empty style="max-height: 62vh;" sticky-header>
            <template #empty="scope">
                <div style="height: 54vh" class="d-flex flex-column justify-content-center align-items-center">
                    <h4>{{scope.emptyFilteredText}}</h4>
                    <b-button variant="info" class="mt-4" @click="reset"><eva-icon name="sync" width="20px" class="me-2" fill="#fff"></eva-icon>Reset Filters</b-button>
                </div>
            </template>
            <template #table-busy>
                <div class="skeleton" v-for="item in 15" :key="item"></div>
            </template>
            <template #cell(date)="data">
                {{$d(new Date(data.item.created_at), 'alt_short')}}
            </template>
            <template #cell(product)="data">
                <b>({{data.item.cod_articulo}})</b>  {{data.item.product.description | textFormat}}
            </template>
            <template #cell(customer)="data">
                <div class="d-flex align-items-center">
                    <avatar :name="data.item.customer.first_name" :lastname="data.item.customer.last_name" :showStar="false" :customerImg="data.item.customer.photo_url"
                    :width="30" :showTextVerified="false" :customerVerified="data.item.customer_verified"/>
                </div>
            </template>
            <template #cell(verified_by_admin)="data">
                <div class="d-flex justify-content-center">
                    <icon-check-circle v-if="data.item.verified_by_admin" :size="17" :color="'#02a499'"/>
                    <icon-danger-circle :color="'#ff4560'" v-else/>     
                </div>
            </template>
            <template #cell(actions)=data>
                <div class="d-flex align-items-center justify-content-center">
                    <eva-icon name="trash-2" width="22px" class="me-2 cursor-pointer" fill="#ff4560" @click.native="remove(data.item.id)"></eva-icon>
                    <!-- <eva-icon name="message-circle" width="22px" fill="#626ed4" class="me-2 cursor-pointer" @click.native="showFormAnswer = true"></eva-icon> -->
                </div>
            </template>
        </b-table>
        <b-pagination v-model="filters.page" :total-rows="totalDocs" :per-page="perPage" class="justify-content-center mt-4 mb-4"></b-pagination>
        <main-modal v-if="showAnswers" @closeModal="showAnswers = false">
            <answers :answers="selectProduct.answers"/>
        </main-modal>
    </layout>
</template>

<script>
import { ProductsService} from "../service/products-service";
const productService = new ProductsService();
import Layout from "@/modules/template/layouts/main";
import IconCheckCircle from '../../../common/components/svg/IconCheckCircle.vue';
import IconDangerCircle from '../../../common/components/svg/IconDangerCircle.vue';
import MainModal from '../../../common/components/modals/MainModal.vue';
import Answers from '../components/products/Answers.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; 
import Avatar from '../components/avatar/Avatar.vue';
import { mapGetters } from 'vuex';
export default {
    name: "Reviews",
    components:{
        Layout,
        IconCheckCircle,
        IconDangerCircle,
        MainModal,
        Answers,
        DatePicker,
        Avatar
    },
    data() {
        return {
            questions: [],
            totalDocs: 0,
            filters: {
                page: 1,
                limit: 15,
                range: null,
                order_by: 'created_at',
                customer_verified: null,
                verified_by_admin: null,
                q_customer: null,
                q_customer_email: null,
                q_customer_comments: null,
                q_product: null
            },
            totalPages: 0,
            perPage: 0,
            selectProduct: {},
            showAnswers: false,
            showFormAnswer: false
        }
    },
    methods:{
        async getReviews(){
            try {
                this.loading = true
                const response = await productService.getReviewsAdmin(this.filters)
                this.questions = response.docs
                this.totalDocs = response.totalDocs
                this.totalPages = response.totalPages
                this.perPage = response.limit;
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        },
        async remove(id){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "¿Esta seguro de que eliminar esta reseña?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirmar', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        const response = await productService.deleteReview(id);
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: response.message, 
                            icon: "success",
                        })
                        this.items = []
                        this.taskTable += 1
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                            icon: "error",
                        })
                    }
                }
            })
        },
        reset(){
            this.filters = {
                page: 1,
                limit: 15,
                range: null,
                order_by: 'created_at',
                customer_verified: null,
                verified_by_admin: null,
                q_customer: null,
                q_customer_email: null,
                q_customer_comments: null,
                q_product: null
            }
        }
    },
    watch:{
        filters:{
            deep: true,
            async handler(){
                await this.getReviews()
            }
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const reviews = modules?.subModules?.find(data => data.name === 'Reseñas')
                // Verifica si se han cambiado los permisos de administrador
                if (!reviews.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
        },
    },
    computed:{
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        fields(){
            return [
                {label: this.$t('ecommerce.code'), key: 'customer_id', sortable: true},
                {label: this.$t('ecommerce.date'), key: 'date', sortable: true},
                {label: this.$t('ecommerce.product'), key: 'product', sortable: true},
                {label: this.$t('ecommerce.admin'), key: 'verified_by_admin', sortable: true},
                {label: this.$t('ecommerce.review'), key: 'comment', sortable: true},
                {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions'},
            ]
        },
    },
    async created(){
        this.getPermissions()
        try {
            await this.getReviews()
        } catch (error) {
            console.log(error);
        }
    }
}
</script>
<style scoped>
.table-responsive::v-deep > table{
    vertical-align: middle;
}
.avatar{
    background: #FA9696;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    object-fit: cover;
}
.table-responsive::v-deep > table > tbody > tr > td{
    max-width: 500px;
    overflow: hidden;
}
.table-responsive::v-deep > table > thead{
    position: sticky;
    top: 0px;
}
.img-profile{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 1px solid #e2e1ee;
    border-radius: 50%;
}
.total-answers{
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 12px;
    color: white;
    line-height: 21px;
    cursor: pointer;
}
</style>

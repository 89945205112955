<template>
    <main-modal @closeModal="() => this.$emit('closeModal')">
        <b-row class="flex-wrap">
            <b-col sm="7" lg="4" xl="3" class="drag-container mx-auto mx-lg-0" style="position: sticky; width: 300px;">
                <h3>{{ $t("delivery.actions") }}</h3>
                <div class="mb-2">
                    <b-form-group :label="$t('delivery.search')">
                        <b-form-input type="text" v-model="searchText" @keydown="filterList1"></b-form-input>
                    </b-form-group>
                </div>
                <button class="btn btn-success mb-3" @click="addAll">{{ $t("delivery.add_all") }}</button>
                <draggable
                    class="drag-area-1 list-group"
                    :list="this.filteredList1"
                    :group="{ name: 'group1', pull: 'clone', put: false }"
                    @change="log"
                >
                    <div class="list-group-item" v-for="element in this.filteredList1" :key="element.id">
                        {{ element.description }}
                    </div>
                </draggable>
            </b-col>

            <b-col lg="8" xl="8" class="ms-3 drag-container">
                <h3>{{ $t("delivery.selected_actions") }}</h3>
                <div class="d-flex mb-3 align-items-center">
                    <p><strong>{{ $t("delivery.type") }}:</strong> {{ this.typeData.description }}</p>
                    <b-button @click="save" :variant="'primary'" class="d-block ms-auto" :disabled="saveLoading">
                        {{ $t("delivery.save") }}
                    </b-button>
                </div>

                <draggable
                    class="drag-area-2 list-group"
                    :list="list2"
                    group="group1"
                    @change="log"
                    :emptyInsertThreshold="500"
                >
                    <div class="list-group-item d-flex justify-content-between" v-for="(element, index) in list2" :key="element.id">
                        {{ element.description }}
                        <eva-icon class="cursor-pointer" @click.native="removeElement(index)" name="trash-2" fill="#ec4561" width="25" height="25"></eva-icon>
                    </div>

                    <div v-if="list2.length < 1" class="drop-here-background d-flex flex-column justify-content-center align-items-center" style="z-index: 1; opacity: 0.4;">
                        <icon-drop-here class="drop-here-icon mt-3" :size="118"/>
                        <h4 style="margin-top: 10px; color: rgb(184 184 184);">{{ $t('delivery.drag_here') }}</h4>
                    </div>
                </draggable>
                <div v-if="this.list2.length > 0" class="d-flex mt-2 justify-content-end">
                    <button class="btn btn-danger" @click="deleteAll">{{ $t("delivery.erase_all") }}</button>
                </div>
            </b-col>
        </b-row>
        <vue-element-loading :active="loading" :is-full-screen="false"/>
    </main-modal>
</template>

<script>
    import MainModal from '@/common/components/modals/MainModal.vue';
    import draggable from 'vuedraggable';
    import IconDropHere from '../../../assets/svg/iconDropHere.vue';
    import { http as backend} from "@/common/services/web-backend.js";

    // let idGlobal = 8;

    export default {
        components: {
            MainModal,
            draggable,
            IconDropHere
        },
        props: {
            type: String,
            typeData: {
                type: Object
            }
        },
        data() {
            return {
                baseUrl: "",
                searchText: "",
                loading: false,
                saveLoading: false,
                // list with all the actions
                list1: [], 
                filteredList1: [],
                // list with the actions for the selected type 
                list2: [],
            }
        },
        methods: {
            addAll() {
                this.list2 = [...this.list1];
            },
            deleteAll() {
                this.list2 = [];
            },
            filterList1() {
                this.filteredList1 = this.list1.filter(item => item.description.toLowerCase().includes(this.searchText.toLowerCase()));
            },
            removeElement(index) {
                this.list2.splice(index, 1);
            },
            log: function(evt) {
                window.console.log(evt);
            },
            async save() {
                try {
                    this.saveLoading = true;
                    const payload = {
                        actions: []
                    };

                    payload.actions = this.list2.map((row) => row.id);
                    const response = await backend.put(`${this.baseUrl}/${this.typeData.id}/actions`, payload);

                    if (response.status != 200) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    this.$swal.fire({
                        title: `${this.$t('delivery.the_actions_for_the_type')} '${this.typeData.description}' ${this.$t("delivery.were_updated_successfully")}`,
                        icon: "success",
                    }).then(() => {
                        this.$emit('closeModal')
                    });
                } catch (e) {
                    console.log(e);
                } finally {
                    this.saveLoading = false;
                }
            },
        },
        async created() {
            switch (this.type) {
                case "task-setting":
                    this.baseUrl = "task-types";
                    break;
                case "incident-setting":
                    this.baseUrl = "incidents/types"
                    break;
                default:
                    this.baseUrl = "task-types";
                    break;
            }
            try {
                this.loading = true;

                const responses = await Promise.all([
                    backend.get(`actions`),
                    backend.get(`${this.baseUrl}/${this.typeData.id}/actions`)
                ]);
                
                const allFulfilled = responses.every(response => {
                    return response.status == 200;
                });

                if (!allFulfilled) {
                    throw new Error(this.$t("delivery.something_went_wrong"));
                }

                const allActionsData = responses[0]?.data?.data;
                const selectedTypeActionsData = responses[1]?.data?.data;

                this.list1 = allActionsData;
                this.list2 = selectedTypeActionsData;

                this.filteredList1 = this.list1;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }      
        }
    }
</script>

<style lang="scss" scoped>
    p {
        margin: 0;
        padding: 0;
    }
    .drag-container {
        max-height: 750px;
        overflow-y: auto;
    }

    .drag-area-2 {
        border: 2px solid #e9ecef;
        background-color:#eff5f7;;
    }

    .grabbing * {
        cursor: grabbing;
    }
</style>

<template>
    <icon-base :color="color" :size="size">
        <path class="st0" d="M125,0.259C56.108,0.259,0.259,56.108,0.259,125S56.108,249.741,125,249.741
        c68.892,0,124.741-55.848,124.741-124.741S193.892,0.259,125,0.259z M159.825,74.238h-0.197c0,0.984-0.787,1.968-1.967,1.968
        h-14.953c-3.739,0-6.69,2.951-6.69,6.689v15.74h21.249c1.18,0,1.967,0.984,1.967,1.967l-1.967,22.036
        c0,0.984-0.984,1.771-1.968,1.771h-19.478v73.388c0,0.984-0.787,1.967-1.968,1.967h-26.365c-0.984,0-1.967-0.787-1.967-1.967V125.59
        v-1.18H92.142c-0.984,0-1.968-0.787-1.968-1.968v-22.036c0-0.984,0.787-1.967,1.968-1.967h13.379V77.189
        c0-14.953,12.001-26.955,26.955-26.955h25.381c0.984,0,1.968,0.787,1.968,1.967V74.238z"/>
    </icon-base>
</template>
<script>
import IconBase from './IconBase.vue'
export default {
    components: { IconBase },
        name: 'IconFacebook',
        props: {
            size: {
                type: Number,
                default: 20,
            },
            color: {
                type: String,
                default: 'var(--dark-main-navy)'
            }
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-6 d-flex align-items-center">
                <p class="subtitle-17 mb-0">{{ $t(subtitle) }}</p>
            </div>
            <div class="col-md-6">
            <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneralCollapse",
    props: {
        subtitle:{
            type: String
        },
        title: {
            typ: String
        }
    }
}
</script>

<style scoped>
</style>
<template>
    <div>
        <div class="my-2">
            <b-row class="mt-2">
                    <b-col cols="12">
                        <b-row>
                            <b-col lg="4" xl="3">
                                <label for="date" class="me-2">{{ $t('delivery.filter_for_date') }}:</label>
                                <date-picker v-model="range" :value="range" :input-class="'datePick'" range></date-picker>
                            </b-col>
                            <b-col class="d-flex align-items-center mt-3">
                                <b-button variant="warning" @click="getTaskIncidents" size="sm" class="ms-3">
                                    <eva-icon name="refresh" fill="#fff"></eva-icon>
                                </b-button>
                            </b-col>
                            <b-col class="d-flex align-items-center justify-content-end mt-3">
                                <div class="me-2">
                                    <b-button-group size="sm" class="d-block">
                                        <b-button v-for="(btn, idx) in optionsOrderBy" :key="idx" :pressed.sync="btn.state" @click="filters.order_by = btn.value" variant="info">
                                            {{ btn.caption }}
                                        </b-button>
                                    </b-button-group>
                                </div>
                                <div>
                                    <b-button-group size="sm" class="d-block">
                                        <b-button v-for="(btn, idx) in optionsOrderByType" :key="idx" :pressed.sync="btn.state" @click="filters.order_by_type = btn.value" variant="info">
                                            {{ btn.caption }}
                                        </b-button>
                                    </b-button-group>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col lg="6" xl="3">
                        <label class="me-2">{{ $t('delivery.search_by_incident_name_task_id_or_task_document_id') }}:</label>
                        <b-form-input v-model="filters.keywords" debounce="1000"></b-form-input>
                    </b-col>
                    <b-col lg="4" xl="2">
                        <multi-select-with-filters :options="stores" @onSelect="(data)=>{filters.sales_location_id = data}" label="delivery.filter_by_branch" id="filter-by-branch" trackBy="codplace" selectLabel="description"/>
                    </b-col>
                    <b-col lg="4" xl="2">
                        <multi-select-with-filters :options="status" @onSelect="(data)=>{filters.status = data}" label="delivery.filter_by_state" id="filter-by-status" trackBy="id" selectLabel="description"
                        :default="defaultStatus"/>
                    </b-col>
                    <b-col lg="4" xl="2">
                        <multi-select-with-filters :options="incidentTypes" @onSelect="(data)=>{filters.incident_type_id = data}" label="delivery.filter_by_incident_type" id="filter-by-incident-type" trackBy="id" selectLabel="description" />
                    </b-col>
            </b-row>
        </div>
        <div>
            <vue-element-loading :active="loadingAssing" :is-full-screen="false"/>
            <!-- <b-table striped hover responsive :items="taskIncidents" :fields="fields" class="bg-white" thead-class="bg-primary text-white" style="max-height: 45vh;overflow-x: hidden;" :tbody-tr-class="rowClass">
                <template #cell(employee)="data">
                    <vue-custom-tooltip :label="(`${data.item.employee.first_name} ${data.item.employee.last_name}`).toUpperCase()" :size="'is-small'" v-if="data.item.employee">
                        <h6 class="text-capitalize text-limited mb-0" style="max-width: 160px;">
                            {{ data.item.employee.first_name | textFormat}} {{data.item.employee.last_name | textFormat}}
                        </h6>
                    </vue-custom-tooltip>
                </template>
                <template #cell(created_at)="data">
                    {{moment(data.item.created_at).format("DD-MM-YYYY HH:mm:ss")}}
                </template>
                <template #cell(actions)="data">
                    <div class="d-flex justify-content-center">
                        <vue-custom-tooltip :label="$t('delivery.incident_detail')" :size="'is-small'" class="me-3">
                            <eva-icon name="eye" width="30" height="30" :fill="'#02a499'" @click.native="selectIncident(data.item)"></eva-icon>
                        </vue-custom-tooltip>
                        <vue-custom-tooltip :label="'Detalles de la tarea'" :size="'is-small'" class="me-3">
                            <eva-icon name="car-outline" width="30" height="30" :fill="'#02a499'" @click.native="selectTask(data.item)"></eva-icon>
                        </vue-custom-tooltip>
                    </div>
                </template>
                <template #cell(status)="data">
                <div :style="{background : data.item.status.background_color}" class="time-sla mx-2">
                    {{ data.item.status.description }}
                </div>
                </template>
            </b-table> -->
            <incident-table :items="taskIncidents" @selectIncident="selectIncident" @selectTask="selectTask"></incident-table>
        </div>
        <div class="d-flex justify-content-end align-items-center my-2">
            <b-pagination v-model="filters.page" :total-rows="totalDocs" :per-page="filters.limit" class="m-0"></b-pagination>
            <span class="ms-5 me-2">
                {{ $t('delivery.showing') }}
            </span>
            <div class="d-flex align-items-center">
                <icon-minus class="cursor-pointer" :color="'#626ed4'" @click.native="filters.limit = parseInt(filters.limit) - 1"/>
                <b-form-input id="limit" v-model="filters.limit" debounce="1000" class="input-limit"></b-form-input>
                <icon-plus class="cursor-pointer" :color="'#626ed4'" @click.native="filters.limit = parseInt(filters.limit) + 1"/>
            </div>
            <span class="ms-2">
                {{$t('delivery.for_page')}}
            </span>
        </div>
        <incident-details @closeModal="showIncidentDetailModal = false" v-if="showIncidentDetailModal" />
        <task-details @closeModal="showTaskDetailModal = false" v-if="showTaskDetailModal"/>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; 
import IconPlus from '../../../../common/components/svg/IconPlus.vue';
import IconMinus from '../../../../common/components/svg/IconMinus.vue';
import TaskDetails from './modals/TaskDetails.vue';
import IncidentDetails from "./modals/IncidentDetails.vue";
import MultiSelectWithFilters from '../../../../common/components/selects/MultiSelectWithFilters.vue';
import IncidentTable from "./IncidentTable.vue";

export default {
    name: "IncidentManager",
    data() {
        return {
            filters: {
                employee_id: [],
                sales_location_id: [],
                date: null,
                order_by_type: 'desc',
                order_by: 'created_at',
                keywords: null,
                limit: 15,
                page: 1,
                status: [{id: 3, description: 'En curso'}],
                incident_type_id: []
            },
            totalPages: 0,
            perPage: 10,
            range: null,
            loadingAssing: false,
            showMap: false,
            showIncidentDetailModal: false,
            showTaskDetailModal: false,
            optionsAssign:[
                {value: 0, text: 'No asignadas'},
                {value: 1, text: 'Asignadas'}
            ],
            totalDocs: 0,
            moment: moment,
            defaultStatus:[{id: 3, description: 'En curso'}]
        }
    },
    components:{
        DatePicker,
        IconPlus,
        IconMinus,
        TaskDetails,
        MultiSelectWithFilters,
        IncidentDetails,
        IncidentTable
    },
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
            stores: "ecommerce/stores",
            status: "delivery/status",
            incidentTypes: "delivery/incidentType",
            taskIncidents: "delivery/taskIncidents",
            taskDetails: "delivery/taskDetails"
        }),
        deliveryOptions(){
            let employees = []
            for (let i = 0; i < this.employees.length; i++) {
                const employee = this.employees[i];
                employees.push({value: employee.entity.code, text: `${employee.entity.first_name}  ${employee.entity.last_name}`})
            }
            return employees
        },
        branchOptions(){
            let stores = [{value: null, text: this.$t('delivery.choose_an_option')}]
            for (let i = 0; i < this.stores.length; i++) {
                const store = this.stores[i];
                stores.push({value: store.codplace, text: store.description})
            }
            return stores
        },
        // fields() {
        //     return [
        //         {label: '# '+ this.$t('delivery.incident'), key: 'id', sortable: true},
        //         {label: this.$t('delivery.name'), key: 'name', sortable: true},
        //         {label: this.$t('delivery.incident_type'), key: 'incident_type', sortable: true},
        //         {label: this.$t('delivery.task_id'), key: 'task_id', sortable: true},
        //         {label: this.$t('delivery.task_type'), key: 'task_type', sortable: true},
        //         {label: this.$t('delivery.delivery'), key: 'task_employee_name', sortable: true},
        //         {label: this.$t('delivery.document_id'), key: 'task_document_id', sortable: true},
        //         {label: this.$t('delivery.date'), key: 'created_at', sortable: true},
        //         // {label: 'Fields', key: 'fields', sortable: true},
        //         {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},
        //     ];
        // },
        optionsOrderByType(){
            return [
                { caption: 'Desc', state: this.filters.order_by_type == 'desc', value: 'desc' },
                { caption: 'Asc', state: this.filters.order_by_type == 'asc', value: 'asc' },
            ]
        },
        optionsOrderBy(){
            return [
                { caption: this.$t('delivery.creation'), state: this.filters.order_by == 'created_at', value: 'created_at' },
            ]
        },
        dragOptions() {
            return {
                animation: 500,
                group: "tasks",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    methods:{
        async getTaskIncidents(){
            try {
                this.loadingAssing = true
                let status = this.filters.status.map((item)=> item.id)
                let employees = this.filters.employee_id.map((item)=> item.value)
                let branches = this.filters.sales_location_id.map((item)=> item.codplace)
                let incidentTypes = this.filters.incident_type_id.map(item => item.id)
                let filters = {
                    type_id: incidentTypes,
                    employee_code: employees,
                    task_point_of_sale_id: branches,
                    date: this.filters.date,
                    order_by: this.filters.order_by,
                    order_by_type: this.filters.order_by_type,
                    q: this.filters.keywords ? this.filters.keywords : null,
                    task_status_id: status,
                    limit : this.filters.limit,
                    page: this.filters.page,
                }
                
                const response = await this.$store.dispatch('delivery/getAllTaskIncidents', filters)
                this.totalDocs = response.total
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('delivery.something_went_wrong'),
                    text: error.response?.data?.message,
                })
            }finally{
                this.loadingAssing = false
            }
        },
        async selectTask(item){
            try {
                this.showTaskDetailModal = true
                await this.$store.dispatch('delivery/getTaskDetails', item.task_id)
                await this.$store.dispatch('delivery/getTaskIncidents', {task_id: item.task_id})
            } catch (error) {
                console.log(error);
            }
        },
        async selectIncident(item) {
            try {
                this.showIncidentDetailModal = true;
                this.$store.commit("delivery/SET_INCIDENT_DETAILS", item);
            } catch (error) {
                console.log(error);
            }
        },
        // rowClass(item, type) {
        //     if (!item || type !== "row") return;

        //     // Mark as green the incidents which task are completed
        //     if (item.task_status_id == 5) {
        //         return "table-success";
        //     }
        // }
    },
    watch: {
        filters:{
            deep: true,
            async handler(){
                try {
                    this.loadingAssing = true
                    await this.getTaskIncidents()
                } catch (error) {
                    console.log(error);
                }finally{
                    this.loadingAssing = false
                }
            }
        },
        range(val){
            if(val[0]){
                let start = moment(val[0]).format('YYYY-MM-DD')
                let end = moment(val[1]).format('YYYY-MM-DD')
                this.filters.date = [start, end]
            }else{
                this.filters.date = null
            }
        },
    },
    async created(){
        let dateMnsFive = moment(new Date).subtract(5, 'day');
        let date = new Date(dateMnsFive.toISOString());
        this.range = [date, new Date()]
    },
}
</script>

<style scoped>
    .mx-datepicker::v-deep{
        border: none;
        padding: 0;
        width: 100%;
    }
    .modal-mask.delivery-detail ::v-deep > .modal-container > .row > .map > .delivery-map{
        min-width: 500px;
    }
    .input-limit{
        height: 35px;
        width: 45px;
        padding: 4px;
        text-align: center;
        font-size: 12px;
        background: transparent;
        border: 0;
    }
    .table-responsive::v-deep > .table > tbody > tr > td{
        padding: 4px;
        vertical-align: middle;
    }
    .table-responsive::v-deep > .table{
        text-align: center;
    }
    .cursor-no-drop{
        cursor:no-drop !important;
    }
    .time-sla{
        border-radius: 20px;
        text-align: center;
        color: #fff;
        /* max-width: 100px; */
        /* margin: auto; */
    }
</style>



export default {
  SET_EMPLOYEES(state, employees){
    state.employees = employees
  },
  SET_TASK_TYPE(state, taskType){
    state.taskType = taskType
  },
  SET_INCIDENT_TYPE(state, incidentType) {
    state.incidentType = incidentType
  },
  SET_TASKS(state, tasks){
    state.tasks = tasks
  },
  SET_TASK_INCIDENTS(state, incidents) {
    state.taskIncidents = incidents
  },
  SET_SINGLE_TASK_INCIDENTS(state, incidents) {
    state.singleTaskIncidents = incidents
  },
  SELECT_TASK(state, task){
    let condition = state.selectTask.findIndex((el) => el.id == task.id) == -1
    if(condition)
    state.selectTask.push({...task, selected: true})
  },
  RESET_SELECT_TASK(state){
    state.selectTask = []
  },
  SET_TASK_STATUS(state, payload){
    state.status = payload
  },
  SET_TASK_METHOD(state, payload){
    state.methods = payload
  },
  SET_AGENTS(state, payload){
    state.agents = payload
  },
  SET_LOCATION_ASSIGN_MAP(state, payload){
    state.locationAssignMap = payload;
  },
  SET_TASK_DETAILS(state, payload){
    state.taskDetails = payload;
  },
  SET_SALES_EMPLOYEE(state, payload){
    state.salesEmployee = payload;
  },
  SET_INCIDENT_DETAILS(state, payload) {
    state.incidentDetails = payload;
  }
};

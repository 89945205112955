<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 390.206 390.206" >
        <path style="fill:#FFC10D" d="M284.796,63.418c0,16.291,22.756,59.604,41.762,91.604c18.941-31.418,41.762-75.313,41.762-91.604
            c0-23.273-18.941-41.762-41.762-41.762C303.802,21.657,284.796,40.663,284.796,63.418z"/>
        <g>
            <path style="fill:#194F82" d="M327.074,0c-35.232,0-63.935,28.703-63.935,63.418c0,31.418,48.808,108.929,54.174,117.592
                c3.814,6.529,14.093,7.046,18.424,0c5.43-8.663,54.174-86.174,54.174-117.592C390.493,28.703,361.79,0,327.074,0z M368.836,63.418
                c0,16.291-22.756,59.604-41.762,91.604c-18.941-32-42.279-75.313-42.279-91.604c0-23.273,18.941-41.762,41.762-41.762
                C349.83,21.657,368.836,40.663,368.836,63.418z"/>
            <path style="fill:#194F82" d="M331.988,70.465h-9.762c-5.947,0-10.861-4.848-10.861-10.861s4.849-10.861,10.861-10.861h9.762
                c5.947,0,10.861,4.848,10.861,10.861S337.935,70.465,331.988,70.465z"/>
            <path style="fill:#194F82" d="M274.517,390.206H50.711c-5.947,0-10.861-4.848-10.861-10.861c0-5.947,4.848-10.861,10.861-10.861
                H274c29.285,0,53.139-23.855,53.139-53.139s-23.919-53.01-53.139-53.01h-95.418c-27.087,0-49.325-22.238-49.325-49.325
                s22.238-49.325,49.325-49.325H297.79c5.947,0,10.861,4.848,10.861,10.861c0,5.947-4.848,10.861-10.861,10.861H178.581
                c-15.192,0-27.669,12.477-27.669,27.669c0,15.192,12.477,27.669,27.669,27.669h95.935c41.18,0,74.796,33.616,74.796,74.796
                C348.796,356.59,315.179,390.206,274.517,390.206z"/>
        </g>
        <path style="fill:#56ACE0" d="M76.699,266.085c0,5.947-4.848,10.861-10.861,10.861s-10.861-4.848-10.861-10.861v-17.907H22.525
            l-0.517,86.691l86.691,0.517c0,0,0.517,0,0.517-0.517v-86.626H76.699C76.699,248.242,76.699,266.085,76.699,266.085z"/>
        <path style="fill:#194F82" d="M108.699,226.521H22.525c-11.895,0-22.238,9.762-22.238,22.238v86.174
            c0,11.895,9.762,22.238,22.238,22.238h86.174c11.895,0,22.238-9.762,22.238-22.238v-86.691
            C130.872,236.283,120.594,226.521,108.699,226.521z M109.216,334.933C109.216,334.933,108.699,335.451,109.216,334.933H21.943
            l0.517-86.691h32.517v17.907c0,5.947,4.848,10.861,10.861,10.861c6.012,0,10.861-4.848,10.861-10.861v-17.907h32.517V334.933z"/>
    </icon-base>
</template>

<script>
export default {
name: 'IconRouter',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


import { AuthService } from "@/modules/auth/services/auth-service.js";
import router from "../../../router";
const authService = new AuthService();
import { Auth } from 'aws-amplify';
import { db } from "@/firebase-config";

export default {
    async login ({commit}, {form}) {
      function mergeModulesWithExtraAccess(modules, extraAccess) {
        for (const module of modules) {
            const matchingExtraAccess = extraAccess.find(extraModule => extraModule.id === module.id);
  
            if (matchingExtraAccess) {
            if (matchingExtraAccess.active !== module.active) {
                module.active = matchingExtraAccess.active;
            }
  
            if (module.subModules && matchingExtraAccess.subModules) {
                module.subModules = mergeModulesWithExtraAccess(module.subModules, matchingExtraAccess.subModules);
            }
  
            // Verificar y actualizar 'active' en 'actions' y 'permissions'.
            const subObjectsToCheck = ['actions', 'permissions'];
            for (const key of subObjectsToCheck) {
                if (module[key] && matchingExtraAccess[key]) {
                for (let i = 0; i < module[key].length; i++) {
                    if (matchingExtraAccess[key][i] && module[key][i].active !== matchingExtraAccess[key][i].active) {
                    module[key][i].active = matchingExtraAccess[key][i].active;
                    }
                }
                }
            }
            }
        }
  
          return modules;
      }

      try {
        // Iniciar sesion con cognito. (1)
        Auth.configure({authenticationFlowType: "CUSTOM_AUTH"})
        const response = await Auth.signIn(form);
        const mfaEmployee = await authService.getEmployeeMFA({username:form.username})
        if(mfaEmployee.mfa_activated === true){
            return mfaEmployee
        }
        // Verificando si el email no esta confirmado. (3)
        // console.log(response.challengeName === 'SOFTWARE_TOKEN_MFA', 'hola');
        if(response.challengeParam?.challenge == 'EMAIL_CONFIRM_CHALLENGE'){
          return {user: response, confirmEmail: true}
        }
        // <-----------3------------>
        let groups = response.signInUserSession.accessToken.payload["cognito:groups"]


        const refreshToken = response.signInUserSession.refreshToken.token

        // Comprobar si es un empleado.
        if( groups.indexOf("EMPLOYEE") !== -1){
          commit('SET_TOKEN', response.signInUserSession.idToken.jwtToken)
          commit("SET_REFRESH_TOKEN", refreshToken);

        }else{
          return 'isEmployee'
        }
        // <-----------1------------>
        // Obtener los datos del usuario. (2)
        const information = await authService.login()
          const accessEmployeesRef = db.ref(`admin/employees`);
            await accessEmployeesRef.once('value', (snapshot) => {
                const employeesRef = snapshot.val();
                const AllEmployees = []
                for (const id in employeesRef) {
                    if (Object.hasOwnProperty.call(employeesRef, id)) {
                        const object = {
                            id,
                            ...employeesRef[id]
                        };
                        AllEmployees.push(object);
                    }
                }
                this.convertData = AllEmployees?.find((employee) => employee?.id === information.user.entity.code)
                // console.log(this.convertData);
          })

          if(this.convertData){
            const getAccess = db.ref(`admin/accessLevels`);
            await getAccess.once('value', (snapshot) => {
              const getLevels = snapshot.val();
              const data = getLevels.filter(data => data?.id === this.convertData?.accessLevels)
              this.modules = data[0]?.modules
            })

            this.result = mergeModulesWithExtraAccess(this.modules, this.convertData?.extraAccess);

              const mergedArray = [this.convertData]
              const data = []
              mergedArray.forEach(employee => {
                  data.push({
                      id: employee.id,
                      name: employee.name,
                      active: employee.active,
                      accessLevels: employee.accessLevels,
                      modules: this.result || this.modules
                  })
              });
    
            localStorage.setItem('userAcess', JSON.stringify(data[0]));
          }
        commit('SET_USER', information.user);
        // <------------2------------>

      } catch (e) {
        throw e
      }
    },

    async employeeLogin({commit},  {username, password}){
      function mergeModulesWithExtraAccess(modules, extraAccess) {
        for (const module of modules) {
            const matchingExtraAccess = extraAccess.find(extraModule => extraModule.id === module.id);
  
            if (matchingExtraAccess) {
            if (matchingExtraAccess.active !== module.active) {
                module.active = matchingExtraAccess.active;
            }
  
            if (module.subModules && matchingExtraAccess.subModules) {
                module.subModules = mergeModulesWithExtraAccess(module.subModules, matchingExtraAccess.subModules);
            }
  
            // Verificar y actualizar 'active' en 'actions' y 'permissions'.
            const subObjectsToCheck = ['actions', 'permissions'];
            for (const key of subObjectsToCheck) {
                if (module[key] && matchingExtraAccess[key]) {
                for (let i = 0; i < module[key].length; i++) {
                    if (matchingExtraAccess[key][i] && module[key][i].active !== matchingExtraAccess[key][i].active) {
                    module[key][i].active = matchingExtraAccess[key][i].active;
                    }
                }
                }
            }
            }
        }
  
          return modules;
      }

      try {
        Auth.configure({authenticationFlowType: "CUSTOM_AUTH"})
        const response = await Auth.signIn(username, password);
        let groups = response.signInUserSession.accessToken.payload["cognito:groups"]
        const refreshToken = response.signInUserSession.refreshToken.token

        // Comprobar si es un empleado.
        if( groups.indexOf("EMPLOYEE") !== -1){
          commit('SET_TOKEN', response.signInUserSession.idToken.jwtToken)
          commit("SET_REFRESH_TOKEN", refreshToken);

        }else{
          return 'isEmployee'
        }
        // <-----------1------------>
        
        // Obtener los datos del usuario. (2)
        const information = await authService.login()
          const accessEmployeesRef = db.ref(`admin/employees`);
          await accessEmployeesRef.once('value', (snapshot) => {
                const employeesRef = snapshot.val();
                const AllEmployees = []
                for (const id in employeesRef) {
                    if (Object.hasOwnProperty.call(employeesRef, id)) {
                        const object = {
                            id,
                            ...employeesRef[id]
                        };
                        AllEmployees.push(object);
                    }
                }
                this.convertData = AllEmployees?.find((employee) => employee.id === information.user.entity.code)
          })
        
        const getAccess = db.ref(`admin/accessLevels`);
        await getAccess.once('value', (snapshot) => {
          const getLevels = snapshot.val();
          const data = getLevels.filter(data => data.id === this.convertData?.accessLevels)
          this.modules = data[0]?.modules
        })

        if(this.convertData){
          this.result = mergeModulesWithExtraAccess(this.modules, this.convertData.extraAccess);

          const mergedArray = [this.convertData]
          const data = []
          mergedArray.forEach(employee => {
              data.push({
                  id: employee?.id,
                  name: employee?.name,
                  active: employee?.active,
                  accessLevels: employee?.accessLevels,
                  modules: this.result || this.modules
              })
          });
  
          localStorage.setItem('userAcess', JSON.stringify(data[0]));
        }

        commit('SET_USER', information.user);
        router.push({path: '/'})
        } catch (e) {
          console.log(e);
          throw e
        }
    },
    logout({commit}){
      // Limpiar el localStorage (1)
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('userAcess');
      // <------------1------------->
    }
};

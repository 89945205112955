<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 64 64">
        <defs>
            <path id="a" d="M.06 64V.04H64V64z"></path>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a"></use>
            </mask>
            <path :fill="color"
                d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32"
                mask="url(#b)"></path>
            <path :fill="color"
                d="M37.699 55H26.302c-1.65 0-3.955-.955-5.122-2.121l-8.059-8.059C11.955 43.653 11 41.348 11 39.699V28.301c0-1.649.955-3.954 2.121-5.121l8.059-8.059C22.347 13.955 24.652 13 26.302 13h11.397c1.649 0 3.954.955 5.121 2.121l8.059 8.059C52.045 24.347 53 26.652 53 28.301v11.398c0 1.649-.955 3.954-2.121 5.121l-8.059 8.059C41.653 54.045 39.348 55 37.699 55">
            </path>
            <path :fill="color"
                d="M26.302 53c-1.65 0-3.955-.955-5.122-2.121l-8.059-8.059C11.955 41.653 11 39.348 11 37.699V26.301c0-1.649.955-3.954 2.121-5.121l8.059-8.059C22.347 11.955 24.652 11 26.302 11h11.397c1.649 0 3.954.955 5.121 2.121l8.059 8.059C52.045 22.347 53 24.652 53 26.301v11.398c0 1.649-.955 3.954-2.121 5.121l-8.059 8.059C41.653 52.045 39.348 53 37.699 53H26.302">
            </path>
            <path fill="#FFFFFE" d="M45 34a4 4 0 0 1-4 4H23a4 4 0 0 1-4-4v-4a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v4"></path>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconDelivery',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


<template>
    <main-modal :title="'Detalles del Cliente'" @closeModal="$emit('closeModal')">
        <b-tabs card class="mt-2" v-model="tabIndex">
            <b-tab title="Informacion">
                <!-- <b-row> -->
                    <!-- <b-col lg="12"> -->
                        <!-- <h3>Cliente</h3> -->
                        <avatar :name="entity.first_name" :lastname="entity.last_name" :showStar="false" :customerImg="entity.photo_url" :active="entity.activated_customer"
                        :width="70"/>
                        <el-tooltip class="item" effect="dark" :content="tooltipLabel" placement="top">
                            <el-button v-if="entity.selectedAddress" @click="copyText(`${entity.selectedAddress.latitude}, ${entity.selectedAddress.longitude}`)" size="small" class="mb-2">
                                {{ entity.selectedAddress.latitude }} , {{ entity.selectedAddress.longitude }} 
                            </el-button>
                        </el-tooltip>
                        <b-row class="max-width: 800px;">
                            <!-- <b-col>
                                Nombre: {{entity.first_name}} {{entity.last_name}}
                            </b-col> -->
                            <b-col lg="6" class="mb-3">
                                <h5 class="mb-0">Nombre Compania:</h5>
                                <span class="text-capitalize">
                                    {{entity.company_name | textFormat}}
                                </span>
                            </b-col>
                            <b-col lg="6" class="mb-3">
                                <h5 class="mb-0">Codigo:</h5> 
                                {{entity.code}}
                            </b-col>
                            <b-col lg="6" class="mb-3">
                                <h5 class="mb-0">Membresia:</h5> 
                                {{entity.membership}}
                            </b-col>
                            <b-col lg="6" class="mb-3">
                                <h5 class="mb-0">RNC:</h5> 
                                {{entity.rnc}}
                            </b-col>
                            <b-col lg="6" class="mb-3">
                                <div class="container-contact">
                                    <eva-icon name="email" width="20"></eva-icon>
                                    {{entity.company_email}}
                                </div>
                            </b-col>
                            <b-col lg="6" class="mb-3">
                                <div class="container-contact">
                                    <eva-icon name="phone" width="20"></eva-icon>
                                    {{entity.phone | phoneFormat}}
                                </div>
                            </b-col>
                            <b-col lg="12">
                                <swiper :options="swiperOption" :key="renderSwiper" style="height: 50vh;border-radius:10px;">
                                    <swiper-slide :key="index" v-for="({path_s3}, index) in images">
                                        <!-- <div class="container-img"> -->
                                                <!-- <image-product :source="img.picture_url" :styleObject="{'object-fit': 'cover'}"/> -->
                                            <img v-lazy="path_s3" width="100%" class="container-img">
                                        <!-- </div> -->
                                    </swiper-slide>
                                    <div class="swiper-button-prev mr-5" slot="button-prev">
                                        <icon-arrow :size="30" style="margin-right: -12px; transform: rotate(180deg);" :color="'var(--gray-1001)'"/>
                                    </div>
                                    <div class="swiper-button-next" slot="button-next">
                                        <icon-arrow :size="30" style="margin-left: -12px" :color="'var(--gray-1001)'"/>
                                    </div>
                                    <div class="swiper-pagination" slot="pagination"></div>
                                </swiper>
                            </b-col>
                        </b-row>
                    <!-- </b-col> -->
                <!-- </b-row> -->
            </b-tab>
            <b-tab title="Historial de Compra" disabled>
            </b-tab>
            <b-tab title="Direcciones">
                <b-table :items="addresses" :fields="fields" striped responsive style="max-height: 300px;" thead-class="bg-primary text-white" class="bg-white mb-2">
                </b-table>
                <mgl-map class="create-task-map" :center="center" :accessToken="accessToken" :zoom="7" :dragRotate="false" @load="onMapLoaded" v-if="tabIndex == 2" style="height:400px;border-radius:10px;overflow: hidden;" :mapStyle="mapStyle" >      
                    <mgl-marker style="cursor: pointer" :coordinates="[address.longitud, address.latitud]" color="#c40316" v-for="address in addresses" :key="address.id">
                    </mgl-marker>
                    <mgl-geolocate-control :trackUserLocation="true" position="top-right" />
                    <mgl-navigation-control position="top-right"/>
                </mgl-map>
            </b-tab>
            <b-tab title="Tickets" disabled>
            </b-tab>
        </b-tabs>
    </main-modal>
</template>
<script>
    import MainModal from '../../../../common/components/modals/MainModal.vue'
    import IconArrow from '../../../../common/components/svg/IconArrow.vue'
    import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl} from 'v-mapbox'
    import Mapbox from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
    import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
    import Avatar from '../avatar/Avatar.vue'
    export default {
        components: { 
            MainModal, IconArrow, Avatar, 
            MglMap, MglMarker, MglGeolocateControl, MglNavigationControl,
        },
        name: 'CustomerDetails',
        props:{
            active:{
                type:String
            },
            images:{
                type: Array
            },
            entity:{
                type: Object
            },
            addresses:{
                type: Array
            },
            selectedAddress:{
                type: Object
            }
        },
        data(){
            return{
                swiperOption: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true
                    }
                },
                renderSwiper: 0,
                renderMap: 0,
                mapbox: null,
                center: [-70.70238447994983, 19.45556310283655],
                coordinates: [-70.70238447994983, 19.45556310283655],
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                mapStyle: 'mapbox://styles/mundomovil28/ckgwp0y0g06r219pb8oqihafv',
                tabIndex: 0,
                tooltipLabel: 'Copiar',
                // addresses:[
                //     {id:1, name: 'Address 1', longitude: 19.522897429612673, latitude: -70.82940540986175},
                //     {id:2, name: 'Address 2', longitude: 19.500093036882774, latitude: -71.97937060818748},
                //     {id:3, name: 'Address 3', longitude: 18.615493179626295, latitude: -70.29172904611032},
                //     {id:4, name: 'Address 4', longitude: 18.8717646474839, latitude: -71.44563986917866},
                //     {id:5, name: 'Address 5', longitude: 18.60836898690753, latitude: -71.36294919130407},
                //     {id:6, name: 'Address 6', longitude: 18.8575375654445, latitude: -69.3858902566658},
                //     {id:7, name: 'Address 7', longitude: 18.643986965847624, latitude: -70.29924638046256},
                //     {id:8, name: 'Address 8', longitude: 18.205370540223765, latitude: -71.38550119436077}
                // ]
            }
        },
        computed:{
            fields(){
                return [
                {label: this.$t('ID'), key: 'id', sortable: true},
                {label: this.$t('Nombre'), key: 'name', sortable: true},
                {label: this.$t('Direccion textual'), key: 'address_line', sortable: true},
                {label: this.$t('Referencia'), key: 'reference', sortable: true},
                {label: this.$t('Activa'), key: 'active', sortable: true},

                ]
            }
        },
        mounted(){
            this.renderSwiper += 1
        },
        methods:{
            async onMapLoaded(){
                this.mapbox = Mapbox;
                if(this.renderMap == 1)
                this.renderMap += 1
            },
            copyText(text) {
                navigator.clipboard.writeText(text).then(() => {
                    this.tooltipLabel = 'Coordenadas copiadas'
                }, (err) => {
                    console.error('Error copying text: ', err);
                });
                setTimeout(() => {
                    this.tooltipLabel = 'Copiar'
                }, 1000);
            }
        }
    }
</script>

<style scoped>

    .swiper-container::v-deep .swiper-button-prev::after, .swiper-container::v-deep .swiper-button-next::after{
        content: none;
    }
    .swiper-container::v-deep > .swiper-wrapper{
        align-items: center;
    }
    .container-img {
        /* width: 390px;
        height: 360px; */
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    .avatar-container::v-deep > div > div > span{
        font-size: 20px;
    }
    .container-contact{
        border: 2px solid #c7d9e770;
        border-radius: 6px;
        padding: 4px 10px;
        color: #626ed4;
        text-align: center;
        /*
        display: inline-block;
        width: 200px; 
        */
    }
    .container-contact i {
        margin-right: 10px;
    }
</style>

<template>
    <main-modal @closeModal="$emit('closeModal')" :title="$t('tracking.tracking_detail')">
        <b-card no-body class="mt-2">
            <b-tabs card>
                <b-tab :title="$t('tracking.general_information')" active>
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h4>{{ $t('tracking.timeline') }}</h4>
                        <div class="d-flex align-items-center">
                            <h5 class="me-4">
                                <span class="weight-500">
                                    {{ $t('tracking.total_time') }}: 
                                </span>
                                <span :id="`details-time-${details.document.id}`" class="weight-600">{{startTimer(details.document)}}</span> 
                            </h5>
                            <b-button variant="warning" class="position-relative" @click="refresh" size="sm" :disabled="loading">
                                <eva-icon name="refresh" fill="#fff"></eva-icon>
                                <vue-element-loading :active="loading" :is-full-screen="false"/>
                            </b-button>
                        </div>
                    </div>
                    <time-line :status="details.states_available" :statusOrder="details.actions_log" :type="details.type_id" class="mb-5"/>
                    <h4>{{ $t('tracking.detail') }}</h4>
                    <b-row>
                        <b-col>
                            <b-list-group>
                                <b-list-group-item class="d-flex justify-content-between">
                                    <span> <b>{{ $t('tracking.document') }}:</b></span>
                                    <span>{{detailsLocal.document}}</span>
                                </b-list-group-item>
                                <b-list-group-item class="d-flex justify-content-between">
                                    <span> <b>{{ $t('tracking.customer') }}:</b></span>
                                    <span class="text-capitalize weight-500">
                                        {{detailsLocal.customer | textFormat}}
                                    </span>
                                </b-list-group-item>
                                <b-list-group-item class="d-flex justify-content-between">
                                    <span> <b>{{ $t('tracking.branch') }}:</b></span>
                                    <span>{{detailsLocal.branch_name}}</span>
                                </b-list-group-item>
                                <b-list-group-item class="d-flex justify-content-between">
                                    <span> <b>{{ $t('tracking.actual_state') }}:</b></span>
                                    <span>{{detailsLocal.action_name}}</span>
                                </b-list-group-item>
                                <b-list-group-item class="d-flex justify-content-between">
                                    <span> <b>{{ $t('tracking.type') }}:</b></span>
                                    <span>{{detailsLocal.type.name}}</span>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab :title="$t('tracking.timeline_chart')">
                    <h4 class="mb-0">{{ $t('tracking.timeline_chart') }}</h4>
                    <apexchart type="rangeBar" height="400" width="1200" :options="chartOptions" :series="series" :key="apexchartKey"></apexchart>
                </b-tab>
            </b-tabs>
        </b-card>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import TimeLine from '../TimeLine.vue'
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
    components: { MainModal, TimeLine },
    name: "OrderDetails",
    props:{
        status:{
            type: Array
        },
        details:{
            type: Object
        },
        types:{
            type: Array
        },
        detailsLocal:{
            type: Object
        }
    },
    computed: {
        ...mapGetters({
            stores: "ecommerce/stores", 
            documentDetails: "tracking/documentDetails", 
        }),
        getStatusLog(){
            let chart = []
            for (let i = 0; i < this.details.actions_log.length; i++) {
                const element = this.details.actions_log[i];
                chart.push({
                    x: element.name,
                    y:[
                        new Date(element.date_time).getTime(),
                        element.date_time_start ? new Date(element.date_time_start).getTime() : new Date().getTime()
                    ],
                    fillColor: element.background
                })
            }
            return chart
        },
        series(){
            return [
                {
                    data: this.getStatusLog
                }
            ]

        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: 415,
                    type: 'rangeBar'
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        distributed: true,
                        dataLabels: {
                            hideOverflowingLabels: false
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        var a = moment(val[0])
                        var b = moment(val[1])
                        var diff = b.diff(a, 'minutes')
                        return diff + (diff > 1 ? ' mins' : ' min')
                    },
                    style: {
                        colors: ['#f3f4f5', '#fff']
                    }
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    show: true
                },
                grid: {
                    row: {
                        colors: ['#f3f4f5', '#fff'],
                        opacity: 1
                    }
                }
            },
            apexchartKey: 0,
            loading: false,
            interval: null
        }
    },
    methods:{
        async refresh(){
            try {
                this.loading = true
                await this.$store.dispatch('documentsFollowup/getDocumentDetails', this.details.document.document)
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        },
        startTimer(data){
            let startDate = moment(data.firts_date_time_start_of_process)
            let endDate = moment(data.follow_up_completed ? moment(data.date_time).format() : moment())
            let dtDiff = moment(endDate).diff(startDate);

            //express as a duration
            const diffDuration = moment.duration(dtDiff);
            let result = moment().startOf("day");
            result.add(diffDuration.hours(), 'hours')
            result.add(diffDuration.minutes(), 'minutes')
            result.add(diffDuration.seconds(), 'seconds')
            let days = diffDuration.days()
            let time = `${days >= 1 ? `${days}D` : ''} ${result.format('HH:mm:ss')}`;
            if(!data.follow_up_completed){
                this.interval = setInterval(() => {
                    result.add(1, 'second');
                    let span = document.getElementById(`details-time-${data.id}`)
                    if(span){
                        span.innerHTML = `${days >= 1 ? `${days}D` : ''} ${result.format('HH:mm:ss')}`;
                    }
                }, 1000)
            }else{
                return time
            }
        },
    },
    mounted(){
        setTimeout(() => {
            this.apexchartKey += 1
        }, 1000);
    },
    beforeDestroy(){
        clearInterval(this.interval)
    }
}
</script>

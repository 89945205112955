<template>
    <icon-base :color="color" :size="size" class="icon-twitter">
        <path class="st0" d="M125,0.259C56.108,0.259,0.259,56.108,0.259,125c0,68.892,55.848,124.741,124.741,124.741 
                    c68.892,0,124.741-55.848,124.741-124.741C249.741,56.108,193.892,0.259,125,0.259z M196.699,81.938
                    c-3.345,4.525-7.083,8.657-11.608,12.002c-0.59,0.59-1.771,0.984-1.771,1.968c0,24.004-6.099,48.007-23.61,65.322
                    c-22.233,22.233-55.681,28.332-85.193,20.069c-5.706-1.574-11.215-3.738-16.527-6.689c-1.771-0.984-3.542-1.967-5.115-3.148
                    c-0.393-0.394-0.787-0.59-0.393-0.984c0.393-0.197,0.59-0.197,1.967,0c8.264,0.787,16.921-0.393,24.791-3.148
                    c4.329-1.574,13.576-4.525,16.33-8.657c0,0,0.197,0,0.197-0.197c-1.181-0.197-2.164-0.197-3.148-0.394
                    c-8.067-2.361-18.888-6.296-23.807-19.872c-0.393-0.787,0-1.18,0.787-0.984c6.69,0.59,9.051,0.197,11.412-0.394
                    c-1.377-0.197-2.558-0.59-3.935-1.18c-10.231-3.935-17.511-13.773-18.888-24.397c-0.197-1.18-0.197-2.361-0.197-3.345
                    c0-0.787,0.393-0.984,1.181-0.59c5.312,2.755,11.018,3.345,11.608,3.148c-1.377-0.984-2.558-2.164-3.738-3.148
                    c-8.854-8.264-12.199-25.381-5.116-35.809c0.59-0.787,0.984-0.787,1.574,0c16.133,18.298,36.005,26.955,60.009,30.497
                    c0.59,0,0.787,0,0.59-0.787c-0.59-4.132-0.787-8.264,0.393-12.592c0.984-4.329,3.148-8.46,5.903-11.805
                    c2.754-3.345,6.099-6.099,9.837-8.067c3.345-1.771,7.083-2.951,11.018-3.345c0.131,0,0.262,0,0.394,0c0.393,0,0.59,0,0.983-0.197
                    c4.328-0.197,8.854,0.394,12.986,2.164c3.148,1.18,5.903,2.951,8.46,5.115c0.59,0.394,0.984,0.984,1.574,1.574
                    c0.393,0.394,0.787,0.394,1.181,0.394c6.099-1.574,11.805-3.738,17.511-6.69c0.197-0.197,0.393-0.197,0.787,0
                    c0.197,0.197,0.197,0.59,0,0.787c-0.983,3.345-2.755,6.296-4.919,8.854c-1.377,1.771-4.919,6.099-7.28,6.493
                    c4.85-0.776,9.887-2.132,14.553-4.055c0.005-0.106,0.006-0.202,0.006-0.274c0-0.393,0.197-0.393,0.197,0.197
                    c-0.067,0.028-0.137,0.049-0.203,0.077C197.459,80.332,197.344,81.132,196.699,81.938z"/>
    </icon-base>
</template>

<script>
export default {
    name:'IconTwitter',
    components:{
        IconBase: () => import('./IconBase.vue')
    },
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        hover: {
            type: Boolean
        }
    }
}
</script>


<template>
    <icon-base viewBox="0 0 32 32" :width="size + 'px'" :fill="color"> 
        <path d="M27,19c0-0.6,0.4-1,1-1h3.6c-0.8-1.8-2.5-3-4.6-3h-2.5L21,10.8C20,9.7,18.6,9,17.1,9H9.5C7.6,9,5.9,10.1,5,11.8L3.4,15H3
        c-1.7,0-3,1.3-3,3v4c0,1.1,0.9,2,2,2h1.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3h8.3c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H30c1.1,0,2-0.9,2-2
        v-2h-4C27.4,20,27,19.6,27,19z M7,25c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S8.1,25,7,25z M9.4,15H5.6l1.2-2.3c0.5-1,1.5-1.7,2.7-1.7
        h1.9L9.4,15z M11.6,15l2-4h3.5c0.9,0,1.7,0.4,2.3,1.1l2.4,2.9H11.6z M23,25c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S24.1,25,23,25z"/>
    </icon-base>
</template>

<script>
import IconBase from '../../../../../common/components/svg/IconBase.vue'
export default {
    components: { IconBase },
    name: "IconCart",
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
    }
}
</script>

<style>

</style>
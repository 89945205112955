<template>
    <div>
        <h4 class="mb-4">({{product.codarticulo}}) {{product.description | textFormat}}</h4>
        <apexchart width="500" :options="chartOptions" :series="series" v-if="false"></apexchart>
        <b-table :items="reviews" responsive striped thead-class="bg-primary text-white" :fields="fields" details-td-class="bg-primary" class="bg-white">
            <template #cell(date)="data">
                {{$d(new Date(data.item.created_at), 'alt_short')}}
            </template>
            <template #cell(images)="data">
                <div v-if="data.item.images.length > 0" class="d-flex">
                    <div v-for="(image, index) in data.item.images" :key="index">
                        <img v-lazy="image" width="50" height="50" class="img-product">
                    </div>
                </div>
            </template>
            <template #cell(customer)="data">
                <div class="d-flex align-items-center">
                    <div class="avatar d-flex align-items-center justify-content-center" :style="{background: getRandomColor}" v-if="!data.item.customer.photo_url">
                        <abbr>{{data.item.customer.first_name.slice(0,1)+'.'+data.item.customer.last_name.slice(0,1)}}</abbr>
                    </div>
                    <img :src="data.item.customer.photo_url" class="img-profile" alt="profile" v-else>
                    <b class="me-3 ms-3">
                        {{data.item.customer.first_name}}
                        {{data.item.customer.last_name}}
                    </b>
                    <div>
                        <icon-check-circle v-if="data.item.customer_verified" :size="17" :color="'#02a499'"/>
                        <icon-danger-circle :color="'#ff4560'" v-else/>
                    </div>
                </div>
            </template>
            <template #cell(actions)>
                <div>
                    <b-button variant="danger" size="sm">{{$t('ecommerce.delete')}}</b-button>
                </div>
            </template>
        </b-table>
        <b-pagination v-model="filters.page" :total-rows="totalPages" :per-page="perPage" class="justify-content-center mt-4 mb-4"></b-pagination>
    </div>
</template>

<script>
import IconCheckCircle from '../../../../common/components/svg/IconCheckCircle.vue';
import IconDangerCircle from '../../../../common/components/svg/IconDangerCircle.vue';
import { ProductsService} from "../../service/products-service";
const productService = new ProductsService();
export default {
    components: { IconCheckCircle, IconDangerCircle },
    name: "Reviews",
    data() {
        return {
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['1 estrella', '2 estrella', '3 estrella', '4 estrella', '5 estrella'],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ]
            },
            series: [1, 0, 0, 0, 0],
            reviews: [],
            filters:{
                page: 1
            },
            totalPages: 0,
            perPage: 5
        }
    },      
    props:{
        product: {
            type: Object
        }
    },
    computed:{
        getRate(){
            let totalR = 0
            if (this.product.reviews.length){
                totalR = this.product.reviews.length;
                var sum = 0;
                for (let r in this.product.reviews){
                    sum += this.product.reviews[r].rating
                }
                return  parseFloat((sum/totalR).toFixed(1));
            }
            console.log(this.product.reviews);
            return totalR
        },
        fields(){
            return [
                {label: this.$t('ecommerce.score'), key: 'rating', sortable: true},
                {label: this.$t('ecommerce.date'), key: 'date', sortable: true},
                {label: this.$t('ecommerce.images'), key: 'images', sortable: true},
                {label: this.$t('ecommerce.title'), key: 'title', sortable: true},
                {label: this.$t('ecommerce.comment'), key: 'comment', sortable: true},
                {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions'},
            ]
        },
    },
    methods:{
        async getAllReviews(){
            try {
                const response = await productService.getReviewsByProduct(this.product.codarticulo)
                this.reviews = response.docs
                this.totalPages = response.totalPages
                this.perPage = response.limit
            } catch (error) {
                console.log(error);
            }
        }
    },
    async created(){
        await this.getAllReviews()
    }
}
</script>

<style scoped>
.table-responsive::v-deep > table{
    vertical-align: middle;
}
.avatar{
    background: #FA9696;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    object-fit: cover;
}
.img-profile{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 1px solid #e2e1ee;
    border-radius: 50%;
}
.table-responsive::v-deep > table > tbody > tr > td:nth-child(5){
    max-width: 400px;
    overflow: hidden;
}
.img-product{
    border: 1px solid #f8f9fa;
    border-radius: 5px;
    object-fit: cover;
}
</style>

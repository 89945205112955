import {http} from '../../../common/services/web-backend'
// import http from 'axios'

export default {
  async getDocuments({commit}, filters){
    try {
      const docs = await http.get('/documents_followup', {params: { ...filters}} )
      commit('SET_DOCUMENTS', docs.data.data)
    } catch (error) {
      throw error
    }
  },
  async getDocumentDetails({commit}, code){
    try {
      const response = await http.get(`/documents_followup/getDetailsDocument/${code}`)
      commit('SET_DOCUMENT_DETAILS', response.data.data)
    } catch (error) {
      throw error
    }
  },
  async getSLA({commit}){
    try {
      const response = await http.get(`/documents_followup/getSLAStatus`)
      commit('SET_SLA', response.data.data)
    } catch (error) {
      throw error
    }
  },
  async getActions({commit}){
    try {
      const response = await http.get(`/documents_followup/getActions`)
      commit('SET_ACTIONS', response.data.data)
    } catch (error) {
      throw error
    }
  },
  async getTypes({commit}){
    try {
      const response = await http.get(`/documents_followup/getTypesDocuments`)
      commit('SET_TYPES', response.data.data)
    } catch (error) {
      throw error
    }
  },
  async updateActions(_, {params}){
    try {
      const response = await http.put(`/documents_followup/updateActions`, params)
      return response
    } catch (error) {
      throw error
    }
  },
  async deleteAction(_, id){
    try {
      const response = await http.delete(`/documents_followup/deleteAction/${id}`)
      return response
    } catch (error) {
      throw error
    }
  },
  async getSummaryDocuments({commit}, filters){
    try {
      const docs = await http.get('/documents_followup/getSummaryDocuments', {params: { ...filters}} )
      commit('SET_DOCUMENT_SUMMARY', docs.data.data)
    } catch (error) {
      throw error
    }
  },
  async getChartsDocuments({commit}, filters){
    try {
      const docs = await http.get('/documents_followup/getCharts', {params: { ...filters}} )
      commit('SET_DOCUMENT_CHARTS', docs.data.data)
    } catch (error) {
      throw error
    }
  },
};

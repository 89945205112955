<template>
    <main-modal @closeModal="() => this.$emit('closeModal')">

        <el-card class="box-card http-card-container">
            <div slot="header" class="clearfix">
                <h3>HTTP Log Detail</h3>
            </div>

            <el-row>
                <p><strong>Path:</strong> {{ httpLogData.path }}</p>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <p><strong>Method:</strong> {{ httpLogData.method }}</p>

                    <p><strong>Status Code:</strong>
                        <el-tag :type="tagType" style="margin-left: 5px;">{{ httpLogData.status_code }}</el-tag>
                    </p>
                    
                    <p><strong>Controller Action:</strong> {{ httpLogData.controller_action }}</p>
                </el-col>

                <el-col :span="12">
                    <p><strong>IP:</strong> {{ httpLogData.ip }}</p>
                    <p><strong>Memory Usage:</strong> {{ httpLogData.memory_usage }}</p>
                    <p><strong>Duration:</strong> {{ httpLogData.duration }}</p>
                </el-col>
            </el-row>

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="Payload">
                    <!-- <el-row type="flex" justify="end">
                        <el-column>
                            <el-button icon="el-icon-copy-document"></el-button>
                        </el-column>
                    </el-row> -->
                    <vue-json-pretty :data="httpLogData.request_body" :virtual="true" :highlightSelectedNode="true" />
                </el-tab-pane>
                <el-tab-pane label="Request Headers">
                    <vue-json-pretty :data="httpLogData.request_headers" :virtual="true" :highlightSelectedNode="true" />
                </el-tab-pane>
                <el-tab-pane label="Response">
                    <vue-json-pretty :data="httpLogData.response_body" :virtual="true" :highlightSelectedNode="true" />
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </main-modal>
</template>

<script>
    import MainModal from '@/common/components/modals/MainModal.vue';
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';


    export default {
        components: { MainModal, VueJsonPretty },
        name: "ViewHttpLogModal",
        props: {
            httpLogData: {
                type: Object,
                required: true,
                default: () => {
                    return {
                        method: "",
                        status_code: "",
                        path: ""
                    }
                }
            }
        },
        data() {
            return {
                activeName: "",
            }
        },
        computed: {
            tagType() {
                return this.getTagStyle(this.httpLogData.status_code);
            }
        },
        methods: {
            getTagStyle(status) {
                if (status >= 200 && status < 300) {
                    return "success";
                } else if (status >= 300 && status < 400) {
                    return "warning";
                } else if (status >= 400 && status < 500) {
                    return "danger";
                } else if (status >= 500) {
                    return "info";
                }
            },
            handleClick() {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .http-card-container {
        width: 100%;
        height: 100%;
        width: 960px;
        height: 750px;
    }

    // reverse response technique
    @media (max-width: 1200px) {
        .http-card-container {
            width: 100%;
        }
    }

    @media (max-width: 750px) {
        .http-card-container {
            height: 100%;
    }
}
</style>
import { http } from "../../../common/services/web-backend";
export class CustomerService {
    async getImages(code){
        try{
          const response = await http.get('ecommerce/admin/getImagesCustomerStores/'+code);
          return response.data;
        }catch (e) {
          throw e
        }
    }
    async getEntity(code){
      try{
        const response = await http.get('entities/'+code);
        return response.data;
      }catch (e) {
        throw e
      }
  }
  async getAllAddress(code){
      try{
        const response = await http.get(`entities/${code}/address`);
        return response.data;
      }catch (e) {
        throw e
      }
  }
  async getCity(){
    try{
      const cities = await http.get('/addresses/get-cities');
      return cities.data.data
    }catch (e) {
      throw e
    }
  }
}

<template>
    <Layout>
        <el-row class="http-logs-container">            
            <el-row class="filters-container" type="flex" justify="space-between">
                <el-form :inline="true" :model="filters" class="inline-form">
                    <el-form-item label=""> 
                        <el-input v-model="q" placeholder="Path, Status, Entity Code" class="q-input"></el-input>
                    </el-form-item>

                    <el-form-item label="">
                        <el-select v-model="filterKey" placeholder="Select Key...">
                            <el-option label="Path" value="path"></el-option>
                            <el-option label="Status" value="status_code"></el-option>
                            <el-option label="Entity Code" value="entity_code"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="">
                        <el-select v-model="filters.status_code" placeholder="Status Code" multiple>
                            <el-option v-for="statusCode in statusCodes" :key="statusCode" :label="statusCode" :value="statusCode">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="">
                        <el-select v-model="filters.method" placeholder="Methods">
                            <el-option label="All Methods" value=""></el-option>
                            <el-option label="GET" value="get"></el-option>
                            <el-option label="POST" value="post"></el-option>
                            <el-option label="PUT" value="put"></el-option>
                            <el-option label="DELETE" value="delete"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="">
                        <el-select v-model="filters.project_id" placeholder="Select Project...">
                            <el-option label="All Projects" value=""></el-option>
                            <el-option label="backend-erp" value="1"></el-option>
                            <el-option label="product-service" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button type="primary" icon="el-icon-refresh" @click="getHttpLogsData(filters)" class="refresh-button"></el-button>
            </el-row>
    
            <el-row class="table-container">
                <vue-element-loading :active="loading" :is-full-screen="false"/>
                <el-col>
                    <el-table :data="httpLogsData" size="medium" class="http-logs-table" height="650" border stripe>
                        <el-table-column prop="id" label="ID" width="250"></el-table-column>
                        <el-table-column prop="entity_code" label="Entity Code" width="100"></el-table-column>
                        <el-table-column prop="method" label="Method" width="100">
                            <template slot-scope="scope">
                                <el-tag :type="getTagMethodStyle(scope.row.method)">{{ scope.row.method }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="path" label="Path" width="650">
                            <template slot-scope="scope">
                                {{ trimText(scope.row.path) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="status_code" label="Status" width="100">
                            <template slot-scope="scope">
                                <el-tag :type="getTagStatusCodeStyle(scope.row.status_code)">{{ scope.row.status_code }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="duration" label="Duration" width="100">
                            <template slot-scope="scope">
                                {{ toFixed2(scope.row.duration) }} ms
                            </template>
                        </el-table-column>
                        <el-table-column prop="happened" label="Happened" width="200"></el-table-column>
                        <el-table-column prop="actions" label="Actions" align="center">
                            <template slot-scope="scope">
                                <el-row type="flex" justify="center">
                                    <el-button type="text" @click="handleView(scope.$index, scope.row)" icon="el-icon-view" class="font-size-20"></el-button>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <el-row type="flex" align="middle" justify="space-between" class="pagination-container">
                <el-col :span="12" class="pagination-detail-container">
                    <p>{{ httpLogsData.length }} doc(s) of {{ totalDocs }} Page {{ filters.page }} of {{ totalPages }}</p>
                </el-col>

                <el-col :span="12" class="pagination-widget-container">
                    <el-pagination
                        @size-change="handleSizeChange"
                        :current-page.sync="filters.page"
                        :page-sizes="[50, 100, 150, 200]"
                        :page-size="filters.limit"
                        layout="total, sizes, prev, pager, next"
                        :total="totalDocs"
                    ></el-pagination>
                </el-col>

                <!-- <el-row type="flex" justify="end" align="middle" class="pagination-widget-container">
                    <el-button type="primary" @click="previousPage" icon="el-icon-arrow-left" :disabled="filters.page === 1"></el-button>
                    <span><strong>{{ filters.page }}</strong></span>
                    <el-button type="primary" @click="nextPage" icon="el-icon-arrow-right" :disabled="filters.page === totalPages"></el-button>
                </el-row> -->
            </el-row>

            
            <view-http-log-modal v-if="showViewHttpLogModal" :httpLogData="httpLogModalData" @closeModal="showViewHttpLogModal = false"></view-http-log-modal>
        </el-row>
    </Layout>
    
</template>

<script>
    import Layout from "@/modules/template/layouts/main";
    import ViewHttpLogModal from "../components/modals/ViewHttpLogModal.vue";
    import { HttpLogsService } from "../service/http-logs-service";
    import { mapGetters } from "vuex";
    const httpLogsService = new HttpLogsService();

    export default {
        name: "HttpLogs",
        components:{
            Layout,
            ViewHttpLogModal,
        },
        data() {
            return {
                httpLogsData: [],
                httpLogModalData: {},
                statusCodes: [200, 201, 204, 400, 401, 403, 404, 500, 503, 504],
                q: "",
                filterKey: "path",
                filters: {
                    path: "",
                    entity_code: "",
                    status_code: [],
                    method: "",
                    project_id: "1",
                    page: 1,
                    limit: 50,
                    // pagination_option: ""
                },
                showViewHttpLogModal: false,
                loading: false,
                totalDocs: 0,
                totalPages: 1,
            }
        },
        computed: {
            ...mapGetters({
                userData: "auth/user",
                permissions: "auth/permissions"
            }),
        },
        watch: {
            filterKey: {
                handler: function(newValue, oldValue) {
                    this.filters[oldValue] = "";
                    this.filters[newValue] = this.q;
                }
            },
            q: {
                handler: function(newValue) {
                    this.filters[this.filterKey] = newValue;
                }
            },
            filters: {
                handler: async function (newFilters) {
                    this.getHttpLogsData(newFilters);
                },
                deep: true
            },
            'permissions'() {
                const modules = this.permissions?.modules?.find(data => data.name == 'Admin')
                const route = modules?.subModules?.find(data => data.name === 'http logs')

                // Verifica si se han cambiado los permisos de administrador
                if (!route.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
            }
        },
        methods: {
            async getHttpLogsData(filters, resetPagination = true) {
                try {
                    this.loading = true;
                    const httpLogsResponse = await httpLogsService.get(filters);
                    this.httpLogsData = httpLogsResponse.data;

                    if (resetPagination) {
                        this.totalDocs = httpLogsResponse.total_docs;
                        this.totalPages = httpLogsResponse.total_pages;
                        // this.filters.page = 1;
                    }

                    return httpLogsResponse;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.loading = false;
                }
            },
            async getHttpLogById(id) {
                try {
                    const httpLogResponse = await httpLogsService.getById(id);

                    return httpLogResponse;
                } catch (e) {
                    console.log(e);
                }
            },
            // async previousPage() {
            //     const filters = {
            //         ...this.filters,
            //         pagination_option: "previous",
            //         first_document_id: this.httpLogsData[0]?.id
            //     };

            //     this.getHttpLogsData(filters, false);
            //     this.filters.page--;
            // },
            // async nextPage() {
            //     const filters = {
            //         ...this.filters,
            //         pagination_option: "next",
            //         last_document_id: this.httpLogsData[this.httpLogsData.length - 1]?.id
            //     };

            //     this.getHttpLogsData(filters, false); 
            //     this.filters.page++;
            // },
            async handleSizeChange(newPageSize) {
                this.filters.limit = newPageSize;
                await this.getHttpLogsData(this.filters);
            },
            async handleView(index, row) {
                this.httpLogModalData = await this.getHttpLogById(row.id);
                this.showViewHttpLogModal = true;
            },
            // handlePageChange(newPageNumber) {
            //     if (newPageNumber > this.filters.page) {
            //         this.nextPage();
            //     } else if(newPageNumber < this.filters.page) {
            //         this.previousPage();
            //     }

            //     this.filters.page = newPageNumber;
            // },
            getTagStatusCodeStyle(statusCode) {
                if (statusCode >= 200 && statusCode < 300) {
                    return "success";
                } else if (statusCode >= 300 && statusCode < 400) {
                    return "warning";
                } else if (statusCode >= 400 && statusCode < 500) {
                    return "danger";
                } else if (statusCode >= 500) {
                    return "info";
                }
            },
            getTagMethodStyle(method) {
                if (method === "GET") {
                    return "success";
                } else if (method === "POST") {
                    return "info";
                } else if (method === "PUT") {
                    return "warning";
                } else if (method === "DELETE") {
                    return "danger";
                }
            },
            toFixed2(value) {
                if (typeof value === "number") {
                    return value.toFixed(2);
                }
                return value;
            },
            trimText(text, length = 80) {
                if (text.length > length) {
                    return text.substring(0, length) + "...";
                }
                return text;
            }
        },
        async created() {
            this.getPermissions()
            await this.getHttpLogsData(this.filters);
        },
    }
</script>

<style lang="scss" scoped>
    .font-size-20 {
        font-size: 20px;
    }

    .filters-container {
        margin-top: 25px;
    }

    .pagination-container {
        margin-top: 5px;
    }

    .http-logs-table .el-table__header-wrapper {
        background-color: #f2f2f2;
    }

    .http-logs-table .el-table__row {
        &:hover {
            background-color: #eef1f6 !important;
        }
    }

    .http-logs-table .el-button {
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .q-input {
        width: 350px;
    }

    .refresh-button {
        padding: 0;
        width: 50px;
        height: 40px;
    }

    .pagination-widget-container {
        display: flex;
        justify-content: flex-end;
    }

    // .pagination-widget-container {
    //     margin-top: 15px;
    //     margin-bottom: 15px;
    //     gap: 10px;
    // }

    // .pagination-widget-container .el-button {
    //     padding: 0;
    //     width: 30px;
    //     height: 30px;
    // }

    .pagination-detail-container {
        display: flex;
        align-items: center;
    }

    .pagination-detail-container p {
        margin: 0;
    }

    // xl screen breakpoint
    @media (min-width: 2000px) {
        .http-logs-container {
            max-width: 1701px; 
            margin: 0 auto;
        }
    }

</style>
import {http} from '@/common/services/node-service'

export class HttpLogsService {
    async get(params) {
        try {
            const { data } = await http.get('http-logs', { params });
            return data.data;
        } catch (e) {
            if (e.response) {
                return e.response;
            } else {
                throw e
            }
        }   
    }

    async getById(id) {
        try {
            const { data } = await http.get('http-logs/' + id);
            return data.data;
        } catch (e) {
            if (e.response) {
                return e.response;
            } else {
                throw e
            }
        }
    }
}

/**
 * Dynamic form mixin, this encapsulate the logic to handle dynamics form on ManageTaskSetting and ManageIncidentSetting
 * The components that use this mixing should implement the next methods: 
 *  - createRequest(fields)
 *  - updateRequest(fields)
 *
 * The components that implement the mixing can have custom logic overwriting the methods, data and hooks...
 */
export const dynamicSettingMixin = {
    data() {
        return {
            fieldOptions: [
                { id: 1, name: 'text-field', label: this.$t('delivery.field_text')},
                { id: 2, name: 'multiselect', label: this.$t('delivery.multi_select')},
                { id: 3, name: 'photo', label: this.$t('delivery.field_photo')},
                { id: 4, name: 'signature', label: this.$t('delivery.signature')},
                { id: 5, name: 'validate-location', label: this.$t('delivery.validate_location')}
            ],
            selectedTypeId: null,
            formFields: [],
            // incidentName: '',
            recordType: null,
            dirtyForm: false,
            error: { invalid: false, index: null, message: '', option: null },
            selectLabel: 'Seleccionar',
            drag: false,
            dragFocus: false,
            comparison: { enable: false, old_fields: null, old_name: null, has_changed: false },
        }
    },
    props: {
        selectedRecord: {
            type: Object,
            required: true
        }
    },
    methods: {
        clone({ name, label, id }) {
            if (this.formFields.length < 20) {
                const base = { _id: id, label, name, id: this.uniqueId(), description: '', required: false, comment: '' };
                if (name === "text-field" || name === "photo") {
                    return { ...base, min: null, max: null };
                } else if (name === "multiselect") {
                    return {...base, options: [ { id: this.uniqueId(), label: '' }] };
                } else if (name === "validate-location") {
                    return {...base};
                } else {
                    base.description = 'Firma';
                    return {...base};
                }
            }else {
                return;
            }
        },
        selectOption(id, action, _id) {
            const add = action === 'add';
            this.formFields.map((form, index_) => {
                if (form.id === id){
                    if (add) {
                        this.formFields[index_].options.push({id: this.uniqueId(), label: ''});
                    }else {
                        this.formFields[index_].options.forEach((option, option_index) => { // we will need to search the uid (not using index because it could cause some problems)
                            if (option.id === _id && option_index !== 0) {
                                this.formFields[index_].options.splice(option_index, 1);
                                this.validateFields();
                            }
                        });
                    }
                    return;
                }
            });
        },
        isBlank(val){
            if (!val) {
                return false;
            }

            const valid = val.replace(/ +(?= )/g,''); //removing multiple spaces
            return !valid || valid === ' ' || valid.match(/\s{2,}/g); 
        },
        fieldErrorMessage(index, type, option_index){ //this method is used to give both options, incident-name or regular fields error messages.
            if ( (index == null || index == undefined) || !this.error) return; //this is for not prompting errors in our console.
            if (this.error.type === 'greater' && (type === 'min' || type === 'max') && index === this.error.index) return this.error.message; // the max and min greater problem.
            
            if (option_index !== null && option_index !== undefined){//validating if the option_index is being used
                if (index == this.error.index && option_index === this.error.option) {
                    return this.error.message;
                } //the options within error message.
                return '';
            }
            if (index === this.error.index && type === this.error.type) return this.error.message; // the normal field error message.
            return ''; //regular or none value
        },
        // recordNameError(){
        //     if (!this.error) return;
        //     if (this.error.type === 'record-name') return this.error.message
        // },
        checkFields(){
            for (let index = 0; index < this.formFields.length; index++) {//if the incident-name is valid then FIND the next invalid.
                if (this.validField(index).invalid) {
                    this.error = this.validField(index);
                    return;
                }
                this.error = {invalid: false}
            }
        },
        input(record) { //run the loop only when the new input is valid
            if (!this.dirtyForm) return;
            if (record) { //if we are modifying our incident only.
                // if (this.isBlank(this.incidentName)) { //validating if the incident has a valid name.
                //     this.error = { invalid: true, message: 'Nombre de la incidencia inválido.', type: 'incident-name' };
                //     return;
                // }else {
                //     this.checkFields();
                // }

                this.checkFields();
            }else {
                this.checkFields(); // (fix) find a way to not loop over the entire thing while typing
            }
        },
        validField(index){
            const { name, description, max, min, options } = this.formFields[index];
            if (this.isBlank(description)) return { index, invalid: true, message: 'Ingrese un título válido', type: 'description' }// if the description is the one that is invalid
            if (name === 'text-field' || name === 'photo') {
                const conditions = {
                    min: { invalid: min <= 0 || min == null, message: 'El valor mínimo de carácteres no puede ser cero o menor' },
                    max: { invalid: max <= 0 || max == null, message: 'El valor máximo de carácteres no puede ser cero o menor' },
                    greater: { invalid: max < min, message: 'El valor máximo de caracteres no puede ser menor a su mínimo' },
                }
                for (const key in conditions) {
                    if (conditions[key].invalid) return { index, invalid: true, message: conditions[key].message, type: key }
                }
            }else if (name == 'multiselect') {
                for (let option_index = 0; option_index < options.length; option_index++) {
                    const option = options[option_index];
                    if (this.isBlank(option.label)) return { index, invalid: true, option: option_index, message: 'No pueden haber opciones nulas.', type: 'select-option'};
                }
            }
            return {invalid: false, index}
        },
        validateFields(action) {
            this.dirtyForm = true;
            this.input(null, true); // validating our incidents name
            if (this.error.invalid || this.formFields.length < 1) return;
            for (let index = 0; index < this.formFields.length; index++) {
                this.error = this.validField(index);
                if (this.error.invalid) return;
            }
            if (action === 'to-send') this.addRecordForm();
            
        },
        async addRecordForm() {
            let fields = this.formFields.map(({_id, required, name, description, comment, max, min, options}) => { //mutating the data for it to be suitable for the API.
                const base = { 
                    field_type_id: _id, 
                    field_type: name, 
                    label: description,
                    comment,
                    required,
                }
                // return name === 'multiselect' ? { ...base, values: options.map(field => field.label)} : { ...base, max, min, };
                return name === 'multiselect' ? { ...base, values: options.map(field => field)} : { ...base, max, min, };
            });
            try {
                if (this.isUpdate) {
                    if (!this.comparison.has_changed) { //if we have actually perform changes in our fields
                        this.$emit("closeModal");
                        return;
                    }
                    await this.updateRequest(fields);
                }else {
                    await this.createRequest(fields);
                }
                
                const action = this.isUpdate ? 'actualizada' : 'registrada';
                this.$swal.fire({
                    icon: 'success',
                    title: `Setting ${action}.`,
                    text: `Su setting ha sido ${action} con éxito.`,
                    showConfirmButton: false,
                    timer: 2000
                });
                this.formFields = [];
                this.dirtyForm = false;
                this.$emit("formCompleted");
                this.$emit("closeModal");

            } catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'HA OCURRIDO UN ERROR PROCESANDO el Setting',
                    text: error.response.data.message,
                });
            }
        },
        removeElement(index){
            this.$swal({
                title: this.$t('delivery.you_sure_you_want_to_remove_field'),
                confirmButtonText: this.$t('delivery.yes'),
                denyButtonText: this.$t('delivery.no'),
                showDenyButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.formFields.splice(index, 1);
                    this.checkFields();
                }
            });
        },
        setDragCursor(value) { // drag cursor
            const html = document.getElementsByTagName('html').item(0)
            html.classList.toggle('grabbing', value)
        },
        onStart() {
            this.dragFocus = true;
            this.setDragCursor(true);
        },
        onEnd() {
            this.dragFocus = false;
            this.setDragCursor(false);
        },
        uniqueId() {
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        },
        fillForm(){
            // this.selectedTypeId = this.updateRecord.setting.type.id;
            // this.incidentName = this.updateIncident.name;
            this.recordType = this.selectedRecord.setting.type;
            this.formFields = this.selectedRecord.setting.fields.map((field) => {
                // const base = { _id: field.id, id: this.uniqueId(), name: field.name, description: field.label, comment: field.comment, required: field.required }
                const base = { _id: field.field_type_id, id: this.uniqueId(), name: field.field_type, description: field.label, comment: field.comment, required: field.required }
                if (field.field_type === "text-field" || field.field_type === "photo") {
                    return { ...base, label: this.$t('delivery.field_text'), min: field.min, max: field.max }
                } else if (field.field_type === "multiselect") {
                    // return { ...base, label: 'Selección múltiple', options: field.values.map((option) =>  { return {id: option.id, label: option.name} }) }
                    return { ...base, label: this.$t('delivery.multi_select'), options: field.values.map((option) =>  { return {id: option.id, label: option.label} }) }
                }else {
                    return {...base, label: this.$t('delivery.signature')};
                }
            });
            this.dirtyForm = true;
            this.comparison = {enable: true, has_changed: false, old_fields: JSON.stringify(JSON.parse(JSON.stringify(this.formFields))), old_name: this.recordName}
        },
    },
    watch: {
        formFields: {
            handler(val){
                if (this.comparison.enable) this.comparison.has_changed = this.comparison.old_fields !== JSON.stringify(val);
            },
            deep: true,
        },
        // recordName(val){
        //     if (this.comparison.enable) this.comparison.has_changed = JSON.stringify(this.comparison.old_name) !== JSON.stringify(val);
        // },
        // recordType(val){
        //     if (this.comparison.enable) this.comparison.has_changed = JSON.stringify(this.comparison.old_name) !== JSON.stringify(val);
        // }
    },
    computed: {
        dragOptions() {
            return {
                animation: 350,
                group: "fields",
                disabled: false,
                ghostClass: "ghost"
            }
        },
        // ...mapGetters({
        //     taskTypes: "delivery/taskType",
        // }),
        optionsType(){
            let options= [
                // { value: null, text: 'Incidencia' },
            ];
            this.types.forEach(type => {
                options.push({value: type.id, text: `${type.description}`})
            });
            return options;
        },
        isUpdate() {
            return this.selectedRecord && Object.keys(this.selectedRecord.setting).length > 0;
        }
    },
    // async created(){
    //     if (this.updateIncident) this.fillForm();
    //     try {
    //         await this.$store.dispatch("delivery/getTaskType");
    //     } catch (error) {
    //         console.error(error);
    //     }
    // },
    destroyed(){
        clearInterval(this.errorCounter);
    }
};
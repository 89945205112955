<template>
    <b-row class="create-record mt-3 flex-wrap">
        <!-- (fix) Que crezca y se reduzca el container a medida que lo usamos. -->
        <b-col sm="7" lg="4" xl="3" class="form-options mx-auto mx-lg-0" style="position: sticky; width: 300px;">
            <h3 style="font-size: 18px;" class="mb-3">{{ $t('delivery.options') }}</h3>
            <draggable class="drag-area list-group" :sort="false" :clone="clone" :list="fieldOptions" 
            :group="{name: 'fields', pull: 'clone', put: false}" @start="onStart" @end="onEnd">
                <div v-for="{id, name, label} in fieldOptions" :key="id" class="list-group-item draggable-field pe-1">
                    <div class="d-flex justify-content-between w-100 cursor-grab"> 
                        <span>{{label}}</span>
                        <eva-icon :name="getIconName(name)"
                        fill="#d9d9d9" width="50" />
                    </div>
                </div>
            </draggable>
        </b-col>
        <b-col lg="8" xl="8" class="create-form ms-3">
            <div :style="{paddingRight: formFields.length > 1 ? '20px' : '10px'}" style="max-height: 795px; overflow-y: auto;">
                <h3 style="font-size: 18px;" class="mb-3">{{this.isUpdate ? this.$t('delivery.update'):this.$t('delivery.create')}} {{ $t('delivery.form') }}</h3>
                <div class="d-flex mb-3 align-items-end">
                    <vue-custom-tooltip class="input-container record-name" style="width: 241px;" position="is-bottom" :class="{'active': error.type === 'record-name'}">
                        <div class="d-flex">
                            <!-- <div>
                                <label>Nombre</label>
                                <b-form-input v-model="incidentName" @input="input(true)" style="min-width: 200px" placeholder="Nombre de la incidencia" />
                            </div> -->
                            <div class="d-flex flex-column">
                                <label>{{$t('delivery.type_setting')}}: {{ this.selectedRecord.description }}</label>
                                <!-- <b-form-select v-model="selectedTypeId" :options="optionsType" class="h-100" style="border: 1px solid #ced4da; border-radius: 0.25rem;color: #495057;width: 200px;"/> -->
                            </div>
                        </div>
                    </vue-custom-tooltip>
                    <b-button @click="validateFields('to-send')" :variant="this.isUpdate ? 'success':'primary'" class="d-block ms-auto" :disabled="formFields.length < 1">
                        {{this.isUpdate ? this.$t('delivery.update'):this.$t('delivery.create')}} Setting
                    </b-button>
                </div>
                <draggable class="field-form drag-area position-relative list-group" ref="formField" 
                v-bind="dragOptions" :list="formFields" @update="checkFields" :class="{'drag-focus': dragFocus}" :empty-insert-threshhold="500">
                    <b-row v-for="(field, index) in formFields" :key="field.id" class="list-group-item mx-0 px-0" style="z-index: 2;" :class="{[`error-${error.type}`]: error.index === index}">
                        <b-col v-if="field.name === 'text-field' || field.name === 'photo'" class="d-flex" md="12">
                            <div class="d-flex flex-column flex-wrap me-lg-3 position-relative">
                                <div class="d-flex flex-wrap">
                                    <div style="width: min-content;">
                                        <span class="field-title d-block mb-2">{{ field.label }}</span>
                                        <vue-custom-tooltip class="input-container description-input" position="is-bottom" :label="fieldErrorMessage(index, 'description')">
                                            <b-form-input v-model="field.description" @input="input" placeholder="Título del campo" style="width: 226px;"/>
                                        </vue-custom-tooltip>
                                        <div class="d-flex flex-column mt-3">
                                            <span>{{ $t('delivery.quantity_for') }} {{field.name === 'text-field' ? 'caracteres':'fotos'}}</span>
                                            <div class="d-flex mt-1">
                                                <vue-custom-tooltip class="input-container min-input me-2" position="is-bottom" :label="fieldErrorMessage(index, 'min')">
                                                    <b-form-input v-model.number="field.min" @input="input" class="pe-1" type="number" placeholder="Mínima" />
                                                </vue-custom-tooltip>
                                                <vue-custom-tooltip class="input-container max-input" position="is-bottom" :label="fieldErrorMessage(index, 'max')">
                                                    <b-form-input v-model.number="field.max" @input="input" type="number" placeholder="Máxima" class="pe-1" />
                                                </vue-custom-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms-lg-4" style="margin-top: 34px;">
                                        <b-form-textarea v-model="field.comment" :placeholder="$t('delivery.comentary')" style="width: 200px; height: 76px;" />
                                    </div>
                                </div>
                                <b-form-checkbox v-model="field.required" :id="`checkbox-${field.id}`" class="mt-3" :name="'required-'+field.id">{{ $t('delivery.required') }}</b-form-checkbox>
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-between ms-auto">
                                <eva-icon class="cursor-pointer" @click.native="removeElement(index)" name="trash-2" fill="#ec4561" width="25" height="25"></eva-icon>
                                <eva-icon class="background-icon position-absolute" :name="field.name === 'text-field' ? 'text':'image-outline'" fill="#d9d9d9" width="50" height="50" />
                            </div>
                        </b-col>
                        <b-col v-else-if="field.name === 'multiselect'" md="12" class="d-flex">
                            <div class="d-flex flex-column flex-wrap">
                                <div>
                                    <span class="field-title d-block mb-1">{{ field.label }}</span>
                                    <div class="d-flex">
                                        <vue-custom-tooltip class="input-container description-input" position="is-bottom" :label="fieldErrorMessage(index, 'description')">
                                            <b-form-input v-model="field.description" @input="input" :placeholder="$t('delivery.title_field')" style="width: 226px;" />
                                        </vue-custom-tooltip>
                                    </div>
                                    <div class="select-options d-flex ps-2 mt-3" style="max-width: 280px;">
                                        <div class="divider me-3" />
                                        <div class="d-flex flex-column align-items-center">
                                            <vue-custom-tooltip v-for="(option, option_index) in field.options" :label="fieldErrorMessage(index, 'select-option', option_index)"
                                            class="option d-flex align-items-center justify-content-between mb-3" :class="{'active': option_index === error.option}" :key="option.id" tag="div" position="is-bottom">
                                                <b-form-input v-model="option.label" class="option-input" @input="input" placeholder="Nombre de la opción" style="max-width: 165px;"/>
                                                <eva-icon @click.native="selectOption(field.id, 'remove', option.id)" style="margin-left: 10px;" class="cursor-pointer" name="minus-circle" fill="#ec4561" width="25" />
                                            </vue-custom-tooltip>
                                            <b-button class="me-auto py-1" variant="primary" @click="selectOption(field.id, 'add')" style="width: 165px;">
                                                <eva-icon style="fill: #fff;" class="cursor-pointer" name="plus" width="25" height="25" />
                                                {{ $t('delivery.adding_options') }}
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                                <b-form-checkbox v-model="field.required" :id="`checkbox-${field.id}`" class="d-flex align-items-center mt-3" :name="'required-'+field.id">{{ $t('delivery.required') }}</b-form-checkbox>
                            </div>
                                <div class="mt-4 ps-4 pt-1">
                                    <b-form-textarea v-model="field.comment" class="w-100" :placeholder="$t('delivery.comentary')" style="width: 300px; height: 76px;" />
                                </div>
                                <div class="position-relative d-flex flex-column align-items-center justify-content-between ms-auto">
                                    <eva-icon class="cursor-pointer" @click.native="removeElement(index)" name="trash-2" fill="#ec4561" width="25" height="25" />
                                    <eva-icon class="background-icon position-absolute" style="bottom: -15px; right: -5px;" name="arrow-down" fill="#d9d9d9" width="70" height="70" />
                                </div>
                        </b-col>
                        <b-col v-else-if="field.name === 'validate-location'" md="12" class="d-flex">
                            <eva-icon name="pin" fill="green"></eva-icon>
                            <h4 class="dragged-subtitle">{{ $t('delivery.validate_location') }} </h4>
                            <div class="position-relative d-flex flex-column align-items-center justify-content-between ms-auto">
                                <eva-icon class="cursor-pointer" @click.native="removeElement(index)" name="trash-2" fill="#ec4561" width="25" height="25" />
                            </div>
                        </b-col>
                        <b-col v-else class="d-flex">
                            <div>
                                <span class="field-title d-block mb-2">{{ field.label }}</span>
                                <vue-custom-tooltip class="input-container description-input" position="is-bottom" :label="fieldErrorMessage(index, 'description')">
                                    <b-form-input v-model="field.description" @input="input" placeholder="Título del campo" style="width: 300px;" />
                                </vue-custom-tooltip>
                                <b-form-textarea v-model="field.comment" class="mt-2" debounce="1000" :placeholder="$t('delivery.comentary')" style="width: 300px;" />
                                <b-form-checkbox v-model="field.required" :id="`checkbox-${field.id}`" class="d-flex align-items-center mt-3" :name="'required-'+field.id">{{ $t('delivery.required') }}</b-form-checkbox>
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-between ms-auto">
                                <eva-icon class="cursor-pointer" @click.native="removeElement(index)" name="trash-2" fill="#ec4561" width="25" height="25" />
                                <eva-icon class="background-icon position-absolute" name="brush" style="bottom: 8px; right: 16px;" fill="#d9d9d9" width="60" height="60" />
                            </div>
                        </b-col>
                    </b-row>
                    <div v-if="formFields.length < 1" class="drop-here-background position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center" style="z-index: 1; opacity: 0.4;">
                        <icon-drop-here class="drop-here-icon mt-3" :size="118"/>
                        <h4 style="margin-top: 10px; color: rgb(184 184 184);">{{ $t('delivery.drag_the_fields_here') }}</h4>
                    </div>
                </draggable>
            </div>
        </b-col>
        <!-- <b-col sm="12" class="mt-4">
            <div v-for="(field, index) in formFields" :key="index">
                {{field}}
            </div>
        </b-col> -->
    </b-row>
</template>

<script>
import Draggable from 'vuedraggable'
import IconDropHere from '../../assets/svg/iconDropHere.vue';
import { http as backend} from "@/common/services/web-backend.js";
// import { mapGetters } from "vuex"

import { dynamicSettingMixin } from "../../helpers/mixins/dynamic-setting-mixin.js";

export default {
    name: "ManageSetting",
    props: {
        type: {
            type: String
        }
    },
    components: { Draggable, IconDropHere},
    mixins: [dynamicSettingMixin],
    computed: {
        // ...mapGetters({
        //     types: "delivery/taskType",
        // }),
        types() {
            switch (this.type) {
                case 'task-setting':
                    return this.$store.getters["delivery/taskType"];
                case "incident-setting":
                    return this.$store.getters["delivery/incidentType"];
                default: 
                    return this.$store.getters["delivery/taskType"];
            }
        }
    },
    methods: {
        async createRequest(fields) {
            switch (this.type) {
                case 'task-setting':
                    await backend.post('task-settings',{ fields: JSON.stringify(fields), task_type_id: this.selectedTypeId });
                    break;
                case "incident-setting":
                    await backend.post('incident-settings',{ fields: JSON.stringify(fields), incident_type_id: this.selectedTypeId });
                    break;
            }
        },
        async updateRequest(fields) {
            switch (this.type) {
                case 'task-setting':
                    await backend.put(`task-settings/${this.selectedRecord.setting.id}`, { fields: JSON.stringify(fields), task_type_id: this.selectedTypeId });
                    break;
                case "incident-setting":
                    await backend.put(`incident-settings/${this.selectedRecord.setting.id}`, { fields: JSON.stringify(fields), incident_type_id: this.selectedTypeId });
                    break;
            }
        },
        getIconName(fieldName) {
            const iconNames = {
                "text-field": "text",
                "photo": "image-outline",
                "multiselect": "arrow-down",
                "validate-location": "pin-outline"
            };

            if (fieldName in iconNames) {
                return iconNames[fieldName];
            }

            return "brush";
        }
    },
    async created(){
        if (this.isUpdate) this.fillForm();

        // the type_id will always be equal to the of the selectedRecord
        this.selectedTypeId = this.selectedRecord.id;
        
        try {
            switch (this.type) {
                case 'task-setting':
                    await this.$store.dispatch("delivery/getTaskType");
                    break;
                case "incident-setting":
                    await this.$store.dispatch("delivery/getIncidentType");
                    break;
                default: 
                    await this.$store.dispatch("delivery/getTaskType");
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    },
    // destroyed(){
    //     clearInterval(this.errorCounter);
    // }
}
</script>

<style scoped>
    .input-container {
        --vue-custom-tooltip-color: rgb(255, 54, 54) !important;
        --vue-custom-tooltip-background: rgb(255, 255, 255) !important;
        --vue-custom-tooltip-border-radius: 5px !important;
        --vue-custom-tooltip-font-weight: 400;
        max-width: 256px;
    }
    .option {
        --vue-custom-tooltip-color: rgb(255, 54, 54) !important;
        --vue-custom-tooltip-background: rgb(255, 255, 255) !important;
        --vue-custom-tooltip-border-radius: 5px !important;
        --vue-custom-tooltip-font-weight: 400;
    }
    /* error tooltips */
    .record-name.input-container.vue-custom-tooltip::v-deep::before { left: 20px; } 
    .record-name.input-container.vue-custom-tooltip::v-deep::after { left: 47%; }
    /* field-title tooltips */
    .description-input.input-container.vue-custom-tooltip::v-deep::before { left: 20px; }
    .description-input.input-container.vue-custom-tooltip::v-deep::after { left: 36%; }
    /* character quantity */
    .min-input.input-container.vue-custom-tooltip::v-deep::after, .max-input.input-container.vue-custom-tooltip::v-deep::after { left: 50%; transform: translateX(-15%);}
    /* option creator */
    .option.vue-custom-tooltip::v-deep::before { left: 20px; }
    .option.vue-custom-tooltip::v-deep::after { left: 56%; }

    .grabbing * {
        cursor: grabbing;
    }
    .draggable-field::v-deep svg {
        transition: all 120ms ease-in-out;
    }
    .draggable-field:hover::v-deep svg {
        fill: #495057;
    }
    .error-description .input-container.description-input input,
    .error-min .input-container.min-input input,
    .error-max .input-container.max-input input,
    .error-greater .input-container.max-input input, 
    .error-greater .input-container.min-input input,
    .error-select-option .active input,
    .incident-name.active input{
        border-color: #ec4561 !important;
    }
    .min-max-input {
        width: 92px;
    }
    .background-icon {
        bottom: 20px;
        right: 20px;
    }
    .error-field i.background-icon::v-deep svg {
        animation: iconError 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
    .list-group-item.error-field {
        animation: backgroundError 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
    @keyframes backgroundError {
        from { background-color: rgb(255, 203, 203); }
        to { background-color: #fff; }
    }
    @keyframes iconError {
        from { fill: red;}
        to { fill: #d9d9d9;}
    }
    .form-control.error:focus, .form-control.error {
        border-color: #ec4561 !important;
    }
    .field-title {
        font-weight: 600;
        font-size: 17px;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .draggable-here {
        height: 40px;
    }
    .field-form {
        min-height: 200px;
        background-color: #eff5f7;
        border: 2px solid #e9ecef;

        transition: min-height 500ms cubic-bezier(.79,.01,.29,.79); /* que cuando estemos sujetando un objeto se cambie la clase a la dicha */
    }
    .drag-focus {
        border: 2px dashed #d6d6d6 !important;
    }
    .custom-checkbox {
        cursor: auto;
    }
    .custom-checkbox::v-deep input, .custom-checkbox::v-deep label {
        cursor: pointer !important;
    }
    .select-options .option:last-child {
        margin-bottom: 0 !important;
    }
    .divider {
        width: 4px;
        border-radius: 20px; 
        background-color: #ced4da;
    }
    textarea {
        resize: none;
    }
    svg.drop-here-icon {
        fill: rgb(184 184 184);
    }

    .dragged-subtitle {
        font-size: 18px;
        margin: 0 0 0 5px;
        color: green;
    }
</style>
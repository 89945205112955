import { http } from "@/common/services/web-backend.js";

export class CitiesService {
  async getCities(){
    try {
      const response = await http.get('ecommerce/cities')
      return response.data
    } catch (error) {
      console.log(error);
    }
  }
}

<template>
    <div>
        <div>
            <el-container class="d-flex flex-column">
                <h5>Empleados</h5>
                <div class="bg-white p-2">
                    <div class="d-flex align-items-end justify-content-between">
                        <div class="me-2">
                            <label for="search-employee">Buscar empleado</label>
                            <el-input v-model.lazy="search" size="mini" placeholder="Type to search" clearable id="search-employee"/>
                        </div>
                        <!-- <el-button @click="showSearch = true" size="mini">Agregar empleado</el-button> -->
                    </div>
                    <el-table size="medium" :data="employees" stripe class="mt-2" style="max-width: 800px;">
                        <el-table-column prop="id" label="ID" width="100" align="center"/>
                        <el-table-column prop="name" label="Nombre"/>
                        <el-table-column label="Access Level" prop="accessLevels" align="center" width="150"/>
                    </el-table>
                </div>
            </el-container>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
    name: "ListEmployees",
    components:{

    },
    data(){
        return{
            search: '',
            activeTab: 'tab3',
        }
    },
    computed:{
        ...mapGetters({
            list: 'auth/listEmployees'
        }),
        employees(){
            if(this.list){
                const filter = this.list.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
                return filter;
            }
            return [];
        },
    },
    methods:{
    }
}
</script>

<style>

</style>
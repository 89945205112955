<template>
    <main-modal v-if="showModal" :title="$t('delivery.create_setting')" @closeModal="closeModal">
        <manage-setting ref="ManageSetting" :type="type" @closeModal="closeModal" @formCompleted="() => { $emit('formCompleted') }" :selectedRecord="selectedRecord" />
    </main-modal>
</template>

<script>
import MainModal from '../../../../../common/components/modals/MainModal.vue';
// import ManageTaskSetting from '../ManageTaskSetting.vue';
import ManageSetting from '../ManageSetting.vue';

export default {
    name: "SettingModal",
    components: { MainModal, ManageSetting },
    data() {
        return {
            showModal: false,
        }
    },
    props: {
        type: {
            type: String
        },
        selectedRecord: {
            type: Object,
            required: true
        }
    },
    methods: {
        openModal(){ this.showModal = true; }, //used outside
        closeModal(){
            if (this.$refs.ManageSetting.formFields.length && this.$refs.ManageSetting.comparison.has_changed) {
                this.$swal({
                    title: '¿Estás seguro de que quieres cerrar?',
                    text: 'Aún tienes cambios sin guardar',
                    confirmButtonText: 'Si',
                    denyButtonText: 'No',
                    showDenyButton: true,
                    icon: 'warning',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.showModal = false;
                        this.$emit("cleanRecordSelected");
                    }
                });
            }else {
                this.showModal = false;
                this.$emit("cleanRecordSelected");
            }
        }
    }

}
</script>

<style>

</style>
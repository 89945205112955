<template>
    <main-modal @closeModal="$emit('closeModal')">
        <h2 class="text-center">{{ $t("delivery.incident_detail") }}</h2>
        <dynamic-form-renderer :fields="incidentDetails.fields" />
    </main-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import MainModal from '../../../../../common/components/modals/MainModal.vue';
    import DynamicFormRenderer from "../DynamicFormRenderer.vue";

    export default {
        name: "IncidentDetails",
        components: {
            MainModal,
            DynamicFormRenderer
        },
        computed: {
            ...mapGetters({
                incidentDetails: "delivery/incidentDetails"
            })
        }
    }
</script>

<style scoped>

</style>
<template>
    <icon-base :fill="color" :width="size" viewBox="0 0 100 100">
        <path class="background" d="M50-.001c27.614 0 50 22.386 50 50s-22.386 50.001-50 50.001-50-22.387-50-50.001 22.386-50 50-50z"/>
        <defs>
            <path id="a" d="M50-.001c27.614 0 50 22.386 50 50s-22.386 50.001-50 50.001-50-22.387-50-50.001 22.386-50 50-50z"/>
        </defs>
        <clipPath id="b">
            <use xlink:href="#a" overflow="visible"/>
        </clipPath>
        <g clip-path="url(#b)">
            <path fill="#EFC75E" class="route" d="M99.484 85.309l-55.819-55.82 44.663-44.663-5.656-5.657-44.663 44.663-34.82-34.819-5.674 5.674 67.818 67.819-47.661 47.664 5.656 5.657 47.663-47.663 22.819 22.818 5.674-5.673z"/>
            <!-- <path fill="#CCC7A5" d="M63.004 33.866l-.008-12.859 27 26.156-.023 12.827-26.969-26.124z"/> -->
            <!-- <path fill="#DAD5B1" d="M126.996 22.979l-.055-13.003-36.941 37.023v13.031l36.996-37.051z"/> -->
            <!-- <path fill="#E4DFBD" d="M62.91 21.022l32.18-32.671 27 27.562-32.086 32.046-27.094-26.937z"/> -->
            <path fill="#E2574C" d="M38.002 20.992c10.488 0 18.99 8.466 18.99 18.909 0 .203-.093 5.063-1.726 8.528-4.678 10.741-17.248 22.64-17.265 22.578-.02.041-12.536-11.787-17.236-22.514-1.395-2.541-1.755-8.178-1.755-8.593.001-10.442 8.504-18.908 18.992-18.908z"/>
            <path fill="#fff" d="M38 29.999c-5.522 0-10 4.477-10 10 0 5.522 4.478 10 10 10s10-4.478 10-10c0-5.523-4.478-10-10-10zm0 16.001c-3.313 0-6-2.687-6-6s2.687-6 6-6 6 2.686 6 6-2.687 6-6 6z"/>
        </g>
    </icon-base>
</template>

<script>
import IconBase from '../../../../common/components/svg/IconBase.vue'
export default {
    components: { IconBase },
    name: "IconMap",
    props:{
        color: {
            type: String
        },
        size:{
            type: Number,
            default: 20
        }
    }
}
</script>

<style scoped>
    .dark > .background{
        fill: #343332
    }
    .light > .background{
        fill: #f6f6f4
    }
    .default > .background{
        fill: #F4F0D3
    }
    .dark .route{
        fill: #a1a1a1;
    }
    .light .route{
        fill: #fff;
    }
</style>

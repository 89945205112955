import MapboxDraw from "@mapbox/mapbox-gl-draw";
import Mapbox from "mapbox-gl";
import * as turf from '@turf/turf';
import { StoreService} from "../../service/stores-services";
const service = new StoreService();
export const limitionsMixin = {
    methods: {
        mapStyle(){
            return `mapbox://styles/mundomovil28/${ this.mapStyles[this.styleSelected]}`
        },
        onMapLoaded(){
            this.mapbox = Mapbox;
        },
        async getStores(){
            try{
                const response = await service.getStores()
                this.stores = response
            }catch (e) {
                console.log(e);
            }
        },
        onRowSelected(item) {
            try {
                this.editStore(item[0])
            } catch (error) {
                console.log(error)
            }
        },
        editStore(data){
            window.scrollTo(0,0);
            this.editLocationMode = true;
            this.storeSelected = data;
            this.mapOptions.defaultMarker = [data.longitude, data.latitude];
            this.getLimits(data.codplace);
            this.$refs.map.map.flyTo({
                center: [data.longitude, data.latitude],
                zoom: 14,
                bearing: 0,
                speed: 2, // make the flying slow
                curve: 1, // change the speed at which it zooms out
                easing: function(t) {
                return t;
                },
                essential: true
            });
        },
        changeMarket(data){
            this.mapOptions.defaultMarker = [data.marker._lngLat.lng, data.marker._lngLat.lat];
        },
        async getLimits(code){
            try {
                const response = await service.getLimits(code)
                this.limits = response
                return response
            } catch (error) {
                console.log(error);
            }
        },
        async updateArea(e) {
            console.log(e.features);
            var data = this.control.getAll();
            var answer = document.getElementById('calculated-area');
            if (data.features.length > 0) {
                this.limits = await this.getLimitsClean(data);
                var area = turf.area(data);
                this.roundedArea = Math.round((area * 100)/1000000) / 100;
            } else {
                answer.innerHTML = '';
                console.log(e)
                // if (e.type !== 'draw.delete')
                //   alert('Use the draw tools to draw a polygon!');
            }
        },
        async getLimitsClean(data){
            let limits = [];
            await data.features.forEach((feature, indexf)=>{
              feature.geometry.coordinates.forEach((coordinate)=>{
                limits[indexf] = {  limits: []  };
                coordinate.forEach((p)=>{
                    limits[indexf].limits.push({ longitude: p[0], latitude: p[1] })
                })
              })
            })
            return limits;
        },
        async changeLimitationState(){
            this.editLimitation = !this.editLimitation;
            if (this.editLimitation){
                this.control = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        polygon: true,
                        trash: true
                    }
                });
                this.$refs.map.map.addControl(this.control, 'top-left');
                this.$refs.map.map.on('draw.create', this.updateArea);
                this.$refs.map.map.on('draw.delete', this.updateArea);
                this.$refs.map.map.on('draw.update', this.updateArea);
                await this.limits.forEach(limit=>{
                let coordenates = [];
                let coords = [];
                limit.limits.forEach((l, indexY)=>{
                    coords.push([Number(l.longitude), Number(l.latitude)]);
                    if (indexY === limit.limits.length - 1) {
                    coordenates.push(coords);
                    const feature = {id: limit._id, type: 'Polygon', coordinates: coordenates };
                    this.control.add(feature);
                    coordenates = [];
                    }
                })
                });
                console.log(this.limits);
                // this.updateArea()
                this.btnLabel1 = "Ocultar limitacion control"
            }else{
                if (this.control.hasOwnProperty('deleteAll')) {
                this.control.deleteAll();
                this.$refs.map.map.removeControl(this.control);
                }
                this.btnLabel1 = "Mostrar limitacion control"
            }
        },
        changeMarketState(){
            this.editMarker = !this.editMarker;
            if (this.editMarker){
                this.btnLabel2 = "Desactivar mover marca"
            }else{
                this.btnLabel2 = "Activar mover marca"
            }
        },
        cancel(){
            this.$refs.map.map.zoomOut({
                zoom: 7,
                duration: 2000,// make the flying slow
            });

            this.editLocationMode = false;
            this.editMarker = false;
            this.editLimitation = false;
            this.storeSelected = {};
            this.btnLabel2 = "Activar mover marca";
            this.btnLabel1 = "Mostrar limitacion control";
            if (this.control.hasOwnProperty('deleteAll')){
                this.control.deleteAll();
                this.$refs.map.map.removeControl(this.control);
            }
            this.limits = [];
            this.btnLabel1 = "Mostrar limitacion control"
        },
        async update(obj){
            console.log(obj, this.limits);
            this.$swal.fire({ 
            title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
            icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
            cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    console.log(this.limits);
                    try {
                        await service.update(obj, this.limits, this.mapOptions, this.storeSelected.codplace)
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                            icon: "success",
                        })
                        this.items = []
                        this.taskTable += 1
                    } catch (error) {
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: error.response.data.message, 
                            icon: "error",
                        })
                    }
                }
            })
        },
        async getZones(){
          // console.log(this.stores);
          let listLimits = []
          for (let i = 0; i < this.stores.length; i++) {
            const store = this.stores[i];
            const limits = await this.getLimits(store.codplace)
            listLimits.push(limits[0])
            console.log(limits);
          }
              this.control = new MapboxDraw({
                  displayControlsDefault: false,
                  controls: {
                      polygon: true,
                      trash: true
                  }
              });
              this.$refs.map.map.addControl(this.control, 'top-left');
              this.$refs.map.map.on('draw.create', this.updateArea);
              this.$refs.map.map.on('draw.delete', this.updateArea);
              this.$refs.map.map.on('draw.update', this.updateArea);
              console.log(listLimits);
              await listLimits.forEach(limit=>{
                console.log(limit);
              let coordenates = [];
              let coords = [];
                limit.limits.forEach((l, indexY)=>{
                    coords.push([Number(l.longitude), Number(l.latitude)]);
                    if (indexY === limit.limits.length - 1) {
                    coordenates.push(coords);
                    const feature = {id: limit._id, type: 'Polygon', coordinates: coordenates };
                    this.control.add(feature);
                    coordenates = [];
                    }
                })
              });
              this.showMarkers = false
              // console.log(this.limits);
      },
    },
}

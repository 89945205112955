import { http } from "../../../common/services/node-service";
export class DictionaryService {

  async index(page){
    try{
      const {data} = await http.get('dictionaries?page=' + page);
      return data.data;
    }catch (e) {
      if (e.response) {
        e.response.data
      }else{
        throw e
      }
    }
  }

  async store(obj){
    try{
      const { data } = await http.post('dictionaries', { ...obj });
      return data.data;
    }catch (e) {
      if (e.response){
        throw e.response.data;
      }else{
        throw e
      }
    }
  }

  async storeBadWord(obj){
    try{
      const data = await http.post('dictionaries/badword', obj);
      const res = data.data;
      return res.data;
    }catch (e) {
      if (e.response){
        throw e.response.data;
      }else{
        throw e
      }
    }
  }
  async update(){
    try{
      const data = await http.get('dictionaries');
      const res = data.data;
      return res.data;
    }catch (e) {
      if (e.response){
        throw e.response.data;
      }else{
        throw e
      }
    }
  }
  async destroy(id, word =null){
    try{
      const data = await http.delete('dictionaries/'+id, { data: { word }});
      const res = data.data;
      return res.data;
    }catch (e) {
      if (e.response){
        throw e.response.data;
      }else{
        throw e
      }
    }
  }
}

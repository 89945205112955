<template>
    <main-modal @closeModal="$emit('closeModal')">
        <template>
            <video width="1300" height="800" :src="url" controls>
            </video>
        </template>
    </main-modal>
</template>

<script>
    import { http } from "../../../common/services/node-service";
    import MainModal from '../../../common/components/modals/MainModal.vue'
    
    export default {
        components: { MainModal },
        name: 'guieForVideos',
        data(){
            return {
                url: null,
            }
        },
        computed:{
        },
        methods:{
            async getVideo(){
                try {
                    const response = await http.get('/containers/getVideos?name=filters-video.mov')
                    console.log(response);
                    this.url = response.data.data.videos[0].url
                } catch (error) {
                    console.log(error)
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Guia',
                        text: 'El video no se encuentra disponible ingrese mas tarde',
                    })
                }
            },
        },
        watch:{
        },
        async created(){
            await this.getVideo()
        }
    }
</script>

<style scoped>
</style>
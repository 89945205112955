<template>
    <icon-base :width="size + 'px'" :fill="color" class="icon-arrow">
        <g>
            <path  d="M66.3,0.5c3.3,0,6.6,1.3,9.2,3.8l121.2,121.2L76.6,245.7c-5.1,5.1-13.3,5.1-18.4,0c-5.1-5.1-5.1-13.3,0-18.4   L160,125.5L57.1,22.7C52,17.6,52,9.4,57.1,4.3C59.6,1.8,63,0.5,66.3,0.5z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'iconArrow',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


<template>
    <main-modal :title="'zones.list_to_setting_routes'" @closeModal="$emit('closeModal')">
        <b-row class="mb-3">
            <b-col lg="5">
                <!-- <div class="d-flex mb-2"> -->
                    <el-input :placeholder="$t('zones.search_by_name')" v-model="search" class="input-with-select" />
                    <!-- <b-form-input v-model="search" :placeholder="$t('zones.search_by_name')"></b-form-input> -->
                <!-- </div> -->
            </b-col>
        </b-row>
        <el-table size="mini" :data="search ? result : routes" responsive thead-class="bg-primary text-white" 
        table-class="text-center mb-0" class="bg-white" header-cell-class-name="text-white" :header-cell-style="{'background-color' : '#308EEC'}" style="width: 100%" max-height="450">
            <el-table-column fixed type="id" prop="id" :label="$t('zones.id')" height="100"/>
            <el-table-column width="300" prop="name" :label="$t('zones.name')"/>
            <el-table-column width="150" prop="task_type_id" :label="$t('zones.type')"/>
            <el-table-column width="150" prop="week_days" :label="$t('zones.days')"/>
            <el-table-column width="150" prop="start_date" :label="$t('zones.start_date')"/>
            <el-table-column width="150" prop="end_date" :label="$t('zones.end_date')"/>
            <el-table-column width="200" :label="$t('zones.creation_date')" class-name="text-center">
                <template slot-scope="scope">
                    <el-tag>{{$d(new Date(scope.row.created_at), 'alt_short')}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column width="200" :label="$t('zones.actions')" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-tooltip :content="$t('zones.edit')" placement="top">
                        <el-button size="mini" type="warning" @click="handleEdit(scope.row)" circle icon="el-icon-edit"/>
                    </el-tooltip>
                    <el-tooltip  :content="$t('zones.show')" placement="top">
                        <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)" circle icon="el-icon-view"/>
                    </el-tooltip>
                    <el-tooltip :content="$t('zones.delete')" placement="top">
                        <el-popconfirm :title="$t('zones.question_remove_item')" @confirm="handleDelete(scope.$index, scope.row.id)">
                            <el-button style="margin-left: 7px;" size="mini" type="danger" slot="reference" circle icon="el-icon-delete"/>
                        </el-popconfirm>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <!-- <b-table :items="search ? result : routes" :fields="fields" responsive striped thead-class="bg-primary text-white" 
        table-class="text-center mb-0" class="bg-white">
            <template #cell(created_at)="data">
                {{moment(data.item.created_at).format('YYYY-MM-DD HH:MM:SS A')}}
            </template>
            <template #cell(actions)>
                <div class="d-flex flex-column">
                    <b-button size="sm" style="width: 65px;" class="me-2 m-1" variant="warning">{{ $t('zones.edit') }}</b-button>
                    <b-button size="sm" style="width: 65px;"  class="me-2 m-1" variant="danger">{{ $t('zones.delete') }}</b-button>
                </div>
            </template>
        </b-table> -->
        <vue-element-loading :active="loading" :is-full-screen="false"/>
        <single-route-details :singleRoute="singleRoute" :stores="stores" v-if="showRoute" @closeModal="showRoute = false" />
    </main-modal>
</template>

<script>
    import { StoreService } from "../../ecommerce/service/stores-services";
    const service = new StoreService();
    import { http as erp} from "../../../common/services/web-backend";
    import MainModal from '../../../common/components/modals/MainModal.vue'
    import moment from "moment";
    import SingleRouteDetails from "./SingleRouteDetails.vue";
    
    export default {
        components: { MainModal, SingleRouteDetails },
        name: 'RouteList',
        data(){
            return {
                routes: [],
                result: [],
                stores: [],
                moment: moment,
                loading: false,
                search: '',
                singleRoute: {},
                showRoute: false
            }
        },
        computed:{
            fields(){
                const labels = [
                    {label: this.$t('zones.id'), key: 'id'},
                    {label: this.$t('zones.name'), key: 'name'},
                    { label: this.$t('zones.type'), key: 'type' },
                    {label: this.$t('zones.days'), key: 'days'},
                    {label: this.$t('zones.start_date'), key: 'start_date'},
                    {label: this.$t('zones.end_date'), key: 'end_date'},
                    {label: this.$t('zones.creation_date'), key: 'created_at'},
                    {label: this.$t('zones.actions'), key: 'actions'},
                ]
                return labels;
            }
        },
        methods:{
            async getRoutes(){
                try {
                    this.loading = true
                    const response = await erp.get('/route-settings')
                    this.routes = response.data.data;
                    // console.log(this.routes)
                } catch (error) {
                    // console.log(error)
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('zones.error_occurred'),
                        text: error.response ? error.response.data.message : error.message,
                    })
                } finally {
                    this.loading = false
                }
            },
            async getStores(){
                try{
                    const response = await service.getStores()
                    this.stores = response
                    // console.log(this.stores);
                }catch (e) {
                    console.log(e);
                }
            },
            handleEdit(row){
                this.$emit("send-Filter", row)
                this.$emit("closeModal")
            },
            handleShow(index, id){
                this.getSingleRoutesDetails(id)
                this.showRoute = true
            },
            handleDelete(index, id){
                this.deleteRoutesDetails(id)
            },
            async getSingleRoutesDetails(id){
                try {
                    this.loading = true
                    const response = await erp.get(`/route-settings/${id.id}`)
                    this.singleRoute = response.data.data;
                    // console.log(this.singleRoute)
                } catch (error) {
                    console.log(error)
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('zones.error_occurred'),
                        text: error.response ? error.response.data.message : error.message,
                    })
                } finally {
                    this.loading = false
                }
            },
            async deleteRoutesDetails(id){
                try {
                    this.loading = true
                    const response = await erp.delete(`/route-settings/${id}`)
                    console.log(response);
                    this.getRoutes()
                } catch (error) {
                    // console.log(error)
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('zones.error_occurred'),
                        text: error.response ? error.response.data.message : error.message,
                    })
                } finally {
                    this.loading = false
                }
            }
        },
        watch:{
            search(val){
                // console.log(this.search)
                const result = this.routes.filter(route => route.name.toLowerCase().includes(val.toLowerCase()))
                this.result = result
            }
        },
        async created(){
            await this.getRoutes()
            await this.getStores()
            // console.log(this.routes);
        }
    }
</script>

<style scoped>
    .table-responsive{
        height: 600px;
    }
</style>
export default {
  employees: (state) => state.employees,
  taskType: (state) => state.taskType,
  incidentType: (state) => state.incidentType,
  assignMessenger: (state) => state.assignMessenger,
  tasks: (state) => state.tasks,
  taskIncidents: (state) => state.taskIncidents,
  singleTaskIncidents: (state) => state.singleTaskIncidents,
  selectTask: (state) => state.selectTask,
  status: (state) => state.status,
  methods: (state) => state.methods,
  agents: (state) => state.agents,
  locationAssignMap: (state) => state.locationAssignMap,
  taskDetails: (state) => state.taskDetails,
  salesEmployee: (state) => state.salesEmployee,
  incidentDetails: (state) => state.incidentDetails
}

<template>
  <layout>
    <setting-manager type="task-setting">

    </setting-manager>
  </layout>
</template>

<script>
  import SettingManager from '../components/tasks/SettingManager.vue';
  import Layout from "@/modules/template/layouts/main";

  export default {
    components: { SettingManager, Layout },
    name: "TaskSettingsManager"
  };
</script>
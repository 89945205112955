<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 451.827 451.827">
        <g>
            <g>
                <path d="M225.922,0C101.351,0,0.004,101.347,0.004,225.917s101.347,225.909,225.917,225.909    c124.554,0,225.901-101.347,225.901-225.909C451.823,101.347,350.476,0,225.922,0z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconCircle',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


const Module = () => import("./Module.vue");
const Roles = () => import("./view/Roles.vue");
const HttpLogs = () => import("./view/HttpLogs.vue");

const localData = JSON.parse(localStorage.getItem('userAcess'));
const isActive = localData?.modules?.find(data => data?.name === 'Admin')

// const httpLogs = localData?.subModules?.find(data => data.name === 'Http Logs')
const httpLogs = localData?.subModules?.find(data => data.name === 'http Logs')

const moduleRoute = {
  path: "/admin",
  component: Module,
  name: "Admin",
  children: [
    {
      path: "access",
      name: 'Administrar acceso',
      component: Roles,
      meta: { requiresAuth: true, active: isActive?.subModules[0]?.active, search: isActive?.subModules[0]?.active}
    },
    {
      path: "http-logs",
      name: "http logs",
      component: HttpLogs,
      meta: { requiresAuth: true, active: httpLogs?.active, search: httpLogs?.active}
    }
  ],
};

export default router => {
    router.addRoutes([moduleRoute]);
};

<template>
    <div>
        <b-row>
            <b-col class="d-flex flex-column">
                <label for="date" class="me-2">{{$t('tracking.filter_by_date')}}:</label>
                <date-picker v-model="rangeForChart" :value="rangeForChart" :input-class="'datePick'" range style="max-width: 200px;"></date-picker>
            </b-col>
            <b-col class="d-flex flex-column align-items-end">
                <label for="avg-time-by-actions-by-branch" class="me-2">{{$t('tracking.time_in')}}:</label>
                <multiselect :id="'avg-time-by-actions-by-branch'" v-model="timeFormatByActionsByBranch" :options="timeFormats" :label="'text'" track-by="value" style="max-width: 200px;"/>
            </b-col>
        </b-row>
        <b-row class="mt-3 bg-white m-1">
            <b-col lg="12">
                <apexchart ref="avgTimeByActionsByBranch" type="bar" height="400" :options="avgTimeByActionsByBranch.chartOptions" :series="avgTimeByActionsByBranch.series"></apexchart>
            </b-col>
            <b-col lg="6">
                <apexchart type="area" height="300" :options="slaByBranch.chartOptions" :series="slaByBranch.series"></apexchart>
            </b-col>
            <b-col lg="6">
                <apexchart ref="avgForFinishedDocuments" type="line" height="300" :options="avgForFinishedDocuments.chartOptions" :series="avgForFinishedDocuments.series"></apexchart>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
    export default {
        name: "DocumentFollowupCharts",
        components:{
            DatePicker
        },
        props:{
            tabIndex:{
                type: Number
            }
        },
        data(){
            return{
                rangeForChart: null,
                timeFormatByActionsByBranch: { text: "Minutos", value: 60 },
            }
        },
        computed:{
            ...mapGetters({
                documentChart: "documentsFollowup/documentChart",
                actions: "documentsFollowup/actions",
            }),
            timeFormats(){
                return [
                    { text: this.$t('tracking.minutes'), value: 60 },
                    { text: this.$t('tracking.hours'), value: 3600 },
                    { text: this.$t('tracking.days'), value: 86400 },
                ]
            },
            avgTimeByActionsByBranch() {
                const data = this.getAvgTimeByActionsByBranch();
                return {
                    series: data.chartData,
                    chartOptions: {
                    chart: {
                        height: 400,
                        type: "line",
                        dropShadow: {
                        enabled: false,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                        },
                        toolbar: { show: false },
                    },
                    colors: [
                        "#e33d59",
                        "#f8b425",
                        "#38a4f8",
                        "#f1734f",
                        "#8d6e63",
                        "#afb42b",
                        "#02a499",
                        "#6f42c1",
                        "#e37f7f",
                        "#3d3d3d",
                    ],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        curve: "smooth",
                    },
                    title: {
                        text: `${this.$t('tracking.actions')} → ${this.$t('tracking.avg_time_per_branch')}`,
                        align: "left",
                    },
                    grid: {
                        borderColor: "#e7e7e7",
                        row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5,
                        },
                    },
                    markers: {
                        size: 1,
                    },
                    xaxis: {
                        categories: data.stores,
                        title: {
                        text: `${this.$t('tracking.branches')}`,
                        },
                    },
                    yaxis: {
                        title: {
                        text: `${this.$t('tracking.time')}`,
                        },
                        // "min": 5,
                        // "max": 40
                    },
                    legend: {
                        position: "top",
                        horizontalAlign: "right",
                        floating: true,
                        offsetY: -25,
                        offsetX: -5,
                    },
                    plotOptions: {
                        bar: {
                        borderRadius: 2,
                        dataLabels: {
                            position: "center",
                            orientation: "vertical",
                        },
                        },
                    },
                    },
                };
            },
            slaByBranch() {
                const data = this.getSlaByBranch();
                return {
                    series: [
                    { name: `${this.$t('tracking.very_late')}`, data: data.veryLateCount },
                    { name: `${this.$t('tracking.late')}`, data: data.lateCount },
                    { name: `${this.$t('tracking.on_time')}`, data: data.onTimeCount },
                    ],
                    chartOptions: {
                    chart: { height: 350, type: "area" },
                    dataLabels: { enabled: false },
                    stroke: { curve: "smooth" },
                    colors: ["#e84242", "#d8a10a", "#348547"],
                    xaxis: { type: "category", categories: data.stores },
                    tooltip: { x: { format: "dd/MM/yy HH:mm" } },
                    title: { text: `${this.$t('tracking.sla_per_branches')}`, align: "left" },
                    yaxis: { title: { text: "Service Level Agreement" } },
                    legend: {
                        position: "top",
                        horizontalAlign: "left",
                        floating: true,
                        offsetY: -28,
                        offsetX: 155,
                    },
                    },
                };
            },
            avgForFinishedDocuments() {
                const data = this.getAvgForFinishedDocuments();
                return {
                    series: [{ name: "Tiempo", data: data.chartData }],
                    chartOptions: {
                    chart: { height: 350, type: "line", zoom: { enabled: false } },
                    dataLabels: { enabled: true },
                    stroke: { curve: "straight" },
                    title: {
                        text: `${this.$t('tracking.documents')} → ${this.$t('tracking.avg_time_per_finished_documents')}`,
                        align: "left",
                    },
                    grid: {
                        borderColor: "#e7e7e7",
                        row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 },
                    },
                    xaxis: { categories: data.stores },
                    },
                };
            }, 
        },
        methods:{
            getSlaByBranch() {
                let stores = [];
                let slas = [
                    { sla_id: 1, name: "On Time" },
                    { sla_id: 2, name: "Late" },
                    { sla_id: 3, name: "Very Late" },
                ];
                this.documentChart.sla_by_branch.forEach((item) => {
                    let findIndex = stores.findIndex(
                    (store) => store.branch_code == item.branch_code
                    );
                    if (findIndex == -1)
                    stores.push({
                        branch_code: item.branch_code,
                        branch_name: item.branch_name,
                    });
                });
                let chartData = [];
                stores.forEach((store) => {
                    slas.forEach((sla) => {
                    this.documentChart.sla_by_branch.forEach((item) => {
                        let findIndex = chartData.findIndex(
                        (el) =>
                            store.branch_code == el.branch_code && sla.sla_id == el.sla_id
                        );
                        if (findIndex == -1) {
                        if (
                            store.branch_code == item.branch_code &&
                            sla.sla_id == item.sla_id
                        ) {
                            chartData.push(item);
                        } else {
                            chartData.push({
                            branch_code: store.branch_code,
                            branch_name: store.branch_name,
                            sla_id: sla.sla_id,
                            total_document: 0,
                            });
                        }
                        } else {
                        if (
                            store.branch_code == item.branch_code &&
                            sla.sla_id == item.sla_id
                        ) {
                            chartData[findIndex] = item;
                        }
                        }
                    });
                    });
                });

                // obtener los documentos con el mismo sla
                let onTime = chartData.filter((item) => item.sla_id == 1);
                let late = chartData.filter((item) => item.sla_id == 2);
                let veryLate = chartData.filter((item) => item.sla_id == 3);

                // obtener el total de documentos por sla
                let onTimeCount = onTime.map((item) => item.total_document);
                let lateCount = late.map((item) => item.total_document);
                let veryLateCount = veryLate.map((item) => item.total_document);

                // obtener los nombres de las sucursales
                let storesName = stores.map((store) => store.branch_name);

                return {
                    onTimeCount: onTimeCount,
                    lateCount: lateCount,
                    veryLateCount: veryLateCount,
                    stores: storesName,
                };
            },
            getAvgForFinishedDocuments() {
                let stores = [];
                this.documentChart.avg_time_elapsed_documents_by_branch.forEach(
                    (item) => {
                    let findIndex = stores.findIndex(
                        (store) => store.branch_code == item.branch_code
                    );
                    if (findIndex == -1) stores.push(item.branch_name);
                    }
                );
                let chartData = [];
                this.documentChart.avg_time_elapsed_documents_by_branch.forEach((el) => {
                    let minutes = Math.floor(el.time_elapsed / this.timeFormatByActionsByBranch.value);
                    chartData.push(minutes);
                });
                return { stores, chartData };
            },
            getAvgTimeByActionsByBranch() {
                let stores = [];
                this.documentChart.sla_by_actions_avg_by_branch.forEach((item) => {
                    let findIndex = stores.findIndex(
                    (store) => store.branch_code == item.branch_code
                    );
                    if (findIndex == -1)
                    stores.push({
                        branch_code: item.branch_code,
                        branch_name: item.branch_name,
                    });
                });
                let chartData = [];
                stores.forEach((store) => {
                    this.actions.forEach((action) => {
                        this.documentChart.sla_by_actions_avg_by_branch.forEach((item) => {
                            let findIndex = chartData.findIndex((el) => store.branch_code == el.branch_code && action.id == el.action_id);
                            if (findIndex == -1) {
                                if (store.branch_code == item.branch_code && action.id == item.action_id) {
                                    chartData.push(item);
                                } else {
                                    chartData.push({branch_code: store.branch_code, branch_name: store.branch_name, action_id: action.id, name: action.name, total_document: 0, avg: 0});
                                }
                            } else {
                                if (store.branch_code == item.branch_code && action.id == item.action_id)
                                chartData[findIndex] = item;
                            }
                        });
                    });
                });
                // obtener los documentos con el mismo sla
                let finalData = [];
                chartData.forEach((data) => {
                    let index = finalData.findIndex((item) => item.name == data.name);
                    if (index == -1) {
                        finalData.push({name: data.name, data: [Math.floor(data.avg / this.timeFormatByActionsByBranch.value)]});
                    } else {
                        finalData[index].data.push(Math.floor(data.avg / this.timeFormatByActionsByBranch.value));
                    }
                });
                let storesName = stores.map((store) => store.branch_name);
                return { chartData: finalData, stores: storesName };
            },
        },
        watch:{
            async rangeForChart(val) {
                let dates = [];
                if (val.length > 0)
                val.forEach((element) => { if (element) dates.push(new Date(element).toISOString().split("T")[0]) });
                try {
                    await this.$store.dispatch("documentsFollowup/getChartsDocuments", {date: dates});
                } catch (error) {
                    this.$swal.fire({title: this.$t('tracking.error_loading_data'), text: error.response.data.message,  icon: "error"})
                }
                this.$refs.avgTimeByActionsByBranch.refresh();
            },
            timeFormatByActionsByBranch() {
                setTimeout(() => {
                    this.$refs.avgTimeByActionsByBranch.refresh();
                    this.$refs.avgForFinishedDocuments.refresh();
                }, 0);
            },
            tabIndex(val){
                if(val == 1 && !this.rangeForChart)
                this.rangeForChart = [new Date(), new Date()]
            }
        }
    }
</script>

<style scoped>

</style>
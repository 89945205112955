<template>
    <div class="multi-select-with-filters">
        <div class="d-flex position-relative">
            <label for="filter-type" class="me-2 text-nowrap">{{$t(label)}}: <b class="text-primary cursor-pointer" @click="showStatusFilterType = true">({{statusFilterType.text}})</b></label>
            <multiselect :id="'filter-type'" v-model="statusFilterType" :options="types" :label="'text'" track-by="id" class="position-absolute filter-type" 
            @close="showStatusFilterType = false" v-if="showStatusFilterType" :allowEmpty="false">
                <template slot="selection"></template>
            </multiselect>
        </div>
        <multiselect :id="id" v-model="value" :options="options" :label="selectLabel" :track-by="trackBy" :multiple="true"/>
    </div>
</template>

<script>
    export default {
        name: "MultiSelectWithFilters",
        data(){
            return {
                types: [
                    {id: 1, text: 'is'},
                    {id: 2, text: 'is not'},
                ],
                statusFilterType: {id: 1, text: 'is'},
                showStatusFilterType: false,
                value: []
            }
        },
        props:{
            label: {
                type: String
            },
            options:{
                type: Array
            },
            default:{
                type: [Object, Array]
            },
            id: {
                type: String
            },
            trackBy:{
                type: String
            },
            selectLabel:{
                type: String            
            },
            reload: {
                type: Array
            }
        },
        watch:{
            reload(){
                this.value = this.reload
            },
            value(val){
                if(this.statusFilterType.id == 2 && this.value.length > 0){
                    let intersection = this.options.filter(el => !val.includes(el));
                    this.$emit('onSelect', intersection)
                }else{
                    this.$emit('onSelect', val)
                }
            },
            statusFilterType(val){
                if(val.id == 2 && this.value.length > 0){
                    let intersection = this.options.filter(el => !this.value.includes(el));
                    this.$emit('onSelect', intersection)
                }else{
                    this.$emit('onSelect', this.value)
                }
            },
            default(val){
                this.value = val
            }
        },
        methods(){

        },
        created(){
            if(this.default)
            this.value = this.default
        }
    }
</script>

<style scoped>
    .filter-type{
        z-index: 1;
        width: 100px;
        right: 35px;
    }
</style>
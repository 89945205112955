
import { http } from "../../../common/services/node-service";
export class ProductsService {
  async getProducts(filters){
    try {
      const response = await http.get('/products', {params:filters})
      return response.data.data
    } catch (error) {
      console.log(error);
    }
  }
  async getCategories(){
    try{
      const categories = await http.get('/categories');
      return categories.data.data
    }catch (e) {
      throw e
    }
  }
  async getBrands(){
    try{
      const brands = await http.get('/products?type=brand&place=1');
      return brands.data.data
    }catch (e) {
      throw e
    }
  }
  async getCity(){
    try{
      const cities = await http.get('/addresses/get-cities');
      return cities.data.data
    }catch (e) {
      throw e
    }
  }
  async getModels(){
    try{
      const model = await http.get('/products?type=model&place=1');
      return model.data.data
    }catch (e) {
      throw e
    }
  }
  async getProductCode(id, codplace, code){
    try{
        const products = await http.get('/products/cod-articulo/'+id, {
            params: {
                codplace: codplace || 25,
                customer_id:code === 1 ? null : code,
            }
        });
        return products
    }catch (e) {
        throw e
    }
  }
  async getReviewsByProduct(id, filters){
    try{
        const reviews = await http.get(`/products/reviews/${id}`, {
            params: {
              filters
            }
        });
        return reviews.data.data
    }catch (e) {
        throw e
    }
  }
  async getQuestionsByProduct(id, filters){
    try{
        const questions = await http.get(`/products/questions/${id}`, {
            params: {
              filters
            }
        });
        return questions.data.data
    }catch (e) {
        throw e
    }
  }
  async getQuestionsAdmin(filters){
    let obj = Object.fromEntries(Object.entries(filters).filter(([, el]) => el != null && el != ''));
    try{
        const questions = await http.get(`/products/questions/getProductsQuestionsAdmin`, {
            params: {
              ...obj
            }
        });
        return questions.data.data
    }catch (e) {
        throw e
    }
  }
  async getReviewsAdmin(filters){
    let obj = Object.fromEntries(Object.entries(filters).filter(([, el]) => el != null && el != ''));
    try{
        const questions = await http.get(`/products/reviews/getProductsReviewAdmin`, {
            params: {
              ...obj
            }
        });
        return questions.data.data
    }catch (e) {
        throw e
    }
  }
  async addAnswer(id, answer){
    try{
        const response = await http.post(`/products/questions/${id}/addAnswerAdmin`, {answer});
        return response.data.data
    }catch (e) {
        throw e
    }
  }
  async deleteQuestion(id){
    try{
        const response = await http.delete(`/products/questions/deleteProductsQuestionsAdmin/${id}`);
        return response.data
    }catch (e) {
        throw e
    }
  }
  async deleteAnswer(id){
    try{
        const response = await http.delete(`/products/questions/deleteProductsAnswersQuestionsAdmin/${id}`);
        return response.data
    }catch (e) {
        throw e
    }
  }
  async deleteReview(id){
    try{
        const response = await http.delete(`/products/reviews/deleteProductsReviewAdmin/${id}`);
        return response.data
    }catch (e) {
        throw e
    }
  }
}

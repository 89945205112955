import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from "./common/helpers/locales/index";

Vue.use(VueI18n)

const dateTimeFormats = {
    'en': {
        alt_short:{
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        short: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        long: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        month_day: {
            month: 'short',
            day: 'numeric',
        },
        standard_hour: {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        },
    },
    'es': {
        alt_short:{
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        short: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        long: {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        month_day:{
            month: 'short',
            day: 'numeric',
        },
        standard_hour:{
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        },
    },
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || localStorage.getItem("lang") || 'es',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
    messages,
    dateTimeFormats
})

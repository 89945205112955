<template>
    <main-modal @closeModal="() => this.$emit('closeModal')">
        <b-row>
            <h2 class="text-center">{{ $t('Crear nivel de acceso') }}</h2>
        </b-row>
        <div>
            <el-input size="medium" style="width: 400px;" placeholder="Ingrese nombre del nivel de Acceso" v-model="input"></el-input>
            <div class="mt-4 text-center">
                <el-button type="success" @click="save" plain>Save</el-button>
                <el-button type="danger" @click="$emit('closeModal')" plain>Cancel</el-button>
            </div>
        </div>
    </main-modal>
</template>

<script>

import MainModal from '../../../common/components/modals/MainModal.vue';
import { mapGetters } from 'vuex';
import { db } from "@/firebase-config";
export default {
    name: 'CreateAccessLevel',
    components: {
        MainModal
    },
    props:{
        accessLevels:{
            type: Array
        }
    },
    data(){
        return{
            input: '',
            newAccessLevel: [],
            contentModules: [
                            {
                                id: 1, icon: "bx mdi mdi-shopping", route:'ecommerce', name: "Ecommerce", active: false,
                                subModules: [
                                        { 
                                            id: 7, route:'/ecommerce/orders', name: "Ordenes", active: false,
                                            permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],
                                            actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        },
                                        { id: 8, route:'/ecommerce/products', name: "Productos", active: false, 
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 9, route:'/ecommerce/customers', name: "Clientes", active: false, 
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 10, route: '/ecommerce/limitions', name: "Limitaciones", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 11, route: '/ecommerce/delivery-price', name: "Precio de envio", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 12, route: '/ecommerce/settings/general', name: "Configuracion", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 13, route: '/ecommerce/requests', name: "Solicitudes de codigo", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 14, route: '/ecommerce/reviews', name: "Reseñas", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 15, route: '/ecommerce/questions', name: "Preguntas", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 16, route: '/ecommerce/dictionary', name: "Diccionario", active: false,                                             
                                        actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ], 
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                    ]
                                },
                                { 
                                    id: 2, icon: "bx mdi mdi-truck-outline", route:'delivery', name: "Delivery", active: false, 
                                    subModules: [
                                        { id: 1, route:'/delivery/incident-settings', name: "Config. de incidencias", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ], },
                                        { id: 2, route:'/delivery/tasks', name: "Tareas", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ], },
                                        { id: 3, route: '/delivery/task-settings',name: "Config. de tarea", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ], },
                                        { id: 4, route:'/delivery/dashboard-tracking',name: "Rastreo", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                    ]
                                },
                                { id: 3, icon: "bx mdi mdi-router", route: 'zones', name: "Zonificacion", active: false, 
                                subModules: [
                                        { id: 1, route: '/zones/limits', name: "Zonas de cobertura", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ], },
                                        { id: 2, route: '/zones/routes',name: "Rutas", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ], },
                                    ]},
                                { id: 4, icon: "bx mdi mdi-poll", route:'documents-followup', name: "Rastreo de estados", active: false,                                 
                                subModules: [
                                        { id: 1, route:'/documents-followup', name: "Seguimiento de documentos", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                        { id: 2, route:'/documents-followup/maintenance', name: "Mantenimiento", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                    ]},
                                { id: 5, icon: "bx mdi mdi-desktop-tower", route: 'visit', name: "App", active: false,                                 
                                subModules: [
                                        { id: 1, route: '/visit/dashboard',name: "Dashboard", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                    ]},
                                { id: 6, icon: "bx mdi mdi-security", route: 'admin', name: "Admin", active: false,                                 
                                subModules: [
                                        { id: 1, route: '/admin/access', name: "Administrar permisos", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                            { id: 2, route: '/admin/http-logs', name: "http logs", active: false, actions:[
                                                { id: 1, name: "Asignar tarea", active: false, },
                                                { id: 2, name: "Desasignar tarea", active: false, },
                                                { id: 3, name: "Ver hisorial", active: false, },
                                                { id: 4, name: "Crear tarea", active: false, },
                                                { id: 5, name: "Editar estados", active: false, },
                                                { id: 6, name: "Ignorar ubicacion", active: false, },
                                            ],
                                        permissions:[
                                                { id: 1, name: "Ver", active: false, },
                                                // { id: 2, name: "Editar", active: false, },
                                                // { id: 3, name: "Guardar", active: false, },
                                                // { id: 4, name: "Cancelar", active: false, },
                                            ],},
                                    ]},
                            ],
            newId: '',
        }
    },
    computed: {
        ...mapGetters({
            getAccessLevels: 'auth/accessLevels',
        }),
    },
    methods: {
        async save(){
            try {
                this.mergedModules()
                const mergedArray = [...this.getAccessLevels, ...this.newAccessLevel]
                this.$store.commit('auth/SET_ACCESS_LEVELS', mergedArray)
                const accesLevelsRef = db.ref(`admin/accessLevels`);
                await accesLevelsRef.update(mergedArray);
                this.$notify.success({
                    title: 'Success',
                    message: 'Save access Levels'
                });
                this.$emit('closeModal');
            } catch (error) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Error'
                });
                this.$emit('closeModal');
            }
        },
        mergedModules(){
            this.newAccessLevel.push({
                id: this.newId,
                name: this.input,
                active: false,
                employees: [],
                modules: this.contentModules
            })
            console.log(this.newAccessLevel);
        },
        // handleEdit(row){
        //     // MUTANDO LA DATA EDITAR LAS ACCIONES DEL EMPLEADO
        //     const module = this.selectedModule
        //     const singleModule = this.modules.find(i=> i.id ==  module.id)
        //     const singlesubModule = singleModule.subModules.find(s=> s.id == this.selectedSubModule.id)
        //     this.selectedEmployee.push({
        //         id: row.id,
        //         name: row.name,
        //         active: row.active,
        //         module: module ?? {
        //             id: module.id,
        //             name: module.name,
        //             active: module.active,
        //         },
        //         subModules: singlesubModule ?? {
        //             action: singlesubModule.action
        //         }
        //     })
        //     this.showEmployeeActions = true
        // },
    },
    async created(){
        const modulesRef = db.ref(`admin/modules`);
        await modulesRef.update(this.contentModules);
        console.log(modulesRef);
        this.newId = this.getAccessLevels.length + 1
    }
}
</script>

<style>

</style>
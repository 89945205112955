import axios from 'axios'
import store from '../../store'
import router from '../../router'


const http = axios.create({
  baseURL: process.env.VUE_APP_MM_PRODUCT+"api/",
  timeout: 120000,
});

  http.setToken = function (token, refreshToken) {
    this.defaults.headers.common['Authorization'] = "Bearer "+token;
    this.defaults.headers.common['x-refresh-token'] = refreshToken;
  };
  http.interceptors.request.use((config) => {
      let token = localStorage.getItem('token')
      let refreshToken = localStorage.getItem('refreshToken')
      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`
        config.headers['x-refresh-token'] = `${refreshToken}`
      }
      // console.log(config.headers);
      return config
  },

  (error) => {
    console.log(error);
    return Promise.reject(error)
  })
  http.interceptors.response.use((response) => {
    return new Promise( (resolve) => {
      if(response.headers['x-id-token']){
        store.commit('auth/SET_TOKEN', response.headers['x-id-token'])
      }
      resolve(response)
    })
  },
  (err)=>{
    return new Promise( (resolve, reject) => {
      if(err.response){
        if (err.response.status == 401) {
            store.dispatch('auth/logout')
            router.push('/')
        }
      }
      reject(err)
    })
  });
  export { http };

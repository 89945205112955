<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 64 64" >
        <g id="discount">
            <path class="cls-1" d="M40.124,39.673a1,1,0,0,1-1.515-.857V19.7a1,1,0,0,1,1-1H56V7.7H5V47.257H56V35.992H46.258ZM32,45H8.052a1,1,0,1,1,0-2H32a1,1,0,0,1,0,2ZM7,36a1,1,0,0,1,1-1H31.939a1,1,0,1,1,0,2H8A1,1,0,0,1,7,36Zm25,5H8.052a1,1,0,1,1,0-2H32a1,1,0,0,1,0,2Zm1-11.184a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V10.7a1,1,0,0,1,1-1H32a1,1,0,0,1,1,1Z"/>
            <rect class="cls-2" height="4.029" width="11.063" x="25.328" y="49.257"/>
            <rect class="cls-3" height="1.015" width="17.594" x="22.063" y="55.286"/>
            <path class="cls-4" d="M40.609,20.7v16.35l4.858-2.914a1,1,0,0,1,.514-.143H59V20.7ZM56,32.669H44a1,1,0,0,1,0-2H56a1,1,0,0,1,0,2Zm0-4H44a1,1,0,0,1,0-2H56a1,1,0,0,1,0,2Zm0-4H44a1,1,0,0,1,0-2H56a1,1,0,0,1,0,2Z"/>
            <path d="M60,18.7H58V6.7a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1V48.257a1,1,0,0,0,1,1H23.328v4.029H21.063a1,1,0,0,0-1,1V57.3a1,1,0,0,0,1,1H40.657a1,1,0,0,0,1-1V54.286a1,1,0,0,0-1-1H38.391V49.257H57a1,1,0,0,0,1-1V35.992h2a1,1,0,0,0,1-1V19.7A1,1,0,0,0,60,18.7ZM39.657,56.3H22.063V55.286H39.657ZM25.328,53.286V49.257H36.391v4.029ZM56,47.257H5V7.7H56v11H39.609a1,1,0,0,0-1,1V38.816a1,1,0,0,0,1.515.857l6.134-3.681H56Zm3-13.265H45.981a1,1,0,0,0-.514.143l-4.858,2.914V20.7H59Z"/>
            <circle class="cls-5" cx="23.345" cy="24.081" r="0.911"/>
            <path class="cls-5" d="M9,28.816H31V11.7H9Zm17.257-4.735a2.911,2.911,0,1,1-2.912-2.912A2.913,2.913,0,0,1,26.257,24.081ZM23.9,13.691a1,1,0,0,1,.277,1.386l-7.646,11.47a1,1,0,0,1-1.664-1.109l7.646-11.47A1,1,0,0,1,23.9,13.691Zm-8.2-.168a2.911,2.911,0,1,1-2.912,2.911A2.912,2.912,0,0,1,15.7,13.523Z"/>
            <circle class="cls-5" cx="15.699" cy="16.434" r="0.911"/>
            <path d="M32,9.7H8a1,1,0,0,0-1,1V29.816a1,1,0,0,0,1,1H32a1,1,0,0,0,1-1V10.7A1,1,0,0,0,32,9.7ZM31,28.816H9V11.7H31Z"/>
            <path d="M15.7,19.346a2.911,2.911,0,1,0-2.912-2.912A2.913,2.913,0,0,0,15.7,19.346Zm0-3.823a.911.911,0,1,1-.912.911A.911.911,0,0,1,15.7,15.523Z"/>
            <path d="M20.434,24.081a2.911,2.911,0,1,0,2.911-2.912A2.912,2.912,0,0,0,20.434,24.081Zm3.823,0a.911.911,0,1,1-.912-.912A.912.912,0,0,1,24.257,24.081Z"/>
            <path d="M15.144,26.824a1,1,0,0,0,1.387-.277l7.646-11.47a1,1,0,0,0-1.664-1.109l-7.646,11.47A1,1,0,0,0,15.144,26.824Z"/>
            <path d="M8,37H31.939a1,1,0,1,0,0-2H8a1,1,0,0,0,0,2Z"/>
            <path d="M32,39H8.052a1,1,0,1,0,0,2H32a1,1,0,0,0,0-2Z"/>
            <path d="M32,43H8.052a1,1,0,1,0,0,2H32a1,1,0,0,0,0-2Z"/>
            <path d="M56,22.669H44a1,1,0,0,0,0,2H56a1,1,0,0,0,0-2Z"/>
            <path d="M56,26.669H44a1,1,0,0,0,0,2H56a1,1,0,0,0,0-2Z"/>
            <path d="M56,30.669H44a1,1,0,0,0,0,2H56a1,1,0,0,0,0-2Z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
name: 'IconRequestCode',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>

<style scoped>
    .cls-1{fill:#e9e9f3;}.cls-2{fill:#47568c;}.cls-3{fill:#61729b;}.cls-4{fill:#fdbf00;}.cls-5{fill:#4bb9ec;}
</style>

<template>
    <div>
        <h1>{{$t('ecommerce.questions')}}</h1>
        <b-table :items="questions" responsive striped thead-class="bg-primary text-white" :fields="fields" details-td-class="bg-primary" class="bg-white">
            <template #cell(date)="data">
                {{$d(new Date(data.item.created_at), 'alt_short')}}
            </template>
            <template #cell(images)="data">
                <div v-if="data.item.images.length > 0" class="d-flex">
                    <div v-for="(image, index) in data.item.images" :key="index">
                        <img v-lazy="image" width="50" height="50" class="img-product">
                    </div>
                </div>
            </template>
            <template #cell(customer)="data">
                <div class="d-flex align-items-center">
                    <div class="avatar d-flex align-items-center justify-content-center" :style="{background: getRandomColor}" v-if="!data.item.customer.photo_url">
                        <abbr>{{data.item.customer.first_name.slice(0,1)+'.'+data.item.customer.last_name.slice(0,1)}}</abbr>
                    </div>
                    <img :src="data.item.customer.photo_url" class="img-profile" alt="profile" v-else>
                    <b class="me-3 ms-3">
                        {{data.item.customer.first_name}}
                        {{data.item.customer.last_name}}
                    </b>
                    <div class="d-flex">
                        <icon-check-circle v-if="data.item.customer_verified" :size="16" :color="'#02a499'"/>
                        <icon-danger-circle :color="'#ff4560'" v-else/>
                    </div>
                </div>
            </template>
            <template #cell(actions)=data>
                <div class="d-flex align-items-center">
                    <eva-icon name="trash-2" width="20px" class="me-2 cursor-pointer" fill="#ff4560" @click.native="remove"></eva-icon>
                    <eva-icon name="message-circle" width="20px" fill="#626ed4" class="me-2 cursor-pointer"></eva-icon>
                    <b-button variant="success" size="sm" @click="selectProduct = data.item; showAnswers = true;">{{$t('ecommerce.answers')}} ({{data.item.answers.length}})</b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import IconCheckCircle from '../../../../common/components/svg/IconCheckCircle.vue';
import IconDangerCircle from '../../../../common/components/svg/IconDangerCircle.vue';
import { ProductsService} from "../../service/products-service";
const productService = new ProductsService();
export default {
    components: { IconCheckCircle, IconDangerCircle },
    name: "Questions",
    props:{
        product:{
            type: Object
        }
    },
    data() {
        return {
            questions: []
        }
    },
    computed:{
        fields(){
            return [
                {label: this.$t('ecommerce.question'), key: 'question', sortable: true},
                {label: this.$t('ecommerce.date'), key: 'date', sortable: true},
                {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions'},
            ]
        },
    },
    async created(){
        try {
            const response = await productService.getQuestionsByProduct(this.product.codarticulo)
            this.questions = response.docs
        } catch (error) {
            console.log(error);
        }
    }
}
</script>
<style scoped>
.table-responsive::v-deep > table{
    vertical-align: middle;
}
.avatar{
    background: #FA9696;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    object-fit: cover;
}
.img-profile{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 1px solid #e2e1ee;
    border-radius: 50%;
}
</style>

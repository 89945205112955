

export default {
  SET_TOKEN (state, token) {
    state.token = token;
    localStorage.setItem('token', state.token);
  },
  SET_USER (state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  SET_REFRESH_TOKEN (_, token) {
    localStorage.setItem('refreshToken', token);
  },
  SET_ACCESS_LEVELS(state, data){
    state.accessLevels = data
  },
  SET_ACCESS_LIST_EMPLOYEEES(state, data){
    state.listEmployees = data
  },
  // SET_MODULES_CONTENT(state, data){
  //   state.modules = data
  // },
  SET_PERMISSIONS(state, data){
    state.permissions = data
  }
};

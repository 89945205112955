<template>
    <icon-base viewBox="0 0 405.333 405.333" :size="size" :fill="color">
        <g>
            <g>
                <path d="M202.667,0C108.373,0,32,10.667,32,85.333v213.333c0,18.88,8.32,35.627,21.333,47.36V384
                    c0,11.733,9.6,21.333,21.333,21.333H96c11.84,0,21.333-9.6,21.333-21.333v-21.333H288V384c0,11.733,9.493,21.333,21.333,21.333
                    h21.333c11.733,0,21.333-9.6,21.333-21.333v-37.973c13.013-11.733,21.333-28.48,21.333-47.36V85.333
                    C373.333,10.667,296.96,0,202.667,0z M106.667,320c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32
                    S124.373,320,106.667,320z M298.667,320c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S316.373,320,298.667,320z
                    M330.667,192h-256V85.333h256V192z"/>
            </g>
        </g>
    </icon-base>
</template>

<script>
import IconBase from "../../../../common/components/svg/IconBase.vue"

export default {
    name: "IconDeliveryMainGreen",
    components: { IconBase },
    props:{
        color:{
            type: String
        },
        size:{
            default: 20
        }
    }
}
</script>

<template>
    <layout>
        <div>
            <div v-if="unsavedChanges" style="padding: 7px;" class="unsaved-message">
                ¡Hay cambios no guardados en la Pestaña!
            </div>
            <div class="d-flex position-absolute" style="right: 21px;top: 74px;z-index: 800;">
                <el-button size="small" @click="saveAccessLevels()" style="width: 150px;" type="primary">{{$t('common.save')}}</el-button>
                <el-button size="small" @click="cancel()" style="width: 150px;" type="danger">{{$t('common.cancel')}}</el-button>
            </div>
        </div>
        <!-- <div class="d-flex mb-3 justify-content-end gap-10 mt-1">
            <el-button @click="saveAccessLevels()" type="success" round>Save</el-button>
            <el-button @click="cancel()" type="danger" round>Cancel</el-button>
        </div> -->
        <el-tabs type="border-card" v-model="activeTab">
            <el-tab-pane label="Access Levels" name="tab1" :disabled="unsavedChanges">
                <div v-if="activeTab === 'tab1'">
                    <!-- TODO: CREAR BOTTON PARA GUARDAR -->
                    <!-- TODO: CREAR PARA CANCELAR -->
                    <el-row class="my-4" :gutter="40">
                        <el-col :xl="6" :lg="8" :md="12">
                            <div class="d-flex flex-column">
                                <label for="access-level">Nivel de acceso</label>
                                <div class="d-flex align-items-center">
                                    <el-select size="mini" v-model="accessLevel" filterable clear class="me-2" value-key="id">
                                        <el-option v-for="item in accessLevels" :key="item.id" :label="`[${item.id}] ${item.name}`" :value="item"/>
                                    </el-select>
                                    <!-- TODO: AGREGAR MODAL PARA ANADIR UN NUEVO NIVEL DE ACCESO -->
                                    <!--* PARAMS: {id: 1, name: "Admin", createdAt: Now,} -->
                                    <el-button @click="showCreateAccess = true" size="mini">Nuevo</el-button>
                                    <el-checkbox v-if="accessLevel" v-model="accessLevel.active" @change="updateActiveLevel(accessLevel.active)" class="ms-2">Activo</el-checkbox>
                                </div>
                            </div>
                            <br/>
                            <div class="d-flex flex-column">
                                <label for="modules">Modulo</label>
                                <div class="d-flex align-items-center">
                                    <el-select size="mini" v-model="selectedModule" filterable clear class="me-2" value-key="id">
                                        <el-option v-for="item in modules" :key="item.id" :label="`[${item.id}] ${item.name}`" :value="item"/>
                                    </el-select>
                                    <!-- TODO: AGREGAR MODAL PARA ANADIR UN NUEVO MODULO -->
                                    <!--* PARAMS: {id: 1, name: "Delivery", createdAt: Now,} -->
                                    <!-- <el-button @click="showCreateModal = true" size="mini">Nuevo</el-button> -->
                                    <el-checkbox v-if="selectedModule" v-model="selectedModule.active" @change="updateModulesActive(selectedModule.active)" class="ms-2">Activo</el-checkbox>
                                </div>
                            </div>
                        </el-col>
                        <!-- <el-col :xl="6" :lg="8" :md="12">
                        </el-col> -->
                    </el-row>
                    <el-container style="gap:20px">
                        <el-aside>
                            <h5>Sub modulos</h5>
                            <el-table size="mini" :data="subModules" stripe :row-class-name="tableRowClassName">
                                    <el-table-column prop="id" label="ID" width="40"/>
                                    <el-table-column prop="name" label="Nombre"/>
                                    <el-table-column label="Acciones" align="center" width="100">
                                        <template slot-scope="scope">
                                            <el-button size="mini" @click="showSubModule(scope.row)" icon="el-icon-right" :type="scope.row.active ? 'success' : 'danger'" circle/>
                                        </template>
                                    </el-table-column>
                                </el-table>
                        </el-aside>
                        <template>
                            <el-container  v-if="selectedSubModule" class="d-flex flex-column">
                                <h5>Permisos</h5>
                                <el-table size="mini" :data="[selectedSubModule]" :key="`permissions-${tableKey}`">
                                        <el-table-column prop="id" label="ID" width="40"/>
                                        <el-table-column prop="name" label="Nombre" width="120"/>
                                        <el-table-column :label="permission.name"  v-for="permission in selectedSubModule.permissions" :key="permission.id" align="center" width="120">
                                            <template>
                                                <el-checkbox v-model="permission.active" @change="updatePermission(permission.key, permission.active)"></el-checkbox>
                                            </template>
                                        </el-table-column>
                                </el-table>
                                <el-card size="mini" style="max-width: 655px;">
                                    <div slot="header" class="d-flex align-items-center justify-content-between">
                                        <h6 class="mb-0">Acciones</h6>
                                        <!-- TODO: AGREGAR MODAL PARA ANADIR UNA NUEVA ACCION - NO HACER-->
                                        <!--* PARAMS: {id: 1, name: "Crear tarea", createdAt: Now,} -->
                                        <!-- <el-button type="text" class="p-0">Agregar accion</el-button> -->
                                    </div>
                                    <div class="container-actions bg-white">
                                        <el-checkbox v-model="action.active" v-for="action in selectedSubModule.actions" :key="`action-key-${action.id}`" @click.native="updateAction(action.id, action.active)">
                                            {{ action.name }}   
                                        </el-checkbox>
                                    </div>
                                </el-card>
                            </el-container>
                            <el-empty description="Por favor selecciona algun sub modulo para comenzar a configurar." v-else  class="m-auto"/>
                        </template>
                        <main-modal v-if="showSearch" @closeModal="showSearch = false">
                            <search-employee />
                        </main-modal>
                        <create-access-level v-if="showCreateAccess" :accessLevels="accessLevels" @closeModal="showCreateAccess = false"/>
                        <!-- <create-module v-if="showCreateModal" @closeModal="showCreateModal = false" /> -->
                    </el-container>
                    <template v-if="accessLevel">
                        <div style="margin-top: 30px;">
                            <el-container class="d-flex flex-column">
                                <h5>Empleados</h5>
                                <div class="bg-white p-2">
                                    <div class="d-flex align-items-end justify-content-between">
                                        <div class="me-2">
                                            <label for="search-employee">Buscar empleado</label>
                                            <el-input v-model.lazy="search" size="mini" placeholder="Type to search" clearable id="search-employee"/>
                                        </div>
                                        <!-- <el-button @click="showSearch = true" size="mini">Agregar empleado</el-button> -->
                                    </div>
                                    <el-table size="mini" :data="employees" stripe class="mt-2" style="max-width: 600px;">
                                        <el-table-column prop="id" label="ID" width="100" align="center"/>
                                        <el-table-column prop="name" label="Nombre"/>
                                        <el-table-column label="Acciones" align="center" width="150">
                                            <template slot-scope="scope">
                                                <!-- ELIMINAR EMPLEADO DEL NIVEL DE ACCESO -->
                                                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row.id)" circle icon="el-icon-delete"/>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <main-modal v-if="showSearch" @closeModal="showSearch = false">
                                    <search-employee />
                                </main-modal>
                            </el-container>
                        </div>
                    </template>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Access levels employee" name="tab2" :disabled="unsavedChanges"><employee-rol :key="componentKey" v-if="activeTab === 'tab2'" /></el-tab-pane>
            <el-tab-pane label="Employees" name="tab3" :disabled="unsavedChanges"><list-employees v-if="activeTab === 'tab3'"/></el-tab-pane>
        </el-tabs>
    </layout>
</template>

<script>
    import Layout from "../../template/layouts/main.vue";
    // import SearchEmployee from "./SearchEmployee.vue";
    import CreateAccessLevel from "./CreateAccessLevel.vue";
    // import EmployeeActions from './EmployeeActions.vue';
    // import CreateModule from "./CreateModule.vue";
    import { mapGetters } from "vuex";
    import MainModal from "../../../common/components/modals/MainModal.vue";
    import EmployeeRol from './EmployeeRol.vue';
    import ListEmployees from "./ListEmployees.vue";
    // import { http }  from "@/common/services/web-backend.js";
    import { db } from "@/firebase-config";
    export default {
        name: "Admin",
        components:{
            Layout,
            MainModal,
            // SearchEmployee,
            CreateAccessLevel,
            EmployeeRol,
            ListEmployees
        },
        computed:{
            ...mapGetters({
                getAccessRols: 'auth/accessLevels',
                listEmployees: 'auth/listEmployees',
                userData: "auth/user",
                permissions: "auth/permissions"
            }),
            subModules(){
                const filter = this.modules?.find((module)=>module?.id == this.selectedModule?.id);
                return filter?.subModules;
            },
            selected(){
                const filter = this.subModules?.find((module)=>module?.id == this.selectedSubModule?.id);
                return filter;
            },
            employees(){
                if(this.accessLevel?.employees){
                    const filter = this.accessLevel?.employees.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
                    return filter;
                }
                return [];
            },
            filteredEmployee: function() {
                if (!this.accessLevel) return this.accessLevels;
                return this.accessLevels.filter(access => access.name === this.accessLevel);
            },
        },
        data() {
            return {
                showCreateModal: false,
                showCreateAccess: false,
                showSearch: false,
                showEmployeeActions: false,
                accessLevel: [],
                accessLevels: [],
                selectedEmployee: [],
                countEmployee: null,
                selectedModule: null,
                selectedSubModule: null,
                action: [],
                modules: [],
                activeTab: 'tab1',
                unsavedChanges: false,
                value: [],
                filterMethod(query, item) {
                    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
                selectedPermissions:[],
                search: '',
                componentKey: 0,
                tableKey: 1,
            }
        },
        watch:{
            'listEmployees'(){
                if(this.countEmployee != this.listEmployees.length){
                    this.unsavedChanges = true
                } else {
                    this.unsavedChanges = false
                }
            },
            'getAccessRols'(){
                this.accessLevels = this.getAccessRols
            },
            'accessLevels'(){
                const noEmployeeData = this.accessLevels?.filter(item => !item.hasOwnProperty('employees'));
                if(noEmployeeData.length > 0){
                    this.addEmployee()
                }

                this.accessLevel = this.accessLevels[0]
                this.modules = this.accessLevel?.modules
                // this.selectedModule = this.modules[0]
                this.$store.commit('auth/SET_ACCESS_LEVELS', this.accessLevels)
                this.setAllEmployeees()
            },
            'accessLevel'(){
                this.selectedSubModule = null
                this.selectedModule = null
                this.modules = this.accessLevel?.modules
            },
            'selectedModule'(){
                this.selectedSubModule = null
            },
            'permissions'() {
                const isActive = this.permissions?.modules?.find(data => data?.name === 'Admin')
                    // Verifica si se han cambiado los permisos de administrador
                    if (!isActive.subModules[0].active) {
                        // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                        this.$router.push('/permission');
                    }
            },
        },
        methods:{
            addEmployee(){
                const add1 = 'employees'
                const add2 = [] 
                this.accessLevels.map(item => {
                    if (!item.hasOwnProperty(add1)) {
                        item[add1] = add2;
                    }
                    return item;
                });
            },
            showSubModule(sub){
                this.selectedSubModule = sub;
                this.selectedPermissions = sub.permissions;
            },
            updateActiveLevel(val){
                this.$set(this.accessLevel, 'active', val);
            },
            updateModulesActive(val){
                this.$set(this.selectedModule, 'active', val);
            },
            updateAction(id, val){
                const indexToUpdate = this.selectedSubModule.actions.findIndex(p => p.id == id);
                if (indexToUpdate !== -1) {
                    this.$set(this.selectedSubModule.actions[indexToUpdate], 'active', val);
                }
                this.tableKey++;
                this.activitySubModule()
                this.updateActiveModules()
            },
            updatePermission(id, val){
                const indexToUpdate = this.selectedSubModule.permissions.findIndex(p => p.id == id);
                if (indexToUpdate !== -1) {
                    this.$set(this.selectedSubModule.permissions[indexToUpdate], 'active', val);
                }
                this.tableKey++;
                this.activitySubModule()
                this.updateActiveModules()
            },
            tableRowClassName({row}) {
                const validate = this.selectedSubModule?.id == row.id;
                if (validate) return 'success-row';
            
                return '';
            },
            updateActiveModules(){
                const activeItems = this.selectedModule.subModules.filter(item => item.active === true);
                if(activeItems.length > 0){
                    this.$set(this.selectedModule, 'active', true);
                } else{
                    this.$set(this.selectedModule, 'active', false);
                }
            },
            handleDelete(index, id){
                this.accessLevel.employees.splice(index, 1);
                this.deleteEmployee(id)
                this.setAllEmployeees()
            },
            activitySubModule(){
                const filteredObjectsActions = this.selectedSubModule.actions.filter(obj => obj.active === true);
                const filteredObjectsPermisions = this.selectedSubModule.permissions.filter(obj => obj.active === true);
                if(filteredObjectsPermisions.length > 0 || filteredObjectsActions.length > 0){
                    this.$set(this.selectedSubModule, 'active', true);
                } else {
                    this.$set(this.selectedSubModule, 'active', false);
                }
            },
            handleEdit(row){
                // MUTANDO LA DATA EDITAR LAS ACCIONES DEL EMPLEADO
                const module = this.selectedModule
                const singleModule = this.modules.find(i=> i.id ==  module.id)
                const singlesubModule = singleModule.subModules.find(s=> s.id == this.selectedSubModule.id)
                this.selectedEmployee.push({
                    id: row.id,
                    name: row.name,
                    active: row.active,
                    module: module ?? {
                        id: module.id,
                        name: module.name,
                        active: module.active,
                    },
                    subModules: singlesubModule ?? {
                        action: singlesubModule.action
                    }
                })
                this.showEmployeeActions = true
            },
            // async getModules(){
            //     const modulesRef = db.ref(`admin/modules`);
            //     await modulesRef.once('value', (snapshot) => {
                    
            //         const allModules = snapshot.val();
            //         console.log(allModules);
            //     }) 
            // },
            async getAccessLevels(){
                const accesLevelsRef = db.ref(`admin/accessLevels`);
                await accesLevelsRef.once('value', (snapshot) => {
                    this.accessLevels = snapshot.val()
                    console.log(this.accessLevels);
                })
            },
            updateComponentKey() {
                this.componentKey += 1; // Cambia la clave para forzar la actualización
            },
            cancel(){
                this.getAccessLevels()
                this.updateComponentKey()
            },
            async saveAccessLevels(){
                // try {
                    this.$swal.fire({ 
                        title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                        icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                        cancelButtonText: 'Cancelar',
                    }).then(async (result) => {
                        if(result.isConfirmed){
                            try {
                                const accesLevelsRef = db.ref(`admin/accessLevels`);
                                await accesLevelsRef.set(this.accessLevels);

                                const convertData = {}
                                for (const objecto of this.listEmployees) {
                                    const { id, ...propiedades } = objecto;
                                    convertData[id] = propiedades;
                                }

                                const accessEmployeesRef = db.ref(`admin/employees`);
                                await accessEmployeesRef.set(convertData);

                                this.getEmployees()
                                this.getAccessLevels()
                                this.accessLevel = this.accessLevels[0]
                                this.modules = this.accessLevel?.modules 
                                this.$swal.fire({ 
                                    title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                    icon: "success",
                                })
                                this.$store.commit('auth/SET_ACCESS_LEVELS', this.accessLevels)
                                // this.getEmployees()
                                this.updateComponentKey()
                                this.getEmployeeFirebase()
                            } catch (error) {
                                if(error){
                                    this.$swal.fire({
                                    icon: 'error',
                                    title: 'No se pudo cargar los datos',
                                    text: error,
                                    })
                                }else{
                                    this.$swal.fire({
                                        icon: 'error',
                                        title: 'No se pudo cargar los datos',
                                        text: error,
                                    })
                                }
                            }
                        }
                    })

                //     this.$notify.success({
                //         title: 'Success',
                //         message: 'Save access Levels'
                //     });
                // } catch (error) {
                //     console.log(error);
                //     this.$notify.error({
                //         title: 'Error',
                //         message: 'Error'
                //     });
                // }
            },
            async getEmployees(){
                const accessEmployeesRef = db.ref(`admin/employees`);
                await accessEmployeesRef.once('value', (snapshot) => {
                    const accesLevelsRef = snapshot.val();
                    const listObjet = [];
                    for (const id in accesLevelsRef) {
                        if (Object.hasOwnProperty.call(accesLevelsRef, id)) {
                            const object = {
                                id,
                                ...accesLevelsRef[id]
                            };
                            listObjet.push(object);
                        }
                    }
                    this.$store.commit('auth/SET_ACCESS_LIST_EMPLOYEEES', listObjet)
                })
            },
            setAllEmployeees(){
                const employeeNames = this.accessLevels.map(obj => obj.employees);
                const allEmployees = [].concat(...employeeNames);
                const cleanedArr = allEmployees.filter(item => item !== undefined && item !== null);
                this.$store.commit('auth/SET_ACCESS_LIST_EMPLOYEEES', cleanedArr)
            },
            deleteEmployee(value){
                const accessEmployeesRef = db.ref(`admin/employees`);
                accessEmployeesRef.child(value).remove()
                .then(() => {
                    console.log('Item removed successfully');
                    this.getEmployeeFirebase()
                })
                .catch((error) => {
                    console.error('Error removing item:', error);
                })
            },
            async getEmployeeFirebase(){
                const employeeRef = db.ref(`admin/employees`);
                await employeeRef.once('value', (snapshot) => {
                    const getEmployee = snapshot.val();
                    console.log(getEmployee);

                    const newArray = Object.keys(getEmployee).map(key => {
                    const item = getEmployee[key];
                        return {
                            accessLevels: item.accessLevels,
                            active: item.active,
                            extraAccess: item.extraAccess,
                            name: item.name
                        };
                    });
                    this.countEmployee = newArray.length
                })
            },
        },
        created(){
            this.getPermissions()
            this.getEmployees()
            this.getAccessLevels()
            this.getEmployeeFirebase()
            this.accessLevel = this.accessLevels[0]
            this.modules = this.accessLevel?.modules
        }
    }
</script>

<style scoped>
    .el-table::v-deep .warning-row {
        background: oldlace;
    }

    .el-table::v-deep .success-row,.el-table::v-deep .el-table__row--striped.success-row > td{
        background: #f0f9eb !important;
    }

    .mb-3 {
        margin-bottom: 0.25rem !important;
    }
    .el-table::before {
        left: 0;
        bottom: 0;
        width: 0% !important;
        height: 1px;
    }
    .container-actions {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
        gap: 16px; /* Espacio entre los elementos del grid */
    }
    .unsaved-message {
        color: red;
        margin-top: 5px;
    }
</style>
import { http } from "../../../common/services/node-service";

export class StoreService {
  async getStores(){
    try{
        let stores = await http.get("storesWithFiltre", {params: {activated: 1, order_by: 'description', order_by_type: 'asc', defined_location: 1}});
        stores.data.data.sort((a, b) =>a.description.localeCompare(b.description))
        return stores.data.data
    }catch (e) {
        console.log(e)
    }
  }
  async getLimits(id){
    try{
      const limits = await http.get("stores/"+id+"/limits");
      return limits.data.data;
    }catch (e) {
      console.log(e)
    }
  }
  async update(obj, limits, mapOptions, code){
    try {
      await http.put("stores/"+code, {
          limits: limits,
          long: mapOptions.defaultMarker[0],
          lat: mapOptions.defaultMarker[1],
          ...obj
        });
    }catch (e) {
        throw e
    }
  }
  async getStoreWithLimits(){
    try {
      const response = await http.get('getStoresWithLimits')
      return response.data.data
    } catch (error) {
      throw error
    }
  }
}

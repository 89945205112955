<template>
    <layout>
        <b-row class="my-2">
        <b-col lg="3">
            <label for="search">{{$t('ecommerce.filter_by_name_or_code')}}</label>
            <b-form-input v-model="searchCustomer" type="search"></b-form-input>
        </b-col>
        <b-col>
            <label for="options">{{ $t('ecommerce.filter_for_name') }}</label>
            <multiselect v-model="showCustomLayers" :options="optionsLayers" :multiple="true" placeholder="Type to search" track-by="value" label="name">
                <span slot="noResult">{{$t('ecommerce.no_option_matches')}}</span>
            </multiselect>
        </b-col>
        <b-col>
            <label for="options">{{ $t('ecommerce.show_branches') }}</label>
            <multiselect v-model="filterStores" :options="stores" :multiple="true" placeholder="Type to search" track-by="codplace" label="description">
                <span slot="noResult">{{$t('ecommerce.no_option_matches')}}</span>
            </multiselect>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
            <b-button @click="editMode" variant="warning" size="sm" class="me-2" :disabled="showEditMode">{{$t('ecommerce.edit')}}</b-button>
            <b-button size="md" style="width: 100px;" @click="saveZones" variant="primary" class="me-2" :disabled="!showEditMode">{{$t('ecommerce.save')}}</b-button>
            <b-button size="md" style="width: 100px;" @click="editMode" variant="danger" class="me-2" :disabled="!showEditMode">{{$t('ecommerce.cancel')}}</b-button>
        </b-col>
        </b-row>

        <MglMap style="height: 50vh" :zoom="mapOptions.defaultZoom" ref="map" :dragRotate="false" :accessToken="mapOptions.accessToken" :center="mapOptions.defaultCoordinates" 
        :fadeDuration="300" @load="onMapLoaded" :mapStyle="styleSelectMap.value" :key="styleSelectMap.id || mapKey">
        <vue-google-autocomplete id="vue-google-autocomplete" @focus="showMessage = true" :placeholder="$t('delivery.write_reference_to_locate_the_map')" 
            class="form-control autocomplete" :country="[generalSetting.general.web_page.country_default.text]" :types="['locality']" v-on:placechanged="setPlace"/>
            <mgl-marker style="cursor: pointer" :coordinates="[customer.geometry.coordinates[0], customer.geometry.coordinates[1]]" :draggable="false" 
            v-for="(customer, index) in unclusteredCustomers" :key="customer.properties.address_id + index" @click="getImages(customer.properties.code, customer.geometry.coordinates)">
                <div slot="marker">
                    <icon-pin :color="customer.properties.verified ? '#348547' : '#c40316'" :size="25"/>
                </div>
                <mgl-popup>
                    <div>
                        <h6>{{customer.properties.first_name}} {{customer.properties.last_name}}</h6>
                        <ul class="p-0">
                            <li> <b>{{$t('ecommerce.code')}}</b>  {{customer.properties.code}}</li>
                            <li> <b>ID</b>  {{customer.properties.address_id}}</li>
                            <li> <b>{{$t('ecommerce.verified_buyer')}}</b>  {{customer.properties.verified}}</li>
                        </ul>
                    </div> 
                </mgl-popup>
            </mgl-marker>
            <mgl-marker style="cursor: pointer" :coordinates="[store.longitude, store.latitude]" :draggable="false" color="#c40316" v-for="store in filterStores" :key="store.codplace">
                <div slot="marker">
                    <div class="icon-container icon-container-store bg-danger">
                        <icon-store :size="25" :color="'#fff'"/>
                    </div>
                </div>
                <mgl-popup>
                    <div>
                        <h3>{{store.description}}</h3>
                    </div> 
                </mgl-popup>
            </mgl-marker>
            <MglNavigationControl position="top-left"/>
            <MglGeolocateControl position="top-left" />
            <MglFullscreenControl position="top-left"/>
            <div style="position: fixed; margin-top: 181px; margin-left: 10px;">
                <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                <el-popover placement="right" width="210" trigger="click">
                    <b-row class="mt-2" style="width: 205">
                        <b-col>
                            <div class="d-flex">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                    </span>
                                    <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                </button>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="d-flex" style="margin-left: -7px;">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                    </span>
                                    <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                <button slot="reference" class="gmaps-button">
                    <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i> <!-- Puedes reemplazar esto con un icono real, como un SVG o un icono de FontAwesome -->
                </button>
                </el-popover>
            </div>
            <mgl-geojson-layer :layer="clusters" :sourceId="'customers'" :layerId="clusters.id" :source.sync="sourceCustomers" @click="clickZoom" @mouseenter="putPointer" @mouseleave="removePointer"/>
            <mgl-geojson-layer :layer.sync="clusterCount" :sourceId="'customers'" :layerId="clusterCount.id"/>
            <mgl-geojson-layer :layer.sync="unclustered" :sourceId="'customers'" :layerId="unclustered.id" :clearSource="true" @mouseenter="putPointer" @mouseleave="removePointer"/>
            <eva-icon name="map" @mouseenter.native="showLegend = true" @mouseleave.native="showLegend = false" class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer"></eva-icon>
            <div class="map-legend p-2" v-if="showLegend">
                <ul>
                    <li><icon-pin :color="'#348547'"/> {{$t('ecommerce.verified_addresses')}}</li>
                    <li><icon-pin :color="'#c40316'"/> {{$t('ecommerce.unverified_addresses')}}</li>
                    <li><icon-store :color="'#626ed4'"/> {{$t('ecommerce.branches')}}</li>
                    <li><icon-zones/> {{$t('ecommerce.zones')}}</li>
                </ul>
            </div>
            <vue-element-loading :active="loading" :is-full-screen="false"/>
        </MglMap>

        <b-row>
            <b-col class="d-flex justify-content-end" style="margin-top: 10px;">
                <b-button size="md" @click="() => showCreateLimitTypeModal = true" variant="primary">{{ $t('ecommerce.create_limit_type') }}</b-button>
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col :lg="showMenu ? 8 : 12">
                <b-table sticky-header small striped responsive style="max-height: 25vh;" thead-class="green-bg bg-primary text-white" class="bg-white" hover :items="zones" 
                :fields="fields"  selectable select-mode="single" ref="selectableTable">
                    <template #cell(index)="data">
                        {{data.index + 1}}
                    </template>
                    <template #cell(name)="data">
                        <h6 :class="{'form-active-description':showMenu}" class="mb-0">
                            {{data.item.properties.name}}
                        </h6>
                    </template>
                    <template #cell(branches)="row">
                        <span v-if="row.item.properties.branches.length == 1" class="text-capitalize">
                            {{stores | getDescriptionBranch(row.item.properties.branches[0])}}
                        </span>
                        <span v-else-if="row.item.properties.branches.length > 1">
                            {{row.item.properties.branches.length}} {{$t('ecommerce.selected')}}
                        </span>
                    </template> 
                    <template #cell(type)="row">
                        <span v-if="row.item.properties.type">{{row.item.properties.type.description}}</span>
                    </template>
                    <template #cell(color)="row">
                        <div class="container-color" :style="{backgroundColor: row.item.properties.color}"></div>
                    </template>
                    <template #cell(square_kilometers)="row">
                        {{row.item.properties.square_kilometers}} Km
                    </template>
                    <template #cell(customer_count)="row">
                        {{row.item.properties.customer_count}}
                    </template>
                    <template #cell(actions)="row">
                        <b-button @click.prevent="selectZone(row.item)" style="width: 70px" variant="success" size="sm">{{$t('ecommerce.details')}}</b-button>
                        <b-button @click.prevent="deleteZone(row.item)" style="width: 70px" variant="danger" size="sm" class="ms-1">{{$t('ecommerce.delete')}}</b-button>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="4" v-if="showMenu">
                <div>
                    <label for="name" class="me-2">{{$t('ecommerce.name')}}:</label>
                    <div class="d-flex">
                        <b-form-input v-model="form.name" id="name" class="me-3"></b-form-input>
                        <color-picker v-model="form.color" :position="{left: '-220px', top: '40px'}"></color-picker>
                    </div>
                </div>

                <label for="type" class="me-2">{{$t('ecommerce.type')}}:</label>
                <multiselect :id="'city'" v-model="form.type" :options="types" :label="'description'" track-by="id"></multiselect>
                
                <div>
                    <label for="options">{{ $t('ecommerce.task_methods') }}</label>
                    <multiselect v-model="form.task_methods" :options="task_methods" :multiple="true" placeholder="Type to search" track-by="id" label="description">
                        <span slot="noResult">{{$t('ecommerce.no_option_matches')}}</span>
                    </multiselect>
                </div>

                <b-form-group :label="$t('ecommerce.select_branches')" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-checkbox-group id="checkbox-group-stores" v-model="form.branches" :options="formatterStore" :aria-describedby="ariaDescribedby" name="flavour-1" style="columns: 2;">
                    </b-form-checkbox-group>
                </b-form-group>

                <b-button @click.prevent="deleteZone(selectPolygon)" style="width: 70px" variant="danger" size="sm" class="ms-1">{{$t('Eliminar')}}</b-button>
            </b-col>
        </b-row>
        <customer-details :active="images[0].path_s3" :images="images" :addresses="addresses" :entity="entity" v-if="showLightBox" @closeModal="showLightBox = false"/>
        <light-box v-if="false" :active="images[0].path_s3" :images="images" @closeLightBox="showLightBox = false"/>

        <create-limit-type-modal v-if="showCreateLimitTypeModal" @closeModal="showCreateLimitTypeModal = false;"></create-limit-type-modal>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import "mapbox-gl/dist/mapbox-gl.css";
import 'v-mapbox/dist/v-mapbox.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import { MglMap, MglMarker,  MglNavigationControl, MglGeolocateControl, MglFullscreenControl, MglGeojsonLayer, MglPopup} from 'v-mapbox'
import Mapbox from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { http } from "../../../common/services/node-service";
import { http as backend_erp_http } from "../../../common/services/web-backend";
import { mapGetters } from 'vuex';
import { customersMixin } from '../helpers/mixins/customers-mixin'
import IconPin from '../../../common/components/svg/IconPin.vue';
import * as turf from "@turf/turf";
import IconStore from '../../delivery/assets/svg/IconStore.vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { CustomerService } from "../service/customer-service";
import LightBox from '../../../common/components/modals/LightBox.vue';
import IconZones from '../components/svg/IconZones.vue';
import CustomerDetails from '../components/limits/CustomerDetails.vue';
import { setlayersMixin  } from "../helpers/mixins/zones-mixin";
import CreateLimitTypeModal from "../components/limits/CreateLimitTypeModal.vue";

const customerService = new CustomerService()

export default {
    name: "Limitions",
    components:{
                VueGoogleAutocomplete,
                Layout,
                MglMap, 
                MglMarker, 
                MglNavigationControl, 
                MglGeolocateControl, 
                MglFullscreenControl, 
                MglGeojsonLayer, 
                MglPopup, IconPin, 
                IconStore, 
                LightBox, 
                IconZones, 
                CustomerDetails,
                CreateLimitTypeModal
    },
    mixins: [customersMixin, setlayersMixin],
    data() {
        return {
            mapOptions: {
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                defaultZoom: 3,
                defaultCoordinates: [-102.8720307149821, 42.31277964204047],
            },
            zones: [],
            selected: [],
            name: null,
            showMenu: false,
            selectPolygon: null,
            branch: [],
            showEditMode: false,
            styleMap: [
                {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
            ],
            styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
            layers: [],
            type: null,
            types:[],
            form:{
                branches: [],
                name: null,
                type: null,
                color: null,
                task_methods: []
            },
            color: null,
            mapKey: 0,
            centers: [],
            points: [],
            showCustomLayers: [],
            showLightBox: false,
            images: [],
            customerZone: {},
            filters:{
                showCustomersWithZone: false,
                showCustomersWithoutZone: false,
                verified: false,
                notVerified: false,
            },
            showLegend: false,
            showStores: false,
            filterStores: null,
            entity: null,
            addresses: [],
            loading: false,
            task_methods: [],
            showCreateLimitTypeModal: false
        }
    },
    computed:{
        ...mapGetters({
            stores: "ecommerce/stores",
            mapStyle: 'layout/mapStyle',
            generalSetting: "ecommerce/generalSetting",
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        formatterStore(){
            let options = []
            this.stores.forEach(store => {
                options.push({value: store.codplace, text: store.description},)
            });
            return options
        },
        fields(){
            return [ 
                {label: this.$t('#'), key: 'index', sortable: true},
                // {label: this.$t('ID'), key: 'properties.id', sortable: true},
                {label: this.$t('ecommerce.name'), key: 'name', sortable: true},
                {label: this.$t('ecommerce.branches'), key: 'branches', sortable: true},
                {label: this.$t('ecommerce.type'), key: 'type', sortable: true},
                {label: this.$t('ecommerce.square_kilometers'), key: 'square_kilometers', sortable: true},
                {label: this.$t('Color'), key: 'color', sortable: true},
                {label: this.$t('ecommerce.customers'), key: 'customer_count', sortable: true},
                {label: this.$t('ecommerce.actions'), key: 'actions', sortable: true},
            ]
        },
        sources() {
            let sources = [];
            for (let layer of this.zones){
                sources.push({
                'type': 'geojson',
                'data': layer
                })
            }
            return sources;
        },
        filterBranches(){
            let findBranches = this.showCustomLayers.filter(branch => branch.codplace)
            return findBranches
        },
        optionsLayers(){
            let options = [
                // {
                //     main: 'Cliente',
                //     options: [
                        { name: this.$t('ecommerce.with_zoned'), value: 'customer_with_zone' },
                        { name: this.$t('ecommerce.no_Zoning'), value: 'customer_without_zone' },
                        { name: this.$t('ecommerce.verified'), value: 'verified' },
                        { name: this.$t('ecommerce.not_verified'), value: 'not_verified' },

                    // ]
                // },
                // {
                //     main: 'Sucursales',
                //     options: []
                // },
            ]
            // let branches = []
            // this.stores.forEach(item => {
            //     branches.push({...item, name: item.description, value: item.codplace})
            // });
            // options[1].options = branches
            return options
        },
    },
    methods:{
        async onMapLoaded(){
            await this.fetchPoints()
            await this.getZones()
            this.mapbox = Mapbox;
            setTimeout(() => {
                this.getMarkers()
            }, 500);
            // moveend
            this.$refs.map.map.on('render', () => {
                this.getMarkers()
            });
            this.$refs.map.map.on('draw.create', this.addZone);
            this.$refs.map.map.on('draw.delete', this.addZone);
            this.$refs.map.map.on('draw.update', this.addZone);

            // Obtener la zona seleccionada.
            this.$refs.map.map.on('draw.selectionchange', this.selectionChange);
            // <--------------------------->

            // Cargando los clientes en el mapa.
            this.setLayers()
            await this.getCustomerZones()
            // <--------------------------->

        },
        selectionChange(e){
            if(e.features.length > 0){
                this.selectPolygon = e.features[0]
                this.form = {
                    name: this.selectPolygon.properties.name,
                    type: this.selectPolygon.properties.type, 
                    branches: this.selectPolygon.properties.branches,
                    color: this.selectPolygon.properties.color,
                    task_methods: this.selectPolygon.properties.task_methods
                }
                this.showMenu = true
            }else{
                this.selectPolygon = null
                this.showMenu = false
            }
        },
        async addZone(e) {
            if(!e.features[0].properties.id && !e.features[0].properties.temporary_id){
                this.control.setFeatureProperty(e.features[0].id, 'temporary_id', e.features[0].id)
                this.control.setFeatureProperty(e.features[0].id, 'branches', [])
                this.control.setFeatureProperty(e.features[0].id, 'name', '')
                this.control.setFeatureProperty(e.features[0].id, 'type', null)
                this.control.setFeatureProperty(e.features[0].id, 'color', '#000')
                this.control.setFeatureProperty(e.features[0].id, 'task_methods', [])
            }
            const squareKilometers = await this.calculateArea(e.features[0])
            this.control.setFeatureProperty(e.features[0].id, 'square_kilometers', squareKilometers)
            
            const customerCount = await this.getCustomerCount(e.features[0])
            this.control.setFeatureProperty(e.features[0].id, 'customer_count', customerCount)

            const features = this.control.getAll()


            this.zones = features.features
        },
        async saveZones(){
            this.loading = true;
            try {
                let features = this.control.getAll();
                for (let i = 0; i < features.features.length; i++) {
                    const feature = features.features[i];
                    let coords = []
                    feature.geometry.coordinates[0].forEach(element => {
                        coords.push({longitude: element[1], latitude: element[0]})
                    });
                    const squareKilometers = await this.calculateArea(feature)

                    let taskMethodsIds = [];
                    if (feature?.properties?.task_methods) {
                        taskMethodsIds = feature.properties.task_methods.map(task_method => task_method.id);
                    }

                    let zone = {
                        name: feature.properties.name || '',
                        color: feature.properties.color || '',
                        type: feature.properties.type?._id || '',
                        limits: coords,
                        square_kilometers: squareKilometers,
                        branches: feature.properties.branches || [],
                        customer_count: feature.properties.customer_count || 0,
                        task_methods: taskMethodsIds,
                    }
                    if(!feature.properties.id){
                        await http.post('/limits', zone)
                    }else{
                        await http.put(`/limits/${feature.properties.id}`, zone)
                    }
                }
                this.loading = false;
                await this.getCustomerZones()
                this.editMode()
            } catch (error) {
                console.log(error.response);
                this.$swal.fire({
                    icon: 'error',
                    title: 'HA OCURRIDO UN ERROR',
                    html: error.response ? `<b>${error.response.data.message}</b> <br> ${error.response.data.errors}` : error.message,
                })
            }
        },
        async getZones(){
            try {
                // const response = await http.get('/testConllection')
                const response = await http.get('/limits')
                let polygons = []
                response.data.data.forEach(limit => {
                    // console.log(limit);
                    let coordinates = [];
                    limit.limits.forEach((coords) => {
                        // console.log(limits);
                        // limits.limits.forEach((l, i) => {
                            // coords = [l.longitude, l.latitude];
                        // });
                        coordinates.push([coords.longitude, coords.latitude])
                    });
                    let branches = limit.codplaces.map(branch => {
                        return branch.codplace
                    });
                    // console.log(branches);
                    // console.log(coordinates)
                    // console.log(limit);
                    let poly = turf.polygon([coordinates]);
                    const customerCount = this.getCustomerCount(poly)
                    poly.properties['id'] = limit._id
                    poly.properties['branches'] = branches
                    poly.properties['name'] = limit.name
                    poly.properties['type'] = limit.type
                    poly.properties['color'] = limit.color
                    poly.properties['square_kilometers'] = limit.square_kilometers
                    poly.properties['customer_count'] = customerCount
                    // to include the objects instead of the array of task_ids
                    poly.properties['task_methods'] = [];
                    if (limit.task_methods) {
                        poly.properties['task_methods'] = this.task_methods.filter(obj => limit.task_methods.includes(obj.id));
                    }
                    polygons.push(poly)
                    // console.log(poly);
                    // poly.properties['codplace'] = store.codplace
                    // poly.properties['description'] = store.description
                });
                // this.zones = response.data.data.json || []
                this.zones = polygons || []
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'HA OCURRIDO UN ERROR',
                    text: error.response ? error.response.data.message : error.message,
                })
            }
        },
        selectZone(item){
            if(this.showEditMode){
                this.selectPolygon = item
                this.form = {
                    name: item.properties.name,
                    type: item.properties.type, 
                    branches: item.properties.branches,
                    color: item.properties.color,
                    task_methods: item.properties.task_methods
                }
                this.showMenu = true
            }
            let centroid = turf.centroid(item.geometry);
            this.$refs.map.map.flyTo({ center: centroid.geometry.coordinates, zoom: 10, bearing: 0, speed: 2,
                easing: function(t) {
                    return t;
                },
                essential: true
            });
        },
        deleteZone(item){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        let index = this.zones.findIndex(el => el.properties.id == item.properties.id)
                        this.zones.splice(index, 1)
                        
                        // const response = await http.post('/testConllection', this.zones)
                        // const response = 
                        await http.delete(`/limits/${item.properties.id}`)
                        // this.zones = response.data.data.json

                        if(this.control)
                        this.control.delete(item.id)

                        this.$refs.map.map.removeLayer(item.properties.id)
                        this.$refs.map.map.removeSource('limit-areas-'+item.properties.id)
                        this.$swal.fire({ title: 'Guardado con exito', text: "Los cambios se han almacenado",  icon: "success" })
                    } catch (error) {
                        console.log(error)
                    }
                }
            })
        },
        async editMode(){
            this.showEditMode = !this.showEditMode
            if(this.showEditMode){
                this.control = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: { polygon: true, trash: true,},
                });
                this.$refs.map.map.addControl(this.control, 'top-left');
                this.zones.forEach(layer => {
                    this.$refs.map.map.removeLayer(layer.properties.id)
                    this.$refs.map.map.removeSource('limit-areas-'+layer.properties.id)
                });
                await this.zones.forEach((limit)=>{
                    let coordenates = [];
                    let coords = [];
                    limit.geometry.coordinates.forEach(async (el)=>{
                        el.forEach(el => {
                            coords.push([Number(el[0]), Number(el[1])]);
                        });
                        coordenates.push(coords);
                        const feature = {type: 'Polygon', coordinates: coordenates};
                        const id = this.control.add(feature);
                        const squareKilometers = await this.calculateArea(limit)
                        const customerCount = this.getCustomerCount(limit)
                        if(limit.properties.id){
                            this.control.setFeatureProperty(id[0], 'id', limit.properties.id)
                        }
                        this.control.setFeatureProperty(id[0], 'branches', limit.properties.branches)
                        this.control.setFeatureProperty(id[0], 'name', limit.properties.name)
                        this.control.setFeatureProperty(id[0], 'type', limit.properties.type)
                        this.control.setFeatureProperty(id[0], 'color', limit.properties.color)
                        this.control.setFeatureProperty(id[0], 'square_kilometers', squareKilometers)
                        this.control.setFeatureProperty(id[0], 'customer_count', customerCount)
                        this.control.setFeatureProperty(id[0], 'task_methods', limit.properties.task_methods)
                    })
                });
            }else{
                this.showMenu = false
                this.control.deleteAll()
                this.$refs.map.map.removeControl(this.control);
                await this.getZones()
                this.sources.forEach((source)=>{
                    this.$refs.map.map.addSource('limit-areas-'+source.data.properties.id, source);
                    this.$refs.map.map.addLayer(
                        {   
                            id: source.data.properties.id, type: 'fill', source: 'limit-areas-'+source.data.properties.id, layout: {},  
                            paint: { 'fill-color': source.data.properties.color, 'fill-opacity': 0.3}
                        },
                    );
                    let centroid = turf.centroid(source.data.geometry);
                    this.$refs.map.map.on('click', source.data.properties.id, ()=>{
                        new Mapbox.Popup()
                            .setLngLat(centroid.geometry.coordinates)
                            .setHTML(`<h4>${source.data.properties.name}</h4>`)
                            .addTo(this.$refs.map.map)
                    })
                })
            }
        },
        async calculateArea(coordinates) {
            try {
                const area = turf.area(coordinates);
                const roundedArea = Math.round((area * 100) / 1000000) / 100;
                return  roundedArea
            } catch (e) {
                console.log(e);
            }
        },
        getCustomerCount(polygon){
            let sum = 0
            for (let i = 0; i < this.customers.length; i++) {
                const customer = this.customers[i];
                let point = turf.point(customer.geometry.coordinates); 
                const compare = turf.inside(point, polygon);
                if(compare)
                sum += 1
            }
            return sum
        },
        async getImages(code, coordinates){
            console.log(coordinates);
            try {
                this.loading = true;
                const response = await customerService.getImages(code)
                const entity = await customerService.getEntity(code)
                const addresses = await customerService.getAllAddress(code)
                
                this.images = response.data
                this.entity = {...entity.data, selectedAddress :{latitude: coordinates[1], longitude:coordinates[0]} ,}
                this.addresses = addresses.data
                this.showLightBox = true
            } catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'HA OCURRIDO UN ERROR',
                    html: error.response ? `<b>${error.response.data.message}</b>` : error.message,
                })
            } finally{
                this.loading = false;
            }
        },
        async getCustomerZones(){
            let customersWithZone = []
            let customersWithoutZone = []
            
            try{
                await this.zones.forEach((limit)=>{
                    for (let i = 0; i < this.customers.length; i++) {
                        const customer = this.customers[i];
                        let point = turf.point(customer.geometry.coordinates); 
                        const compare = turf.inside(point, limit);
                        // <-------------------------------->
                        if(compare){
                            let findIndex = customersWithZone.findIndex(item=> item.properties.address_id == customer.properties.address_id)
                            if(findIndex == -1)
                            customersWithZone.push(customer)
                        }
                    }
                })
                this.customers.forEach(zone => {
                    let index = customersWithZone.findIndex(item => item.properties.address_id == zone.properties.address_id)
                    if(index == -1)
                    customersWithoutZone.push(zone)
                });
                this.customerZone = {customersWithZone: customersWithZone, customersWithoutZone: customersWithoutZone}
            }catch(e){
                console.log(e);
            }
        },
        async getTypes(){
            try {
                const response = await http.get('/limits/types')
                this.types = response.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async getTaskMethods() {
            try {
                const response = await backend_erp_http.get("/task-methods");
                this.task_methods = response.data.data;
            } catch (error) {
                console.log(error);
            }
        },
        setPlace(place){
            this.mapOptions.defaultCoordinates = [place.longitude, place.latitude];
            setTimeout(() => {
                this.$refs.map.map.flyTo({ 
                    center: this.mapOptions.defaultCoordinates, 
                    zoom: 12, 
                    bearing: 0, 
                    speed: 2, 
                    curve: 1, 
                    essential: true,
                    easing: (t) => t 
                });
            }, 500);
        }
    },
    watch:{
        form:{
            deep: true,
            handler(val){
                let features = this.control.getAll();
                let findSelectTempory = features.features.filter(item => item.properties.temporary_id == this.selectPolygon.properties.temporary_id)

                let select = null
                let find = features.features.filter(item => item.properties.id == this.selectPolygon.properties.id && this.selectPolygon.properties.id)
                if(find.length > 0){
                    select = find
                }else{
                    select = findSelectTempory
                }
                this.control.setFeatureProperty(select[0].id, 'branches', val.branches)
                this.control.setFeatureProperty(select[0].id, 'name', val.name)
                this.control.setFeatureProperty(select[0].id, 'type', val.type)
                this.control.setFeatureProperty(select[0].id, 'color', val.color) 
                this.control.setFeatureProperty(select[0].id, 'task_methods', val.task_methods) 
            }
        },
        'styleSelectMap'(){
            this.onMapLoaded()
        },
        'permissions'() {
            if(this.$route.path == '/ecommerce/limitions'){
                const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
                const limitions = modules?.subModules?.find(data => data.name === 'Limitaciones')
                if (!limitions.active) {
                    this.$router.push('/permission');
                }
            } else {
                const modules = this.permissions?.modules?.find(data => data.name == 'Zonificacion')
                const limitions = modules?.subModules?.find(data => data.name === 'Zonas de cobertura')
                if (!limitions.active) {
                    this.$router.push('/permission');
                }
            }
        },
        showCustomLayers:{
            deep:true,
            handler(val){
                // this.filters.showAllCustomer = val.findIndex(option => option.value == 'customer_all') != -1
                this.filters.showCustomersWithZone = val.findIndex(option => option.value == 'customer_with_zone') != -1
                this.filters.showCustomersWithoutZone = val.findIndex(option => option.value == 'customer_without_zone') != -1

                this.filters.verified = val.findIndex(option => option.value == 'verified') != -1
                this.filters.notVerified = val.findIndex(option => option.value == 'not_verified') != -1

                // Testing customers, verified, no verified, zone, out zone => filters
                this.filters.showAllCustomer = val.findIndex(option => option.value == 'customer_all') != -1
                //


                if(!this.filters.showCustomersWithZone && !this.filters.showCustomersWithoutZone
                && !this.filters.verified && !this.filters.notVerified){
                    this.$refs.map.map.setLayoutProperty('clusters', 'visibility', 'none');
                    this.unclusteredCustomers = [] 
                }else{
                    this.$refs.map.map.setLayoutProperty(
                        'clusters',
                        'visibility',
                        'visible'
                    );
                    setTimeout(() => {this.getMarkers()}, 100);
                }
            }
        },
        mapStyle:{
            deep: true,
            handler(){
                this.mapKey += 1
            }
        }
    },
    async created(){
        await this.getTypes()
        await this.getTaskMethods()
        console.log('here');
        this.getPermissions()
        this.mapOptions.defaultCoordinates = this.defaultCountry()
        this.mapOptions.defaultZoom = this.defaultSize()
        setTimeout(() => {
            this.$store.commit('layout/SET_IS_MAP', true)
        }, 100);
    },
    destroyed(){
        this.$store.commit('layout/SET_IS_MAP', false)
    }
}
</script>

<style scoped>
    .menu-right{
        right: 0;
        background: #fff;
        width: 300px;
        height: 100%;
    }
    .form-group::v-deep > div > #checkbox-group-stores .custom-control{
        margin-bottom: 10px;
        cursor: pointer;
    }
    .form-group::v-deep > div > #checkbox-group-stores .custom-control > input{
        cursor: pointer;
    }
    .form-group::v-deep > div > #checkbox-group-stores .custom-control > label{
        cursor: pointer;
    }
    .form-group::v-deep > div > #checkbox-group-stores .custom-control > label{
        margin-left: 10px;
    }
    .container-color{
        width: 20px;
        height: 20px;
    }
    .icon-container{
        background-color: #fff;
        border: 1px solid rgb(211, 191, 191);
        border-radius: 20px;
        padding: 5px;
    }
    .multiselect::v-deep > .multiselect__content-wrapper > .multiselect__content > .multiselect__element > .multiselect__option{
        margin-left: 15px;
    }
    .icon-map{
        position: absolute;
        right: 10px;
        top: 11%;
        padding: 3px;
        
    }
    .map-legend > ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .map-legend > ul > li{
        margin-bottom: 5px;
    }
    .map-legend{
        position: absolute;
        z-index: 1;
        background: #fff;
        box-shadow: 3px 6px 20px #7c879329;
        border-radius: 17px;
        top: 2%;
        right: 60px;
    }
    .map-legend::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 2px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
        transform: translate(50%, -50%) rotate(45deg);
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
    }
    .form-active-description{
        max-width: 250px;
    }
    .autocomplete{
        position: absolute;
        width: 310px;
        top: 10px;
        border: 0;
        right: 10px;
        z-index: 1;
    }
    .gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }
    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }
</style>

<template>
    <svg :width="size" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
        <slot/>
    </svg>
</template>

<script>
export default {
    name: "IconBase",
    props: {
        size: {
            type: Number,
            default: 20
        },
        viewBox:{
            type: String,
            default: '0 0 250 250'
        }
    }
}
</script>

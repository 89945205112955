<template>
    <Layout>
        <el-tabs type="border-card" v-model="activeTab">
            <el-tab-pane label="Routes" name="tab1">
                <div v-if="activeTab === 'tab1'">
                        <b-row>
                            <b-col lg="10">
                                <b-row class="mt-3">
                                    <b-col lg="2" style="width: 200px;">
                                        <label for="search">{{ $t('zones.name') }}</label>
                                        <div class="d-flex">
                                            <b-form-input class="form-control-name" v-model="$v.form.name.$model" style="border-top-right-radius: 0;border-bottom-right-radius: 0;"
                                            :class="{'invalid' : $v.form.name.$error}"></b-form-input>
                                            <b-button :disabled="loading" class="py-0 px-2" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" @click="showList = true">
                                                <eva-icon name="search" width="20"></eva-icon>
                                            </b-button>
                                        </div>
                                    </b-col>
                                    <b-col lg="2">
                                        <multi-select-with-filters :reload="zonesReload" :options="zones" @onSelect="selectZone" :label="$t('zones.zonifications')" id="select-zones" 
                                        trackBy="_id" selectLabel="name" v-model="filters.zones" :class="{'invalid': submited && this.filters.zones.length == 0}"/>
                                    </b-col>
                                    <b-col lg="2" style="width: 175px">
                                        <label>{{ $t("zones.route_type") }}</label>

                                        <el-select v-model="$v.form.type.$model" :class="{'invalid' : $v.form.type.$error}" clearable placeholder="Select option">
                                            <el-option  v-for="item in types" :key="item.id" :label="item.description" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </b-col>
                                    <b-col lg="2" style="width: 160px">
                                        <div>
                                            <label class="mb-1 cursor-pointer">{{ $t('zones.days') }}</label>
                                            <el-popover placement="bottom" width="200" trigger="click">
                                            <div>
                                                <el-select v-model="filterDays" placeholder="Select" size="small">
                                                    <el-option v-for="item in typesDays" :key="item.id" :label="item.text" :value="item.id"/>
                                                </el-select>
                                            </div>
                                            <el-button type="text" class="ms-2 p-0" slot="reference">
                                                <i :class="[filterDays == 1 ? 'el-icon-folder' : 'el-icon-folder-delete']" :style="{color:filterDays == 2 ? '#F56C6C' : null}"></i>
                                            </el-button>
                                        </el-popover>
                                        </div>  
                                        <el-select style="margin-top: -4px" v-model="form.days" multiple collapse-tags filterable allow-create default-first-opiton placeholder="Select">
                                            <el-option class="filter-days"  style="width: 200px;"  v-for="item in days" :key="item.value" :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </b-col>
                                    <b-col lg="2" style="width: 210px">
                                        <label for="range">{{ $t('zones.time_interval')}}</label>
                                        <el-date-picker style="height: 40px; width: 200px;" v-model="form.date" :default-time="['00:00:00', '23:59:59']" sformat="yyyy/MM/dd" value-format="yyyy-MM-dd"  type="daterange" range-separator="-" start-placeholder="Start date" end-placeholder="End date" id="range" size="small"></el-date-picker>
                                    </b-col>
                                    <b-col lg="2" style="width: 200px">
                                        <label for="select-city">{{ $t('Seleccionar la ciudad') }}</label>
                                        <el-select v-model="filters.cities" filterable multiple style="margin-top: -0px" @change="postFilters()" placeholder="Select">
                                            <el-option  v-for="(cities, index) in city" :key="index" class="filter-days" :label="cities.city"  :value="cities.city">
                                                {{ cities.city }}
                                            </el-option>
                                        </el-select>
                                    </b-col>
                                    <b-col lg="2" style="width: 100px" class="d-flex align-items-end">
                                        <div class="position-relative">
                                            <b-button class="mt-3" size="sm" @click="showConditions = !showConditions">
                                                <eva-icon name="options-2" fill="#343a40" width="20" v-if="!showConditions"></eva-icon>
                                                <eva-icon name="close" fill="#343a40" width="20" v-else></eva-icon>
                                            </b-button>
                                            <popup :styles="{top: '65px', right: '-148px'}" class="p-3" style="min-width: 250px; z-index: 3;" v-if="showConditions">
                                                <div style="max-height: 520px; overflow: auto; padding: 10px;">
                                                    <div class="mt-2">
                                                        <el-tooltip  :content="$t('Este filtro permite filtrar a los clientes en función de las fechas asociadas con sus acciones o facturas')" placement="top"><label for="range" class="mb-2 title-15 weight-500">{{ $t('zones.date_range')}}</label></el-tooltip>
                                                        <el-date-picker style="height: 34px; width: 264px;" :default-time="['00:00:00', '23:59:59']" v-model="filters.date" sformat="yyyy/MM/dd" value-format="yyyy-MM-dd"  type="daterange" range-separator="-" start-placeholder="Start date" end-placeholder="End date" id="range" size="small"></el-date-picker>
                                                    </div>
                                                    <hr>
                                                    <b-col lg="1" class="d-flex align-items-center">
                                                        <div class="position-relative">
                                                            <el-popover placement="left" trigger="click" class="p-3" style="max-height: 780px; max-width: 780px;">
                                                                    <el-badge slot="reference" :value="filtersTest[0].value != '' ? filtersTest.length : ''" class="item">
                                                                        <a @click="showConditionsDynamic = !showConditionsDynamic" style="text-wrap: nowrap; margin-left: 53px;" class="'me-2'" href="#" fill="#626ed4"><eva-icon name="options-2" fill="#626ed4" width="17"/> {{ $t('Filtros avanzados') }}</a>
                                                                    </el-badge>
                                                                <template>
                                                                    <div class="container py-4" style="max-height: 750px; max-width: 750px; overflow-y: auto; overflow-x: auto">
                                                                        <el-row class="my-4" :gutter="40">
                                                                            <draggable class="field-form drag-area position-relative list-group" ref="formField" v-bind="dragOptions" :list="filtersTest" :class="{'drag-focus': dragFocus}" :empty-insert-threshhold="500">
                                                                            <!-- Iterate over filters -->
                                                                            <div v-for="(filter, index) in filtersTest" :key="filter.id" class="card my-2">
                                                                                <el-col class="card-body">
                                                                                    <!-- Filter controls -->
                                                                                    <div class="form-row align-items-center">

                                                                                    <!-- Filter logic (AND/OR) -->
                                                                                    
                                                                                    <el-col :xl="4" :lg="4" :md="3">
                                                                                        <label>Logic</label>
                                                                                        <el-select v-model="filter.operator" size="small" placeholder="Select">
                                                                                            <el-option  v-for="logic in logicAtribbutes" :key="logic.value" :label="logic.label" :value="logic.value">
                                                                                            </el-option>
                                                                                        </el-select>
                                                                                    </el-col>

                                                                                    <!-- Attribute selection -->
                                                                                    <el-col :xl="6" :lg="5" :md="3" style="padding-left: 1px;">
                                                                                        <label>Attribute</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-select v-model="filter.field" size="small" placeholder="Select" @change="updateDataType(index, filter.field)">
                                                                                                <el-option  v-for="(options, attribute) in availableAttributes" :key="attribute" :value="options.attribute_key" :label="options.attribute_name">
                                                                                                    {{  options.attribute_name }}
                                                                                                </el-option>
                                                                                            </el-select>
                                                                                        </div>
                                                                                    </el-col>
                                                                                    
                                                                                    <!-- Operator selection -->
                                                                                    <el-col :xl="4" :lg="4" :md="3" style="padding-left: 1px;">
                                                                                        <label>Operator</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-select :key="filter.field" v-model="filter.condition" size="small" placeholder="Select">
                                                                                                <el-option  v-for="(operator) in combinesData(filter.field)" :key="operator" :value="operator">
                                                                                                    {{ operator }}
                                                                                                </el-option>
                                                                                            </el-select>
                                                                                        </div>
                                                                                    </el-col>

                                                                                    <!-- Value input -->
                                                                                    <el-col :xl="5" :lg="5" :md="6" style="padding-left: 1px;" v-if="filter.data_type === 'numeric'">
                                                                                        <label v-if="filter.field">Value</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-input type="number" size="small" v-model="filter.value" placeholder="Enter value" />
                                                                                        </div>
                                                                                    </el-col>
                                                                                    <!-- <el-col :xl="5" :lg="5" :md="6" style="padding-left: 1px;" v-if="filter.field === 'average_invoice_amount' || filter.field === 'sales_amount' || filter.field === 'seller_id' || filter.field === 'classification_id' || filter.field === 'sub_category_id' || filter.field === 'brand_id' || filter.field === 'model_id' || filter.field === 'portfolio_seller_id'">
                                                                                        <label v-if="filter.field">Value</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-select v-model="value2" multiple collapse-tags style="margin-left: 20px;" placeholder="Select">
                                                                                                <el-option
                                                                                                v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                                                                                </el-option>
                                                                                            </el-select>
                                                                                        </div>
                                                                                    </el-col> -->
                                                                                    <!-- <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="filter.field === 'customer' || filter.field === 'select_name'">
                                                                                        <label v-if="filter.field">Value</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-input type="string" size="small" v-model="filter.value" placeholder="Enter value" />
                                                                                        </div>
                                                                                    </el-col> -->
                                                                                    <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="filter.data_type === 'string'">
                                                                                        <label v-if="filter.field">Value</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-select :key="filter.field" v-model="filter.value" filterable size="small" placeholder="Select">
                                                                                                <el-option  v-for="category in getDataCombine(filter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                    {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                </el-option>
                                                                                            </el-select>
                                                                                        </div>
                                                                                    </el-col>
                                                                                    <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="filter.data_type === 'array' || filter.data_type === 'integer'">
                                                                                        <label v-if="filter.field">Value</label>
                                                                                        <div class="d-flex flex-column">
                                                                                            <el-select :key="filter.field" v-model="filter.value" filterable multiple collapse-tags size="small" placeholder="Select">
                                                                                                <el-option  v-for="category in getDataCombine(filter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                    {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                </el-option>
                                                                                            </el-select>
                                                                                        </div>
                                                                                    </el-col>

                                                                                    <!-- Buttons for removing or adding nested filters -->
                                                                                    <el-col :xl="5" :lg="4" style="padding-left: 1px;" class="d-flex justify-content-between align-items-center">
                                                                                        <div style="margin-top: 20px;">
                                                                                            <el-button circle type="danger" @click="removeFilter(index)" round size="small"><i class="el-icon-close" /></el-button>
                                                                                            <el-button circle type="primary" @click="addNested(index)" round size="small"><i class="el-icon-plus" /></el-button>
                                                                                        </div>
                                                                                    </el-col>
                                                                                    </div>
                                                                                </el-col>
                                                                                <div v-if="filter.detail" style="margin-top: -14px; padding-left: 37px;">
                                                                                    <el-col v-for="(childFilter, childIndex) in filter.detail" :key="childFilter.id" style="margin-top: 5px;">
                                                                                        <div class="align-items-center">

                                                                                            <el-col :xl="4" :lg="5" :md="8" style="padding-left: 1px;">
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-select size="small" v-model="childFilter.field" placeholder="Select" @change="updateDataTypeNested(index, childIndex, childFilter.field)">
                                                                                                        <el-option  v-for="(options, attribute) in availableAttributes" :key="attribute" :label="options.attribute_name" :value="options.attribute_key">
                                                                                                            {{  options.attribute_name }}
                                                                                                        </el-option>
                                                                                                    </el-select>
                                                                                                </div>
                                                                                            </el-col>

                                                                                            <el-col :xl="4" :lg="4" :md="3" style="padding-left: 1px;">
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-select size="small" v-model="childFilter.condition" placeholder="Select">
                                                                                                        <el-option  v-for="operator in combinesData(childFilter.field)" :key="operator" :value="operator">
                                                                                                            {{ operator }}
                                                                                                        </el-option>
                                                                                                    </el-select>
                                                                                                </div>
                                                                                            </el-col>

                                                                                            <el-col :xl="4" :lg="4" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'numeric'" >
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-input size="small" type="number" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                </div>
                                                                                            </el-col>
                                                                                            <!-- <el-col :xl="5" :lg="6" :md="8" style="padding-left: 1px;" v-if="childFilter.field === 'customer' || childFilter.field === 'select_name'">
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-input size="small" type="string" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                </div>
                                                                                            </el-col> -->
                                                                                            <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'string'">
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-select :key="childFilter.field" v-model="childFilter.value" filterable size="small" placeholder="Select">
                                                                                                        <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                            {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                        </el-option>
                                                                                                    </el-select>
                                                                                                </div>
                                                                                            </el-col>
                                                                                            <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'array' || childFilter.data_type === 'integer'">
                                                                                                <!-- <label v-if="filter.field">Value</label> -->
                                                                                                <div class="d-flex flex-column">
                                                                                                    <el-select v-model="childFilter.value" filterable multiple collapse-tags size="small" placeholder="Select">
                                                                                                        <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                            {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                        </el-option>
                                                                                                    </el-select>
                                                                                                </div>
                                                                                            </el-col>

                                                                                            <el-col :xl="5" :lg="4" style="padding-left: 1px;" class="d-flex justify-content-between align-items-center">
                                                                                                <div>
                                                                                                    <el-button circle type="danger" @click="removeFilterNested(index, childIndex)" round size="small"><i class="el-icon-close" /></el-button>
                                                                                                    <el-button circle type="primary" @click="addNestedChild(index, childIndex)" round size="small"><i class="el-icon-plus" /></el-button>
                                                                                                </div>
                                                                                            </el-col>
                                                                                        </div>
                                                                                        <!-- PARENTCHILD -->

                                                                                        <div v-if="childFilter.detail" style="margin-top: -14px; padding-left: 1px;">
                                                                                            <el-col v-for="(childFilter, childChild) in childFilter.detail" :key="childFilter.id" style="margin-top: 10px">
                                                                                                <el-col :xl="4" :lg="5" :md="8" style="padding-left: 1px;">
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-select size="small" v-model="childFilter.field" placeholder="Select" @change="updateDataTypeNestedChild(index, childIndex, childChild, childFilter.field)">
                                                                                                            <el-option  v-for="(options, attribute) in availableAttributes" :key="attribute" :label="options.attribute_name" :value="options.attribute_key">
                                                                                                                {{  options.attribute_name }}
                                                                                                            </el-option>
                                                                                                        </el-select>
                                                                                                    </div>
                                                                                                </el-col>

                                                                                                <el-col :xl="4" :lg="4" :md="3" style="padding-left: 1px;">
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-select size="small" v-model="childFilter.condition" placeholder="Select">
                                                                                                            <el-option  v-for="operator in combinesData(childFilter.field)" :key="operator" :value="operator">
                                                                                                                {{ operator }}
                                                                                                            </el-option>
                                                                                                        </el-select>
                                                                                                    </div>
                                                                                                </el-col>

                                                                                                <el-col :xl="5" :lg="4" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'numeric'">
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-input size="small" type="number" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                    </div>
                                                                                                </el-col>
                                                                                                <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.field === 'customer' || childFilter.field === 'select_name'">
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-input size="small" type="string" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                    </div>
                                                                                                </el-col>
                                                                                                <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'string'">
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-select :key="childFilter.field" v-model="childFilter.value" filterable size="small" placeholder="Select">
                                                                                                            <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                                {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                            </el-option>
                                                                                                        </el-select>
                                                                                                    </div>
                                                                                                </el-col>
                                                                                                <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'array' || childFilter.data_type === 'integer'">
                                                                                                    <!-- <label v-if="filter.field">Value</label> -->
                                                                                                    <div class="d-flex flex-column">
                                                                                                        <el-select v-model="childFilter.value" filterable multiple collapse-tags size="small" placeholder="Select">
                                                                                                            <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id" :value="category.code || category.id || category.description">
                                                                                                                {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                            </el-option>
                                                                                                        </el-select>
                                                                                                    </div>
                                                                                                </el-col>

                                                                                                <el-col :xl="5" :lg="4" style="padding-left: 1px;" class="d-flex justify-content-between align-items-center">
                                                                                                    <div>
                                                                                                        <el-button circle type="danger" @click="removeFilterNestedChild(index, childIndex, childChild)" round size="small"><i class="el-icon-close" /></el-button>
                                                                                                        <el-button circle type="primary" @click="addNestedChildChild(index, childIndex, childChild)" round size="small"><i class="el-icon-plus" /></el-button>
                                                                                                    </div>
                                                                                                </el-col>

                                                                                                <div v-if="childFilter.detail" style="margin-top: -14px; padding-left: 2px;">
                                                                                                    <el-col v-for="(childFilter, childChildChild) in childFilter.detail" :key="childFilter.id" style="margin-top: 10px">
                                                                                                        <el-col :xl="4" :lg="5" :md="8" style="padding-left: 1px;">
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-select size="small" v-model="childFilter.field" placeholder="Select" @change="updateDataTypeNestedChildChild(index, childIndex, childChild, childChildChild, childFilter.field)">
                                                                                                                    <el-option  v-for="(options, attribute) in availableAttributes" :key="attribute" :label="options.attribute_name" :value="options.attribute_key">
                                                                                                                        {{  options.attribute_name }}
                                                                                                                    </el-option>
                                                                                                                </el-select>
                                                                                                            </div>
                                                                                                        </el-col>

                                                                                                        <el-col :xl="4" :lg="4" :md="3" style="padding-left: 1px;">
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-select size="small" v-model="childFilter.condition" placeholder="Select">
                                                                                                                    <el-option  v-for="operator in combinesData(childFilter.field)" :key="operator" :value="operator">
                                                                                                                        {{ operator }}
                                                                                                                    </el-option>
                                                                                                                </el-select>
                                                                                                            </div>
                                                                                                        </el-col>

                                                                                                        <el-col :xl="5" :lg="4" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'numeric'">
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-input size="small" type="number" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                            </div>
                                                                                                        </el-col>
                                                                                                        <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.field === 'customer' || childFilter.field === 'select_name'">
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-input size="small" type="string" v-model="childFilter.value" placeholder="Enter value" />
                                                                                                            </div>
                                                                                                        </el-col>

                                                                                                        <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'string'">
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-select :key="childFilter.field" v-model="childFilter.value" filterable size="small" placeholder="Select">
                                                                                                                    <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id " :value="category.code || category.id || category.description">
                                                                                                                        {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                                    </el-option>
                                                                                                                </el-select>
                                                                                                            </div>
                                                                                                        </el-col>
                                                                                                        <el-col :xl="5" :lg="6" :md="6" style="padding-left: 1px;" v-if="childFilter.data_type === 'array' || childFilter.data_type === 'integer'">
                                                                                                            <!-- <label v-if="filter.field">Value</label> -->
                                                                                                            <div class="d-flex flex-column">
                                                                                                                <el-select v-model="childFilter.value" filterable multiple collapse-tags size="small" placeholder="Select">
                                                                                                                    <el-option  v-for="category in getDataCombine(childFilter.field)" :key="category.code || category.id" :value="category.code || category.id || category.description">
                                                                                                                        {{ category.description || `[${category.code}] ${category.first_name} ${category.last_name}`}}
                                                                                                                    </el-option>
                                                                                                                </el-select>
                                                                                                            </div>
                                                                                                        </el-col>

                                                                                                        <el-col :xl="5" :lg="4" style="padding-left: 1px;" class="d-flex justify-content-between align-items-center">
                                                                                                            <div>
                                                                                                                <el-button circle type="danger" @click="removeFilterNestedChildChild(index, childIndex, childChild, childChildChild)" round size="small"><i class="el-icon-close" /></el-button>
                                                                                                                <!-- <el-button circle type="primary" @click="addNestedChild(index, childIndex)" round size="small"><i class="el-icon-plus" /></el-button> -->
                                                                                                            </div>
                                                                                                        </el-col>
                                                                                                    </el-col>
                                                                                                </div>
                                                                                            </el-col>
                                                                                        </div>
                                                                                    </el-col>
                                                                                </div>
                                                                            </div>
                                                                        </draggable>  
                                                                        </el-row>
                                                                    </div>
                                                                </template>
                                                                <div class="mt-2" style="text-align: right;">
                                                                    <el-button style="margin-top: 10px;" :loading="loading" size="mini" round type="primary" @click="addFilter()">Add filter</el-button>
                                                                    <el-button style="margin-top: 10px;" :loading="loading" size="mini" round type="success" @click="postFilters">Apply filters</el-button>
                                                                </div>
                                                            </el-popover>
                                                        </div>
                                                    </b-col>
                                                    <hr>
                                                    <div>
                                                        <el-tooltip  :content="$t('Este filtro permite buscar y filtrar información sobre ventas o transacciones en función del monto de ventas asociado a clientes')" placement="top"><label for="purchase-range" class="mb-2 title-13 weight-500">{{ $t('Importe de las ventas') }}</label></el-tooltip>
                                                        <div class="d-flex align-items-center mb-2">
                                                            <b-form-input v-model="filters.sales_amount" placeholder="Enter value" class="ms-2"></b-form-input>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <el-tooltip  :content="$t('Este filtro permite definir un rango específico de montos de compra que determina cuánto han gastado los clientes')" placement="top"><label for="purchase-range" class="mb-2 title-13 weight-500">{{ $t('zones.purchase_range') }}</label></el-tooltip>
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="min">Min</label>
                                                            <b-form-input v-model="filters.purchaseRange[0]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                            <span class="mx-2">-</span>
                                                            <label for="max">Max</label>
                                                            <b-form-input v-model="filters.purchaseRange[1]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <el-tooltip  :content="$t('Permite filtrar a los clientes en función del tiempo que ha transcurrido desde su última compra')" placement="top"><label for="purchase-range-for-no-buy" class="mb-2 title-13 weight-500">{{ $t('zones.range_no_buy_days') }}</label></el-tooltip>
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="min">Min</label>
                                                            <b-form-input v-model="filters.puchaseRangeDayNotBuy[0]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                            <span class="mx-2">-</span>
                                                            <label for="max">Max</label>
                                                            <b-form-input v-model="filters.puchaseRangeDayNotBuy[1]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <el-tooltip  :content="$t('Este filtro permite definir un rango específico de cantidad de facturas')" placement="top"><label for="purchase-range-for-invoice" class="mb-2 title-13 weight-500">{{ $t('Rango por cantidad de facturas') }}</label></el-tooltip>
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="min">Min</label>
                                                            <b-form-input v-model="filters.puchaseRangeForInvoice[0]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                            <span class="mx-2">-</span>
                                                            <label for="max">Max</label>
                                                            <b-form-input v-model="filters.puchaseRangeForInvoice[1]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div>
                                                        <el-tooltip  :content="$t('Este filtro permite definir un rango específico de promedio de facturas')" placement="top"><label for="avg-purchase-range-for-invoice" class="mb-2 title-13 weight-500">{{ $t('Rango promedio de facturas') }}</label></el-tooltip>
                                                        <div class="d-flex align-items-center mb-2">
                                                            <label for="min">Min</label>
                                                            <b-form-input v-model="filters.avgPuchaseRangeForInvoice[0]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                            <span class="mx-2">-</span>
                                                            <label for="max">Max</label>
                                                            <b-form-input v-model="filters.avgPuchaseRangeForInvoice[1]" placeholder="Enter your name" class="ms-2"></b-form-input>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                        <div>
                                                            <label class="mb-2 title-14 weight-500">{{ $t('Seleccionar el tipo de cliente') }}</label>
                                                            <!-- <el-select v-model="filters.customer_sub_types" multiple size="small" placeholder="Select" class="ms-2">
                                                                <el-option v-for="(subTypes, index) in customerSubtypes" :key="index" :label="subTypes.description" :value="subTypes.id">
                                                                    {{ subTypes.description }}
                                                                </el-option>
                                                            </el-select> -->
                                                            <multiselect class="filter-type" @input="updateFilters" v-model="selectedOptions" placeholder="Select" label="description" track-by="id" :options="customerSubtypes" :multiple="true" :taggable="true"></multiselect>
                                                        </div>
                                                    <hr>
                                                    <div>
                                                        <div class="mt-2">
                                                            <el-tooltip  :content="$t('Filtrar y mostrar solo aquellos clientes que han sido verificados')" placement="top"><el-checkbox size="medium" id="verified-zone" :disabled="false"  v-model="filters.verified" name="verified">{{ $t('zones.verified') }}</el-checkbox></el-tooltip>
                                                            <el-tooltip  :content="$t('Filtrar y mostrar solo aquellos clientes que aún no han sido verificados')" placement="top"><el-checkbox size="medium" id="notVerified-zones" :disabled="false" v-model="filters.notVerified" name="not-verified">{{ $t('zones.not_verified') }}</el-checkbox></el-tooltip>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                        <div class="mt-2">
                                                            <el-tooltip :content="$t('Clientes que están o no bloqueados')" placement="top"><el-checkbox size="medium" id="blocked-zones" :disabled="false" v-model="filters.blocked" name="blocked">{{ $t('Bloqueados') }}</el-checkbox></el-tooltip>
                                                        </div>
                                                </div>
                                                <div class="mt-2" style="text-align: right;">
                                                    <el-link @click="openVideo = true" icon="el-icon-help" style="position: absolute; top: 93%; right: 80%;" type="warning">Guía</el-link>
                                                    <el-button style="margin-left: 202px; margin-top: 10px;" :loading="loading" size="mini" round type="success" @click="postFilters">Apply filters</el-button>
                                                </div>
                                            </popup>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col lg="2" style="margin-top: -10px;" class="d-flex justify-content-between align-items-center">
                                <el-button size="small" @click="optionsSaveRoutes()" style="width: 150px; height: 33px;" :loading="loading" type="primary" class="mt-4 overflow-hidden border-0">{{ this.updateSetting ?  $t('Update setting')  : $t('zones.save') }}</el-button>
                                <el-button size="small"  @click="reset" style="width: 150px; height: 33px;"  type="danger" class="mt-4 border-0">
                                    {{ $t('zones.cancel') }}
                                </el-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col :lg="customersWithFilters.length > 0 ? 8 : 12">
                                <mgl-map :key="styleSelectMap.id" style="height: 70vh;border-radius: 15px;" class="overflow-hidden mt-2" :zoom="mapOptions.defaultZoom" ref="map" :dragRotate="false" 
                                :accessToken="mapOptions.accessToken" :center="mapOptions.defaultCoordinates" :fadeDuration="300" :mapStyle="styleSelectMap.value" @load="onMapLoaded">
                                    <vue-google-autocomplete id="vue-google-autocomplete" @focus="showMessage = true" :placeholder="$t('delivery.write_reference_to_locate_the_map')" 
                                        class="form-control autocomplete" :class="'form-control-name'" :country="[generalSetting.general.web_page.country_default.text]" :types="['locality']" v-on:placechanged="setPlace"/>
                                    <mgl-geojson-layer  :layer="clusters" :sourceId="'customers'" :layerId="clusters.id" :source.sync="sourceCustomersRoutes" @click="clickZoom" @mouseenter="putPointer" @mouseleave="removePointer"/>
                                    <mgl-geojson-layer :layer.sync="clusterCount" :sourceId="'customers'" :layerId="clusterCount.id"/>
                                    <mgl-geojson-layer :layer.sync="unclustered" :sourceId="'customers'" :layerId="unclustered.id" :clearSource="true" @mouseenter="putPointer" @mouseleave="removePointer"/>
                                    <!-- <eva-icon name="map" @mouseenter.native="showLegend = true" @mouseleave.native="showLegend = false" class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer"></eva-icon> -->
                                    <!-- <div class="map-legend p-2" @mouseenter.native="showLegend = true" v-if="showLegend"> -->
                                    <!-- </div> -->
                                    <MglNavigationControl position="top-left"/>
                                    <MglGeolocateControl position="top-left" />
                                    <MglFullscreenControl position="top-left"/>
                                    <div style="position: fixed; margin-top: 181px; margin-left: 10px;">
                                        <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                                        <el-popover placement="right" width="210" trigger="click">
                                            <b-row class="mt-2" style="width: 205">
                                                <b-col>
                                                    <div class="d-flex">
                                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                                            <span class="icon" role="img" aria-label="Mapa">
                                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                                                <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                                            </span>
                                                            <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                                        </button>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <div class="d-flex" style="margin-left: -7px;">
                                                        <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                                            <span class="icon" role="img" aria-label="Mapa">
                                                                <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                                                <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                                            </span>
                                                            <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                                        </button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        <button slot="reference" class="gmaps-button">
                                            <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i> <!-- Puedes reemplazar esto con un icono real, como un SVG o un icono de FontAwesome -->
                                        </button>
                                        </el-popover>
                                    </div>
                                    <!-- <b-dropdown class="icon-map d-none d-md-block ms-2" toggle-class="header-item" menu-class="dropdown-menu-end" right variant="white">
                                        <template v-slot:button-content>

                                                <icon-map :class="styleSelectMap.color || mapStyle.color"/>
                                                <span :style="{color:styleSelectMap.color == 'satellite' ? 'white' : 'black'}"> {{styleSelectMap.text || mapStyle.text}}</span>
                                            <span class="mdi mdi-chevron-down"></span>
                                        </template>
                                        <a href="#" @click.prevent="setStyle(style)" class="dropdown-item notify-item" v-for="style in styleMap" :key="style.value">
                                            <icon-map :class="style.color" class="me-2"/>
                                            <span class="align-middle">
                                                {{style.text}}
                                            </span>
                                        </a>
                                    </b-dropdown> -->
                                    <template v-for="{properties, geometry} in unclusteredCustomers">
                                        <mgl-marker :coordinates="[geometry.coordinates[0], geometry.coordinates[1]]" :draggable="false" 
                                            :key="properties.address_id">
                                            <div slot="marker">
                                                <icon-pin :color="properties.verified ? '#348547' : '#c40316'" :size="25"/>
                                            </div>
                                            <mgl-popup>
                                                <div>
                                                    <ul class="p-0 mb-0 list-unstyled size-14">
                                                        <li> 
                                                            <span class="weight-600 d-block mb-0">
                                                                {{$t('ecommerce.customer')}} 
                                                                <eva-icon name="person" :fill="properties.verified ? '#02a499' : '#ec4561'" width="16"></eva-icon>
                                                            </span>
                                                            <span class="text-capitzalize">({{properties.code}}) {{properties.first_name | textFormat}} {{properties.last_name | textFormat}}</span>  
                                                        </li>
                                                        <!-- <li> 
                                                            <span class="weight-600"></span>  
                                                            {{properties.address_id}}
                                                        </li> -->
                                                        <li class="d-flex"> 
                                                            <span class="weight-600 me-2">{{$t('ecommerce.verified_buyer')}}</span>  
                                                            <icon-check-circle :color="'#02a499'" :size="14" v-if="properties.verified"/>
                                                            <icon-close-circle :color="'#ec4561'" :size="14" v-else/>
                                                        </li>
                                                        <li>
                                                            <a @click="checkingProps(properties)" class="me-2" href="#" fill="#626ed4">{{ $t('zones.customer_details') }}</a>
                                                        </li>
                                                        <li>
                                                            <a @click="checkStreetView(geometry.coordinates)" href="#" class="me-2" fill="#626ed4">{{ $t('Street View') }}</a>
                                                        </li>
                                                    </ul>
                                                </div> 
                                            </mgl-popup>
                                        </mgl-marker>
                                    </template>
                                    <!-- <mgl-marker :coordinates="[customer.geometry.coordinates[0], customer.geometry.coordinates[1]]" :draggable="false" 
                                    v-for="(customer, index) in unclusteredCustomers" :key="customer.properties.address_id + index">
                                        <div slot="marker">
                                            <icon-pin :color="customer.properties.verified ? '#348547' : '#c40316'" :size="25"/>
                                        </div>
                                    </mgl-marker> -->
                                        <!-- <mgl-popup>
                                            <div>
                                                <h6>{{customer.properties.first_name}} {{customer.properties.last_name}}</h6>
                                                <ul class="p-0">
                                                    <li> <b>{{$t('ecommerce.code')}}</b>  {{customer.properties.code}}</li>
                                                    <li> <b>ID</b>  {{customer.properties.address_id}}</li>
                                                    <li> <b>{{$t('ecommerce.verified_buyer')}}</b>  {{customer.properties.verified}}</li>
                                                </ul>
                                            </div> 
                                        </mgl-popup> -->
                                        <vue-element-loading :active="loading" :is-full-screen="false"/>
                                </mgl-map>
                            </b-col>
                            <b-col lg="4" v-if="customersWithFilters.length > 0 && !loading">
                                <!-- v-if="customersWithFilters.length > 0" -->
                                <b-table :items="customersWithFilters" :fields="fields" responsive striped small
                                    thead-class="bg-primary text-white" class="bg-white mt-2 cursor-pointer" style="height: 70vh; border-radius: 10px;">
                                    <template #cell(code)="data">
                                        {{data.item.properties.code}}
                                    </template>
                                    <template #cell(name)="data">
                                        <span class="text-capitalize">
                                            {{data.item.properties.company_name | textFormat}} / 
                                            {{data.item.properties.first_name | textFormat}} {{data.item.properties.last_name | textFormat}}
                                        </span>
                                    </template>
                                    <template #cell(phone)="data">
                                        {{data.item.properties.phone}}
                                    </template>
                                    <template #cell(email)="data">
                                        {{data.item.properties.company_email}}
                                    </template>
                                    <template #cell(addressName)="data">
                                        {{data.item.properties.address_name}}
                                    </template>
                                    <template #cell(verified)="data">
                                        <icon-check-circle :color="'#02a499'" v-if="data.item.properties.verified"/>
                                        <icon-close-circle :color="'#ec4561'" v-else/>
                                    </template>
                                    <template #cell(active)="data">
                                        <icon-check-circle :color="'#02a499'" v-if="data.item.properties.active"/>
                                        <icon-close-circle :color="'#ec4561'" v-else/>
                                    </template>
                                </b-table>
                                <!-- <div class="d-flex justify-content-center align-items-center" style="height: 70vh" v-else>
                                    <div class="bg-white py-4 px-3 text-center">
                                        <h6 class="size-16">Sin zonas de cobertura :(</h6>
                                        <p class="size-13" style="line-height: 15px;">Elige por lo menos un sector si deseas ver los clientes que aplican.</p>
                                        <b-button variant="success" size="sm" @click="showCustomers">Ver todos</b-button>
                                    </div>
                                </div> -->
                            </b-col>
                        </b-row>
                        <guieForVideos  v-if="openVideo" @closeModal="openVideo = false" />
                    <route-list @send-Filter="routeSettingCaptureFilters" v-if="showList" @closeModal="showList = false"/>
                    <info-customers :viewCustomers="viewCustomers[0]" @closeModal="viewCustomersDetails = false"  v-if="viewCustomersDetails" />
                </div>
            </el-tab-pane>
            <el-tab-pane label="Routes details" name="tab2">
                    <div v-if="activeTab === 'tab2'">
                        <b-row>
                            <b-col lg="10">
                                <b-row class="mt-3">
                                    <el-col :xl="6" :lg="6" :md="12">
                                        <div class="d-flex flex-column">
                                            <label class="mb-1 cursor-pointer">{{ $t('Routes Settings') }}</label>
                                            <el-select v-model="selectedRouteSetting" clearable placeholder="Select option">
                                                <el-option  v-for="item in allRoutesSettings" :key="item.id" :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <br/>
                                    </el-col>
                                    <el-col :xl="6" :lg="7" :md="12">
                                        <div v-if="selectedRouteSetting" class="d-flex flex-column">
                                            <label class="mb-1 cursor-pointer">{{ $t('Executions') }}</label>
                                            <el-select v-model="selectedRunTask" clearable placeholder="Select option">
                                                <el-option  v-for="item in selectedSettingsDetails" :key="item.id" :label="changesHoursCreated(item.created_at)" :value="item.id">
                                                    <span style="float: left">{{ changesHoursCreated(item.created_at) }}</span>
                                                    <el-tag :type="item.all_tasks_completed ? 'success'  : 'danger'" style="float: right; font-size: 13px; margin-left: 12px;">{{ completeTask(item.all_tasks_completed) }}</el-tag>
                                                    <el-tag style="float: right; font-size: 13px; margin-left: 12px;">{{ item.tasks_count }}</el-tag>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </el-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="d-flex flex-column">
                            <task-history :selectedRunTask="selectedRunTask" :clearFiltersTask="clearFiltersTask"/>
                        </b-row>                       
                    </div>
            </el-tab-pane>
            <el-tab-pane label="Route dashboard" name="tab3">
                <div v-if="activeTab === 'tab3'">
                </div>
            </el-tab-pane>
        </el-tabs>
    </Layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
// import Draggable from 'vuedraggable'
// import IconMap from '../../layout/components/svg/IconMap.vue'
import MultiSelectWithFilters from '../../../common/components/selects/MultiSelectWithFilters.vue';
// import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { MglMap, MglGeojsonLayer, MglMarker, MglNavigationControl, MglFullscreenControl, MglGeolocateControl, MglPopup } from 'v-mapbox';
import "mapbox-gl/dist/mapbox-gl.css";
import TaskHistory from "../../delivery/components/tasks/TaskHistory.vue";
import 'v-mapbox/dist/v-mapbox.css';
import { mapGetters } from 'vuex';
import IconCheckCircle from '../../../common/components/svg/IconCheckCircle.vue';
import { http } from "../../../common/services/node-service";
import { http as backend } from "@/common/services/web-backend.js";
import { http as erp} from "../../../common/services/web-backend";
import * as turf from "@turf/turf";
import moment from "moment";
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import widgetStat from "../../layout/components/widgets/widget-stat.vue";
import { customersMixin } from '../../ecommerce/helpers/mixins/customers-mixin'
import IconCloseCircle from '../../../common/components/svg/iconCloseCircle.vue';
import Popup from '../../../common/components/popup/Popup.vue';
import RouteList from '../components/RouteList.vue';
import {required} from 'vuelidate/lib/validators'
import Draggable from 'vuedraggable'
import IconPin from '../../../common/components/svg/IconPin.vue';
import { setlayersMixin } from "../../ecommerce/helpers/mixins/zones-mixin";
import InfoCustomers from '../components/InfoCustomers.vue';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ProductsService} from "../../ecommerce/service/products-service";
import guieForVideos from "../components/guieForVideos.vue";
const productService = new ProductsService();
// import AppSelection from '../../../common/components/selects/AppSelection.vue';

export default {
    name: "Routes",
    components: {
        Layout,
        MultiSelectWithFilters,
        // VueSlider,
        MglMap,
        MglGeojsonLayer,
        IconCheckCircle,
        // widgetStat,
        // DatePicker,
        IconCloseCircle,
        InfoCustomers,
        Popup,
        Draggable,
        VueGoogleAutocomplete,
        RouteList,
        IconPin,
        TaskHistory,
        // AppSelection,
        // IconMap,
        MglMarker,
        guieForVideos,
        MglNavigationControl,
        MglFullscreenControl,
        MglGeolocateControl,
        MglPopup,
    },
    mixins: [customersMixin, setlayersMixin],
    data(){
        return {
            activeTab: 'tab1',
            logicAtribbutes: [
                {
                    value: 'AND',
                    label: 'AND'
                },
                {
                    value: 'OR',
                    label: 'OR'
                }
            ],
            filtersTest: [
                {
                    id: 1,
                    field: '',
                    condition: '',
                    value: '',
                    operator: 'AND',
                    detail: [],
                    data_type: ''
                }
            ],
            styleMap: [
                {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
            ],
            drag: false,
            styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
            nextId: 2,
            showLegend: false,
            availableAttributes: [],
            filterDataTypes: {
                string: [
                    "is",
                    "is-not",
                    "contains",
                    "not-contain",
                    "starts-with",
                    "ends-with",
                ],
                numeric: [
                    "equal",
                    "not-equal",
                    "greater-than",
                    "less-than",
                    "greater-than-or-equal",
                    "less-than-or-equal",
                ],
                date: [
                    "is",
                    "is_before",
                    "is_after",
                    "is-on-or-before",
                    "is-on-or-after",
                    "is_between",
                ],
                boolean: [
                    "is",
                    "is-not"
                ],
                array: [
                    "is",
                    "is-not"
                ],
                object: [
                    "is",
                    "is-not"
                ],
                integer: [
                    "equal",
                    "not-equal",
                    "greater-than",
                    "less-than",
                    "greater-than-or-equal",
                    "less-than-or-equal",
                ]
            },
            filters: { 
                purchaseRange: [0, 999999999],
                puchaseRangeDayNotBuy: [0, 999999999],
                puchaseRangeForInvoice: [0, 999999999],
                avgPuchaseRangeForInvoice: [0, 999999999],
                zones: [],
                creditLimit: null,
                showCustomersWithZone: false,
                showCustomersWithoutZone: false,
                filtersDynamic: null,
                verified: false,
                notVerified: false,
                sales_amount: 0,
                date: ["2013-01-01", "2024-12-31"],
                cities: [],
                blocked: false,
                customer_sub_types: [],
            },
            selectedOptions: [],
            zonesReload: [],
            filterDays: 1,
            typesDays: [
                {id: 1, text: 'is'},
                {id: 2, text: 'is not'},
            ],
            dataMerged: {},
            form: {
                name: null,
                days: [],
                date: null,
                type: null 
            },
            mapOptions: {
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                defaultZoom: 7,
                defaultCoordinates: [-70.7260555, 19.0781721],
            },
            days: [
                {value: 'sun', name: this.$t('zones.sunday')} ,
                {value: 'mon', name: this.$t('zones.monday')},
                {value: 'tue', name: this.$t('zones.tuesday')},
                {value: 'wed', name: this.$t('zones.wednesday')},
                {value: 'thu', name: this.$t('zones.thursday')},
                {value: 'fri', name: this.$t('zones.friday')},
                {value: 'sat', name: this.$t('zones.saturday')},
            ],
            customerSubtypes: [],
            types: [],
            zones: [],
            city: [],
            polygons: [],
            // customers: [],
            selectedDataType: null,
            customersWithFiltersCode: [],
            CustomersCodeZone: [], 
            customersWithFilters: [],
            customersAddressID: [],
            loading: false,
            isRoutes: true,
            showConditions: false,
            categories: [],
            showConditionsDynamic: false,
            tasksWithTime: [],
            showList: false,
            submited: false,
            showZones: false,
            products: [],
            brand: [],
            model: [],
            openVideo: false,
            getRouterSetting: {},
            updateSetting: false,
            allRoutesSettings: [],
            selectedRouteSetting: '',
            selectedSettingsDetails: [],
            selectedRunTask: 0,
            taskRun: [],
            statData: [],
            clearFiltersTask: false,
            viewCustomersDetails: false,
            viewCustomers: [],
            dragFocus: false,
        }
    },
    computed:{
        ...mapGetters({
            mapStyle: 'layout/mapStyle',
            generalSetting: "ecommerce/generalSetting",
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        dragOptions() {
            return {
                animation: 500,
                group: "tasks",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        sources() {
            let sources = [];
            for (let layer of this.polygons){
                sources.push({
                    'type': 'geojson',
                    'data': layer
                })
            }
            return sources;
        },
        optionsSaveRoutes(){
            if(this.updateSetting){
                return this.updateRouteSetting
            } else {
                return this.save
            }
        }, 
    },
    validations: {
        form: {
            name: {
                required
            },
            type: {
                required
            },
        }
    },
    watch:{
        showZones(val){
            if(val){
                this.selectZone(this.zones, true)
            }else{
                this.clearZones()
            }
        },
        // 'filters.customer_sub_types'(){
        //     console.log(this.filters.customer_sub_types);
        // },
        'selectedRouteSetting'(){
            if(this.selectedRouteSetting != ''){
                this.selectedSettingsDetails = [],
                this.selectedRunTask =  '',
                this.getRoutesDetails(this.selectedRouteSetting)
            }
        },
        'selectedRunTask'(){
            if(this.selectedRunTask != 0){
                console.log(this.selectedRunTask);
            }
        },
        'filters.zones'(){
            // console.log(this.filters.zones);
            this.mergerDataPost()
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Zonificacion')
            const route = modules?.subModules?.find(data => data.name === 'Rutas')
            // Verifica si se han cambiado los permisos de administrador
            if (!route.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
        async 'styleSelectMap'(){
            this.mergerDataPost()
            this.getRoutes()
        }
    },
    
    methods:{
        updateFilters(options) {
            this.filters.customer_sub_types = options.map(option => option.id)
        },
        checkStreetView(coordinates) {
            const location = { lat: coordinates[1], lng: coordinates[0] }
            // eslint-disable-next-line  
            const sv = new google.maps.StreetViewService(); 
            sv.getPanorama({ location, radius: 70 }, (data, status) => {
                console.log(status, data);
                if (status === 'OK') {
                    const lat = coordinates[1]
                    const lng =  coordinates[0]
                    this.openStreetView(lat, lng);
                } else {
                    this.$swal.fire({ 
                        title: this.$t('Street View'), 
                        text: this.$t('No hay vistas a la calle cerca de la ubicación.'),
                        icon: "warning",
                    })
                }
            });
        },

        openStreetView(lat, lng) {
            const url = `https://www.google.com/maps?cbll=${lat},${lng}&layer=c`;
            window.open(url, '_blank');
        },

        setStyle(style){
            this.styleSelectMap = style
        },

        getDataCombine(value){
            return  this.availableAttributes.find(data => data.attribute_key === value).data
        },

        updateDataType(index, value){
            this.filtersTest[index].value = ''
            this.filtersTest[index].condition = ''
            this.filtersTest[index].data_type = this.availableAttributes.find(attribute => attribute.attribute_key === value).data_type
        },

        updateDataTypeNested(index, childIndex, value){
            this.filtersTest[index].detail[childIndex].value = ''
            this.filtersTest[index].detail[childIndex].condition = ''
            this.filtersTest[index].detail[childIndex].data_type = this.availableAttributes.find(attribute => attribute.attribute_key === value).data_type
        },

        updateDataTypeNestedChild(index, childIndex, childChild, value){
            this.filtersTest[index].detail[childIndex].detail[childChild].value = ''
            this.filtersTest[index].detail[childIndex].detail[childChild].condition = ''
            this.filtersTest[index].detail[childIndex].detail[childChild].data_type = this.availableAttributes.find(attribute => attribute.attribute_key === value).data_type
        },

        updateDataTypeNestedChildChild(index, childIndex, childChild, childChildChild, value){
            this.filtersTest[index].detail[childIndex].detail[childChild].detail[childChildChild].value = ''
            this.filtersTest[index].detail[childIndex].detail[childChild].detail[childChildChild].condition = ''
            this.filtersTest[index].detail[childIndex].detail[childChild].detail[childChildChild].data_type = this.availableAttributes.find(attribute => attribute.attribute_key === value).data_type
        },

        combinesData(field){
            return this.availableAttributes.find(attributes => attributes.attribute_key === field)?.operators
        },

        enrichAttributesWithOperators() {
            this.availableAttributes.forEach(attribute => {
                const dataType = attribute.data_type;
                if (dataType && this.filterDataTypes[dataType]) {
                    attribute.operators = this.filterDataTypes[dataType];
                }
            });
        },

        addNestedChildChild(parentId ,parentChildId, childChild){
            console.log(parentId ,parentChildId);

            const newFilter = {
                id: this.nextId++,
                field: '',
                condition: '',
                value: '',
                operator: this.filtersTest[parentId].operator,
                detail: [],
                data_type: ''
            };

            console.log(this.filtersTest[parentId].detail[parentChildId].detail[childChild].detail.push(newFilter), 'here');
        },

        addNestedChild(parentId ,parentChildId){
            console.log(parentId ,parentChildId);

            const newFilter = {
                id: this.nextId++,
                field: '',
                condition: '',
                value: '',
                operator: this.filtersTest[parentId].operator,
                detail: [],
                data_type: ''
            };

            console.log(this.filtersTest[parentId].detail[parentChildId].detail.push(newFilter));
        },

        addNested(parentId){
            const newFilter = {
                id: this.nextId++,
                field: '',
                condition: '',
                value: '',
                operator: this.filtersTest[parentId].operator,
                detail: [],
                data_type: ''
            };

            this.filtersTest[parentId].detail.push(newFilter)
        },

        addFilter() {
            const newFilter = {
                id: this.nextId++,
                field: '',
                condition: '',
                value: '',
                operator: 'AND',
                detail: [],
                data_type: ''
            };
            this.filtersTest.push(newFilter)
        },

        setPlace(place){
            this.mapOptions.defaultCoordinates = [place.longitude, place.latitude];
            setTimeout(() => {
                this.$refs.map.map.flyTo({ 
                    center: this.mapOptions.defaultCoordinates, 
                    zoom: 12, 
                    bearing: 0, 
                    speed: 2, 
                    curve: 1, 
                    essential: true,
                    easing: (t) => t 
                });
            }, 500);
        },

        findFilterById(filters, id) {
            for (let filter of filters) {
                if (filter.id === id) {
                    return filter;
                }
                if (filter.children && filter.children.length) {
                    const found = this.findFilterById(filter.children, id);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        },

        removeFilter(index) {
            this.filtersTest.splice(index, 1);
        },

        removeFilterNested(index, nestedChild) {
            this.filtersTest[index].detail.splice(nestedChild, 1)
        },

        removeFilterNestedChild(index, childIndex, childChild) {
            this.filtersTest[index].detail[childIndex].detail.splice(childChild, 1)
        },

        removeFilterNestedChildChild(index, childIndex, childChild, childChildChild){
            this.filtersTest[index].detail[childIndex].detail[childChild].detail.splice(childChildChild, 1)
        },

        setDragCursor(value) { // drag cursor
            const html = document.getElementsByTagName('html').item(0)
            html.classList.toggle('grabbing', value)
        },
        onStart() {
            this.dragFocus = true;
            this.setDragCursor(true);
        },
        onEnd() {
            this.dragFocus = false;
            this.setDragCursor(false);
        },

        completeTask(isComplete){
            if(isComplete){
                return  this.$t('zones.completed')
            } else {
                return  this.$t('zones.pending')
            }
        },
        checkingProps(propertiCustomers){
            this.viewCustomers = [{
                properties: propertiCustomers 
            }]
            this.viewCustomersDetails = true
        },
        changesHoursCreated(date){
            const dateHours = new Date(date);
            const dateFormant = dateHours.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // Para mostrar en formato de 24 horas
            });
            return dateFormant
        },
        async getCity(){
            try{
                const cities = await erp.get('/addresses/get-cities');
                this.city = cities.data.data
            }catch (e) {
                throw e
            }
        },
        async getDynamicFilterField(){
            try {
                const field = await erp.get('/filters/dynamic-filter-field-mapping')
                // console.log(field.data);
                this.availableAttributes = field.data
            } catch (error) {
                throw error
            }
        },
        async getCustomerSubtypes(){
            try {
                const subtypes = await erp.get('/customer-subtypes')
                this.customerSubtypes = subtypes.data.data
            } catch (error) {
                throw error
            }
        },
        async getRoutes(){
            try {
                this.clearFiltersTask = true
                const response = await erp.get('/route-settings')
                this.allRoutesSettings = response.data.data;
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('zones.error_occurred'),
                    text: error.response ? error.response.data.message : error.message,
                })
            }
        },
        async getFiltersParam(){
            try {
                this.loading = true
                const response = await erp.get('/filters')
                console.log(response.data.data);
            } catch (error) {
                console.log(error);
            }
        },
        async getRoutesDetails(id){
            try {
                this.loading = true
                const response = await erp.get(`route-settings/${id}/routes`)
                this.selectedSettingsDetails = response.data.data
                // console.log(this.selectedSettingsDetails);
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('zones.error_occurred'),
                    text: error.response ? error.response.data.message : error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onMapLoaded(){
            this.$refs.map.map.on('render', () => {
                this.getMarkers()
            });
        },
        async getZones(){
            try {
                const response = await http.get('/limits')
                this.zones = response.data.data
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('zones.error_ocurred'),
                    text: error.response ? error.response.data.message : error.message,
                })
            }
        },
        async getType(){
            try {
                const response = await backend.get("task-types");
                this.types = response.data.data
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'error task type',
                    text: error.response ? error.response.data.message : error.message,
                })
            }
        },
        async routeSettingCaptureFilters(row){
            this.reset()
            this.updateSetting = true
            this.getRouterSetting = row
            const convertListArray = row.week_days.split(',')
            const resultZones = this.zones.filter(item => row.filters.limits.includes(item._id))
            this.form.name = row.name
            this.$v.form.type.$model = this.types.find(data => data.id == row.task_type_id)
            this.form.days = convertListArray
            this.form.date = [row.start_date, row.end_date]
            this.filters.purchaseRange = [row.filters.sales_amount_range.min, row.filters.sales_amount_range.max]
            this.filters.puchaseRangeDayNotBuy = [row.filters.days_since_last_purchase_range.min, row.filters.days_since_last_purchase_range.max]
            this.filters.puchaseRangeForInvoice = [row.filters.invoice_count_range.min, row.filters.invoice_count_range.max]
            this.filters.date = [row.filters.start_date, row.filters.end_date]
            if(row.filters.cities.length != 0){
                this.filters.cities = [row.filters.cities]
            }
            this.filters.blocked = row.filters.blocked
            this.filters.customer_sub_types = row.filters.sub_types
            this.selectedOptions = this.customerSubtypes.filter(item => row.filters.sub_types.includes(item.id))
            if (row.filters.filters != null) {
                this.filtersTest = row.filters.filters
            }
            this.filters.sales_amount = row.filters.sales_amount
            if(row.filters.verified){
                this.filters.verified =  row.filters.verified
            } else if(row.filters.verified == false){
                this.filters.notVerified = !row.filters.verified
            }
            this.zonesReload = resultZones

            console.log(this.filtersTest);
        },
        getClustersRoutes(){
            let result = []
            if(this.searchCustomer != '' && this.searchCustomer != null){
                result = this.searchResult
            }else if(this.filters.showCustomersWithZone && this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified){
                let filter = this.customerZone.customersWithZone.filter(item=> item.properties.verified == true)
                result = filter
            }else if(!this.filters.showCustomersWithZone && this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
                let filter = this.customerZone.customersWithoutZone.filter(item=> item.properties.verified == true)
                result = filter
            }else if(this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && this.filters.notVerified){
                let filter = this.customerZone.customersWithZone.filter(item=> item.properties.verified == false)
                result = filter
            }else if(!this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && this.filters.notVerified){
                let filter = this.customerZone.customersWithoutZone.filter(item=> item.properties.verified == false)
                result = filter
            }else if(this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified){
                result = this.customerZone.customersWithZone
            }else if(!this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
                result = this.customerZone.customersWithoutZone
            }else if(!this.filters.showCustomersWithZone && this.filters.verified && !this.filters.showCustomersWithoutZone && !this.filters.notVerified
                || this.filters.showCustomersWithZone && this.filters.verified && this.filters.showCustomersWithoutZone && !this.filters.notVerified){
                let verified = this.data.filter(item=> item.properties.verified == true)
                result = verified
            }else if(!this.filters.showCustomersWithZone && !this.filters.verified && !this.filters.showCustomersWithoutZone && this.filters.notVerified
                || this.filters.showCustomersWithZone && !this.filters.verified && this.filters.showCustomersWithoutZone && this.filters.notVerified){
                let notVerified = this.data.filter(item=> item.properties.verified == false)
                result = notVerified
            }
            else{
                result = this.customers
            }
            return result
        },
        clearZones(){
                // <------------------------- LIMPIAR LAYERS ---------------------------->
                if(this.polygons.length > 0){
                    this.sources.forEach(async (source)=>{
                        let getSource = this.$refs.map.map.getSource('limit-areas-'+source.data.properties.id)
                    if(getSource){
                        this.$refs.map.map.removeLayer(source.data.properties.id)
                        this.$refs.map.map.removeSource('limit-areas-'+source.data.properties.id)
                    }})
                    this.customersWithFilters = []
                }
                // <------------------------- LIMPIAR LAYERS ---------------------------->
        },
        selectZone(zones, show, clear){
                if(!show)
                this.filters.zones = zones;
                // console.log(this.filters.zones, '3')
                // <------------------------- LIMPIAR LAYERS ---------------------------->
                // if(this.polygons.length > 0){
                //     this.sources.forEach(async (source)=>{
                //         let getSource = this.$refs.map.map.getSource('limit-areas-'+source.data.properties.id)
                //     if(getSource){
                //         this.$refs.map.map.removeLayer(source.data.properties.id)
                //         this.$refs.map.map.removeSource('limit-areas-'+source.data.properties.id)
                //     }})
                // }
                // <------------------------- LIMPIAR LAYERS ---------------------------->
                if(!clear)
                this.clearZones()
                let polygons = []
                zones.forEach(limit => {
                    let coordinates = [];
                    limit.limits.forEach((coords) => {
                        coordinates.push([coords.longitude, coords.latitude])
                    });
                    let branches = limit.codplaces.map(branch => {
                        return branch.codplace
                    });
                    let poly = turf.polygon([coordinates]);
                    // const customerCount = this.getCustomerCount(poly)
                    poly.properties['id'] = limit._id
                    poly.properties['branches'] = branches
                    poly.properties['name'] = limit.name
                    poly.properties['type'] = limit.type
                    poly.properties['color'] = limit.color
                    poly.properties['square_kilometers'] = limit.square_kilometers
                    poly.properties['customer_count'] = limit.customer_count
                    polygons.push(poly)
                });
                this.polygons = polygons;
                this.setLayers()
        },
        // getCustomerCount(polygon){
        //     let sum = 0
        //     for (let i = 0; i < this.customers.length; i++) {
        //         const customer = this.customers[i];
        //         let point = turf.point(customer.geometry.coordinates); 
        //         const compare = turf.inside(point, polygon);
        //         if(compare)
        //         sum += 1
        //     }
        //     return sum
        // },
        async updateRouteSetting(){
            this.submited = true
            this.$v.$touch();
            if(this.$v.form.$error) return
            if(this.filters.zones.length == 0){
                this.$swal.fire({ 
                    title: this.$t('zones.no_selected_sector'), 
                    text: this.$t('you_must_select_at_least_one_sector'),
                    icon: "error",
                })
                return
            } 
            try {
                this.loading = true
                const body = {
                    name: this.form.name,
                    task_type_id: this.form.type.id,
                    // sectors: this.filters.zones.map(zone=>zone._id),
                    // address_verified: this.verified,
                    // customers: this.customersWithFiltersCode,
                    // addresses_id: this.customersAddressID,
                    // limitions: this.dataMerged.limits,
                    filters: this.dataMerged
                }
                if(this.form.date){
                    body['start_date'] = moment(this.form.date[0]).format('YYYY-MM-DD'),
                    body['end_date'] = moment(this.form.date[1]).format('YYYY-MM-DD')
                }
                if(this.form.days.length > 0){
                    // const listDay = this.form.days.map(day=>day.value)
                    body['week_days'] = this.form.days.join()
                } 
                await erp.put(`route-settings/${this.getRouterSetting.id}`, body)
                this.$swal.fire({ 
                    title: this.$t('zones.saved_successfully'), text: this.$t('zones.saved_successfully'), 
                    icon: "success",
                })
                this.reset()
                this.submited = false
            } catch (error) {
                this.$swal.fire({ 
                    title: this.$t('zones.error_contact_administrator'), text: error.message, 
                    icon: "error",
                })
            } finally {
                this.loading = false
            }
        },
        async save(){
            this.submited = true
            this.$v.$touch();
            if(this.$v.form.$error) return
            if(this.filters.zones.length == 0){
                this.$swal.fire({ 
                    title: this.$t('zones.no_selected_sector'), 
                    text: this.$t('you_must_select_at_least_one_sector'),
                    icon: "error",
                })
                return
            } 
            try {
                this.loading = true
                const body = {
                    name: this.form.name,
                    task_type_id: this.form.type,
                    // sectors: this.filters.zones.map(zone=>zone._id),
                    // address_verified: this.verified,
                    // customers: this.customersWithFiltersCode,
                    // addresses_id: this.customersAddressID,
                    limits: this.dataMerged.limits,
                    filters: this.dataMerged
                }
                if(this.form.date){
                    body['start_date'] = moment(this.form.date[0]).format('YYYY-MM-DD'),
                    body['end_date'] = moment(this.form.date[1]).format('YYYY-MM-DD')
                }
                if(this.form.days.length > 0){
                    // const listDay = this.form.days.map(day=>day.value)
                    body['week_days'] = this.form.days.join()
                }
                await erp.post('/route-settings', body)
                this.$swal.fire({ 
                    title: this.$t('zones.saved_successfully'), text: this.$t('zones.saved_successfully'), 
                    icon: "success",
                })
                this.reset()
                this.submited = false
            } catch (error) {
                this.$swal.fire({ 
                    title: this.$t('zones.error_contact_administrator'), text: error.message, 
                    icon: "error",
                })
            } finally {
                this.loading = false
            }
        },
        reset(){
            this.updateSetting = false
            this.zonesReload = []
            this.selectedOptions = []
            this.filters = {
                purchaseRange: [0, 999999999],
                puchaseRangeDayNotBuy: [0, 999999999],
                puchaseRangeForInvoice: [0, 999999999],
                avgPuchaseRangeForInvoice: [0, 999999999],
                sales_amount: 0,
                zones: [],
                blocked: false,
                customer_sub_types: [],
                creditLimit: null,
                showCustomersWithZone: false,
                showCustomersWithoutZone: false,
                verified: false,
                notVerified: false,
                date: ["2013-01-01", "2023-12-31"]
            }
            this.filtersTest = [{
                id: 1,
                field: '',
                condition: '',
                value: '',
                operator: 'AND',
                detail: [],
                data_type: ''
            }]
            this.filtersDynamic = null
            this.form = {
                name: null,
                days: [],
                date: null,
                type: null
            }
            // this.$refs.MultiSelectWithFilters.doSomething();
            this.clearZones()
            this.customersWithFiltersCode = []
            this.customersAddressID = []
            this.$v.form.$reset();
        },
        async getCustomerZones(){
            let customersWithZone = []
            let customersWithoutZone = []
            try{
                await this.polygons.forEach((limit)=>{
                    for (let i = 0; i < this.customers.length; i++) {
                        const customer = this.customers[i];
                        let point = turf.point(customer.geometry.coordinates); 
                        const compare = turf.inside(point, limit);
                        // console.log(compare);
                        if(compare){
                            let findIndex = customersWithZone.findIndex(item=> item.properties.address_id == customer.properties.address_id)
                            // console.log(findIndex)
                            if(findIndex == -1)
                            customersWithZone.push(customer)
                        }
                    }
                })
                this.customers.forEach(polygons => {
                    let index = customersWithZone.findIndex(item => item.properties.address_id == polygons.properties.address_id)
                    if(index == -1)
                    customersWithoutZone.push(polygons)
                });
                // console.log(this.customers);
                this.customerZone = {customersWithZone: customersWithZone, customersWithoutZone: customersWithoutZone}
                // console.log(customersWithZone.length > 0 && this.showCustomersWithZone == false, 'hola');
                if(customersWithZone.length > 0){
                    this.filters.showCustomersWithZone = true
                }else {
                    this.filters.showCustomersWithZone = false
                }
                this.customersWithFilters = customersWithZone
                // this.customersWithFilters = this.getClustersRoutes()
                // this.customersWithFilters = this.customersWithZone
                this.customersWithFiltersCode = this.customersWithFilters.map(function(element){
                    return element.properties.code
                })
                this.customersAddressID = this.customersWithFilters.map(function(element){
                    return element.properties.address_id
                })
                this.showCustomers()
            }catch(e){
                console.log(e);
            }
        },
        showCustomers(){
                this.$refs.map.map.setLayoutProperty(
                    'clusters',
                    'visibility',
                    'visible'
                ); 
        },
        async mergerDataPost(){
            const arrayListZone = this.filters.zones
            const arrayDeIds = arrayListZone.map(objeto => objeto._id);
            if(this.filtersTest[0].value != ''){
                this.filtersDynamic = this.filtersTest
            }
            if(this.filters.date[1] === '2024-12-31'){
                let date = new Date().toJSON()
                this.filters.date[1] = moment(date).format('YYYY-MM-DD')
            }
            if(this.filters.verified && this.filters.notVerified){
                this.dataMerged = {
                    is_blocked: this.filters.blocked,
                    sub_types: this.filters.customer_sub_types,
                    sales_amount: this.filters.sales_amount,
                    filters: this.filtersDynamic,
                    limits: arrayDeIds,
                    start_date: this.filters?.date[0],
                    end_date: this.filters?.date[1],
                    sales_amount_range: { min: this.filters.purchaseRange[0], max: this.filters.purchaseRange[1]},
                    days_since_last_purchase_range: { min: this.filters.puchaseRangeDayNotBuy[0], max: this.filters.puchaseRangeDayNotBuy[1]},
                    invoice_count_range: { min: this.filters.puchaseRangeForInvoice[0], max: this.filters.puchaseRangeForInvoice[1]},
                    average_invoice_amount: { min: this.filters.avgPuchaseRangeForInvoice[0], max: this.filters.avgPuchaseRangeForInvoice[1]},
                    mutate_data_as_points: true,
                    cities: this.filters.cities
                }
            }else if(!this.filters.verified && !this.filters.notVerified){
                this.dataMerged = {
                    filters: this.filtersDynamic,
                    sub_types: this.filters.customer_sub_types,
                    is_blocked: this.filters.blocked,
                    sales_amount: this.filters.sales_amount,
                    limits: arrayDeIds,
                    start_date: this.filters?.date[0],
                    end_date: this.filters?.date[1],
                    sales_amount_range: { min: this.filters.purchaseRange[0], max: this.filters.purchaseRange[1]},
                    days_since_last_purchase_range: { min: this.filters.puchaseRangeDayNotBuy[0], max: this.filters.puchaseRangeDayNotBuy[1]},
                    invoice_count_range: { min: this.filters.puchaseRangeForInvoice[0], max: this.filters.puchaseRangeForInvoice[1]},
                    average_invoice_amount: { min: this.filters.avgPuchaseRangeForInvoice[0], max: this.filters.avgPuchaseRangeForInvoice[1]},
                    mutate_data_as_points: true,
                    cities: this.filters.cities
                }
            } else {
                this.dataMerged = {
                    filters: this.filtersDynamic,
                    is_blocked: this.filters.blocked,
                    sub_types: this.filters.customer_sub_types,
                    sales_amount: this.filters.sales_amount,
                    limits: arrayDeIds,
                    start_date: this.filters?.date[0],
                    end_date: this.filters?.date[1],
                    sales_amount_range: { min: this.filters.purchaseRange[0], max: this.filters.purchaseRange[1]},
                    days_since_last_purchase_range:  { min: this.filters.puchaseRangeDayNotBuy[0], max: this.filters.puchaseRangeDayNotBuy[1]},
                    invoice_count_range: { min: this.filters.puchaseRangeForInvoice[0], max: this.filters.puchaseRangeForInvoice[1]},
                    average_invoice_amount: { min: this.filters.avgPuchaseRangeForInvoice[0], max: this.filters.avgPuchaseRangeForInvoice[1]},
                    mutate_data_as_points: true,
                    verified: this.filters.verified,
                    cities: this.filters.cities
                }
            }
            // await this.fetchPoints()
            this.getPointCustomers(this.dataMerged)
            this.filtersDynamic = null
        },
        async postFilters(){
            this.mergerDataPost()
        }
    },
    async created(){
        await this.getDynamicFilterField()
        await this.$store.dispatch('delivery/getEmployees');
        await this.$store.dispatch('delivery/getTaskType');
        await this.$store.dispatch('delivery/getTaskMethod');
        await this.$store.dispatch('delivery/getTaskStatus');
        await this.$store.dispatch('delivery/getSalesEmployee');
        await this.$store.dispatch("documentsFollowup/getTypes");
        await this.$store.dispatch('delivery/getIncidentType');
        this.getPermissions()
        await this.getCustomerSubtypes()
        await this.getCity()
        const categories = await productService.getCategories()
        this.categories = categories
        this.mapOptions.defaultCoordinates = this.defaultCountry()
        this.mapOptions.defaultZoom = this.defaultSize()
        await this.getType()
        await this.getZones()
        this.enrichAttributesWithOperators()
        this.mergerDataPost()
        this.getRoutes()
        this.$store.commit('layout/SET_IS_MAP', true)
    },
    destroyed(){
        this.$store.commit('layout/SET_IS_MAP', false)
    }
};
</script>

<style scoped>
    .mx-datepicker::v-deep{
        width: 100%;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input, .form-select::v-deep{
        width: 100%;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        height: 35px;
    }
    .popup::v-deep::before {
        top: 0px;
        transform: translate(50%, -50%) rotate(315deg);
    }
    .multiselect::v-deep.invalid .multiselect__tags{
        border: 1px solid #ec4561 !important;
    }

    .multi-select-with-filters::v-deep.invalid >
    .multiselect > .multiselect__tags{
        border: 1px solid #ec4561 !important;
    }
    .table-responsive{
        height: 600px;
    }
    .mgl-map-wrapper::v-deep > .mapboxgl-map > .mapboxgl-popup{
        max-width: 400px !important;
        z-index: 2;
    }
    .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 21%;
    }
    .el-loading-mask {
        z-index: 4 !important;
    }

    .el-range-editor--small .el-range-separator {
        margin-top: 10px !important;
    }
    .position-filter{
        margin-top: 9px;
        margin-left: 50px;
    }
    .filter-days{
        z-index: 1;
        /* align-self: end; */
    }
    .drag-focus {
        border: 2px dashed #d6d6d6 !important;
    }

    .autocomplete{
        position: absolute;
        width: 310px;
        top: 10px;
        border: 0;
        right: 10px;
        z-index: 1;
    }
    .item {
        margin-top: 10px;
        margin-right: 40px;
    }
    .icon-map{
        position: absolute;
        right: 10px;
        top: 11%;
        padding: 3px;
        
    }
    .map-legend > ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .map-legend > ul > li{
        margin-bottom: 5px;
    }
    .map-legend{
        position: absolute;
        z-index: 1;
        background: #fff;
        box-shadow: 3px 6px 20px #7c879329;
        border-radius: 17px;
        top: 6%;
        right: 60px;
    }
    .map-legend::before{
        content: "";
        position: absolute;
        top: 50%;
        right: 2px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
        transform: translate(50%, -50%) rotate(45deg);
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
    }
    .form-control {
        height: 31px !important;
    }
    .form-control-name {
        height: 40px !important;
    }
    .newButton {
        width: 29px;
        height: 29px;
        display: block;
        padding: 0;
        outline: none;
        border: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: whitesmoke;
        cursor: pointer;
    }

    .gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }

    .filter-type{
        z-index: 1;
        width: 225px;
    }

    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }

</style>
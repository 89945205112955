<template>
    <icon-base :size="size" :fill="color" viewBox="0 0 460.427 460.427">
        <g>
            <g>
                <g>
                    <circle cx="225.108" cy="41.302" r="38.373"/>
                    <path d="M397.111,330.868c-7.377,0-14.458,1.281-21.047,3.611l-3.12-9.271c6.069-1.88,12.506-2.914,19.175-2.957     c8.102-0.052,15.106-6.153,15.609-14.239c0.549-8.84-6.458-16.18-15.179-16.18c-10.213,0-20.059,1.595-29.309,4.541l-20.71-61.54     h9.315c3.709,7.964,10.934,13.96,19.685,15.978c3.329,0.768,6.52-1.762,6.52-5.191v-45.936c0-3.406-3.164-5.96-6.484-5.199     c-8.767,2.01-16.007,8.012-19.721,15.987h-4.463c-2.762,3.551-6.192,6.541-10.09,8.794c-9.936,5.744-18.371,4.656-24.252,4.314     c1.41,4.189,9.775,29.046,11.571,34.383c-1.71,1.595,3.555-6.344-68.423,106.855h-0.684c-2.564,8.595-6.261,15.549-14.333,21.473     c-1.468,1.077-3.017,2.038-4.623,2.888h19.837c8.186,0,15.805-4.183,20.2-11.09l57.851-90.93l6.585,19.567     c-27.031,17.072-45.069,47.145-45.247,81.37c-0.043,8.292,6.381,15.424,14.668,15.71c8.646,0.299,15.749-6.621,15.749-15.2     c0-20.938,9.758-39.629,24.953-51.8l3.515,10.444c-13.426,12.156-21.633,29.974-20.806,49.648     c1.368,32.53,27.712,59.008,60.235,60.529c36.281,1.697,66.339-27.33,66.339-63.245     C460.427,359.272,432.024,330.868,397.111,330.868z M397.111,416.942c-12.549,0-22.758-10.209-22.758-22.758     s10.209-22.758,22.758-22.758s22.758,10.209,22.758,22.758C419.869,406.733,409.66,416.942,397.111,416.942z"/>
                    <path d="M269.437,259.727c3.117-10.448-2.336-21.534-12.515-25.441l-23.595-9.057l1.407-6.988     c-7.085-2.401-12.47-8.33-14.186-15.588l-13.618-57.806l28.632,49.039c2.935,5.028,8.193,8.252,14.005,8.59l69.342,4.03     c9.601,0.554,17.808-6.774,18.365-16.348c0.557-9.585-6.762-17.807-16.348-18.365l-60.037-3.489l-23.933-40.989l13.567,12.518     l1.624-8.065c2.827-14.035-6.26-27.703-20.294-30.53l-45.317-9.127c-14.035-2.826-27.703,6.26-30.53,20.294l-14.561,72.305     v-69.587c0-4.846-3.929-8.775-8.775-8.775H32.603c-4.846,0-8.775,3.929-8.775,8.775v127.689h-6.084     c-9.8,0-17.744,7.944-17.744,17.744c0,9.8,7.944,17.744,17.744,17.744h73.004v27.041c-29.827,11.281-52.235,37.663-57.884,69.823     c-1.275,7.26,4.317,13.919,11.7,13.919h15.524c-0.135,1.684-0.223,3.381-0.223,5.099c0,34.912,28.403,63.316,63.316,63.316     c34.912,0,63.316-28.403,63.316-63.316c0-1.686-0.086-3.351-0.216-5.004h15.02c-15.51-8.246-23.512-26.573-18.347-43.889     l19.983-66.989h17.417l-21.406,71.76c-3.294,11.041,2.987,22.662,14.028,25.956c11.042,3.294,22.663-2.988,25.956-14.028     L269.437,259.727z M123.18,416.942c-12.549,0-22.758-10.209-22.758-22.758c0-1.753,0.206-3.458,0.583-5.099h44.35     c0.377,1.64,0.583,3.345,0.583,5.099C145.938,406.733,135.728,416.942,123.18,416.942z M141.446,242.814v-17.198     c2.926,6.698,7.462,12.621,13.223,17.198H141.446z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>

<script>
import IconBase from "../../../../common/components/svg/IconBase.vue"

export default {
    name: "IconMotorcycle",
    components: { IconBase },
    props: {
        color: {
            type: String
        },
        size: {
            type: Number,
            default: 20
        }
    }
}
</script>


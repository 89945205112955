<template>
    <layout>
        <b-card no-body v-if="settings.general" :class="'mt-2'">
            <!-- <b-tabs fill card vertical content-class="mt-3" v-model="tabIndex">
                <b-tab :title="$t('ecommerce.general')" href="/general">
                    <general :settings="settings.general" />
                </b-tab>
                <b-tab :title="$t('ecommerce.payment_method')" href="payment-methods">
                    <payment-methods :settings="settings.payment_methods"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.optional_payment')">
                    <buying-options :settings="settings.delivery_options"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.category')" href="categories">
                    <categorias :categories="categories"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.skin')" href="appearance-settings">
                    <appearance-settings/>
                </b-tab>
                <b-tab :title="$t('ecommerce.manage_home')">
                    <manage-home/>
                </b-tab>
            </b-tabs> -->
            <el-tabs :tab-position="tabPosition" class="mt-3" style="height: auto;">
                <el-tab-pane :label="$t('ecommerce.general')" href="/general"><general :store="store"  :settings="settings.general" /></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.payment_method')" href="payment-methods"><payment-methods :settings="settings.payment_methods"/></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.optional_payment')"><buying-options :settings="settings.delivery_options"/></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.category')" href="categories"><categorias :categories="categories"/></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.skin')" href="appearance-settings"><appearance-settings/></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.manage_home')"><manage-home/></el-tab-pane>
                <el-tab-pane :label="$t('ecommerce.veltrix_settings')"><veltrix-settings :settings="settings.veltrix"></veltrix-settings></el-tab-pane>
                <el-tab-pane  :label="$t('ecommerce.account_banks')"><bank-account :settings="settings.general"/></el-tab-pane>
            </el-tabs>
            <template #footer>
                <div class="d-flex justify-content-end">
                    <b-button @click="save" variant="primary">
                        {{ $t('ecommerce.save') }}
                    </b-button>
                </div>
            </template>
        </b-card>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { http } from "../../../common/services/node-service";
import General from '../components/settings/General.vue';
import PaymentMethods from '../components/settings/PaymentMethods.vue';
import Categorias from '../components/settings/Categorias.vue';
import BuyingOptions from '../components/settings/BuyingOptions.vue';
import ManageHome from '../components/settings/ManageHome.vue';
import BankAccount from "../components/settings/BankAccount.vue";
import AppearanceSettings from '../components/settings/AppearanceSettings.vue';
import VeltrixSettings from "../components/settings/VeltrixSettings.vue";
import { mapGetters } from 'vuex';

export default {
    name: "Settings",
    components:{
        Layout,
        General,
        PaymentMethods,
        Categorias,
        BuyingOptions,
        ManageHome,
        AppearanceSettings,
        VeltrixSettings,
        BankAccount
    },
    data() {
        return {
            tabPosition: 'left',
            settings: {
                id: 0,
            },
            categories:[],
            tabIndex: 0,
            store: [],
            tabs: [
                'general',
                'payment-methods', 
                'buying-options',
                'categories',
                'appearance-settings',
                'manage-home'
            ]
        }
    },
    computed:{
        ...mapGetters({
            generalSetting: "ecommerce/generalSetting",
            permissions: "auth/permissions",
            userData: "auth/user"
        })
    },
    watch:{
        generalSetting:{
            deep: true,
            immediate: true,
            handler(val){
                this.settings = val
                this.settings.id = val._id;
            }
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const setting = modules?.subModules?.find(data => data.name === 'Configuracion')
            // Verifica si se han cambiado los permisos de administrador
            if (!setting.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    },
    methods:{
        async getCategories() {
            try {
                const data = await http.get("categories" ); 
                this.categories = data.data.data;
            } catch (e) {
                console.log(e);
            } finally {
                // $(block).unblock();
            }
        },
        async point_of_sale(){
            try {
                const sale = await http.get('stores')
                this.store = sale.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async save(){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        await http.post("configurations", this.settings);
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                            icon: "success",
                        })
                        this.$store.commit("ecommerce/SET_GENERAL_SETTING", this.settings)
                    } catch (error) {
                        if(error.response?.data.message){
                            this.$swal.fire({
                            icon: 'error',
                            title: 'No se pudo cargar los datos',
                            text: error.response.data.message,
                            })
                        }else{
                            this.$swal.fire({
                                icon: 'error',
                                title: 'No se pudo cargar los datos',
                                text: error.message,
                            })
                        }
                    }
                }
            })
        },
    },
    async mounted() {
        try {
            await this.getCategories();
        } catch (error) {
            console.error(error);
        }
        this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.params.tab)
    },
    created(){
        this.point_of_sale()
        this.getPermissions()
    }
}
</script>

<style scoped>
.b-tab {
    margin: 14px
}
.el-tab {
    max-height: auto;
}
</style>

<template>
    <b-row class="h-100">
        <el-col :lg="6" :xl="6" class="d-flex flex-column h-100">
            <el-card shadow="never">
                <div class="d-flex flex-column">
                    <label for="delivery" class="mb-1">{{$t('delivery.delivery_courier')}}</label>
                    <el-select v-model="employee_id" filterable placeholder="Select" size="small" clearable>
                        <el-option v-for="item in employees" :key="item.entity.code" :label="`[${item.entity.code}] ${item.entity.first_name}  ${item.entity.last_name}`" :value="item.entity.code"/>
                    </el-select>
                    <hr>
                </div>
            </el-card>
            <el-table :data="selectedTask" stripe height="100%" size="mini" class="mt-2" :header-cell-style="{'font-weight':400}" v-loading="loadingDetails">
                <el-table-column width="40" class-name="text-center">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.selected"/>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.time')" class-name="text-center">
                    <template slot-scope="scope">
                        <el-tag size="mini">{{$d(new Date(scope.row.created_at), 'alt_short')}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('delivery.customer')">
                    <template slot-scope="scope">
                        <span class="text-muted" v-if="scope.row.document.type_id == 3">{{scope.row.document.branch_name | textFormat}}→{{scope.row.customer | textFormat}}</span>
                        <span class="text-muted text-capitalize" v-else-if="scope.row.customer">
                            <span v-if="scope.row.company_name">{{scope.row.company_name | textFormat}} /</span> {{scope.row.customer | textFormat}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column :label="''" class-name="text-center" width="60">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="Detalles" placement="top">
                            <el-button size="mini" type="text" @click="selectTask(scope.row.id)" circle>
                                <i class="el-icon-view size-18"></i>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="deliveries" stripe height="100%" size="mini" class="mt-2" :header-cell-style="{'font-weight':400}">
                <el-table-column label="Active" width="70" class-name="text-center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.onRoute?'el-icon-success':'el-icon-warning']" class="size-18" :style="{'color': scope.row.onRoute ? '#67C23A' : '#F56C6C'}"></i>
                    </template>
                </el-table-column>
                <el-table-column label="Code" width="70" class-name="text-center">
                    <template slot-scope="scope">
                        <el-tag size="mini" style="width: 50px">{{ scope.row.code }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Name">
                    <template slot-scope="scope">
                        <b class="size-13 text-capitalize text-limited me-2">{{ scope.row.name.toLowerCase() }} {{ scope.row.lastName.toLowerCase() }}</b>
                    </template>
                </el-table-column>
                <el-table-column :label="''" class-name="text-center" width="60">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="Detalles" placement="top">
                            <el-button size="mini" type="text" @click="deliveryDetails = scope.row; showDeliveyDetails = true;" circle>
                                <i class="el-icon-view size-18"></i>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <el-col :lg="16" :xl="18" class="d-flex flex-column">
            <el-row :gutter="12">
                <el-col :lg="4" :xl="4" class="d-flex flex-column">
                    <label for="range" class="mb-1">{{ $t('delivery.filter_for_date') }}</label>
                    <el-date-picker v-model="filters.range" :default-time="['00:00:00', '23:59:59']" format="yyyy/MM/dd" value-format="yyyy-MM-dd"  type="daterange" range-separator="" start-placeholder="Start date" end-placeholder="End date" id="range" size="small" class="w-100"/>
                </el-col>
                <el-col :lg="4" :xl="3" class="d-flex flex-column">
                    <label for="search" class="mb-1">{{ $t('delivery.key_words') }}</label>
                    <el-input placeholder="Please input" v-model="filters.search" size="small" id="search"/>
                </el-col>
                <el-col :lg="4" :xl="6">
                    <app-selection @change="(data)=>{filters.seller = data}" title="tracking.filter_by_seller" :options="employeesOptions" key-value="id" id="seller" />
                </el-col>
                <el-col :lg="4" :xl="5" class="d-flex flex-column">
                    <app-selection @change="(data)=>{filters.branches = data}" title="delivery.filter_by_branch" :options="storesOptions" :default="filters.branches" key-value="codplace" id="branch" />
                </el-col>
                <el-col :lg="4" :xl="3" style="margin-top: -8px;">
                    <app-selection @change="(data)=>{filters.types = data}" title="tracking.filter_by_type" :options="typesOptions" key-value="id" id="types"  class="mt-2"/>
                </el-col>
                <!-- <el-col :lg="4" :xl="4" class="d-flex flex-column">
                    <app-selection @change="(data)=>{filters.document_type = data}" title="tracking.filter_by_type" :options="typesOptions" key-value="id" id="types" />
                </el-col> -->
                <el-col :lg="4" :xl="3" class="d-flex justify-content-end align-items-end h-100">
                    <el-popover placement="bottom" width="300" trigger="click">
                        <div class="d-flex flex-column">
                            <app-selection @change="(data)=>{filters.status = data}" title="delivery.filter_by_state" :options="statusOptions" key-value="id" id="status" :default="filters.status"/>
                            
                            <!-- <app-selection @change="(data)=>{filters.types = data}" title="tracking.filter_by_type" :options="typesOptions" key-value="id" id="types"  class="mt-2"/> -->
                            <!-- <app-selection @change="(data)=>{filters.types = data}" title="tracking.filter_by_type" :options="typesOptions" key-value="id" id="types" /> -->
                            <hr>
                            <el-checkbox v-model="filters.showBranches" class="mt-2" value="1" unchecked-value="0">
                                {{$t('delivery.branch')}}
                            </el-checkbox>
                            <hr>
                            <el-checkbox v-model="filters.showZones" class="mt-2" value="1" unchecked-value="0">
                                {{$t('delivery.show_available_areas')}}
                            </el-checkbox>
                            <hr>
                            <el-checkbox v-model="filters.has_incidences" class="mt-2" value="1" unchecked-value="0">
                                {{$t('delivery.only_tasks_with_incidences')}}
                            </el-checkbox>
                            <hr>
                            <el-radio-group v-model="filters.order_by">
                                <el-radio :label="'created_at'">Fecha de creacion</el-radio>
                                <el-radio :label="'date'">Fecha</el-radio>
                            </el-radio-group>
                            <hr>
                            <el-radio-group v-model="filters.order_by_type">
                                <el-radio :label="'desc'">Desc</el-radio>
                                <el-radio :label="'asc'">Asc</el-radio>
                            </el-radio-group>
                        </div>
                        <el-button slot="reference" size="small">More filters</el-button>
                    </el-popover>
                    <el-button size="small" type="warning" class="ms-2" @click="getTasks"> <i class="el-icon-refresh"></i> </el-button>
                </el-col>

                <!-- <el-col :lg="4" :xl="4" class="d-flex flex-column">
                    <app-selection @change="(data)=>{filters.status = data}" title="delivery.filter_by_state" :options="statusOptions" key-value="id" id="status" />
                    </el-col> -->
                </el-row>
                <!-- :deliveries="getDeliveriesByBranch" -->
                <assign-map :tasks="tasks" :filters="filters" :selectedBranches="filters.branches" :loading="loading" class="mt-2 h-100"/>
        </el-col>

        <task-details v-if="showDetails" @closeModal="showDetails = false"/>
        <delivery-details :show="showDeliveyDetails" :delivery="deliveryDetails" @close="showDeliveyDetails = false;"/>
    </b-row>
</template>

<script>
    import moment from "moment";
    import { mapGetters } from 'vuex';
    import AssignMap from './AssignMap.vue';
    import { required } from "vuelidate/lib/validators"
    import AppSelection from '../../../../../common/components/selects/AppSelection.vue';
    import TaskDetails from '../modals/TaskDetails.vue';
    import { db } from "@/firebase-config";
    import DeliveryDetails from '../../dialogs/DeliveryDetails.vue';

    export default {
    components: { AssignMap, AppSelection, TaskDetails, DeliveryDetails },
        name: 'AssignTasks',
        data(){
            return{
                loading: false,
                loadingDetails: false,
                showDetails: false,
                showAvailableAreas: false,
                tasks:[],
                employee_id: null,
                filters:{
                    search: '',
                    range: null,
                    types: [],
                    methods: [],
                    branches: [],
                    document_type: [],
                    seller: [],
                    status: [1],
                    showBranches: true,
                    showDeliveries: true,
                    showZones: true,
                    has_incidences: 0,
                },
                deliveries: [],
                mountedComponent: false,
                debounceTimer: null,
                showDeliveyDetails: false,
                deliveryDetails: null,
            }
        },
        props:{
            save: {
                type: Boolean
            },
            cancel: {
                type: Boolean
            }
        },
        computed:{
            ...mapGetters({
                employees: "delivery/employees",
                selectedTask:"delivery/selectTask",
                salesEmployee: "delivery/salesEmployee",
                stores: "ecommerce/stores",
                status: "delivery/status",
                taskType: "delivery/taskType",
                types: "documentsFollowup/types",
                user: "auth/user",


            }),
            employeesOptions(){
                return this.salesEmployee.map(e=>{
                    return {label: `[${e.entity.code}] ${e.entity.first_name}  ${e.entity.last_name}`, id: e.entity.code}
                });
            },
            storesOptions(){
                return this.stores.map(e=>{
                    return {label: `[${e.codplace}] ${e.description}`, codplace: e.codplace}
                });
            },
            statusOptions(){
                return this.status.map(e=>{
                    return {label: `[${e.id}] ${e.description}`, id: e.id}
                });
            },
            typesOptions(){
                return this.taskType.map(e=>{
                    return {label: `[${e.id}] ${e.description}`, id: e.id}
                });
            }
        },
        methods:{
            async getTasks(){

                // const branches = this.filters.branches.map(item => item.codplace)
                // const types = this.filters.types.map(item => item.id)
                // const methods = this.filters.methods.map(item => item.id)
                // const documentType = this.filters.document_type.map(item => item.id)
                // const entities = this.filters.seller.map(item => item.code)
                // const status = this.filters.status.map((item)=> item.id)

                try {
                    this.loading = true
                    // method_id: methods, 
                    let date = null;
                    if(this.filters.range)
                    date = [moment(this.filters.range[0]).format('YYYY-MM-DD'), moment(this.filters.range[1]).format('YYYY-MM-DD')]
                    const params = { 
                        limit: 100, 
                        sales_location_id: this.filters.branches, 
                        q: this.filters.search || null,  
                        date: date,
                        type_id: this.filters.types,
                        // document_type : this.filters.document_type,
                        document_entity_code: this.filters.seller,
                        status_id: this.filters.status,
                    }
                    const response = await this.$store.dispatch('delivery/getAllTasks', params)

                    this.tasks = response.data

                    this.getDeliveries();
                } catch (error) {
                    this.$notify.error({
                        title: 'Error',
                        message: `${error.response.data.message}`,
                        duration: 0
                    });
                }finally{
                    this.loading = false
                }
            },
            assignToDelivery(){
                const tasks = this.selectedTask.filter((task) => {
                    return task.selected == true
                }).map(task=>task.id);
                // let tasks = []
                // filterTasks.forEach(task => {
                //     tasks.push(task.id)
                // });
                if(tasks.length == 0){
                    this.$notify({
                        title: 'Advertencia',
                        message: 'Debe seleccionar al menos una tarea.',
                        duration: 5000,
                        type: "warning"
                    });
                    return
                }
                this.$v.employee_id.$touch()
                if (this.$v.employee_id.$error) {
                    this.$notify({
                        title: 'Advertencia',
                        message: 'Debe seleccionar el mensajero.',
                        duration: 5000,
                        type: "warning"
                    });
                    return
                }
                const params = {
                    employee_code: this.employee_id,
                    tasks: tasks
                }
                this.$swal.fire({
                    title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?", icon: "info", 
                    showCancelButton: true, confirmButtonText: 'Confirma',   cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            this.loading = true
                            await this.$store.dispatch('delivery/assingTasks', params)
                            await this.getTasks()
                            this.employee_id = null
                            this.$store.commit('delivery/RESET_SELECT_TASK')
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                        } catch (error) {
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: error.response.data.message, 
                                icon: "error",
                            })
                        }finally{
                            this.loading = false
                        }
                    }
                })
            },
            cancelAssign(){
                this.$swal.fire({ 
                    title: 'Confirmacion', text: "Realmente desea cancelar esta operacion?", 
                    icon: "warning", showCancelButton: true, confirmButtonText: 'Confirma', 
                    cancelButtonText: 'Cancelar',
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            this.$store.commit('delivery/RESET_SELECT_TASK')
                        } catch (error) {
                            console.log(error);
                            this.$swal.fire({ 
                                title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                                icon: "error",
                            })
                        }
                    }
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            async selectTask(id){
                try {
                    this.loadingDetails = true
                    await this.$store.dispatch('delivery/getTaskDetails', id)
                    this.showDetails = true
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loadingDetails = false;
                }
            },
            getDeliveries() {
                const deliveriesRef = db.ref(`deliveries`)
                deliveriesRef.once('value', (snapshot) => {
                    const deliveries = snapshot.val();
                    const deliveriesFromObj = Object.values(deliveries);
                    this.deliveries = deliveriesFromObj.filter(e=> e.name && e.lastName && e.coordinates);
                })  
            },
        },
        watch:{
            filters:{
                deep:true,
                immediate: true,
                async handler(val){
                    if(val.search){
                        clearTimeout(this.debounceTimer);
                        this.debounceTimer = setTimeout( async() => {
                            await this.getTasks()
                        }, 500);
                        return;
                    }
                    await this.getTasks()
                },
            },
            save(){
                this.assignToDelivery()
            },
            cancel(){
                this.cancelAssign()
            }
            
        },
        validations: {
            employee_id: {
                required,
            }
        },
        async created(){
            const dateMnsFive = moment(new Date).subtract(60, 'day');
            const date = new Date(dateMnsFive.toISOString());
            this.filters.range = [date, new Date()]
            await this.$store.dispatch('delivery/getTasks')
            const branch = this.user.entity?.employee?.point_of_sales_id;
            if(branch){
                this.filters.branches = [branch]
            }
            this.getDeliveries();
            // it is not worth it to put it here because all the data is not mounted yet
            // this.mountedComponent = true

            // setTimeout(async () => {
            // old solution, avoid this, wait for the data to finish loading
            //     this.mountedComponent = true
            //     await this.getTasks()
            // }, 2000);
        },
    }
</script>

<style scoped>

</style>
export default {
  SET_DOCUMENTS(state, docs){
    state.documents = docs
  },
  SET_DOCUMENT_DETAILS(state, doc){
    state.documentDetails = doc
  },
  SET_SLA(state, values){
    state.slas = values
  },
  SET_ACTIONS(state, values){
    state.actions = values
  },
  SET_TYPES(state, values){
    state.types = values
  },
  SET_DOCUMENT_SUMMARY(state, payload){
    state.documentSummary = payload
  },
  SET_DOCUMENT_CHARTS(state, payload){
    state.documentChart = payload
  },
  VERIFY_IS_IFRAME(state, payload){
    state.iframe = payload
  }
};

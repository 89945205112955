<script>
import IconCustomers from '@/common/components/svg/IconCustomers.vue';
import IconDeliveryPrice from '@/common/components/svg/IconDeliveryPrice.vue';
import IconHome from '@/common/components/svg/IconHome.vue';
import IconOrder from '@/common/components/svg/IconOrder.vue';
import IconRouter from '@/common/components/svg/IconRouter.vue';
import IconSettings from '@/common/components/svg/IconSettings.vue';
import IconRequestCode from '@/common/components/svg/IconRequestCode.vue';
import IconProducts from '@/common/components/svg/IconProducts.vue';
import IconQuestion from '@/common/components/svg/IconQuestion.vue';
import IconAssingTask from '@/common/components/svg/IconAssingTask.vue';
import IconCreateTask from '@/common/components/svg/IconCreateTask.vue';
import IconTrack from '@/common/components/svg/IconTrack.vue';
import IconMap from './svg/IconMap.vue';
import { mapGetters } from 'vuex';
/**
 * Topbar component
 */
export default {
  components: { IconOrder, IconHome, IconCustomers, IconRouter, IconDeliveryPrice, IconSettings, IconRequestCode, IconProducts, IconQuestion, IconAssingTask, IconCreateTask, IconTrack, IconMap },
  data() {
    return {
      search :'',
      routers: [],
      styles: [
          {id:'default',value: 'mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus', text: 'Default', color: 'default'},
          {id:'dark',value: 'mapbox://styles/mapbox/dark-v10', text: 'Dark', color: 'dark'},
          {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'Light', color: 'light'},
          // {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'}
      ],
    };
  },
  computed:{
    ...mapGetters({
      mapStyle: 'layout/mapStyle',
      isMap: 'layout/isMap'
    }),
  },
  methods: {
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    logout(){
      this.$store.dispatch('auth/logout')
      this.$router.replace({path: '/login'})
    },
    goTo(router){
      if(this.$route.path != router.path)
      this.$router.replace({path: router.path})
    },
    setStyle(style){
      this.$store.commit('layout/SET_MAP_STYLE', style)
    },
    focusInSearch(){
      setTimeout(() => { 
        let input = document.getElementById("input-search")
        input.focus() 
      }, 10);
    },
    async changeLanguage(lang){
        setImmediate(() => { this.$i18n.locale = lang});
        localStorage.setItem("lang", lang)
    }
  },
  watch:{
    search(val){
      const routes = this.$router.getRoutes();
      const result = routes.filter((route) => route.name?.toLowerCase().includes(val.toLowerCase()))
      this.routers = result
    }
  },
  mounted(){
    let btn = document.querySelector(".b-dropdown.search button");
    document.addEventListener('keydown', (event) => {
      let codeValue = event.code;
      if(codeValue == "F10"){
        btn.click()
        setTimeout(() => { 
          let input = document.getElementById("input-search")
          input.focus() 
        }, 10);
      }
    }, false);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', () => {})
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/common/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/common/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/common/assets/images/logo-sm.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/common/assets/images/logo-light.png" alt height="18" />
            </span>
          </router-link>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-24 header-item" id="vertical-menu-btn" @click="toggleMenu()" >
          <i class="mdi mdi-menu"></i>
        </button>

        <div class="d-none d-sm-block">
          <b-dropdown variant="white" class="pt-3 d-inline-block">
            <template v-slot:button-content>
              Create
              <i class="mdi mdi-chevron-down"></i>
            </template>
            <b-dropdown-item>Action</b-dropdown-item>
            <b-dropdown-item>Another action</b-dropdown-item>
            <b-dropdown-item>Something else here</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>Separated link</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown variant="white" class="d-inline-block search" toggle-class="header-item noti-icon" menu-class="dropdown-menu-lg dropdown-menu-end">
          <template v-slot:button-content>
            <i class="mdi mdi-magnify" @click="focusInSearch"></i> 
          </template>
          <!-- <form class="p-3"> -->
            <div class="form-group m-0 p-3">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." autocomplete="off" v-model="search" aria-label="Recipient's username" id="input-search"/>
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit" id="btn-search" style="border-top-left-radius: 0;border-bottom-left-radius: 0;">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          <!-- </form> -->
          <b-dropdown-item v-for="(router, index) in routers" :key="index" @click="goTo(router)" v-show="router.meta.search">
            <div class="d-flex justify-content-between align-items-center">
              <span style="width: 150px;">{{router.name | textFormat}}</span> <b v-if="router.parent">{{router.parent.name | textFormat}}</b> <component :is="router.meta.icon"></component>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <!-- App Search-->

        <b-dropdown class="d-none d-md-block ms-2" toggle-class="header-item" menu-class="dropdown-menu-end" right variant="white">
          <template v-slot:button-content>
            <img class="me-2" :src="$i18n.locale == 'es' ? require('@/common/assets/images/flags/spain_flag.jpg') :require('@/common/assets/images/flags/us_flag.jpg')" alt="Header Language" height="16"/>
              {{$i18n.locale == 'es' ? 'Español' :'English'}}
            <span class="mdi mdi-chevron-down"></span>
          </template>
          <!-- item-->
          <a href="#" @click.prevent="changeLanguage(lang = 'es')" class="dropdown-item notify-item">
            <img src="@/common/assets/images/flags/spain_flag.jpg" alt="user-image" class="me-1" height="12"/>
            <span class="align-middle">Spanish</span>
          </a>
          <!-- item-->
          <a href="#" @click.prevent="changeLanguage(lang = 'en')" class="dropdown-item notify-item">
            <img src="@/common/assets/images/flags/us_flag.jpg" alt="user-image" class="me-1" height="12"/>
            <span class="align-middle">English</span>
          </a>
        </b-dropdown>
        <b-dropdown class="d-none d-md-block ms-2" toggle-class="header-item" menu-class="dropdown-menu-end" right variant="white" v-if="isMap">
          <template v-slot:button-content>
            <icon-map :class="mapStyle.color"/>
              {{mapStyle.text}}
            <span class="mdi mdi-chevron-down"></span>
          </template>
          <!-- item-->
          <a href="#" @click.prevent="setStyle(style)" class="dropdown-item notify-item" v-for="style in styles" :key="style.value">
            <icon-map :class="style.color" class="me-2"/>
            <span class="align-middle">
              {{style.text}}
            </span>
          </a>
        </b-dropdown>
        <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div>
        <b-dropdown class="d-inline-block" toggle-class="header-item noti-icon" menu-class="dropdown-menu-lg p-0 dropdown-menu-end" right variant="white">
          <template v-slot:button-content>
            <i class="mdi mdi-bell-outline"></i>
            <span class="badge bg-danger rounded-pill">3</span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">Notifications (258)</h5>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px;">
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-success rounded-circle font-size-16">
                      <i class="mdi mdi-cart-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-warning rounded-circle font-size-16" >
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">New Message received</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-info rounded-circle font-size-16" >
                      <i class="mdi mdi-glass-cocktail"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">Your item is shipped</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      It is a long established fact that a reader will
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="mdi mdi-cart-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">Your order is placed</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <span class="avatar-title bg-danger rounded-circle font-size-16">
                      <i class="mdi mdi-message-text-outline"></i>
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mt-0 mb-1">New Message received</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="p-2 border-top">
            <div class="d-grid">
              <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                View all
              </a>
            </div>
          </div>
        </b-dropdown>

        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <img class="rounded-circle header-profile-user" :src="require('../../../common/assets/images/mundo-movil/logo-sm.png')" alt="Header Avatar"/>
          </template>
          <b-dropdown-item>
            <i class="mdi mdi-account-circle font-size-17 align-middle me-1"></i>
            Profile
          </b-dropdown-item>
          <b-dropdown-item>
            <i class="mdi mdi-wallet font-size-17 align-middle me-1"></i> My
            Wallet
          </b-dropdown-item>
         <a class="dropdown-item d-block" href="#"><span class="badge bg-success float-end">11</span><i class="mdi mdi-cog font-size-17 align-middle me-1"></i> Settings</a>
          <b-dropdown-item>
            <i class="mdi mdi-lock-open-outline font-size-17 align-middle me-1"></i>
            Lock screen
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="#" @click.prevent="logout">
            <i class="bx bx-power-off font-size-17 align-middle me-1 text-danger" ></i>
            Logout
          </a>
        </b-dropdown>

        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle toggle-right" @click="toggleRightSidebar">
            <i class="mdi mdi-cog-outline toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
  .search.b-dropdown::v-deep > ul{
    width: 400px;
  }
</style>

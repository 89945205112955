<template>
    <layout>
        <div v-if="editLocationMode" class="my-2">
            <button type="submit" @click="changeLimitationState" class="btn me-3" :class="{'btn-danger': editLimitation, 'btn-success': !editLimitation }">
                    {{ btnLabel1 }}
            </button>
            <button type="submit" @click="changeMarketState" class="btn" :class="{'btn-danger': editMarker, 'btn-info': !editMarker }">
                {{ btnLabel2 }}
            </button>
        </div>
        <MglMap style="height: 500px" :zoom="mapOptions.defaultZoom" ref="map" :dragRotate="false" :accessToken="mapOptions.accessToken" :center="mapOptions.defaultCoordinates" :fadeDuration="300" @load="onMapLoaded" :mapStyle="mapStyle()">
            <template v-if="!editLocationMode">
                <MglMarker v-for="store in stores" :key="store.codplace"  :coordinates="[store.longitude, store.latitude]">
                    <MglPopup>
                        <div>{{ store.description }}</div>
                    </MglPopup>
                </MglMarker>
            </template>
            <MglMarker v-if="editLocationMode" :coordinates="mapOptions.defaultMarker" @dragend="changeMarket"  :draggable="editMarker"/>
            <MglNavigationControl position="top-right"/>
        </MglMap>
        <div id="calculated-area" class="d-flex align-items-center mt-2">
            <strong>{{roundedArea}}</strong>
            <span class="ms-3">{{$t('delivery.square_kilometers')}}</span>
        </div>
        <store-form @cancel="cancel" @update="update" v-if="editLocationMode" class="mt-3" :descripcion="storeSelected.description" :direccion="storeSelected.address"/>
        <b-table sticky-header thead-class="green-bg bg-primary text-white" class="mt-4" hover thAttr :items="stores"  selectable select-mode="single" @row-selected="onRowSelected" ref="selectableTable">
            <template #cell(actions)="row">
                <b-button @click.prevent="editStore(row.item)" variant="primary">{{$t('lang.ecommerce.limitation.EDIT')}}</b-button>
            </template>
        </b-table>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import { MglMap, MglMarker,  MglNavigationControl, MglPopup} from 'v-mapbox'
import StoreForm from '../components/stores/StoreForm.vue';
import { limitionsMixin } from '../helpers/mixins/limitions-mixin'

export default {
    name: "Limitions",
    components:{
        Layout,
        MglMap,
        MglMarker,
        MglNavigationControl,
        MglPopup,
        StoreForm,
    },
    mixins: [limitionsMixin],
    data() {
        return {
            mapOptions: {
                accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
                mapStyle: 'mapbox://styles/mundomovil28/ckgwp0y0g06r219pb8oqihafv', // your map style
                defaultZoom: 7,
                defaultCoordinates: [-70.7260555, 19.0781721],
                defaultMarker: [-70.7260555, 19.0781721],
            },
            styleSelected: "Mapa",
            mapStyles: {
                Mapa: "ckgwp0y0g06r219pb8oqihafv",
                Satellite: "ckgwp7pg4058g19qk1bv044er",
            },
            editLocationMode: false,
            stores: [],
            editMarker: false,
            limits: [],
            btnLabel1: 'Mostral control de limitaciones',
            btnLabel2: 'Activar mover marca',
            editLimitation: false,
            storeSelected: {},
            roundedArea: 0
        }
    },
    async created(){
        await this.getStores()
    }
}
</script>

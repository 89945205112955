
export default () => ({
    documentDetails: [],
    documents: [],
    slas: [],
    actions: [],
    types: [],
    documentSummary: [],
    documentChart: {
      sla_by_branch: [],
      avg_time_elapsed_documents_by_branch: [],
      sla_by_actions_avg_by_branch: []
    },
    iframe: false
});


import { http } from "@/common/services/web-backend.js";
export class RequestService {

  async getRequests(filters){
    try{
      const response = await http.get('ecommerce/admin/requests'+"?page="+filters.page, {
        params: {
          states: filters.states,
          name: filters.name,
          range: filters.range,
          cities: filters.cities
        }
      });
      return response.data;
    }catch (e) {
        throw e
    }
  }
  async approvedRequest(params){
    try{
      const response = await http.put('ecommerce/admin/requests/'+params.id+'/status', {
          status_id: 2,
          code: params.code
      });
      return response.data;
    }catch (e) {
        throw e
    }
  }
  async deniedRequest(params){
    try{
      const response = await http.put('ecommerce/admin/requests/'+params.id+'/status', {
          status_id: 3,
          code: params.code
      });
      return response.data;
    }catch (e) {
        throw e
    }
  }
}

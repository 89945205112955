<template>
    <main-modal @closeModal="closeModal">
        <el-row>
            <el-col :span="24">
                <h1 class="title">{{  $t("ecommerce.create_limit_type") }}</h1>
                <el-form ref="form" :model="form">
                    <el-form-item :label="$t('ecommerce.name')">
                        <el-input v-model="form.description"></el-input>
                    </el-form-item>

                    <el-row type="flex" justify="center">
                        <el-button type="primary" @click="onSubmit">{{ $t("ecommerce.create") }}</el-button>
                        <el-button @click="closeModal">{{ $t('ecommerce.close') }}</el-button>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </main-modal>
</template>

<script>
import MainModal from '@/common/components/modals/MainModal.vue';
import { http } from '@/common/services/node-service';

export default {
    name: "CreateLimitTypeModal",
    components: { MainModal },
    data() {
        return {
            form: {
                description: ""
            }
        }
    },
    methods: {
        resetForm() {
            this.form.description = "";
        },
        closeModal() {
            this.$emit('closeModal');
        },
        async onSubmit() {
            try {
                if (!this.form.description) {
                    throw new Error(this.$t("ecommerce.limit_name_cannot_be_empty"))
                }

                const response = await http.post("limits/types", {
                    description: this.form.description
                });

                if (!response) {
                    throw new Error(this.$t("ecommerce.invalid_backend_response"));
                }

                this.$swal.fire({
                    title: this.$t('ecommerce.limit_type_created_succesfully'),
                    icon: "success",
                });

                this.resetForm();
            } catch(error) {
                let errorMessage = error?.response?.data?.message ?? error;

                this.$swal.fire({ 
                    title: this.$t(`ecommerce.something_went_wrong`),
                    text: errorMessage, 
                    icon: "error",
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .title {
        font-size: 22px;
    }
</style>
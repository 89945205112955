<template>
  <div style="min-height: 600px" class="card">
    <vue-element-loading :active="isLoading" :is-full-screen="true"/>
    <div class="card-header header-elements-inline d-flex justify-content-between">
      {{ $t('ecommerce.well_written_words') }}
      <!-- <b-form-checkbox id="checkbox-1" v-model="isAuto" name="checkbox-1" value="true" unchecked-value="false"> -->
      <!-- <span style="margin-left: 5px"> Auto generar plurar</span> -->
    <!-- </b-form-checkbox> -->
    </div>
    <div class="card-body">
      <div class="input-group">
        <label for="inputWord"></label>
        <input id="inputWord" v-model.trim="word" @keydown.enter="addWord" type="text" class="form-control" >
        <span class="input-group-append">
                <button class="btn btn-success" style="border-radius: 0;" @click="addWord" type="button">{{ btnText }}</button>
              </span>
      </div>
      <div style="margin-top: 30px" class="card card-body border-top-warning">
        <div style="margin: 0;" class="list-feed" >
          <transition-group name="fade">
            <div :key="correct_word._id"  v-for="(correct_word, index) in words" class="list-feed-item" :class="[index > 0 ? 'TopMargin' : '']">
              {{ correct_word.word  }}
              <i class="fas fa-check-circle" style="float: right;     
              color: #4CAF50;
              float: right;
              font-size: 20px; cursor: pointer;" @click="$emit('wordSelected', correct_word)"></i>
              <i class="fas fa-trash-alt" @click="deleteWord(correct_word._id, index);" style="float: right;font-size: 20px;
              color: #c62828;
              margin-right: 14px;cursor: pointer;"></i>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import {node} from "../../http/node";
  import Swal from "sweetalert2";
  import { DictionaryService } from "../../service/dictionary-service";
  const service = new DictionaryService();
  export default {
    name: "correctWords",
    data(){
      return{
        word: '',
        words: [],
        btnText: this.$t('ecommerce.adding'),
        lastLetter: '',
        pluralLetter: '',
        isAuto: 'false',
        pagination: {
          page: 1,
          pages: 0,
        },
        isLoading: false,
      }
    },
    created(){
      this.getWords()
    },
    components:{
    },
    methods: {
      async addWord() {
        try {
          if (this.word.trim().length === 0) {
            return
          }
          this.btnText = this.$t('lang.ecommerce.dictionary.ADDING') + ' ...';
          await service.store({word: this.word});
          await this.getWords()
          this.word = "";
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Advertencia',
            text: e.message,
          })
        }finally {
          this.btnText = 'Agregar';
        }
      },
      async addBadWord(data){
        try{
          if (this.word.trim().length === 0){
            return
          }
          for (let i = 0; i < data.word.length; i++) {
            // console.log(data.word[data.word.length - 1])
            this.lastLetter = data.word[data.word.length - 1]
          }
          if(this.isAuto === 'true'){
            if(this.lastLetter != 'z' && this.lastLetter != 'x' && this.lastLetter != 's' && this.lastLetter != 'a' && this.lastLetter != 'e' && this.lastLetter != 'i' && this.lastLetter != 'o' && this.lastLetter != 'u'){
              this.pluralLetter = 'es'
            }else if(this.lastLetter === 'z' && this.lastLetter != 'x' && this.lastLetter != 's' && this.lastLetter != 'a' && this.lastLetter != 'e' && this.lastLetter != 'i' && this.lastLetter != 'o' && this.lastLetter != 'u'){
              this.pluralLetter = 'ces'
              data.word = data.word.slice(0, -1)
            }else{
              this.pluralLetter = 's'
            }
            await service.storeBadWord({ word: data.word + this.pluralLetter, _id: data._id });
            data.badWords.push(this.word);
          }
          
          this.getWords()
          this.data= "";
          this.pluralLetter = '';
        }catch (e) {
          Swal.fire({
            title: 'Error',
            text: e.message,
            icon: "error",
          })
        }
      },
      async getWords(){
        try {
          this.isLoading = true;
          const data = await service.index(this.pagination.page);
          this.pagination.pages =  data.totalPages;
          this.words = data.docs
        }catch (e) {
          console.error(e)
        }finally{
          this.isLoading = false;
        }
      },
      async deleteWord(_id){
      const t = this;

      Swal.fire({
        title: this.$t('ecommerce.warning'),
        text: this.$t('ecommerce.are_you_sure'),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: this.$t('ecommerce.confirm'),
        cancelButtonText: this.$t('ecommerce.cancel'),
      }).then(async (result) => {
        const { isConfirmed } = await result;
        if (isConfirmed) {
          try {
            await service.destroy(_id);
            this.words = await this.words.filter(element => { return element._id !== _id })
            this.$emit('wordSelectedNull')
            await t.post();
            t.$bvToast.toast(t.$t("TOAST.SAVE_DATA"), {
              title: "Success",
              variant: "success",
              toaster: "b-toaster-bottom-right",
              solid: true,
            });
            await Swal.fire({
              icon: "success",
              title: t.$t("lang.SWAL_ALERT.TRANSACTION.SUCCESFUL"),
              showConfirmButton: false,
              timer: 1500,
            });
          } catch (e) {
            t.$bvToast.toast(`${e.response.data.message}`, {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-bottom-right",
              solid: true,
            });

            await Swal.fire({
              icon: "error",
              title: t.$t("lang.SWAL_ALERT.TRANSACTION.FAILED"),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
      }
    }
  }
</script>

<style scoped>
  .TopMargin{
    margin-top:15px
  }
</style>

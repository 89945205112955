<template>
        <el-row :gutter="20">
            <el-col :lg="9" :xl="9">
                <el-card>
                    <div slot="header" class="clearfix">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="mb-0 me-2">Bank Accounts</h5>
                                <el-tag type="success">{{ 'Banks' }}</el-tag>
                            </div>
                            <el-popover placement="bottom" trigger="click">
                                <div>
                                    <label for="new">Add new account</label>
                                    <div class="d-flex align-items-center">
                                            <el-input size="small" placeholder="Name bank"  style="max-width: 200px" class="me-2" id="new" v-model="nameAccount"/>
                                            <el-input size="small" placeholder="Number Account" style="max-width: 200px" class="me-2" id="new" v-model="numberAccount"/>
                                            <el-input size="small" placeholder="enter url img" style="max-width: 200px" class="me-2" id="new" v-model="imgUrl"/>
                                    </div>
                                    <br>
                                    <div class="d-flex justify-content-end">
                                        <el-button size="small" @click="saveInput">Save</el-button>
                                    </div>
                                </div>
                                <el-button slot="reference" type="text">Add</el-button>
                            </el-popover>
                        </div>
                        <!-- <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog> -->
                    </div>
                    <el-table stripe :data="bank" style="width: 100%"  size="small" height="310" row-class-name="text-capitalize"
                    :header-cell-style="{'font-weight': 500}">
                        <el-table-column label="id" prop="id" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="img" prop="img" align="center">
                            <template slot-scope="scope">
                                <el-image
                                    style="width: 60px; height: 60px"
                                    :src="scope.row.img"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="name" width="120" prop="name" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="account" prop="account" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.account }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column  align="center" fixed="right" label="Action" width="120">
                            <template slot-scope="scope">
                                <el-button @click.prevent="deleteRow(scope.row.id)"
                                type="text"
                                size="small">
                                Remove
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <b-form-group id="enter-message-rnc-id" :label="'RNC & Bank account in the name of'" label-for="enter-message-rnc" class="size-input-grup ms-2 my-2 my-md-4">
                            <b-form-textarea class="custom-texarea" id="textarea" v-model="settings.web_page.rnc" placeholder="Enter variable" rows="6">
                            </b-form-textarea>
                    </b-form-group>
                </el-card>
            </el-col>
        </el-row>
</template>

<script>

// import Layout from "@/modules/template/layouts/main";

export default {
    name: 'bankAccount',
    props: {
        settings:{
            type: Object
        }
    },
    data(){
        return {
            value: '',
            nameAccount: null,
            numberAccount: null,
            imgUrl: null,
            bank: []
        }
    },
    components:{
        // Layout
    },
    computed:{

    },
    methods: {
        saveInput(){
            const name = this.nameAccount
            const number = this.numberAccount
            const convertObject = {
                id: this.bank.length + 1,
                name: name, 
                account: number,
                img: this.imgUrl
            }
            this.bank.push(convertObject)
            this.settings.web_page.bank_accounts = this.bank
        },
        deleteRow(id){
            const selectedId = id
            const bankFilter = this.bank.filter(item => item.id !== selectedId);
            this.bank = bankFilter
            this.settings.web_page.bank_accounts = this.bank
        },
    },
    created(){
        // console.log(this.settings.web_page.bank_accounts);
        this.bank = this.settings.web_page.bank_accounts
    }
}
</script>

<style scoped >

.custom-texarea{
    height: 59px;
    width: 420px;
}
    /* .el-upload--picture-card {
        width: 140px !important;
        height: 35px !important;
        line-height: 46px !important;
    } */
</style>
<template>
    <main-modal @closeModal="$emit('closeModal')">
    <b-tabs v-model="tabIndex" card>
        <b-tab title="Detalles">
            <MglMap style="height: 500px;" ref="map" :center="[taskDetails.longitude, taskDetails.latitude]" :accessToken="accessToken" :zoom="10" :dragRotate="false"  
                @load="onMapLoaded" :mapStyle="mapStyle" v-if="renderMap">
                    <MglGeolocateControl :trackUserLocation="true" position="top-right" />
                    <MglMarker :coordinates="[taskDetails.longitude, taskDetails.latitude]" color="#BC2C35"/>
                    <MglMarker :coordinates="[taskDetails.sales_location.longitude, taskDetails.sales_location.latitude]" color="#BC2C35">
                        <div slot="marker" class="icon-store">
                            <icon-store :size="25" :color="'#BC2C35'"/>
                        </div>
                    </MglMarker>
                    <MglMarker :coordinates="[deliveryData.tookan.longitude, deliveryData.tookan.latitude]" color="#BC2C35" v-if="deliveryData.tookan">
                        <div slot="marker">
                        <!-- class="icon-store" -->
                            <!-- <icon-motorcycle :size="25" :color="'#626ed4'"/> -->
                            <img src="../../../assets/images/icon-motorcycle.png" alt="con-motorcycle" width="30">
                        </div>
                        <mgl-popup>
                            <div class="d-flex align-items-center">
                                <icon-check-circle :color="'#02a499'" class="me-2" v-if="deliveryData.tookan.is_available"/>
                                <icon-close-circle :color="'#ec4561'" class="me-2" v-else/>
                                <h4 class="text-capitalize text-limited mb-0">{{deliveryData.entity.first_name | textFormat}} {{deliveryData.entity.last_name | textFormat}}</h4>
                            </div> 
                        </mgl-popup>
                    </MglMarker>
                    <MglNavigationControl position="top-right"/>
                    <div class="icon-map mapboxgl-ctrl mapboxgl-ctrl-group cursor-pointer" @click="deliveryLocation" v-if="deliveryData.tookan">
                        <img src="../../../assets/images/icon-motorcycle.png" alt="con-motorcycle" width="25">
                    </div>
                </MglMap>
        </b-tab>
        <b-tab title="Timeline">
            <h4 class="mb-4">Seguimiento de documento</h4>
            <time-line :status="taskDetails.document.states_available" :statusOrder="taskDetails.document.actions_log" :type="taskDetails.document.type_id" class="mb-3"/>
            <h4 class="mb-4">Seguimiento de tarea</h4>
            <time-line-task :task="taskDetails"/>
        </b-tab>
        <b-tab title="Imagenes">
            <swiper :options="swiperOption" style="height: 500px;border-radius:10px;" :key="images.length+tabIndex">
                <swiper-slide :key="index" v-for="({path_s3}, index) in images">
                    <img v-lazy="path_s3" width="100%" height="100%" style="object-fit: contain;">
                </swiper-slide>
                <div class="swiper-button-prev mr-5" slot="button-prev">
                    <icon-arrow :size="30" style="margin-right: -12px; transform: rotate(180deg);" :color="'var(--gray-1001)'"/>
                </div>
                <div class="swiper-button-next" slot="button-next">
                    <icon-arrow :size="30" style="margin-left: -12px" :color="'var(--gray-1001)'"/>
                </div>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </b-tab>
        <b-tab title="Task fields">
            <h4 v-if="!taskDetails.fields" class="text-center">{{ $t("delivery.this_task_does_not_have_fields_yet") }}</h4>
            <dynamic-form-renderer :fields="taskDetails.fields" />
        </b-tab>
        <b-tab :title="$t('delivery.incidents')">
            <incident-table :items="taskIncidents" @selectIncident="selectIncident" :showTaskDetailButton="false"></incident-table>
            <incident-details @closeModal="showIncidentDetailModal = false" v-if="showIncidentDetailModal" />
        </b-tab>
    </b-tabs>
    <vue-element-loading :active="loading" :is-full-screen="false"/>
    </main-modal>
</template>

<script>
    import MainModal from '../../../../../common/components/modals/MainModal.vue'
    import { MglMap, MglMarker, MglGeolocateControl, MglNavigationControl, MglPopup } from 'v-mapbox'
    import Mapbox from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
    import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
    import IconStore from '../../../assets/svg/IconStore.vue';
    import { addressesMixin } from '../../../../ecommerce/helpers/mixins/addresses-mixin';
    
    import IconArrow from '../../../../../common/components/svg/IconArrow.vue';
    
    import { CustomerService } from "../../../../ecommerce/service/customer-service";
    const customerService = new CustomerService()
    import { http } from "@/common/services/web-backend.js";
    import { http as node} from "@/common/services/node-service.js";
    import { mapGetters } from 'vuex';
    import TimeLineTask from '../../TimeLineTask.vue';
    import IconCheckCircle from '../../../../../common/components/svg/IconCheckCircle.vue';
    import IconCloseCircle from '../../../../../common/components/svg/iconCloseCircle.vue';
    import TimeLine from '../../../../documents-followup/components/TimeLine.vue';
    import DynamicFormRenderer from '../DynamicFormRenderer.vue';
    import IncidentTable from '../IncidentTable.vue';
    import IncidentDetails from './IncidentDetails.vue';

    export default{
        components: { MainModal, MglMap, MglMarker, MglGeolocateControl, MglNavigationControl, IconStore, IconArrow, MglPopup, TimeLineTask, IconCheckCircle, IconCloseCircle, TimeLine, DynamicFormRenderer, IncidentTable, IncidentDetails },
        name: "AddressDetails",
        props: {
            // the default tab index for the b-tabs component
            taxIndexProp: {
                type: Number,
                default: 0
            }
        },
        data(){
            return{
                accessToken: 'pk.eyJ1IjoicmVwZG9tIiwiYSI6ImNrMTV6cGN4bTEwbTQzaHJzM3FxYXl3aXgifQ.Vue3ItLfwfA6xmA20Xd-tA', // your access token. Needed if you using Mapbox maps
                // mapStyle: 'mapbox://styles/repdom/ck1to2pew6n411cqrc1fd8cga', // your map style
                mapStyle: 'mapbox://styles/mapbox/light-v10', // your map style
                layers: [],
                images: [],
                entity: {},
                swiperOption: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true
                    }
                },
                renderMap: 0,
                loading: false,
                deliveryData: {},
                delivery: {},
                tabIndex: this.taxIndexProp,
                showIncidentDetailModal: false
            } 
        },
        computed:{
            ...mapGetters({
                taskDetails: "delivery/taskDetails",
                taskIncidents: "delivery/singleTaskIncidents"
            }),
            sources() {
                let sources = [];
                for (let layer of this.layers){
                    sources.push({ type: 'geojson', data: layer })
                }
                return sources;
            },  
        },
        mixins:[addressesMixin],
        methods:{
            async onMapLoaded(event){
                // await this.getLimits(this.taskDetails.sales_location_id)
                await this.getRoute()
                this.mapbox = Mapbox;
                this.sources.forEach((source, id)=>{
                    event.map.addSource('limit-areas-'+id, source);
                    event.map.addLayer(
                        { 
                            id: 'limit-areas-fill-'+id, type: 'fill', source: 'limit-areas-'+id,
                            layout: {},
                            paint: { 'fill-color': '#5A8CDB', 'fill-opacity': 0.3}
                        },
                    );

                })
                event.map.on('style.load', async () => {
                    this.sources.forEach((source, id)=>{
                    event.map.addSource('limit-areas-'+id, source);
                    event.map.addLayer(
                        {
                            id: 'limit-areas-fill-'+id, type: 'fill', source: 'limit-areas-'+id, layout: {},
                            paint: { 'fill-color': '#c40316', 'fill-opacity': 0.5 }
                        },
                    );

                    })
                });
                const animationOptions = {
                    animate: true, duration: 4000, essential: true
                }
                setTimeout(() => {
                        event.map.zoomTo(14, animationOptions)
                }, 2000);
                this.addRoute(this.delivery.coords)
            },
            addRoute(coords){
                if (this.$refs.map.map.getSource('route')) {
                    this.$refs.map.map.removeLayer('route');
                    this.$refs.map.map.removeSource('route')
                } else{
                    this.$refs.map.map.addLayer({
                        id: "route",
                        type: "line",
                        source: {
                            type: "geojson",
                            data: {
                                type: "Feature", properties: {}, geometry: coords
                            }
                        },
                        layout: { "line-join": "round", "line-cap": "round"},
                        paint: { "line-color": "#343a40", "line-width": 7, "line-opacity": 0.7 }
                    });
                }
            },
            async getImages(){
                try {
                    this.loading = true
                    const response = await customerService.getImages(this.taskDetails.customer_code)
                    this.images = response.data
                } catch (error) {
                    console.log(error);
                }finally{
                    this.loading = false
                }
            },
            deliveryLocation(){
                this.$refs.map.map.flyTo({ 
                    center: [this.deliveryData.tookan.longitude, this.deliveryData.tookan.latitude], zoom: 16, bearing: 0, speed: 2, // make the flying slow 
                    curve: 1, // change the speed at which it zooms out 
                    easing: function(t) { return t; }, essential: true
                });
            },
            async getDelivery(){
                try {
                    const response = await http.get(`employee/deliveries/${this.taskDetails.employee_code}`);
                    this.deliveryData = response.data.data
                } catch (e) {
                    console.log(e)
                }
            },
            async getRoute(){
                const response = await node.post("stores/limit/verify", {
                    address:[{
                        latitud: this.taskDetails.latitude,
                        longitud: this.taskDetails.longitude,
                    }],
                    branch_codes: [this.taskDetails.sales_location_id]
                });
                if(response.data.data[0].verify.length > 0){
                    this.delivery = response.data.data[0].verify[0].limits
                }
            },
            async selectIncident(item) {
                try {
                    this.showIncidentDetailModal = true;
                    this.$store.commit("delivery/SET_INCIDENT_DETAILS", item);
                } catch (error) {
                    console.log(error);
                }
            },
        },
        watch: {
            tabIndexProp(newValue) {
                this.tabIndex = newValue;
            }
        },
        async created(){
            if(this.taskDetails.customer_code)
            await this.getImages()
            this.renderMap += 1
            if(this.taskDetails.employee_code)
            await this.getDelivery()
        },
    }
</script>

<style scoped>
    .icon-store{
        background-color: #fff;
        border-radius: 50%;
        padding: 5px;
    }
    .swiper-container::v-deep .swiper-button-prev::after, .swiper-container::v-deep .swiper-button-next::after{
        content: none;
    }
    .swiper-container::v-deep > .swiper-wrapper{
        align-items: center;
    }
    .modal-mask::v-deep > .modal-container {
        min-width: 1000px;
    }
    .icon-map{
        position: absolute;
        right: 10px;
        top: 35%;
        padding: 3px;
    }
</style>

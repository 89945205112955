const Module = () => import("./Module.vue");
const Dashboard = () => import("./view/Dashboard.vue");

// import i18n from "../../i18n";


const localData = JSON.parse(localStorage.getItem('userAcess'));
const modules = localData?.modules?.find(data => data.name == 'App')

const visitDashboard = modules?.subModules?.find(data => data.name === 'Dashboard')

const moduleRoute = {
    path: "/visit",
    component: Module,
    name: "Visit",
    children: [
        {
            path: "dashboard",
            name: "Visit-Controller",
            component: Dashboard,
            meta: {
                requiresAuth: true, 
                active: visitDashboard?.active
            },
        },
    ],
};

export default (router) => {
    router.addRoutes([moduleRoute]);
};

const Module = () => import("./Module.vue");
const Orders = () => import("./view/Orders.vue");
const Customers = () => import("./view/Customers.vue");
const Limitions = () => import("./view/Limitions.vue");
const Limitions1 = () => import("./view/Limitions1.vue");
const DeliveryPrice = () => import("./view/DeliveryPrice.vue");
const Settings = () => import("./view/Settings.vue");
const Requests = () => import("./view/Requests.vue");
const Invoice = () => import("./components/orders/Invoice.vue");
const Products = () => import("./view/Products.vue");
const Questions = () => import("./view/Questions.vue");
const Reviews = () => import("./view/Reviews.vue");
const Dictionary = () => import("./view/Dictionary.vue");
const CognitoEmailVerification = () => import("./view/CognitoEmailVerification.vue");

import i18n from "../../i18n";

const localData = JSON.parse(localStorage.getItem('userAcess'));
const modules = localData?.modules?.find(data => data.name == 'Ecommerce')



const ordenes = modules?.subModules?.find(data => data.name === 'Ordenes')
const customers = modules?.subModules?.find(data => data.name === 'Clientes')
const limitions = modules?.subModules?.find(data => data.name === 'Limitaciones')
const deliveryPrice = modules?.subModules?.find(data => data.name === 'Precio de envio')
const setting = modules?.subModules?.find(data => data.name === 'Configuracion')
const request = modules?.subModules?.find(data => data.name === 'Solicitudes de codigo')
const reviews = modules?.subModules?.find(data => data.name === 'Reseñas')
const questions = modules?.subModules?.find(data => data.name === 'Preguntas')
const products = modules?.subModules?.find(data => data.name === 'Productos')
const dictionary = modules?.subModules?.find(data => data.name === 'Diccionario')


const moduleRoute = {
  path: "/ecommerce",
  component: Module,
  name: "Ecommerce",
  children: [
    {
      path: "orders",
      name: i18n.t("layout.orders"),
      component: Orders,
      meta: {
        icon: "icon-order",
        search: ordenes?.active,
        requiresAuth: true, 
        active: ordenes?.active
      },
    },
    {
      path: "customers",
      name: i18n.t("layout.customers"),
      component: Customers,
      meta: {
        icon: "icon-customers",
        search: customers?.active,
        requiresAuth: true, 
        active: customers?.active
      },
    },
    {
      path: "limitions",
      name: i18n.t("layout.limitions"),
      component: Limitions,
      meta: {
        icon: "icon-router",
        search: limitions?.active,
        requiresAuth: true, 
        active: limitions?.active
      },
    },
    {
      path: "delivery-price",
      name: i18n.t("layout.delivery_price"),
      component: DeliveryPrice,
      meta: {
        icon: "icon-delivery-price",
        search: deliveryPrice?.active,
        requiresAuth: true, 
        active: deliveryPrice?.active
      },
    },
    {
      path: "settings/:tab",
      name: i18n.t("layout.settings"),
      component: Settings,
      meta: {
        icon: "icon-settings",
        search: setting?.active,
        requiresAuth: true, 
        active: setting?.active
      },
    },
    {
      path: "requests",
      name: i18n.t("layout.requests"),
      component: Requests,
      meta: {
        icon: "icon-request-code",
        search: request?.active,
        requiresAuth: true, 
        active: request?.active
      },
    },
    {
      path: "invoice",
      name: "Invoice",
      component: Invoice,
    },
    {
      path: "products",
      name: i18n.t("layout.products"),
      component: Products,
      meta: {
        icon: "icon-products",
        search: products?.active,
        requiresAuth: true, 
        active: products?.active
      },
    },
    {
      path: "questions",
      name: i18n.t("layout.questions"),
      component: Questions,
      meta: {
        icon: "icon-question",
        search: questions?.active,
        requiresAuth: true, 
        active: questions?.active
      },
    },
    {
      path: "reviews",
      name: i18n.t("layout.reviews"),
      component: Reviews,
      meta: {
        icon: "icon-question",
        search: reviews?.active,
        requiresAuth: true, 
        active: reviews?.active
      },
    },
    {
      path: "limitions-1",
      name: i18n.t("layout.limitions"),
      component: Limitions1,
      meta: {
        icon: "icon-question",
        search: true,
        requiresAuth: true, 
      },
    },
    {
      path: "dictionary",
      name: i18n.t("layout.dictionary"),
      component: Dictionary,
      meta: {
        icon: "icon-question",
        search: dictionary?.active,
        requiresAuth: true, 
        active: dictionary?.active,
      },
    },
    {
      path: "cognito-email-verification",
      name: i18n.t("layout.congito_email_verification"),
      component: CognitoEmailVerification,
      meta: {
        icon: "icon-question",
        search: true,
      },
    }
  ],
};

export default router => {
    router.addRoutes([moduleRoute]);
};

<template>
    <div class="popup" :style="styles" :class="arrowPosition">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Popup',
        props:{
            styles:{
                type: Object,
                default: () => {
                    return { top: `${-24}%`, left: `${-38}%` }
                }
            },
            arrowPosition:{
                type: String,
                default: 'center-down-arrow'
            }
        }
    }
</script>

<style scoped>
    .popup{
        position: absolute;
        z-index: 1;
        background: #fff;
        box-shadow: 3px 6px 20px #7c879329;
        border-radius: 17px;
        animation: popup .3s;
    }
    /* .popup::before{
        --shadowSize: 10px;
        --shadowColor: #e2e2ef;
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 24px;
        height: 24px;
        background-color: #fff;
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
        transform: translate(50%, -50%) rotate(45deg);
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
    } */
    .center-down-arrow::before,
    .right-top-arrow::before{
        position: absolute;
        --shadowSize: 10px;
        --shadowColor: #e2e2ef;
        background-color: #fff;
        content: "";
        width: 14px;
        height: 14px;
        clip-path: polygon(
            calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1), 
            calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
        );
        box-shadow: 0 0 var(--shadowSize) var(--shadowColor);
    }
    .center-down-arrow::before{
        top: 26px;
        right: 50%;
        transform: translate(50%, -50%) rotate(135deg);
    }
    .right-top-arrow::before{
        top: 1px;
        right: 20px;
        transform: translate(50%, -50%) rotate(314deg);
    }
    @keyframes popup {
        from{
            transform: scale(0.2);
        }
        to{
            transform: scale(1);
        }
    }
</style>
<template>
    <main-modal @closeModal="$emit('closeModal')">
        <b-row>
            <b-col cols="4">
                <b-row>
                    <h4><eva-icon name="person" :fill=" viewCustomers.properties.verified ? '#02a499' : '#ec4561'" width="20"></eva-icon> {{ $t('zones.customers_details') }}</h4>
                    <b-col class="mt-4" style="overflow-y: auto; max-height: 500px;">
                        <ul class="column-list">
                            <li>
                                <b>{{$t('zones.customer_code')}} </b> {{ viewCustomers.properties.code }}
                            </li>
                            <li>
                                <b>{{$t('zones.full_name')}} </b> &emsp; {{ viewCustomers.properties.first_name | textFormat }}
                            </li>
                            <li>
                                <b>{{$t('zones.last_purchase')}}</b> {{ viewCustomers.properties.last_purchase_date }}
                            </li>
                            <li>
                                <b>{{$t('zones.last_visit')}}</b> {{ viewCustomers.properties.last_visit || 'NA' }}
                            </li>
                            <li>
                                <b>{{$t('zones.verified')}}</b> <icon-check-circle :color="'#02a499'" v-if="viewCustomers.properties.verified" /> <icon-close-circle :color="'#ec4561'" v-if="!viewCustomers.properties.verified" />
                            </li>
                            <li>
                                <b>{{$t('zones.company')}}</b> {{ viewCustomers.properties.company_name | textFormat }}
                            </li>
                            <li>
                                <b>{{$t('zones.price_level')}}</b> {{ viewCustomers.properties.price_level_id}}
                            </li>
                            <li>
                                <b>{{$t('zones.ranking')}}</b>                                  
                                <div class="text-white px-2 d-inline-block" :class="viewCustomers.properties.verified ? 'bg-success' : 'bg-danger'" style="border-radius: 10px;">
                                    {{'NA'}}
                                </div>
                            </li>
                            <li>
                                <b>{{$t('zones.portfolio_salesperson')}}</b> {{ viewCustomers.properties.portfolio_seller}}
                            </li>
                            <li>
                                <b>{{$t('zones.recurring_vendor')}}</b> {{ viewCustomers.properties.recurrent_seller || 'NA'}}
                            </li>
                            <li>
                                <b>{{$t('zones.phone')}}</b> {{ viewCustomers.properties.phone || 'NA'}}
                            </li>
                            <li>
                                <b>{{$t('zones.reference')}}</b>  <el-tooltip  :content="viewCustomers.properties.reference" placement="top"> <span> {{ limitText(viewCustomers.properties.reference, 30) + '...' || 'NA' | textFormat}}</span></el-tooltip>
                            </li>
                            <li>
                                <b>{{ $t('zones.amount_sales_30_days') }}</b> {{ viewCustomers.properties.amount_sales_30_days || 'NA' }}
                            </li>
                            <li>
                                <b>{{ $t('zones.amount_sales_60_days') }}</b> {{ viewCustomers.properties.amount_sales_60_days || 'NA' }}
                            </li>
                            <li>
                                <b>{{ $t('zones.amount_sales_180_days') }}</b> {{ viewCustomers.properties.amount_sales_180_days || 'NA' }}
                            </li>
                            <li>
                                <b>{{$t('zones.subtype_id')}}</b>{{ viewCustomers.properties.subtype_id || 'NA' }}
                            </li>
                            <!-- <li>
                                <b>{{$t('zones.subtype_name')}}</b>{{ viewCustomers.properties.subtype_name || 'NA' }}
                            </li> -->
                        </ul>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="8">
                <h4>{{$t('zones.images')}}</h4>
                <swiper :options="swiperOption" style="height: 500px;border-radius:10px; width: 600px;" :key="images.length+tabIndex">
                    <swiper-slide :key="index" v-for="({path_s3}, index) in images">
                        <img v-lazy="path_s3" width="100%" height="100%" style="object-fit: contain;">
                    </swiper-slide>
                    <div class="swiper-button-prev mr-5" slot="button-prev">
                        <icon-arrow :size="30" style="margin-right: 3px; transform: rotate(180deg);" :color="'var(--gray-1001)'"/>
                    </div>
                    <div class="swiper-button-next" slot="button-next">
                        <icon-arrow :size="30" style="margin-left: 3px" :color="'var(--gray-1001)'"/>
                    </div>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </b-col>
        </b-row>
    </main-modal>
</template>

<script>
import MainModal from '../../../common/components/modals/MainModal.vue';
import iconCloseCircle from '../../../common/components/svg/iconCloseCircle.vue';
import IconCheckCircle from '../../../common/components/svg/IconCheckCircle.vue';
import IconArrow from '../../../common/components/svg/IconArrow.vue';
import { CustomerService } from '../../ecommerce/service/customer-service';
const customerService = new CustomerService()

export default {
    components:{
        MainModal,
        iconCloseCircle,
        IconCheckCircle,
        IconArrow
    },
    props:{
        viewCustomers: { 
            type: Object
        },
        taxIndexProp: {
            type: Number,
            default: 0
        }
    },
    computed: {
    },
    data() {
        return {
            loading: false,
            images: [],
            swiperOption: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                }
            },
            tabIndex: this.taxIndexProp,
        }
    },
    methods:{
        limitText(text, limit){
            if (text.length > limit) {
                return text.substring(0, limit);
            } else {
                return text;
            }
        },
        async getImages(){
            try {
                this.loading = true
                const response = await customerService.getImages(this.viewCustomers.properties.code)
                this.images = response.data
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        }
    },
    async created(){
        this.getImages()
    }
}
</script>

<style>


    .column-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex; /* Use a flex container for a column layout */
        flex-direction: column;
    }

    /* Style each list item */
    .column-list li {
        padding: 8px; /* Add spacing around each item */
        /* border-bottom: 1px solid #ccc; Add a horizontal line between items */
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
        font-size: 14px;
    }

    /* Style the last list item to remove the bottom border */
    .column-list li:last-child {
        border-bottom: none;
    }

    /* .order-details-list{
        list-style: none;
        padding: 0;
    }
    .order-details-list > li{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 15px;
    } */
    .modal-mask::v-deep > .modal-container{
        width: 1200px;
    }
</style>
<template>
    <!-- v-loading="loading" -->
    <div class="position-relative h-100">
        <div class="container-search d-flex flex-column align-items-center my-4">
            <vue-google-autocomplete id="vue-google-autocomplete" @focus="showMessage = true" v-model="addressInput" :placeholder="$t('delivery.write_reference_to_locate_the_map')" 
            class="autocomplete w-100" :country="[generalSetting.general.web_page.country_default.text]" :types="['locality']" v-on:placechanged="setPlace"/>
            <i class="dripicons-location position-absolute btn-show-search size-18 d-flex cursor-pointer ms-2" @click="showSearchByCoordinates = !showSearchByCoordinates"></i>
            <input type="search" name="search-by-coordinates" id="search-by-coordinates" class="autocomplete w-100 mt-2" v-model="searchByCoodinates" 
            @keypress.enter="search" v-if="showSearchByCoordinates">
        </div>
        <mgl-map :key="styleSelectMap.id" class="create-task-map" :center="selected ? selected : coordinates" :accessToken="accessToken" :zoom="defaultSize()" :dragRotate="false" @load="$emit('onMapLoaded', $event)" ref="map" :mapStyle="styleSelectMap.value" >      
            <mgl-marker class="cursor-pointer" :coordinates="selected" v-if="selected">
                <div slot="marker"><icon-pin :size="27" color="#c40316"/></div>
            </mgl-marker>
            <mgl-marker class="cursor-pointer" :coordinates="coordinates" :draggable="task ? task.type_id == 2 : true" @dragend="dragUbication" v-else>
                <div slot="marker">
                    <icon-pin :size="27" :color="select ? select.id ? '#f8b425' : '#c40316' : '#c40316'"/>
                </div>
            </mgl-marker>
            <mgl-marker v-for="address in addresses" :key="address.id" :coordinates="[address.longitud, address.latitud]">
                <!-- <div slot="marker">
                    <icon-pin :size="27" :color="address.latitud == select.latitude && address.longitud == select.longitude ? '#c40316': '#02a499'"/>
                </div> -->
                <mgl-popup>
                    <div>
                        <h3>{{address.name}}</h3>
                        <sub>{{address.longitud}}, {{address.latitud}}</sub>
                    </div> 
                </mgl-popup>
            </mgl-marker>
            <mgl-geolocate-control :trackUserLocation="true" position="top-right" />
            <mgl-navigation-control position="top-right"/>
            <div style="position: fixed; margin-top: 73px; margin-left: 14px;">
                <!-- <button class="newButton"><eva-icon name="layers-outline"></eva-icon></button> -->
                <el-popover placement="right" width="210" trigger="click">
                    <b-row class="mt-2" style="width: 205">
                        <b-col>
                            <div class="d-flex">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[1].value }" @click="styleSelectMap = styleMap[1]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[1].color"/> -->
                                    </span>
                                    <span>{{styleMap[1].text || styleMap[1].text}}</span>
                                </button>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="d-flex" style="margin-left: -7px;">
                                <button :class="{ 'selected': styleSelectMap.value == styleMap[0].value }" @click="styleSelectMap = styleMap[0]" class="map-type-button">
                                    <span class="icon" role="img" aria-label="Mapa">
                                        <el-image style="width: 50px; height: 50px" src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"></el-image>
                                        <!-- <icon-map style="width: 40px;" :class="styleMap[0].color"/> -->
                                    </span>
                                    <span>{{styleMap[0].text || styleMap[0].text}}</span>
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                <button slot="reference" class="gmaps-button">
                    <i class="icon"><eva-icon style="width: 40px;" name="layers-outline" fill="#333333" /></i>
                </button>
                </el-popover>
            </div>
        </mgl-map>
    </div>
</template>

<script>
import {MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup} from 'v-mapbox'
// import Mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import http from 'axios'
import IconPin from '../../../common/components/svg/IconPin.vue';
import { mapGetters } from 'vuex';
export default {
    name: "CreateTaskMap",
    components:{
        MglMap,
        VueGoogleAutocomplete,
        MglMarker,
        MglGeolocateControl,
        MglNavigationControl,
        MglPopup,
        IconPin
    },
    props:{
        clear:{
            type: Boolean
        },
        center:{
            type: Array
        },
        addresses:{
            type: Array
        },
        task:{
            type: Object
        },
        select:{
            type: Object
        },
        selected:{
            type: Array
        }
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            coordinates: [-70.70238447994983, 19.45556310283655],
            addressInput: '',
            showSearchByCoordinates: false,
            searchByCoodinates: '',
            loading: false,
            styleMap: [
                {id:'light',value: 'mapbox://styles/mapbox/light-v10', text: 'View light streets', color: 'light'},
                {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite streets', color: 'satellite'},
            ],
            styleSelectMap: {id:'satellite',value: 'mapbox://styles/mapbox/satellite-streets-v12', text: 'Satellite Streets', color: 'satellite'},
        }
    },
    computed:{
        ...mapGetters({
            mapStyle: 'layout/mapStyle',
            generalSetting: "ecommerce/generalSetting"
        })
    },
    watch: {
        clear(){
            this.addressInput = ''
        },
        select(val){
            this.$refs.map.map.flyTo({ 
                center: [val.longitude, val.latitude], zoom: 12, bearing: 0, speed: 2, // make the flying slow 
                curve: 1, // change the speed at which it zooms out 
                easing: function(t) { return t; }, essential: true
            });
        },
        async selected(val) {
            if(val){
                await this.getAddress(val[0], val[1])
                this.$emit("setCoordinates", val);
            }
        }
    },
    methods:{
        // async clickMap(data){
        //     if(this.task && this.task?.type_id != 2){
        //         return
        //     }
        //     this.coordinates = [data.mapboxEvent.lngLat.lng, data.mapboxEvent.lngLat.lat];
        //     this.$emit("setCoordinates", this.coordinates)
        //     await this.getAddress(data.mapboxEvent.lngLat.lng, data.mapboxEvent.lngLat.lat)
        // },

        async setPlace(place) {
            this.addressInput = `${place.route}, ${place.postal_code}, ${place.country}`;
            this.coordinates = [place.longitude, place.latitude];
            this.$emit("setCoordinates", this.coordinates)
            this.zoomIn();
            await this.getAddress(place.longitude, place.latitude)
        },
        async dragUbication(data){
            // console.log(Mapbox)
            // console.log(data.marker);
            this.coordinates = [data.marker._lngLat.lng, data.marker._lngLat.lat];
            this.$emit("setCoordinates", this.coordinates)
            await this.getAddress(data.marker._lngLat.lng, data.marker._lngLat.lat)

        },
        async getAddress(long, lat){
            try {
                this.loading = true;
                const {data} = await http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${process.env.VUE_APP_MAPBOX_KEY}`);
                if(data.features.length){
                    const address = data.features[0];
                    this.$emit('setAddress', address.place_name);
                }
            } catch (error) {
                console.log(error);
            } finally{
                this.loading = false;
            }
        },
        async search(){
            try {
                const matches = this.searchByCoodinates.substring(this.searchByCoodinates.indexOf(',') + 2);
                const init = this.searchByCoodinates.replace(`,${matches}`, '')
    
                this.coordinates = [parseFloat(matches), parseFloat(init)];
                this.$emit("setCoordinates", this.coordinates);
    
                this.zoomIn();
                await this.getAddress(this.coordinates[0], this.coordinates[1]);
            } catch (error) {
                console.error(error);
            }
        },
        zoomIn(){
            setTimeout(() => {
                this.$refs.map.map.flyTo({ 
                    center: this.coordinates, zoom: 12, bearing: 0, speed: 2, // make the flying slow 
                    curve: 1, // change the speed at which it zooms out 
                    easing: function(t) { return t; }, essential: true
                });
            }, 100);
        }
    },
    created(){
        this.coordinates = this.defaultCountry()
        if(this.center){
            this.coordinates = this.center
        }
    }
}
</script>

<style scoped>
    .container-search{
        position: absolute;
        width: 318px;
        left: 16px;
        z-index: 3;
    }
    .autocomplete{
        border: none;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: 1px solid #DCDFE6;
    }
    .create-task-map{
        /* height: 70vh; */
        border-radius: 5px;
        overflow: hidden;
    }
    .btn-show-search{
        z-index: 1;
        right: 10px;
        top: 8px;
    }
    .gmaps-button {
        background-color: #fff; /* Fondo blanco */
        border: none; /* Sin bordes externos */
        border-radius: 8px; /* Bordes redondeados suaves */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave para profundidad */
        cursor: pointer; /* Cursor tipo puntero */
        font-size: 16px; /* Tamaño del texto o ícono */
        height: 29px; /* Altura del botón */
        width: 29px; /* Ancho del botón */
        display: flex; /* Alineación del contenido con Flexbox */
        justify-content: center; /* Centra contenido horizontalmente */
        align-items: center; /* Centra contenido verticalmente */
        transition: background-color 0.3s, box-shadow 0.3s; /* Efectos de transición */
        outline: none; /* Elimina el borde al enfocar */
    }
    .gmaps-button:hover {
        background-color: #f8f8f8; /* Color de fondo al pasar el mouse */
        box-shadow: 0 2px 6px rgba(0,0,0,0.3); /* Sombra más profunda al pasar el mouse */
    }
    .gmaps-button:active {
        background-color: #e0e0e0; /* Color de fondo al hacer clic */
        box-shadow: 0 1px 5px rgba(0,0,0,0.2); /* Sombra suave al hacer clic */
    }
    .map-type-button {
        background-color: transparent; /* Fondo transparente */
        border: none; /* Elimina los bordes */
        border-radius: 4px; /* Mantiene los bordes redondeados para suavidad */
        box-shadow: none; /* Elimina la sombra para un diseño más limpio */
        color: #555; /* Color de texto blanco para contraste en fondos oscuros */
        cursor: pointer; /* Cursor tipo puntero */
        font-family: Arial, sans-serif; /* Fuente similar a la de Google Maps */
        font-size: 14px; /* Tamaño del texto */
        padding: 8px; /* Espaciado interno */
        display: flex; /* Uso de Flexbox */
        flex-direction: column; /* Organiza los hijos verticalmente */
        align-items: center; /* Centra los hijos horizontalmente */
        justify-content: center; /* Centra los hijos verticalmente */
        gap: 4px; /* Espacio entre ícono y texto */
        transition: background-color 0.2s; /* Transición suave para el color de fondo */
        outline: none; /* Elimina el contorno al enfocar */
        width: 80px; /* Ancho fijo para mantener la forma */
    }
    .map-type-button:hover, .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.1); /* Ligeramente más visible al interactuar */
    }
    .map-type-button:active {
        background-color: rgba(255, 255, 255, 0.2); /* Más visible aún al hacer clic */
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Sombra más ligera al hacer clic */
    }
    .selected {
        border: 2px solid rgb(64, 158, 255); /* Bordes verdes */
        color: rgb(64, 158, 255); /* Texto blanco para contraste */
    }
</style>

<template>
    <main-modal :title="'Detalle de la solicituda'" @closeModal="$emit('closeModal')" class="request-details">
        <b-tabs class="mt-3">
            <b-tab title="Informacion General">
                <b-row>
                    <b-col cols="6" class="mt-3">
                        <label for="name">Nombre</label>
                        <b-form-input v-model="request.entity.first_name" placeholder="Nombre" id="'name'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="name">Apellido</label>
                        <b-form-input v-model="request.entity.last_name" placeholder="Apellido" id="'last_name'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="identification">Cedula</label>
                        <b-form-input v-model="request.entity.identification" placeholder="Cedula" id="'identification'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="email">Email</label>
                        <b-form-input v-model="request.entity.email" placeholder="Email" id="'email'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="phone">Numero Telefonico</label>
                        <b-form-input v-model="request.entity.phone" placeholder="Phone" id="'phone'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="phone2">Numero Telefonico #2</label>
                        <b-form-input v-model="request.entity.phone2" placeholder="Phone" id="'phone2'"></b-form-input>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Cuestionario">
                <ul class="list-unstyled request-details-list mt-3">
                    <li>
                        <b>Categoria:</b>
                        {{$t(request.questionnaire.category)}}
                    </li>
                    <li>
                        <b>Recurrencia de compras:</b>
                        {{$t(request.questionnaire.frequency)}}
                    </li>
                    <li>
                        <b>Motivo de mercancia:</b>
                        {{$t(request.questionnaire.question)}}
                    </li>
                    <li>
                        <b>Promedio de compra mensual:</b>
                        {{request.questionnaire.amount | moneyFormat}}
                    </li>
                    <li>
                        <b>Lo atiende:</b>
                        <span class="text-capitalize">
                            {{$t(request.questionnaire.employee) | textFormat}}
                        </span> 
                    </li>
                </ul>
            </b-tab>
            <b-tab title="Empresa" active v-if="request.company">
                <b-row>
                    <b-col cols="6" class="mt-3">
                        <label for="name">Nombre</label>
                        <b-form-input v-model="request.company.name" placeholder="Nombre" id="'name'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="rnc">RNC</label>
                        <b-form-input v-model="request.company.rnc" placeholder="RNC" id="'rnc'"></b-form-input>
                    </b-col>
                    <b-col cols="6" class="mt-3">
                        <label for="telephone">Telefono</label>
                        <b-form-input v-model="request.company.telephone" placeholder="Telefono" id="'telephone'"></b-form-input>
                    </b-col>
                    <b-col cols="12">
                        <h5 class="mt-4">Direcciones</h5>
                        <div v-for="address in request.addresses" :key="address.id" class="card-address mt-3">
                            <ul class="list-unstyled">
                                <li class="subtitle-15 text-limited color-dark-gray-200"><span class="title-15 mb-0 weight-500">Tel:</span> {{ address.telephone }}</li>
                                <li class="subtitle-15 text-limited"> Direccion: {{ address.address}}</li>
                                <li class="subtitle-15 text-limited"><span class="title-15 mb-0">Ref:</span> {{ address.reference | textFormat}}</li>
                            </ul>
                            <a href="#" @click.prevent="''">{{address.long}}, {{address.lat}}</a>
                        </div>
                    </b-col>
                    <b-col cols="12" class="d-flex align-items-center mt-4">
                        <swiper class="w-100" style="border-radius: 30px; height: 130px" :options="swiperOption">
                            <swiper-slide :key="index" v-for="(img, index) in request.images" class="d-flex align-items-center justify-content-center">
                                <img v-lazy="img.path" :alt="img.id" class="cursor-pointer" style="object-fit: cover;width: 120px;height: 120px;" @click="selectedPicture = img.path; showLightBox = true;"/>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                            <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div>
                        </swiper>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
        <div class="d-flex justify-content-end mt-3">
            <div class="d-flex align-items-center me-2" v-if="request.current_state.id != 2">
                <label for="code" class="me-2">Digite el codigo:</label>
                <b-form-input v-model="code" placeholder="Digite el codigo" id="'code'" style="max-width: 125px;"></b-form-input>
            </div>
            <b-button variant="primary" class="d-flex align-items-center me-3" @click="approvedRequest" v-if="request.current_state.id != 2">Aprobar <i class="fas fa-check ms-2" style="font-size: 13px"></i></b-button>
            <b-button variant="danger" class="d-flex align-items-center" @click="deniedRequest">Denegar <i class="fas fa-trash ms-2" style="font-size: 13px"></i></b-button>
        </div>
        <light-box v-if="showLightBox" :active="selectedPicture" :images="request.images" @closeLightBox="showLightBox = false"/>
    </main-modal>
</template>

<script>
import LightBox from '../../../../common/components/modals/LightBox.vue';
import MainModal from '../../../../common/components/modals/MainModal.vue'
import { RequestService} from "../../service/request-service";
const service = new RequestService();

export default {
    components: { MainModal, LightBox },
    name: "RequestDetails",
    props:{
        request:{
            type: Object
        }
    },
    data() {
        return {
            code: null,
            swiperOption: {
                slidesPerView: 3,
                slidesPerColumn: 1,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
            },
            showLightBox: false,
            selectedPicture: null
        }
    },
    methods:{
        async approvedRequest() {
            try {
                let params = {
                    id: this.request.id,
                    code: this.code
                }
                service.approvedRequest(params)
                this.$emit('closeModal')
            } catch (error) {
                console.log(error);
            }
        },
        async deniedRequest() {
            try {
                let params = {
                    id: this.request.id,
                    code: this.code
                }
                await service.deniedRequest(params)
                this.$emit('closeModal')
            } catch (error) {
                console.log(error);
            }
        },
    },
}
</script>

<style scoped>
    .request-details-list > li{
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 15px;
        border: 1px solid rgba(113, 113, 125, 0.788);
        padding: 10px;
        border-bottom: none;
    }
    .request-details-list > li:last-child{
        border: 1px solid rgba(113, 113, 125, 0.788);
    }
    .request-details.modal-mask::v-deep > .modal-container{
        width: 600px;
    }
    .card-address{
        cursor: pointer;
        position: relative;
        padding: 15px;
        border-radius: 20px;
        box-shadow: 2px 3px 9px 0px #909da41a;
        transition: var(--transition-1);
        border: 1.5px solid rgba(92, 124, 136, 0);
        width: 300px;
    }
    img{
        object-fit: contain;
    }
</style>

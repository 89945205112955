<template>
    <layout>
        <b-row class="mt-3">
            <b-col>
                <label for="">{{ $t('ecommerce.filter_for_name') }}</label>
                <b-form-input v-model="searchByName" :placeholder="$t('ecommerce.filter_for_name')" type="search" @input="(data)=>{filters.name = data}"></b-form-input>
            </b-col>
            <b-col>
                <label for="">{{$t('ecommerce.show_by_date')}}:</label>
                <date-picker v-model="filters.range" :input-class="'datePick'" range></date-picker>
            </b-col>
            <b-col>
                <label for="city" class="me-2">{{$t('ecommerce.filter_by_city')}}:</label>
                <multiselect :id="'city'" v-model="filters.cities" :options="cities" :label="'city'" track-by="city" :multiple="true"/>
            </b-col>
            <b-col>
                <label for="states" class="me-2">{{$t('ecommerce.filter_by_state')}}:</label>
                <multiselect :id="'states'" v-model="filters.states" :options="states" :label="'name'" track-by="name" :multiple="true"/>
            </b-col>
        </b-row>
        <div>
            <b-table sticky-header thead-class="green-bg bg-primary text-white" class="bg-white mt-4" :fields="fields" hover :items="requests">
                <template #cell(date)="data">
                        {{$d(new Date(data.item.created_at), 'alt_short')}}
                </template>
                <template #cell(customer)="data">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="avatar d-flex align-items-center justify-content-center me-3 bg-primary">
                                <abbr>{{data.item.entity.first_name.slice(0,1) | textFormat}}</abbr>
                            </div>
                        </div>
                        <b>{{ data.item.entity.first_name | textFormat}} {{ data.item.entity.last_name | textFormat}}</b>
                    </div>
                </template>
                <template #cell(customer_phone)="data">
                        {{data.item.entity.phone}}
                </template>
                <template #cell(status)="data">
                    <div :class="[data.item.current_state.id == 1 ? 'in_process' : data.item.current_state.id == 2 ? 'delivered' : 'canceled']" class="d-inline-block px-2" style="border-radius: 10px">
                        {{data.item.current_state.name}}
                    </div>
                </template>
                <template #cell(company)="data">
                    <span v-if="data.item.company">
                        {{data.item.company.name | textFormat}}
                    </span>
                </template>
                <template #cell(actions)="data">
                    <b-button variant="primary" size="sm" class="d-flex align-items-center" @click="selectedRequest = data.item; showDetails = true">{{$t('ecommerce.see_details')}} <i class="fas fa-eye ms-2 fs-5"></i></b-button>
                </template>
            </b-table>
            <vue-element-loading :active="loading" :is-full-screen="false"/>
        </div>
        <b-pagination v-model="filters.page" :total-rows="totalPages" :per-page="perPage" class="justify-content-center"></b-pagination>
        <request-details :request="selectedRequest" @closeModal="showDetails = false" v-if="showDetails"/>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { RequestService} from "../service/request-service";
const service = new RequestService();
import { CitiesService} from "../service/cities-service";
const citiesService = new CitiesService();
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import RequestDetails from '../components/request/RequestDetails.vue';
import { mapGetters } from 'vuex';
export default {
    name: "Requests",
    components:{
        Layout,
        DatePicker,
        RequestDetails
    },
    data() {
        return {
            requests: [],
            filters:{
                states: null,
                name: '',
                range: '',
                cities: [],
                page: 1
            },
            selectedRequest: {},
            perPage: 10,
            totalPages: null,
            searchByName: '',
            cities: [],
            states: [
                {id: 1, name: this.$t('ecommerce.review')},
                {id: 2, name: this.$t('ecommerce.aprovech')},
                {id: 3, name: this.$t('ecommerce.not_aprovech')},
            ],
            loading: false,
            showDetails: false
        }
    },
    computed:{
        ...mapGetters({
            userData: "auth/user",
            permissions: "auth/permissions"
        }),
        fields(){
            return [
                    {label: this.$t('ecommerce.request') + ' #', key: 'id', sortable: true},
                    {label: this.$t('ecommerce.date'), key: 'date', sortable: true},
                    {label: this.$t('ecommerce.customer'), key: 'customer', sortable: true},
                    {label: this.$t('ecommerce.customer_phone'), key: 'customer_phone', sortable: true},
                    {label: this.$t('ecommerce.identification'), key: 'entity.identification', sortable: true},
                    {label: this.$t('ecommerce.status'), key: 'status', sortable: true},
                    {label: this.$t('ecommerce.company'), key: 'company', sortable: true},
                    {label: this.$t('ecommerce.actions'), key: 'actions', sortable: false},
            ]
        }
    },
    methods:{
        async getRequests(){
            try {
                this.loading = true
                const response = await service.getRequests(this.filters)
                this.requests = response.data.data
                this.totalPages = response.data.total
                this.perPage = response.data.per_page
            } catch (error) {
                console.log(error)
            }finally{
                this.loading = false
            }
        },
        async getCities(){
            try {
                const response = await citiesService.getCities()
                this.cities = response.data
            } catch (error) {
                console.log(error);
            }
        }
    },
    watch:{
        filters:{
            deep: true,
            immediate: true,
            async handler(){
                await this.getRequests();
            }
        },
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const request = modules?.subModules?.find(data => data.name === 'Solicitudes de codigo')
                // Verifica si se han cambiado los permisos de administrador
                if (!request.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
        },
    },
    async created(){
        this.getPermissions()
        await this.getCities()
    }
}
</script>

<style scoped>
    .avatar{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
    }
    .b-table-sticky-header::v-deep > .table > tbody > tr > td{
        vertical-align: middle;
        /* width: 17%; */
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input{
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
    }
</style>

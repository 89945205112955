<template>
<section class="row mx-0 banner-slide" :class="{'principal-slider-animation': sliderLoaded}">
  <div class="col-12 px-0">
      <div class="banner-background">
        <swiper class="swiper" style="border-radius: 30px; height: 100%" :options="swiperOption">
          <swiper-slide :key="index" v-for="(img, index) in images" class="d-flex align-items-center">
              <img :src="img.imageURL" :alt="img._id" @load="loaded" v-show="sliderLoaded" class="w-100" style="height: 600px;object-fit: cover;"/>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev mr-5" slot="button-prev">
            <icon-arrow :size="40" style="margin-right: -12px; transform: rotate(180deg);" />
          </div>
          <div class="swiper-button-next" slot="button-next">
            <icon-arrow :size="40" style="margin-left: -12px"/>
          </div>
        </swiper>
    </div>
  </div>
</section>

</template>

<script>
export default {
  name: 'Slider',
  components: {
    IconArrow: () => import("../../../../../common/components/svg/IconArrow.vue"),
  },
  props: {
    images: {
      type: Array
    }
  },
  data () {
    return {
      product: {},
      vfImages: [],
      swiperOption: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        autoplay:{
          delay: 2500,
          stopOnLastSlide: true,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true
        }
      },
      sliderLoaded: false
    }
  },
  computed: {
    path () {
      return process.env.VUE_APP_MM_PRODUCT
    },
    mediaQuery () {
      return window.matchMedia('(max-width: 704px)').matches
    },
  },
  methods: {
    isBanner (index) {
      if (index < 5) {
        return true
      }
      return false
    },
    loaded(){
      this.sliderLoaded = true
      this.$emit("sliderLoaded", this.sliderLoaded)
    }
  },
}
</script>

<style scoped>
.banner-slide {
  transform: translateY(30px);
}
.banner-background {
  height: 600px;
}
.swiper-button-prev {
  margin-left: 10px !important;
}
.swiper-button-prev, .swiper-button-next {
  width: 40px;
  z-index: 10;
}
.swiper-button-prev:before, .swiper-button-next:before {
  width: 80px;
}
.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
  visibility: hidden;
}
.swiper-button-prev svg, .swiper-button-next svg {
  fill: var(--gray-100) !important;
  transition: var(--transition-1);
}
.swiper-button-prev svg:hover, .swiper-button-next svg:hover {
  fill: var(--main-red) !important;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: no-drop !important;
  pointer-events: all !important;
}
.swiper-button-prev.swiper-button-disabled:hover, .swiper-button-next.swiper-button-disabled:hover {
  color: var(--gray-50) !important;
}
.principal-slider-animation {
  animation: 800ms cubic-bezier(0.46, 0.89, 0.03, 0.97) slide-up;
  animation-delay: 200ms;
  animation-fill-mode: both;
}
@keyframes slide-up {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0);
  }
}
</style>

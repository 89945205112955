<template>
    <el-form :model="form" ref="createTask" label-position="top" size="small">
        <b-row>
            <b-col cols="4" class="d-flex flex-column">
                <el-form-item prop="date" :label="$t('delivery.choose_a_date')" required>
                    <el-date-picker v-model="form.date" type="date" placeholder="Pick a day" size="small" :picker-options="pickerOptions" class="w-100"/>
                </el-form-item>
            </b-col>
            <b-col class="d-flex flex-column" cols="4">
                <el-form-item prop="employee" :label="$t('delivery.delivery_courier')">
                    <el-select v-model="form.employee" filterable placeholder="Select" size="small" class="w-100" value-key="entity_id">
                        <el-option v-for="item in employees" :key="item.entity_id" :label="`${item.entity.code} - ${item.entity.first_name} ${item.entity.last_name}`" :value="item"/>
                    </el-select>
                </el-form-item>
            </b-col>
            <b-col class="d-flex flex-column" cols="4">
                <el-form-item prop="taskType" :label="$t('delivery.task_type')" required="">
                    <el-select v-model="form.taskType" filterable placeholder="Select" size="small" id="task-type" class="w-100" value-key="id">
                        <el-option v-for="item in taskType" :key="item.id" :label="item.description" :value="item"/>
                    </el-select>
                </el-form-item>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col cols="12">
                        <el-form-item prop="description" :label="$t('delivery.description')" required>
                            <el-input type="textarea"  autosize placeholder="Enter something" v-model="form.description" id="description" class="h-100" size="small"/>
                        </el-form-item>
                    </b-col>
                    <b-col cols="12">
                        <el-form-item prop="address" :label="$t('delivery.address')" required>
                            <el-input placeholder="Please input" v-model="form.address" :disabled="true" size="small"/>
                        </el-form-item>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col class="d-flex flex-column" cols="12">
                        <el-form-item prop="latitude" :label="$t('delivery.latitude')" required>
                            <el-input placeholder="Please input" v-model="form.latitude" :disabled="true" size="small"/>
                        </el-form-item>
                    </b-col>
                    <b-col class="d-flex flex-column" cols="12">
                        <el-form-item prop="longitude" :label="$t('delivery.longitude')" required>
                            <el-input placeholder="Please input" v-model="form.longitude" :disabled="true" size="small"/>
                        </el-form-item>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="6">
                <el-form-item prop="deliveryType" :label="$t('delivery.type_of_delivery')" required>
                    <el-select v-model="form.deliveryType" filterable placeholder="Select" size="small" id="delivery-type" value-key="id" class="w-100">
                        <el-option v-for="item in methods" :key="item.id" :label="item.description" :value="item"/>
                    </el-select>
                </el-form-item>
            </b-col>
            <b-col cols="6">
                <el-form-item prop="branch" :label="$t('delivery.branch')" required>
                    <el-select v-model="form.branch" filterable placeholder="Select" class="w-100" size="small" id="branch" value-key="id" @change="(data)=>{$emit('selectBranch', data)}">
                        <el-option v-for="item in stores" :key="item.id" :label="item.description" :value="item"/>
                    </el-select>
                </el-form-item>
                <sub class="text-danger d-flex" style="line-height: 12px;">{{$t('delivery.the_selected_branch_applied_all_tasks_in_table')}}</sub>
            </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
            <el-button size="small" @click="createTask" style="width: 150px;" :type="editData ? 'warning' :'primary'">{{$t('delivery.add')}}</el-button>
            <el-button size="small" type="danger" @click="reset" style="width: 150px;">{{$t('delivery.clean')}}</el-button>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center"> -->
            <!-- <div>
                <b-button type="submit" class="me-3" :variant="isEdit ? 'warning' : 'primary'">
                    {{$t('delivery.add')}}
                    <eva-icon name="file-add" fill="#fff" width="20"></eva-icon>
                </b-button>
                <b-button type="reset" variant="danger" class="ml-1">
                    {{$t('delivery.clean')}}
                    <eva-icon name="trash-2" fill="#fff" width="20"></eva-icon>
                </b-button>
            </div> -->
            <!-- <div>
                <label for="example-datepicker">{{$t('delivery.branch')}}</label>
                <multiselect v-model="branch" :options="stores" placeholder="Type to search" track-by="codplace" label="description" class="mt-1">
                    <span slot="noResult">{{$t('ecommerce.no_option_matches')}}</span>
                </multiselect>
                <sub>{{$t('delivery.the_selected_branch_applied_all_tasks_in_table')}}</sub>
            </div> -->
        <!-- </div> -->
    </el-form>
    <!-- </b-form> -->
</template>

<script>
import { mapGetters } from "vuex"
import 'vue2-datepicker/index.css';
import moment from "moment";

export default {
    name: "CreateTaskForm",
    data() {
        return {
            form: {
                date: new Date(),
                employee: null,
                taskType: null,
                latitude: null,
                longitude: null,
                deliveryType: null,
                description: null,
                address: null,
                branch: null,
            },
            editIndex: null,
            moment: moment,
            pickerOptions: {
                disabledDate(time) {
                    const dateParse = new Date(time).toISOString().split('T')[0]
                    return dateParse < moment(new Date()).format('YYYY-MM-DD')
                },
            },
        }
    },
    props:{
        coordinates:{
            type: Array
        },
        editData:{
            type: Object
        },
        address:{
            type: String
        }
    },
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
            taskType: "delivery/taskType",
            stores: "ecommerce/stores",
            methods: "delivery/methods",
        }),
    },
    watch:{
        coordinates(val){
            this.form.longitude = val[0]
            this.form.latitude = val[1]
        },
        editData(val){
            console.log('hoal mundo', val);
            if(val){
                this.editIndex = val.index
                this.form = {...val.form}
            }
        },
        address(val){
            this.form.address = val
        },
    },
    methods:{
        submitForm(formName) {
            console.log(this.$refs[formName]);
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        createTask(){
            this.$refs.createTask.validate((valid) => {
                if (valid) {
                    if(this.editData){
                        this.$emit('edit', {form: this.form, index: this.editIndex})
                    }else{
                        this.$emit('add', {...this.form})
                    }
                    this.reset()
                }
            });
        },
        reset(){
            this.$refs.createTask.resetFields();
            // this.form ={
            //     date: new Date(),
            //     employee: null,
            //     taskType: null,
            //     latitude: null,
            //     longitude: null,
            //     deliveryType: null,
            //     description: null,
            //     address: null
            // }
            // // this.branch = null
            // this.editIndex = null
            // setTimeout(() => {
            //     this.$v.$reset()
            // }, 20); 
        },
        disabledDate(time) {
            console.log({time})
            return time.getTime() > Date.now();
        },
        // disabledRange: function (date) {
        //     let dateParse = new Date(date).toISOString().split('T')[0]
        //     console.log( dateParse < moment(new Date()).format('YYYY-MM-DD'));
        //     return dateParse < moment(new Date()).format('YYYY-MM-DD')
        // },
    },
    async created(){
        await this.$store.dispatch('delivery/getTaskMethod')
    } 
}
</script>

<style scoped>
    .mx-datepicker::v-deep{
        width: 100%;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input, .form-select::v-deep{
        width: 100%;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        height: 35px;
    }
    .el-form-item::v-deep label{
        line-height: normal;
    }
    /* .el-form-item--small .el-form-item__content, .el-form-item--small .el-form-item__label */
</style>

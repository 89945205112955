<template>
    <div>
        <b-row>
            <b-col>
                <h3>{{ $t('ecommerce.configuration_color') }}</h3>
                <ul class="color-list" style="padding: 5px;">
                    <li v-for="color in appearanceSettings.colors" :key="color.id">
                        <h6 class="mb-0 size-16">{{color.title}}</h6>
                        <p class="m-0">{{color.description}}</p>
                        <color-picker v-model="color.color" :position="{left: 0, top: '40px'}"></color-picker>
                    </li>
                </ul>
            </b-col>
            <b-col>
                <h3>{{ $t('ecommerce.configuration_logo') }}</h3>
                <vue-dropzone ref="dropzoneSecondaryBanner" id="imgDropZone" class="position-relative mt-3 m-3" :options="dropzoneOptions" :max-file-size-in-m-b="20" :max-number-of-files="6" :useCustomSlot="true" :destroyDropzone="false">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Checkout" x="0px" y="0px" viewBox="0 0 250 250" style="width:50px; fill:var(--gray-1001)" xml:space="preserve">
                            <g xmlns="http://www.w3.org/2000/svg" id="Group_4680" data-name="Group 4680" transform="translate(531.674 -23.232)">
                                <path id="Path_2174" data-name="Path 2174" d="M-401.2,142.005a9.187,9.187,0,0,0-1.1-1.334,4.653,4.653,0,0,0-6.481-.115c-1.867,1.7-3.587,3.557-5.392,5.334a5,5,0,0,1-6.289,1.209,8.621,8.621,0,0,1-2.28-2.107c-2.7-3.44-5.334-6.93-8-10.406-4.08-5.334-8.147-10.668-12.25-16-2.418-3.138-5.046-2.884-6.868.618l-.048.1q-10.175,20.15-20.336,40.3a8.576,8.576,0,0,0-.747,2.022,2.868,2.868,0,0,0,2.024,3.514l.061.016a9.788,9.788,0,0,0,2.667.3q18.588.028,37.183,0h37.729c1.414,0,2.938,0,3.707-1.467s-.106-2.725-.916-3.885Q-394.84,151.041-401.2,142.005Z"/>
                                <path id="Path_2175" data-name="Path 2175" d="M-413.628,115.935a12.132,12.132,0,0,0,12.18-12.081,12.133,12.133,0,0,0-12.082-12.18,12.131,12.131,0,0,0-12.179,12.082A12.138,12.138,0,0,0-413.628,115.935Z"/>
                                <path id="Path_2176" data-name="Path 2176" d="M-321.82,148.473a39.147,39.147,0,0,0-39.148,39.147,39.147,39.147,0,0,0,39.146,39.147,39.147,39.147,0,0,0,39.148-39.146h0A39.147,39.147,0,0,0-321.82,148.473Zm17.386,42.261a3.158,3.158,0,0,1-.456.033h-13.694a.089.089,0,0,0-.089.089v13.695a3.152,3.152,0,0,1-3.159,3.144,3.022,3.022,0,0,1-.446-.033,3.316,3.316,0,0,1-2.668-3.355V190.851a.089.089,0,0,0-.089-.089h-13.7a3.151,3.151,0,0,1-3.144-3.159,3.042,3.042,0,0,1,.033-.446,3.319,3.319,0,0,1,3.355-2.667h13.456a.089.089,0,0,0,.088-.089v-13.7a3.152,3.152,0,0,1,3.16-3.144,3.187,3.187,0,0,1,.446.032,3.316,3.316,0,0,1,2.667,3.356V184.4a.089.089,0,0,0,.089.089h13.455a3.32,3.32,0,0,1,3.356,2.668A3.152,3.152,0,0,1-304.434,190.734Z"/>
                                <path id="Path_2177" data-name="Path 2177" d="M-340.048,141.706a49.3,49.3,0,0,1,10.963-2.92v0a103.018,103.018,0,0,0,.947-13.74A101.769,101.769,0,0,0-429.858,23.232a101.767,101.767,0,0,0-101.816,101.72A101.769,101.769,0,0,0-429.953,226.768a101.767,101.767,0,0,0,62.139-21.137A49.283,49.283,0,0,1-340.048,141.706Zm-34.313,22.6a13.926,13.926,0,0,1-.706,4.351,12.444,12.444,0,0,1-12.241,8.445h-65.386c-6.667,0-13.3.032-19.954,0a12.111,12.111,0,0,1-11.059-6.4,28.115,28.115,0,0,1-1.675-4.649V84.065a1.788,1.788,0,0,0,.173-.4A12.5,12.5,0,0,1-472.425,73H-387.2a12.513,12.513,0,0,1,12.854,12.162c.005.212.006.423,0,.635Q-374.306,125.042-374.361,164.3Z"/>
                            </g>
                        </svg>
                        <h6 class="m-0 my-1 title-16">{{ $t('ecommerce.up_img') }}</h6>
                        <span style="color: #a5b2b7; font-family: 'Rubik'; font-weight: 300;">{{ $t('ecommerce.Drag_or_drop_the_logo') }}</span>
                    </div>
                </vue-dropzone>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {ColorPicker} from 'one-colorpicker'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import VueDropzone from 'vue2-dropzone'
export default {
    name: 'AppearanceSettings',
    components:{
        ColorPicker,
        VueDropzone
    },
    data(){
        return{
            appearanceSettings:{
                colors: [
                    {id: 1, color: '#e47219', title: 'Main color' , description: this.$t('ecommerce.primary_color')},
                    {id: 2, color: '#000', title: 'Main Navbar - Footer' , description: this.$t('ecommerce.main_NavBar')},
                    {id: 3, color: '#cf6413', title: 'Navbar secundario' , description: this.$t('ecommerce.navbar_secundary')},
                    {id: 4, color: '#1aa0e8', title: 'Link color' , description: this.$t('ecommerce.link_colors')}
                ],
                url_log: "lorem"
            },
            dropzoneOptions: {
                url: '/',
                maxFiles: 6,
                resizeQuality: .6,
                addRemoveLinks: true,
                autoProcessQueue: false,
                resizeWidth: 500,
                resizeHeight: 500,
                acceptedFiles: ".jpeg, .jpg, .png",
            },
        }
    },
    methods:{
        change(value){
            console.log(value);
        }
    },
}
</script>

<style scoped>
    .color-preview{
        width: 25px;
        height: 25px;
        border-radius: 3px;
    }
    .color-list{
        padding: 0;
        list-style: none;
    }
    .color-list li{
        justify-content: space-between;
        margin-bottom: 10px;
    }
</style>

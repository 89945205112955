<template>
    <div id="print-invoice">
        <div class="row m-2">
            <div class="col-md-12 mb-3" id="btn-back">
                <a href="#" class="size-14" @click.prevent="goToBack">
                        <icon-arrow :color="'var(--blue-600)'" class="hover-blue" :size="12" style="transform: rotate(180deg);"/>
                        {{$t("ecommerce.back_to_orders")}}
                </a>
            </div>
            <div class="col-md-6" id="logo">
                <img src="../../assets/images/logo.png" alt="">
            </div>
            <div class="col-md-6 align-items-end justify-content-end" id="qr-code">
                <div class="d-flex flex-column align-items-center">
                    <div class="position-relative">
                        <div class="ocrloader d-flex align-items-center justify-content-center">
                            <qr-code :text="invoice.order_token_id" :size="100" color="#000" bg-color="transparent"  error-level="L"></qr-code>
                            <div class="bottom-line"></div>
                        </div>
                    </div>
                    <span class="subtitle-14 color-dark-gray-400 weight-300 mt-2">N.º {{$t('ecommerce.invoice')}}</span>
                </div>
            </div>
            <div class="justify-content-between" id="print-logo">
                <div>
                    <img src="../../assets/images/logo.png" alt="">
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="position-relative">
                        <div class="ocrloader d-flex align-items-center justify-content-center">
                            <qr-code :text="invoice.order_token_id" :size="100" color="#000" bg-color="transparent"  error-level="L"></qr-code>
                            <div class="bottom-line"></div>
                        </div>
                    </div>
                    <span class="subtitle-14 color-dark-gray-400 weight-300 mt-2">N.º {{$t('ecommerce.invoice')}}</span>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title-24 mb-0 font-weight-bold">{{$t("ecommerce.invoice")}}<a href="#" v-print="'print-invoice'" @click.prevent="" class="size-14 ms-2" id="btn-print">{{$t("ecommerce.print")}}</a></h5>
            </div>
            <div class="col-md-12 justify-content-between" id="print-infomation">
                <div>
                    <div class="d-flex flex-column mt-4" style="max-width: 302px">
                        <h6 class="title-16 mb-0 font-weight-bold">Mundo Móvil MH</h6>
                        <span class="subtitle-14">RNC: <span class="color-dark-gray-200">131-12214-2</span> </span>
                        <span class="subtitle-14">{{$t('ecommerce.telephone')}}: <span class="color-dark-gray-200">809-581-7666</span> </span>
                        <span class="subtitle-14">{{$t('ecommerce.email')}}: <span class="color-dark-gray-200">Info@mundomovil.com.do</span> </span>
                        <span class="subtitle-14">{{$t('ecommerce.web')}}: <span class="color-dark-gray-200">www.mundomovil.com.do</span> </span>
                    </div>
                </div>
                <div class="d-flex justify-content-end col-md-6" v-if="invoice.order_primary.option_name  != undefined && invoice.order_primary.option_name != 'pick_up'">
                    <div class="d-flex flex-column text-end mt-4" style="max-width: 302px">
                        <h6 class="title-16 mb-0 font-weight-bold">{{$t('ecommerce.shipping_address')}}</h6>
                        <span class="subtitle-14">{{invoice.order_primary.optionaddress.address.name}} | Tel: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.phone | phoneFormat}}</span> </span>
                        <span class="subtitle-14"> {{invoice.order_primary.optionaddress.address.address_line2 || invoice.order_primary.optionaddress.address.address_line}}, {{invoice.order_primary.optionaddress.address.city_name}} </span>
                        <span class="subtitle-14">Ref: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.reference}}.</span> </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6" id="company">
                <div class="d-flex flex-column mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">Mundo Móvil MH</h6>
                    <span class="subtitle-14">RNC: <span class="color-dark-gray-200">131-12214-2</span> </span>
                    <span class="subtitle-14">{{$t('ecommerce.telephone')}}: <span class="color-dark-gray-200">809-581-7666</span> </span>
                    <span class="subtitle-14">{{$t('ecommerce.email')}}: <span class="color-dark-gray-200">Info@mundomovil.com.do</span> </span>
                    <span class="subtitle-14">{{$t('ecommerce.web')}}: <span class="color-dark-gray-200">www.mundomovil.com.do</span> </span>
                </div>
            </div>
            <div class="d-flex justify-content-end col-md-6" v-if="invoice.order_primary.option_name  != undefined && invoice.order_primary.option_name != 'pick_up'" id="address">
                <div class="d-flex flex-column text-end mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">{{$t('ecommerce.shipping_address')}}</h6>
                    <span class="subtitle-14">{{invoice.order_primary.optionaddress.address.name}} | Tel: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.phone | phoneFormat}}</span> </span>
                    <span class="subtitle-14"> {{invoice.order_primary.optionaddress.address.address_line2}}, {{invoice.order_primary.optionaddress.address.city_name}} </span>
                    <span class="subtitle-14">Ref: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.reference}}.</span> </span>
                </div>
            </div>
            <div class="col-md-12 mt-5 mb-5" id="details">
                <div class="row ms-0 me-0 mr-0 ml-0 p-4 container-information" style="background: #F4F7F9;border-radius: 18px;">
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.invoice_number')}}</h6>
                        <span class="subtitle-16">{{invoice.order_token_id}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.order_placed')}}</h6>
                        <span>{{$d(new Date(invoice.created_at), 'alt_short')}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.delivery_date')}}</h6>
                        <span class="subtitle-16">19 Marzo 2022</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.time')}}</h6>
                        <span class="subtitle-16">{{$d(new Date(invoice.created_at), 'standard_hour')}}</span>
                    </div>                    
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.branch')}}</h6>
                        <span class="subtitle-16">{{filterBranch.description}}, {{filterBranch.city}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.order_total')}}</h6>
                        <span class="subtitle-16">{{invoice.order_primary.amount | moneyFormat(0)}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.payment_method')}}</h6>
                        <span class="subtitle-16" v-for="(payment, index) in invoice.order_primary.payments" :key="index">
                            {{payment.type}} <br>
                        </span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.delivery_type')}}</h6>
                        <span class="subtitle-16">{{invoice.order_primary.option_name != 'pick_up' ? $t('Delivery') : $t('ecommerce.pick_up')}} ({{invoice.amount_delivery | moneyFormat(0)}})</span>
                    </div>
                </div>
            </div>
            <div id="print-table">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.invoice_number')}}</h5>
                                <span class="subtitle-16">{{invoice.order_token_id}}</span>
                            </div>
                            <div class="mt-3">
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.branch')}}</h5>
                                <span class="subtitle-16">{{filterBranch.description}}, {{filterBranch.city}}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.order_placed')}}</h5>
                                <span>{{$d(new Date(invoice.created_at), 'alt_short')}}</span>
                            </div>
                            <div class="mt-3">
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.order_total')}}</h5>
                                <span class="subtitle-16">{{invoice.order_primary.amount | moneyFormat(0)}}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.delivery_date')}}</h5>
                                <span class="subtitle-16">19 Marzo 2022</span>
                            </div>
                            <div class="mt-3">
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.payment_method')}}</h5>
                                <span class="subtitle-16" v-for="(payment, index) in invoice.order_primary.payments" :key="index">
                                    {{payment.type}} <br>
                                </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.time')}}</h5>
                                <span class="subtitle-16">{{$d(new Date(invoice.created_at), 'standard_hour')}}</span>
                            </div>
                            <div class="mt-3">
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t('ecommerce.delivery_type')}}</h5>
                                <span class="subtitle-16">{{invoice.order_primary.option_name != 'pick_up' ? $t('Delivery') : $t('ecommerce.pick_up')}} ({{invoice.amount_delivery | moneyFormat(0)}})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <table class="table table-striped">
                    <thead style="border-bottom:2px solid var(--gray-100);border-top: 1px solid var(--light-gray-100)">
                        <tr>
                            <th scope="col" class="title-16 ps-md-4">{{$t("ecommerce.product_details")}}</th>
                            <th scope="col" class="text-center title-16">{{$t("ecommerce.price")}}</th>
                            <th scope="col" class="text-center title-16">{{$t("ecommerce.quantity")}}</th>
                            <th scope="col" class="text-center title-16">{{$t("ecommerce.total")}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in invoice.order_details" :key="product.product.codarticulo">
                            <td class="py-3 ps-md-4">
                                <div>
                                    <h6 class="text-limited title-14 mb-0">
                                            ({{ product.product.codarticulo  }}) {{ product.product.description }}
                                    </h6>
                                    <div class="d-flex mb-0" >
                                        <span class="size-14 me-3 mr-3">{{$t("ecommerce.color")}}: <span class="ms-1 ml-1 color-dark-gray-300 text-primary">{{product.product.color ? (product.product.color) : 'N/a'}}</span></span>
                                        <span class="size-14">{{$t("ecommerce.brand")}}: <span class="ms-1 ml-1 color-dark-gray-300 text-primary">{{product.product.brand ? (product.product.brand) : 'N/a'}}</span></span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center align-middle py-3">{{product.price_sold | moneyFormat(0)}}</td>
                            <td class="text-center align-middle py-3">{{product.quantity}}</td>
                            <td class="text-center align-middle py-3">{{product.amount | moneyFormat(0)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">
                <div class="d-flex justify-content-end">
                    <ul class="p-0 list-unstyled" style="min-width: 250px; padding-rigth: 4% !important">
                        <li class="d-flex justify-content-between subtitle-16 mt-2 color-dark-gray-300">{{$t("ecommerce.subtotal")}} <span>{{invoice.amount_sub | moneyFormat(0)}}</span> </li>
                        <li class="d-flex justify-content-between subtitle-16 mt-2 color-dark-gray-300">{{$t("ecommerce.tax")}} <span>{{invoice.amount_tax | moneyFormat(0)}}</span></li>
                        <li class="d-flex justify-content-between title-16 mt-2 mb-0 weight-700 font-weight-bold">{{$t("ecommerce.total")}}<span style="color:#BC2C35;">{{invoice.amount | moneyFormat(0)}}</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 mt-5 d-flex flex-column justify-content-center align-items-center">
                <h4 class="title-24 mb-0 weight-700">¡{{$t('ecommerce.thanks_for_your_purchase')}}!</h4>
                <span class="color-dark-gray-300">{{$t('ecommerce.follow_us_on_our_networks')}}</span>
                <span class="color-dark-gray-300" style="line-height: 10px;">@mundomovil</span>
                <div class="mt-4">
                    <icon-twitter :size="30"/>
                    <icon-instagram class="ms-3 ml-3" :size="30"/>
                    <icon-facebook class="ms-3 ml-3" :size="30"/>
                    <!-- <icon-youtube class="ms-3 ml-3" :size="30"/> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QrCode from 'vue-qrcode-component'
import { mapGetters } from "vuex"
import IconArrow from '../../../../common/components/svg/IconArrow.vue'
import IconFacebook from '../../../../common/components/svg/IconFacebook.vue'
import IconInstagram from '../../../../common/components/svg/IconInstagram.vue'
import IconTwitter from '../../../../common/components/svg/IconTwitter.vue'
export default {
    name: "Invoice",
    components: {
        QrCode,
        IconArrow,
        IconTwitter,
        IconInstagram,
        IconFacebook,
    },
    data() {
        return {
            invoice: {}
        }
    },
    props:{
        order:{
            type: Object
        }
    },
    computed:{
        ...mapGetters({
            // invoice: "account/invoice",
            stores: "ecommerce/stores",
            branchSelected: "branches/branchSelected",

        }),
        filterBranch(){
            const branch = this.$options.filters.getBranch(this.stores, this.invoice.branch_code || this.branchSelected.codplace)
            return branch
        }
    },
    methods:{
        goToBack(){
            window.close()
        }
    },
    async created(){
        this.invoice = JSON.parse(localStorage.getItem('invoice'))
        if(this.order){
            this.invoice = this.order
        }
    },
    destroyed() {
        this.invoice = null;
        localStorage.removeItem('invoice')
    },
}
</script>

<style>
    #print-invoice{
        max-width: 1100px;
        margin: auto;
    }
    .container-information{
        background: var(--light-gray-100);
        border-radius: 18px;
    }
    .ocrloader p::before {
        content: '';
        display:inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4979B2;
        position: relative;
        right: 4px;
    }
    .ocrloader p {
        color: #4979B2;
        position: absolute;
        bottom: -30px;
        left: 38%;
        font-size: 16px;
        font-weight: 600;
        animation: blinker 1.5s linear infinite;
        font-family: sans-serif;
        text-transform: uppercase;
    }

    .ocrloader {
        width: 135px;
        height: 130px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .ocrloader:before,
    .ocrloader:after,
    .ocrloader .bottom-line:after,
    .ocrloader .bottom-line:before {
        border-color: #4979B2;
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        border-style: solid;
        border-width: 0px;
    }
    .ocrloader:before {
        left: 0;
        top: 0;
        border-left-width: 3.5px;
        border-top-width: 3.5px;
        border-radius: 5px 0 0 0;
    }
    .ocrloader:after {
        right: 0;
        top: 0;
        border-right-width: 3.5px;
        border-top-width: 3.5px;
        border-radius: 0 5px 0 0;
    }
    .ocrloader .bottom-line:before {
        left: 0;
        bottom: 0;
        border-left-width: 3.5px;
        border-bottom-width: 3.5px;
        border-radius: 0 0 0 5px;
    }
    .ocrloader .bottom-line:after {
        right: 0;
        bottom: 0;
        border-right-width: 3.5px;
        border-bottom-width: 3.5px;
        border-radius: 0 0 5px 0;
    }
    .table-striped>tbody>tr:nth-of-type(odd)>*{
        --bs-table-accent-bg: #e9ecef
    }
    .table>:not(caption)>*>*{
        border-color: #d8e0eb;
    }
    .table-striped > tbody > tr{
        border-bottom: 2px solid #e9ecef;
    }
    @page {
        size: auto;  /* auto is the initial value */
        margin: 0mm; /* this affects the margin in the printer settings */
    }
    #print-table, #print-logo, #print-infomation{
        display: none;
    }
    #details, #logo{
        display: block;
    }
    #qr-code{
        display: flex;
    }
    #btn-print{
        display: initial;
    }
    
            #print-table > table > thead > tr >th{
            padding: 0;
        }
    @media print {
        #btn-back,  #btn-print, #details, #logo, #qr-code, #company, #address{
            display: none !important;
        }
        #print-table{
            display: block !important;
        }
        #print-logo, #print-infomation{
            display: flex;
        }
        #print-table{
            margin: 20px 0px;
        }
    }
    .hover-blue:hover{
        fill: var(--blue-600);
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div class="d-flex flex-column align-items-start ms-3">
            <h6 class="title-22 color-blue-700 mb-1">{{ $t('ecommerce.payment_method') }}</h6>
            <h6 class="subtitle-16 mb-0">{{ $t('ecommerce.info_methods_payments') }}</h6>
        </div>
        <b-row class="justify-content-md m-0 p-0">    
            <b-col class="pt-2 m-4" cols="12" md="auto" xl="6" lg="8">
                <el-collapse>
                    <el-collapse-item name="1">
                        <template slot="title">
                            {{ $t('ecommerce.cash') }}
                        </template>
                        <b-form-checkbox id="cash" v-model="values.cash.active" name="cash"> 
                            {{ $t('ecommerce.cash') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="cash-final" v-model="values.cash.final" name="cash-final" :disabled="!values.cash.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="cash-store" v-model="values.cash.tienda" name="cash-store" :disabled="!values.cash.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            {{ $t('ecommerce.credit') }}
                        </template>
                        <b-form-checkbox id="credit" v-model="values.credit.active" name="credit"> 
                            {{ $t('ecommerce.credit') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="credit-final" v-model="values.credit.final" name="credit-final" :disabled="!values.credit.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="credit-store" v-model="values.credit.tienda" name="credit-store" :disabled="!values.credit.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            {{ $t('ecommerce.note_credit') }}
                        </template>
                        <b-form-checkbox id="notas_de_credito" v-model="values.notas_de_credito.active" name="notas_de_credito"> 
                            {{ $t('ecommerce.note_credit') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="notas_de_credito-final" v-model="values.notas_de_credito.final" name="notas_de_credito-final" :disabled="!values.notas_de_credito.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="notas_de_credito-store" v-model="values.notas_de_credito.tienda" name="notas_de_credito-store" :disabled="!values.notas_de_credito.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <!-- <el-collapse-item title="Stripe Service" name="4">
                        <b-form-checkbox id="stripe-card" v-model="values.stripe.active" name="stripe-card"> 
                            Stripe Service
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="stripe-card-final" v-model="values.stripe.final" name="stripe-card-final" :disabled="!values.stripe.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="stripe-card-store" v-model="values.stripe.tienda" name="stripe-card-store" :disabled="!values.stripe.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item> -->
                    <el-collapse-item title="Paypal" name="5">
                        <b-form-checkbox id="paypal" v-model="values.paypal.active" name="paypal"> 
                            Paypal
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="paypal-final" v-model="values.paypal.final" name="paypal-final" :disabled="!values.paypal.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="paypal-store" v-model="values.paypal.tienda" name="paypal-store" :disabled="!values.paypal.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="6">
                        <template slot="title">
                            {{ $t('ecommerce.azul_payment') }}
                        </template>
                        <b-form-checkbox id="credit_card" v-model="values.credit_card.active" :disabled="values.stripe_backend_implementation.active" name="credit_card"> 
                            {{ $t('ecommerce.card') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="credit_card-final" v-model="values.credit_card.final" name="credit_card-final" :disabled="!values.credit_card.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="credit_card-store" v-model="values.credit_card.tienda" name="credit_card-store" :disabled="!values.credit_card.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Gift Card" name="7">
                        <b-form-checkbox id="git-card" v-model="values.gift_card.active" name="git-card"> 
                            Gift Card
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="git-card-final" v-model="values.gift_card.final" name="git-card-final" :disabled="!values.gift_card.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="git-card-store" v-model="values.gift_card.tienda" name="git-card-store" :disabled="!values.gift_card.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="8">
                        <template slot="title">
                            {{ $t('ecommerce.instant_deposit') }}
                        </template>
                        <b-form-checkbox id="express-deposit" v-model="values.express_deposit.active" name="express-deposit"> 
                            {{ $t('ecommerce.instant_deposit') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="express-deposit-final" v-model="values.express_deposit.final" name="express-deposit-final" :disabled="!values.express_deposit.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="express-deposit-store" v-model="values.express_deposit.tienda" name="express-deposit-store" :disabled="!values.express_deposit.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="9">
                        <template slot="title">
                            {{ $t('ecommerce.avalible_deposit') }}
                        </template>
                        <b-form-checkbox id="deposit" v-model="values.deposit.active" name="deposit"> 
                            {{ $t('ecommerce.avalible_deposit') }}
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="deposit-final" v-model="values.deposit.final" name="deposit-final" :disabled="!values.deposit.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="deposit-store" v-model="values.deposit.tienda" name="deposit-store" :disabled="!values.deposit.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="Card with stripe" name="11">
                        <b-form-checkbox id="stripe_v2" v-model="values.stripe_backend_implementation.active" :disabled="values.credit_card.active" name="stripe_v2"> 
                            Stripe Card
                        </b-form-checkbox>
                        <div class="ms-3">
                            <b-form-checkbox id="stripe_v2-final" v-model="values.stripe_backend_implementation.final" name="azul-final" :disabled="!values.stripe_backend_implementation.active"> 
                                {{ $t('ecommerce.final_customer') }}
                            </b-form-checkbox>
                            <b-form-checkbox id="stripe_v2-store" v-model="values.stripe_backend_implementation.tienda" name="azul-store" :disabled="!values.stripe_backend_implementation.active"> 
                                {{ $t('ecommerce.store') }}
                            </b-form-checkbox>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "PaymentMethods",
    props: {
        settings:{
            type: Object
        }
    },
    data() {
        return {
            values: {},
        }
    },
    created(){
        this.values = this.settings
    }
}
</script>

<style scoped>
    .custom-checkbox::v-deep > input, .custom-checkbox::v-deep > label{
        cursor: pointer;
    }
</style>

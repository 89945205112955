<script>

import Vertical from "../layouts/vertical";
import Horizontal from "../layouts/horizontal";
import { mapGetters } from "vuex"

export default {
  components: { Vertical, Horizontal },
  computed: {
    ...mapGetters({
      layoutType: 'layout/layoutType'
    })
  }
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>

<template>
    <icon-base viewBox="0 0 32 32" :width="size + 'px'" :fill="color"> 
        <path d="M26,21c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0-1.7-1.3-3-3-3h-0.4l-2-4h5.5c1,0,1.9-0.8,1.9-1.9C32,7.3,29.7,5,26.9,5H1
            C0.4,5,0,5.4,0,6v5v15c0,0.6,0.4,1,1,1h1.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H17h2.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H27
            c1.7,0,3-1.3,3-3v-3H26z M6,28c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S7.1,28,6,28z M18,16v-4h4.4l2,4H18z M23,28c-1.1,0-2-0.9-2-2
            s0.9-2,2-2s2,0.9,2,2S24.1,28,23,28z"/>
    </icon-base>
</template>

<script>
import IconBase from '../../../../../common/components/svg/IconBase.vue'
export default {
    components: { IconBase },
    name: "IconTruck",
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        },
    }
}
</script>

<style>

</style>
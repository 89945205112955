<template>
    <main-modal @closeModal="$emit('closeModal')">
        <avatar :name="'Mundo'" :lastname="'Móvil'" :date="getNow" :customerVerified="true" :email="user.company_email" :customerImg="require('../../../../../common/assets/images/mundo-movil/logo-sm.png')"/>
        <form @submit.prevent="send" class="mt-4">
            <label for="review" class="title-15">{{$t('ecommerce.answer')}} <span class="color-dark-gray-200 weight-500">(300 {{$t('ecommerce.characters')}} max)</span></label>
            <b-form-textarea id="answer" v-model="answer" placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea>
            <!-- <div class="form-group">
                <label for="review" class="title-15">{{$t('ecommerce.answer')}} <span class="color-dark-gray-200 weight-500">(300 {{$t('product.characters')}} max)</span></label>
                <textarea class="form-control" id="review" rows="5" placeholder="Escribe tu respuesta..." maxlength="300" v-model="answer" :class="{ 'textarea-invalid': $v.answer.$error }"></textarea>
            </div> -->
            <div class="d-flex justify-content-end mt-4">
                <b-button variant="primary" @click="send">
                    Guardar
                </b-button>
            </div>
        </form>
    </main-modal>
</template>

<script>
import { required, maxLength} from 'vuelidate/lib/validators'
import MainModal from '../../../../../common/components/modals/MainModal.vue'
import { ProductsService} from "../../../service/products-service";
const productService = new ProductsService();
import Avatar from '../../avatar/Avatar.vue';
import {mapGetters} from 'vuex'
export default {
    components: {MainModal, Avatar},
    name: "CreateAnswer",
    data() {
        return {
            answer: ''
        }
    },
    props:{
        code: {
            type: String
        },
        update:{
            type: Object,
            default: null
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/user"
        }),
        getNow(){
            return Date.now()
        }
    },
    validations: {
        answer: {
            required,
            maxLength: maxLength(300)
        },
    },
    created(){
        if(this.update){
            this.answer = this.update.answer
        }
    },
    methods:{
        async send(){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "¿Esta seguro de que agregar esta respuesta?", 
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        // if(this.update){
                        //     await productService.updateAnswer(this.update.id, this.answer)
                        // }else{
                        await productService.addAnswer(this.code, this.answer)
                        this.$swal.fire({ 
                            title: 'Guardado con exito', text: "Respuesta guardada con exito.", 
                            icon: "success",
                            timer: 2000
                        })
                        // }
                        this.$emit('closeModal')
                        this.$emit('refresh')
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({ 
                            title: 'Error al guardar', text: "No se ha podido completar la transaccion", 
                            icon: "error",
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
    .modal-mask::v-deep > .modal-container{
        min-width: 600px;
    }
    .form-group::v-deep.textarea {
        border-radius: 10px;
        border-color: var(--gray-100);
        resize: none;
    }
</style>

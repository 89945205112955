import Mapbox from "mapbox-gl";
import { http } from "@/common/services/web-backend.js";

export const mapCustomer = {
    data(){
        return{
            customers: [],
            maxDistance: 50000,
            markers: [],  // Array para almacenar todos tus marcadores
            visibleMarkers: [],  // Array para almacenar los marcadores visibles
            nonVisibleMarkers: [],  // Array para almacenar los marcadores no visibles
            maxVisibleMarkers: 20,  // Límite para la cantidad de marcadores visibles
        }
    },
    computed:{
        // map() {
        //     return this.$refs.map.map;
        // },
    },
    methods:{
        onMapLoaded(e) {
            const map = e.map;
            // Luego puedes llamar a updateVisibleMarkers en un evento de movimiento del mapa para actualizar los marcadores visibles
            map.on('moveend', () => {
                if(this.showCustomers) this.updateVisibleMarkers();
            });
        },
        async getPointsFromERP(){
            try {
                this.loading = true
                const {data} = await http.get(`geography/map`);
                this.customers = data.data;
                this.customers.forEach(c => {
                    this.addMarker(c.properties.address_id,c.geometry.coordinates[0], c.geometry.coordinates[1], c.properties)
                });
            } catch (e) {
                console.log(e)
            }finally{
                this.loading = false
            }
        },
        // Función para agregar un marcador al mapa y al array
        addMarker(id, lng, lat, properties) {
                
            const htmlContent = `
                <div>
                    <h6>${properties.first_name} ${properties.last_name}</h6>
                    <ul class="p-0">
                        <li> <b>${this.$t('ecommerce.code')}</b>  ${properties.code}</li>
                        <li> <b>ID</b>  ${properties.address_id}</li>
                        <li> <b>${this.$t('ecommerce.verified_buyer')}</b>  ${properties.verified}</li>
                    </ul>
                </div>`;
            const popup = new Mapbox.Popup({ offset: 25 }) // crea un popup y establece el offset
            .setHTML(htmlContent); // establece el contenido HTML que se mostrará en el popup

            // let el = document.createElement('div');
            // el.className = 'marker';
            // el.style.backgroundImage = `url(${require(`../assets/svg/${properties.verified ? 'active' : 'inactive'}.svg`)})`;
            // const marker = new Mapbox.Marker({element: el})
            const marker = new Mapbox.Marker()
                .setLngLat([lng, lat])
                .setPopup(popup) // agrega el popup al marcador
                // .addTo(this.map);


            marker.id = id;
            this.markers.push(marker);

            // Al principio, todos los marcadores son no visibles
            this.nonVisibleMarkers.push(marker);
        },

        // Función para actualizar los marcadores visibles
        updateVisibleMarkers() {
            let bounds = this.map.getBounds();

            // Filtra los marcadores no visibles para encontrar los que están en la vista
            const newlyVisibleMarkers = this.nonVisibleMarkers.filter(marker => bounds.contains(marker.getLngLat()));

            // Agrega los marcadores recién visibles a la lista de marcadores visibles, hasta el límite
            for (let i = 0; i < newlyVisibleMarkers.length && this.visibleMarkers.length < this.maxVisibleMarkers; i++) {
                const marker = newlyVisibleMarkers[i];
                marker.addTo(this.map);
                this.visibleMarkers.push(marker);
                this.nonVisibleMarkers = this.nonVisibleMarkers.filter(m => m.id !== marker.id);
            }

            // Filtra los marcadores visibles para encontrar los que están fuera de la vista
            let newlyNonVisibleMarkers = this.visibleMarkers.filter(marker => !bounds.contains(marker.getLngLat()));

            // Elimina los marcadores recién no visibles de la lista de marcadores visibles y añádelos a los no visibles
            for (let i = 0; i < newlyNonVisibleMarkers.length; i++) {
                let marker = newlyNonVisibleMarkers[i];
                marker.remove();
                this.visibleMarkers = this.visibleMarkers.filter(m => m.id !== marker.id);
                this.nonVisibleMarkers.push(marker);
            }
        },

        updateMarkers(filteredCustomers) {
            // Elimina los marcadores actuales
            for (const marker of this.markers) {
                marker.remove();
            }
            this.markers = [];
            this.visibleMarkers = [];
            this.nonVisibleMarkers = [];
            
            // Agrega los nuevos marcadores según los customers filtrados
            filteredCustomers.forEach(customer => {
                this.addMarker(
                    customer.properties.address_id,
                    customer.geometry.coordinates[0],
                    customer.geometry.coordinates[1],
                    customer.properties
                );
            });
            
            // Actualiza los marcadores visibles
            this.updateVisibleMarkers();
        }
    },
    mounted(){
        // this.getPointsFromERP();
    }
};
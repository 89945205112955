export default {
  documents: (state) => state.documents,
  documentDetails: (state) => state.documentDetails,
  slas: (state) => state.slas,
  actions: (state) => state.actions,
  types: (state) => state.types,
  documentSummary: (state) => state.documentSummary,
  documentChart: (state) => state.documentChart,
  iframe: (state) => state.iframe,
}

<template>
    <div class="container-menu">
        <div class="position-relative h-100">
            <div class="d-flex justify-content-end p-2"> <i class="fas fa-times fs-4 cursor-pointer hover-main-gray" @click="$emit('closeMenu')"> </i> </div>
            <div class="menu px-2" style="height: 93%;">
                <h3 class="px-2 mb-0">{{$t('ecommerce.image_gallery')}}</h3>
                <swiper :options="swiperOption" style="height: 93%;">
                    <swiper-slide :key="image.path_s3" v-for="image in images" @click.native="selectedPicture = image.path_s3; showLightBox = true;" class="d-flex justify-content-center">
                        <img v-lazy="image.path_s3" class="img-product-details"/>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <light-box v-if="showLightBox" :active="selectedPicture" :images="images" @closeLightBox="showLightBox = false"/>
    </div>
</template>

<script>
import LightBox from '../../../../common/components/modals/LightBox.vue'
export default {
    name: "RightMenu",
    props:{
        entity:{
            type: Object
        },
        images:{
            type: Array
        }
    },
    components:{
        LightBox
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 3,
                slidesPerColumn: 1,
                direction: 'vertical',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
            },
            // images: [1, 2, 3, 4, 5, 6, 7, 8],
            showLightBox: false,
            selectedPicture: null
        }
    },
}
</script>

<style scoped>
.container-menu{
    position: absolute;
    width: 300px;
    height: 100%;
    background-color: #fff;
    right: 0;
    top: 0;
}
.img-product-details{
    object-fit: contain;
    margin: auto;
    border: 1px solid #e9ecef;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    width: 230px;
    height: 230px;
}
</style>

<template>
    <main-modal @closeModal="$emit('closeModal')" :title="'Modificar ubicacion de tarea'">
        <b-row>
            <b-col :lg="task.type_id != 2 ? 8 : 12">
                <create-task-map @setCoordinates="setCoordinates" @setAddress="(data)=>{address = data}" class="mt-3" :center.="[task.longitude, task.latitude]"
                :addresses="result" :task="task" :select="select" style="height: 50vh !important;"/>
            </b-col>
            <b-col lg="4" v-if="task.type_id != 2">
                <h4>Lista de direcciones</h4>
                <div class="mb-2">
                    <label for="search">Buscar por nombre</label>
                    <b-form-input v-model="search" id="search" type="search" debounce="500"></b-form-input>
                </div>
                <b-list-group class="address-list" v-if="task.method_id == 1">
                    <b-list-group-item v-for="address in result" :key="address.id" :active="address.latitud == select.latitude && address.longitud == select.longitude"
                    @click="selectAddress(address)">
                        <b>Nombre:</b> <span class="text-capitalize">{{address.name | textFormat}}</span> <br>
                        <span class="container-address-description">{{address.address_line}}</span>
                        <b> Ref:</b> {{address.reference}}
                    </b-list-group-item>
                </b-list-group>
                <b-list-group class="address-list" v-else>
                    <b-list-group-item v-for="address in result" :key="address.id" :active="address.latitud == select.latitude && address.longitud == select.longitude"
                    @click="selectAddress(address)">
                        <b>Nombre:</b> <span class="text-capitalize">{{address.name | textFormat}}</span> <br>
                        <span class="container-address-description">{{address.address_line}}</span>
                        <b> Ref:</b> {{address.reference}}
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
            <b-button  variant="primary" @click="save"> {{$t('common.save')}} <eva-icon name="save" fill="#fff" width="20"></eva-icon></b-button>
        </div>
    </main-modal>
</template>

<script>
    import MainModal from '../../../../../common/components/modals/MainModal.vue'
    import CreateTaskMap from '../../TaskMap.vue'
    import { http } from "@/common/services/web-backend.js";
    import { CustomerService } from "../../../../ecommerce/service/customer-service";
    const customerService = new CustomerService()
    export default {
        components: { MainModal, CreateTaskMap },
        name :"UpdateTask",
        data(){
            return{
                coordinates: null,
                addresses: [],
                select: null,
                search: '',
                result: []
            }
        },
        props:{
            task:{
                type: Object
            }
        },  
        methods:{
            async save(){
                try {
                    let body = {
                        latitude: this.select.latitude,
                        longitude: this.select.longitude,
                    }
                    this.$swal.fire({ 
                        title: 'Confirmacion', text: "Estas seguro que deseas modificar la direccion ?", 
                        icon: "warning", showCancelButton: true, confirmButtonText: 'Confirma', 
                        cancelButtonText: 'Cancelar',
                    }).then(async (result) => {
                        if(result.isConfirmed){ 
                            await http.put(`/task/${this.task.id}`, body)
                            this.$emit('closeModal')
                            this.$swal.fire({ 
                                title: 'Guardado con exito', text: "Los cambios se han almacenado", 
                                icon: "success",
                            })
                        }
                    })
                } catch (error) {
                    this.$swal.fire({ 
                        title: 'Error al guardar', text: error.response.data.message, 
                        icon: "error",
                    })
                }
            },
            async getAddresses(){
                if(this.task.customer_code){
                    if(this.task.method_id == 1){
                        const response = await customerService.getAllAddress(this.task.customer_code)
                        this.addresses = response.data
                        this.result = this.addresses
                    }
                }
            },
            async getBusStop(){
                if(this.task.method_id == 2){
                    const response = await http.get('/BUSStop')
                    this.addresses = response.data.data
                    this.result = this.addresses
                }
            },
            setCoordinates(data){
                this.select = {
                    latitude: data[1],
                    longitude: data[0],
                    id: this.address.id
                }
            },
            selectAddress(address){
                this.select = {
                    latitude: address.latitud,
                    longitude: address.longitud,
                    id: address.id
                }
            }
        },
        watch:{
            search(val){
                const result = this.addresses.filter(address => address.name.toLowerCase().includes(val.toLowerCase()))
                this.result = result
                if(!val){
                    this.result = this.addresses
                }
            },
        },
        async created(){
            this.select = {
                latitude: this.task.latitude,
                longitude: this.task.longitude
            }
            await this.getAddresses()
            await this.getBusStop()
        }
    }
</script>

<style scoped>
    .modal-mask::v-deep > .modal-container{
        min-width: 1200px;
    }
    .container-address-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .address-list{
        max-height: 419px;
        overflow: auto;
    }
</style>

<template>
    <main-modal @closeModal="() => this.$emit('closeModal')">
        <b-row>
            <h2 class="text-center">{{ $t('delivery.create_type_for_incidents') }}</h2>
        </b-row>
        <b-row>
            <b-col xs="12" sm="6" class="mx-auto">
                <b-form @submit="onSubmit" class="mt-4">
                    <b-form-group 
                        id="input-group-description" 
                        label="Description " 
                        label-for="input-description"
                    >
                        <b-form-input
                            id="input-description"
                            v-model="form.description"
                            placeholder="Enter Description"
                            required
                        >
                        </b-form-input>
                    </b-form-group>

                    <div class="mt-4">
                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger" class="close-button" @click="close">{{ $t('delivery.close') }}</b-button>
                    </div>
                </b-form>
            </b-col>
        </b-row>
    </main-modal>
</template>

<script>
    import MainModal from '@/common/components/modals/MainModal.vue';
    import { http } from '@/common/services/web-backend.js';

    export default {
        name: "CreateTaskTypeModal",
        components: { MainModal } ,
        props: {
            closeModal: {
                type: Function
            },
        },
        data() {
            return {
                form: {
                    description: "",
                },
            }
        },
        methods: {
            async create(payload) {
                try {
                    const response = await http.post("incidents/types", {
                        description: payload.description
                    });

                    if (!response || !response.data) {
                        throw new Error("backend-erp respuesta inválida");
                    }

                    this.$swal.fire({
                        title: this.$t("delivery.incident_type_registered_successfully"),
                        icon: "success",
                    }).then(() => {
                        this.$emit("getRecords");
                        this.close();
                    });
                } catch (error) {
                    console.error(error);
                    this.$swal.fire({ 
                        title: 'Oops! Something went wrong.', text: error, 
                        icon: "error",
                    })
                }
            },
            onSubmit(event) {
                event.preventDefault();
                this.create(this.form);
            },
            close() {
                this.$emit('closeModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .close-button {
        margin-left: 10px;
    }
</style>
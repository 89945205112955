<template>
    <div>
            <el-row class="my-4" :gutter="40">
                <el-col :xl="6" :lg="8" :md="12">
                    <div class="d-flex flex-column">
                        <label for="access-level">Empleados</label>
                        <div class="d-flex align-items-center">
                            <el-select size="mini" v-model="employeeSelected" filterable clear class="me-2" value-key="id">
                                <el-option v-for="item in employees" :key="item.id" :label="`[${item.id}] ${item.name}`" :value="item"/>
                            </el-select>
                        </div>
                    </div>
                    <br/>
                    <div class="d-flex flex-column">
                        <label for="modules">Access levels</label>
                        <div class="d-flex align-items-center">
                            <el-select size="mini" v-model="accessLevel" filterable clear class="me-2" value-key="id">
                                <el-option v-for="item in getAccessLevels" :key="item.id" :label="`[${item.id}] ${item.name}`" :value="item"/>
                            </el-select>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-container style="gap:20px">
                <el-aside>
                    <h5>Modules</h5>
                    <el-table size="mini" :data="extraAccess" stripe :row-class-name="tableRowClass">
                            <el-table-column prop="id" label="ID" width="40"/>
                            <el-table-column prop="name" label="Nombre"/>
                            <el-table-column label="subModulos" align="center" width="100">
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="showModule(scope.row)" icon="el-icon-right" :type="scope.row.active ? 'success' : 'danger'" circle/>
                                </template>
                            </el-table-column>
                    </el-table>
                </el-aside>
                <el-aside v-if="selectedModule">
                    <h5>{{ selectedModule.name }}</h5>
                    <el-table size="mini" :data="subModules" stripe :row-class-name="tableRowClassName">
                        <el-table-column prop="id" label="ID" width="40"/>
                        <el-table-column prop="name" label="Nombre"/>
                        <el-table-column label="Acciones" align="center" width="100">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="showSubModule(scope.row)" icon="el-icon-right" :type="scope.row.active ? 'success' : 'danger'" circle/>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-aside>
                <template v-if="selectedSubModule">
                    <el-container class="d-flex flex-column">
                        <h5>Permisos</h5>
                        <el-table size="mini" :data="[selectedSubModule]" :key="`permissions-${tableKey}`">
                                <el-table-column prop="id" label="ID" width="40"/>
                                <el-table-column prop="name" label="Nombre" width="120"/>
                                <el-table-column :label="permission.name"  v-for="permission in selectedSubModule.permissions" :key="permission.id" align="center" width="120">
                                    <template>
                                        <el-checkbox v-model="permission.active" @change="updatePermissionEmployee(permission.key, permission.active)"></el-checkbox>
                                    </template>
                                </el-table-column>
                        </el-table>
                        <el-card size="mini" style="max-width: 655px;">
                            <div slot="header" class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0">Acciones</h6>
                            </div>
                            <div class="container-actions bg-white">
                                <el-checkbox  v-model="action.active" v-for="action in selectedSubModule.actions" :key="`action-key-${action.id}`" @click.native="updateActionEmployee(action.id, action.active)">
                                    {{ action.name }}   
                                </el-checkbox>
                            </div>
                        </el-card>
                    </el-container>
                </template>
                <el-empty description="Por favor selecciona algun sub modulo para comenzar a configurar." v-else  class="m-auto"/>
            </el-container>
    </div> 
</template>

<script>
    import { http }  from "@/common/services/web-backend.js";
    import { db } from "@/firebase-config";
    import { mapGetters } from "vuex";
    export default {
        name: "EmployeeRol",
        components:{
        },
        computed:{
            ...mapGetters({
                getAccessLevels: 'auth/accessLevels',
                listEmployees: 'auth/listEmployees'
            }),
            subModules(){
                const filter = this.extraAccess.find((module)=>module?.id == this.selectedModule?.id);
                return filter?.subModules;
            },
            selected(){
                const filter = this.subModules?.find((module)=>module?.id == this.selectedSubModule?.id);
                return filter;
            },
        },
        data() {
            return {
                selectedLevel: [],
                allEmployee: [],
                singleModules: [],
                employees: [],
                employeeSelected: [],
                accessLevel: [],
                accessLevels:[],
                copyEmployee: [],
                activeTab: 'tab2',
                extraAccess: [],
                extraAccessSelect: [],
                firebaseOnly: [],
                selectedEmployee: [],
                selectedModule: null,
                employeeLevelFirebase: null,
                firebaseDataLocal: [],
                localDataBackUp: [],
                selectedSubModule: null,
                value: [],
                localChangesModules: [],
                filterMethod(query, item) {
                    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
                selectedPermissions:[],
                search: '',
                tableKey: 1,
                isActive: true,
            }
        },
        watch:{
            async 'getAccessLevels'(){
                console.log('employeeSelected');
                this.localChangesModules = []
                this.firebaseDataLocal = this.getAccessLevels
                this.getLevelsRef()
            },
            'employeeSelected'(){
                this.accessLevel = []
                this.extraAccess = []
                this.isActive = true
                if(this.employeeSelected?.accessLevels){
                    const data = this.getAccessLevels.filter(data => data.id === this.employeeSelected.accessLevels)
                    return this.accessLevel = data[0]
                }
            },
            'accessLevel'(){
                this.getLevelsRef()
                this.selectedModule = null
                this.localDataBackUp = this.accessLevel.modules
                if(this.employeeSelected.accessLevels == this.accessLevel.id){
                    if(this.employeeSelected.extraAccess){
                        console.log('entro');
                        this.mergedExtraAccess()
                    }
                }else if(this.employeeSelected.accessLevels != this.accessLevel.id) {
                    const extraAccessNew = this.firebaseOnly.find((data) => data?.id === this.accessLevel?.id)
                    this.extraAccess = extraAccessNew?.modules
                    this.isActive = true
                }
                if(this.accessLevel?.modules){
                    this.handleAccessChange()
                }
            },
            'selectedModule'(){
                this.selectedSubModule = null
            },
        },
        methods:{
            removeDuplicateEmployee(){
                function removeObjectsWithDuplicateIds(array1, array2) {
                    const uniqueIds = new Set(array1.map(obj => obj.id));
                    const filteredArray2 = array2.filter(obj => !uniqueIds.has(obj.id));
                    return filteredArray2;
                }

                const array1 = this.listEmployees

                const array2 =  this.employees

                const uniqueArray2 = removeObjectsWithDuplicateIds(array1, array2);

                this.employees = uniqueArray2
                const mergedEmployee = [...this.employees, ...this.listEmployees]
                this.employees = mergedEmployee
            },
            showModule(sub){
                this.selectedModule = sub;
            },
            showSubModule(sub){
                this.selectedSubModule = sub;
                this.selectedPermissions = sub.permissions;
            },
            updateActiveModules(){
                const data = this.selectedModule.subModules
                const activeItems = data.filter(item => item.active === true);
                if(activeItems.length > 0){
                    this.$set(this.selectedModule, 'active', true);
                } else{
                    this.$set(this.selectedModule, 'active', false);
                }
            },
            updateActionEmployee(id, val){
                this.updateActiveModules()
                const indexToUpdates = this.selectedSubModule.actions.findIndex(p => p.id == id);
                if (indexToUpdates !== -1) {
                    this.$set(this.selectedSubModule.actions[indexToUpdates], 'active', val);
                }
                this.tableKey++;
                this.updateActiveModules()
                this.activitySubModule()
                this.filterExtraAccess()
            },
            updatePermissionEmployee(id, val){
                const indexToUpdates = this.selectedSubModule.permissions.findIndex(p => p.id == id);
                if (indexToUpdates !== -1) {
                    this.$set(this.selectedSubModule.permissions[indexToUpdates], 'active', val);
                }
                this.tableKey++;
                this.activitySubModule()
                this.updateActiveModules()
                this.filterExtraAccess()
            },
            activitySubModule(){
                const filteredObjectsActions = this.selectedSubModule.actions.filter(obj => obj.active === true);
                const filteredObjectsPermisions = this.selectedSubModule.permissions.filter(obj => obj.active === true);
                if(filteredObjectsPermisions.length > 0 || filteredObjectsActions.length > 0){
                    this.$set(this.selectedSubModule, 'active', true);
                } else {
                    this.$set(this.selectedSubModule, 'active', false);
                }
            },
            tableRowClass({row}) {
                const validate = this.selectedModule?.id == row.id;
                if (validate) return 'success-row';
            
                return '';
            },
            tableRowClassName({row}) {
                const validate = this.selectedSubModule?.id == row.id;
                if (validate) return 'success-row';
            
                return '';
            },
            async getEmployee(){
                try {
                    const {data} = await http.get('/employee?limit=500');

                    data.data.data.forEach(employee => {
                        this.employees.push({
                            id: employee.entity.code,
                            name: employee.entity.first_name.toLowerCase(),
                            active: employee.entity.activated_employee
                        })
                    });
                    this.employeeSelected = this.employees[0]
                    this.removeDuplicateEmployee()
                } catch (error) {
                    console.log(error);
                }
            },
            getLevel(){
                this.selectedLevel = this.getAccessLevels.find((data) => data.name === this.accessLevel.name)
            },
            searchEmployeeLevel(){
                const data = this.firebaseDataLocal
                function findAccessLevelsContainingEmployee(employeeName) {
                    return data.filter((accessLevel) => {
                        return accessLevel.employees?.some((employee) => employee.name === employeeName);
                    });
                }

                const result = findAccessLevelsContainingEmployee(this.employeeSelected.name);
                const levelsData = this.getAccessLevels
                const findData = levelsData.find((data) => data?.name === result[0]?.name)
                console.log(levelsData);
                // Function to remove an object based on a condition
                function removeObjectById(arr, idToRemove) {
                    return arr?.filter(item => item.id !== idToRemove);
                }

                // Remove an object with id 2 from the array
                const idToRemove = this.employeeSelected.id;
                const newArray = removeObjectById(findData?.employees, idToRemove);
                console.log(newArray);
                findData.employees = newArray

            },
            handleDelete(){
                this.searchEmployeeLevel()
                const arrayEmployee = [this.employeeSelected]
                const data = []
                arrayEmployee.forEach(employee => {
                    data.push({
                        id: employee.id,
                        name: employee.name,
                        active: employee.active,
                        accessLevels: this.accessLevel.id
                    })
                })
                console.log(arrayEmployee);
                this.employeeSelected = data[0]
            },
            setAllEmployeees(){
                const employeeNames = this.getAccessLevels.map(obj => obj.employees);
                const allEmployees = [].concat(...employeeNames);
                const cleanedArr = allEmployees.filter(item => item !== undefined && item !== null);
                this.$store.commit('auth/SET_ACCESS_LIST_EMPLOYEEES', cleanedArr)
            },
            async handleAccessChange(){
                this.getLevel()
                const searchFireData = this.firebaseDataLocal
                const content = searchFireData.find((data) => data.id === this.accessLevel.id)
                if(content?.employees){
                    if(this.employeeSelected?.accessLevels || this.copyEmployee[0]?.accessLevels){
                        if((this.employeeSelected?.accessLevels || this.copyEmployee[0]?.accessLevels) !== this.accessLevel?.id){
                            // console.log('hola 2');
                            return this.handleDelete()
                        }
                    }
                }

                // console.log(!this.employeeSelected.accessLevels);
                const employeeRef = db.ref(`admin/employees/${this.employeeSelected.id}`);
                await employeeRef.once('value', (snapshot) => {
                    const getEmployee = snapshot.val();
                    this.employeeLevelFirebase = getEmployee
                })
                console.log(this.localChangesModules?.id, 'localChangesModules');
                console.log(this.employeeSelected?.accessLevels, 'employee accessLevels');
                if((this.employeeLevelFirebase?.accessLevels) != this.employeeSelected?.accessLevels){
                    this.localChangesModules = this.accessLevel
                    return this.mergedData();
                } else if(!this.employeeSelected.accessLevels){
                    this.localChangesModules = this.accessLevel
                    return this.mergedData();
                }if(this.localChangesModules?.id){
                    if(this.localChangesModules?.id != this.employeeSelected?.accessLevels){
                        console.log('here');
                        return this.mergedData()
                    }
                }
            },
            mergedData(){
                if(this.extraAccess.length == 0){
                    const selectLevelFirebase = this.firebaseOnly.filter(data => data.id === this.accessLevel.id)
                    this.extraAccess = selectLevelFirebase[0].modules
                }
                const module = this.firebaseOnly.find((data) => data.id === this.accessLevel.id)
                    this.singleModules = module.modules
                    const mergedArray = [this.employeeSelected]
                    console.log(mergedArray);
                    mergedArray.forEach(obj => {
                        obj.modules = this.singleModules
                    })
                    const data = []
                    // console.log(this.extraAccess);
                    mergedArray.forEach(employee => {
                        data.push({
                            id: employee.id,
                            name: employee.name,
                            active: employee.active,
                            accessLevels: this.accessLevel.id,
                            extraAccess: this.extraAccess
                        })
                    });
                    this.copyEmployee = [...data];
                    const mergedEmployee = [...this.accessLevel.employees, ...data]
                    // console.log(mergedEmployee);
                    this.accessLevel.employees = mergedEmployee
                    // console.log(this.copyEmployee);
                    // this.getLevelsRef()
                    // this.firebaseData = this.getAccessLevels
                    this.setAllEmployeees()
                },
                async getLevelsRef(){
                const accesLevelsRef = db.ref(`admin/accessLevels`);
                await accesLevelsRef.once('value', (snapshot) => {
                    const accesLevelsRef = snapshot.val();
                    this.firebaseOnly = accesLevelsRef
                })
            },
            filterExtraAccess(){
                const selectLevelFirebase = this.firebaseOnly.filter(data => data.id === this.accessLevel.id)

                function encontrarDiferencias(array1, array2, diferencias = []) {
                    // Asegurémonos de que ambos arrays tengan la misma longitud
                    if (array1.length !== array2.length) {
                        throw new Error("Los arrays no tienen la misma longitud");
                    }

                    for (let i = 0; i < array1.length; i++) {
                        const objeto1 = array1[i];
                        const objeto2 = array2[i];

                        // Comparamos el valor de la propiedad "active" de ambos objetos
                        if (objeto1.active !== objeto2.active) {
                            // Comprobamos si ya existe en la lista de diferencias
                            const existe = diferencias.some((diff) => diff.id === objeto1.id);
                            if (!existe) {
                                diferencias.push(objeto1);
                            }
                        }

                        // Comprobamos si ambos objetos tienen subModules
                        if (objeto1.subModules && objeto2.subModules) {
                            const subModuleDiferencias = encontrarDiferencias(objeto1.subModules, objeto2.subModules, []);
                            if (subModuleDiferencias.length > 0) {
                                const objetoDiferencia = { ...objeto1 };
                                objetoDiferencia.subModules = subModuleDiferencias;
                                // Comprobamos si ya existe en la lista de diferencias
                                const existe = diferencias.some((diff) => diff.id === objetoDiferencia.id);
                                if (!existe) {
                                    diferencias.push(objetoDiferencia);
                                }
                            }
                        }
                    }

                    return diferencias;
                }
                // console.log(this.isActive);
                // console.log(this.localChangesModules.length != 0);
                if(this.localChangesModules.length != 0){
                    console.log('enter');
                    const diferencias = encontrarDiferencias(this.extraAccess, this.accessLevel.modules);
                    // console.log(selectLevelFirebase[0].modules);
                    // console.log(diferencias);
                    // console.log(diferencias);

                    this.extraAccessSelect = diferencias
                    // console.log(this.extraAccessSelect);
                    // console.log(this.extraAccess, 'extraAccess');
                    // console.log(selectLevelFirebase[0].modules, 'fireBaseModule');
                    const selected = this.accessLevel.employees.find((data) => data.id  === this.employeeSelected.id)

                    selected.extraAccess = this.extraAccessSelect
                } else {
                    console.log('out');
                    const diferencias = encontrarDiferencias(this.extraAccess, this.isActive ?  this.accessLevel.modules : selectLevelFirebase[0].modules );
                    // console.log(selectLevelFirebase[0].modules);
                    // console.log(diferencias, 'diferencias');
                    // console.log(diferencias.length != 0, 'diferent null');
                    if(diferencias.length != 0){
                        // console.log('enter diferencia null');

                        this.extraAccessSelect = diferencias
                        const selected = this.accessLevel.employees.find((data) => data.id  === this.employeeSelected.id)

                        selected.extraAccess = this.extraAccessSelect
                    } else {
                        // console.log('out diferencia null');

                        this.extraAccessSelect = this.extraAccess
                        const selected = this.accessLevel.employees.find((data) => data.id  === this.employeeSelected.id)

                        selected.extraAccess = this.extraAccessSelect
                    }
                }


            },
            mergedExtraAccess(){
                const selectLevelFirebase = this.firebaseOnly.filter(data => data.id === this.accessLevel.id)

                function mergeModulesWithExtraAccess(modules, extraAccess) {
                    for (const module of modules) {
                        const matchingExtraAccess = extraAccess.find(extraModule => extraModule.id === module.id);

                        if (matchingExtraAccess) {
                        if (matchingExtraAccess.active !== module.active) {
                            module.active = matchingExtraAccess.active;
                        }

                        if (module.subModules && matchingExtraAccess.subModules) {
                            module.subModules = mergeModulesWithExtraAccess(module.subModules, matchingExtraAccess.subModules);
                        }

                        // Verificar y actualizar 'active' en 'actions' y 'permissions'.
                        const subObjectsToCheck = ['actions', 'permissions'];
                            for (const key of subObjectsToCheck) {
                                if (module[key] && matchingExtraAccess[key]) {
                                for (let i = 0; i < module[key].length; i++) {
                                    if (matchingExtraAccess[key][i] && module[key][i].active !== matchingExtraAccess[key][i].active) {
                                    module[key][i].active = matchingExtraAccess[key][i].active;
                                    }
                                }
                                }
                            }
                        }
                    }

                    return modules;
                }
                const updatedModules = mergeModulesWithExtraAccess(selectLevelFirebase[0].modules, this.employeeSelected.extraAccess);
                console.log(updatedModules);
                this.extraAccess = updatedModules
            }
        },
        async created(){
            this.firebaseDataLocal = this.getAccessLevels
            await this.getEmployee()
        }
    }
</script>

<style scoped>
    .el-table::v-deep .warning-row {
        background: oldlace;
    }

    .el-table::v-deep .success-row,.el-table::v-deep .el-table__row--striped.success-row > td{
        background: #f0f9eb !important;
    }

    .el-table::before {
        left: 0;
        bottom: 0;
        width: 0% !important;
        height: 1px;
    }
    .container-actions {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
        gap: 16px; /* Espacio entre los elementos del grid */
    }
</style>
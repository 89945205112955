import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const auth = firebase.auth();

export {db, auth};

<template>
    <div>
        <b-row>
            <b-col cols="12">
            <b-row>
                <b-col lg="4" xl="2">
                    <label for="date" class="me-2">{{$t('tracking.filter_by_date')}}:</label>
                    <date-picker v-model="filters.range" :value="filters.range" :input-class="'datePick'" range></date-picker>
                </b-col>
                <b-col lg="4" xl="5" class="d-flex align-items-center mt-3 justify-content-start">
                    <b-button variant="warning" @click="getDocuments('refresh')" size="sm" class="ms-2">
                        <eva-icon name="refresh" fill="#fff"></eva-icon>
                    </b-button>
                </b-col>
                <b-col class="d-flex align-items-center justify-content-end">
                    <b-button-group size="sm" class="me-2">
                        <b-button v-for="(btn, idx) in optionsOrderBy" :key="idx" :pressed.sync="btn.state" class="position-relative" @click="filterByCompleted(btn.value)" :class="{'btn-pressed' : !btn.state}" :variant="btn.variant">
                            <icon-check-circle v-if="btn.state" class="init-tooltip-active" :color="'#348547'"/>
                            {{ btn.caption }}
                        </b-button>
                    </b-button-group>
                </b-col>
            </b-row>
            </b-col>
            <b-col lg="4" xl="2">
                <label for="seller" class="me-2">{{$t('tracking.filter_by_document')}}:</label>
                <b-form-input v-model="filters.document" debounce="1000"></b-form-input>
            </b-col>
            <b-col lg="4" xl="2">
                <multi-select-with-filters :options="salesFormat" @onSelect="(data)=>{filters.seller = data}" label="tracking.filter_by_seller" 
                id="document-filter-by-seller" trackBy="code" selectLabel="name" :default="defaultSeller"/>
            </b-col>
            <b-col lg="4" xl="2">
                <multi-select-with-filters :options="stores" @onSelect="(data)=>{filters.branch = data}" label="delivery.filter_by_branch" id="filter-by-branch" trackBy="codplace" selectLabel="description"/>
            </b-col>
            <b-col lg="4" xl="2">
                <div id="multiselect-state">
                    <multi-select-with-filters :options="actions" @onSelect="(data)=>{filters.state = data}" label="tracking.filter_by_state" id="filter-by-action" trackBy="id" selectLabel="name"/>
                </div>
            </b-col>
            <b-col lg="4" xl="2">
                <multi-select-with-filters :options="types" @onSelect="(data)=>{filters.type = data}" label="tracking.filter_by_type" id="filter-by-type" trackBy="id" selectLabel="name"/>
            </b-col>
            <b-col lg="4" xl="2">
                <div id="timer">
                    <multi-select-with-filters :options="slas" @onSelect="(data)=>{filters.time = data}" label="tracking.filter_by_SLA" id="filter-by-timer" trackBy="id" selectLabel="name"/>
                </div>
            </b-col>
        </b-row>
        <draggable class="list-group" tag="ul" v-model="statData" v-bind="dragOptions" @start="drag = true" @end="drag = false">
        <transition-group type="transition" class="row my-2" :name="!drag ? 'flip-list' : null">
            <b-col v-for="stat in statData" :key="stat.title" class="mt-2 mt-xl-0" lg="4" xl="2" md="6">
                <widget-stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :color="stat.color" :backgroundMini="stat.backgroundMini"/>
            </b-col>
        </transition-group>
        </draggable>
        <div>             
        <b-table-simple class="bg-white" responsive hover striped>
            <b-thead head-variant="dark" class="green-bg bg-primary text-white">
                <draggable tag="tr" v-model="fieldsTest" v-bind="dragOptions2" @start="drag = true" @end="drag = false" style="cursor: grab;">
                        <b-th v-for="field in fieldsTest" :key="field.key">
                            {{field.label}}
                        </b-th>
                </draggable>
            </b-thead>
            <b-tbody>
                <template v-if="!documents.documents || documents.documents.length == 0">
                    <b-td colspan="9" style="height: 47vh;">
                        <h5>
                            {{$t('tracking.no_documents_were_found_with_the_selected_filters')}}.
                        </h5>
                    </b-td>
                </template>
                <b-tr v-for="document in documents.documents" :key="document.document" v-else>
                    <b-td v-for="field in fieldsTest" :key="field.key">
                        <template v-if="field.key == 'state_sla'">
                            <div :style="{background: document.sla_background_color, color: document.sla_text}" class="time-sla mx-2" :class="[`time-sla-${document.sla_status_id}`, document.follow_up_completed ? '' : 'animation']">
                                {{document.sla_name}}
                            </div>
                        </template>
                        <template v-else-if="field.key == 'customer'">
                            <span class="text-capitalize weight-500" v-if="document.type_id != 3">
                                <span v-if="document.company_name && document.company_name.toUpperCase() != document.customer.toUpperCase() && document.company_name != ''">{{document.company_name | textFormat}} /</span> {{document.customer | textFormat}} 
                            </span>
                            <span class="text-capitalize weight-500" v-else>
                                {{document.branch_name | textFormat}} → {{document.customer | textFormat}}
                            </span>
                        </template>
                        <template v-else-if="field.key == 'state'">
                            <div :style="{background : document.action_background_color}" class="time-sla px-2 d-flex justify-content-center container-actions text-white m-auto" style="max-width: 160px;">
                                <vue-custom-tooltip :label="document.action_name" :size="'is-small'">
                                    {{  14 >= document.action_name.length ? document.action_name : document.action_name.slice(0, 14)+ '...'}}
                                </vue-custom-tooltip>
                                ({{timeElapsed(document.time_elapsed)}})
                            </div> 
                        </template>
                        <template v-else-if="field.key == 'type'">
                            {{document.type.name}}
                        </template>
                        <template v-else-if="field.key == 'time'">
                            <span :id="`document-time-${document.id}`"></span>
                        </template>
                        <template v-else-if="field.key == 'was_late'">
                            <eva-icon name="alert-triangle" fill="#e84242" v-if="document.was_late"></eva-icon>
                        </template>
                        <template v-else-if="field.key == 'actions'">
                            <div class="d-flex">
                                <eva-icon name="eye" width="30" height="30" fill="#02a499" class="mx-auto cursor-pointer" @click.native="getDetails(document)"></eva-icon>
                            </div>
                        </template>
                        <span v-else>{{document[field.key]}}</span>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <vue-element-loading :active="loading" :is-full-screen="false"/>
        </div>
        <div class="d-flex justify-content-end align-items-center my-2">
        <span class="me-2">
            {{$t('tracking.export')}} 
        </span>
        <export-excel :data="documents.documents" :fields="json_fields" class="me-5">
            <b-button>Excel</b-button>
        </export-excel>
        <span class="me-2">{{$t('tracking.columns')}}</span>
        <multiselect v-model="fieldsTest" :options="fields" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" label="label" track-by="key" :preselect-first="true"
        style="width: 180px;min-height: 0;" class="me-5">
            <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single size-12" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{$t('tracking.options_selected')}}</span></template>
        </multiselect>
        <b-pagination v-model="filters.page" :total-rows="documents.total_documents" :per-page="documents.limit" class="m-0"></b-pagination>
        <span class="ms-5 me-2">
            {{$t('tracking.showing')}}
        </span>
        <div class="d-flex align-items-center">
            <icon-minus class="cursor-pointer" :color="'#626ed4'" @click.native="filters.limit = parseInt(filters.limit) - 1"/>
            <b-form-input id="limit" v-model="filters.limit" debounce="1000" class="input-limit"></b-form-input>
            <icon-plus class="cursor-pointer" :color="'#626ed4'" @click.native="filters.limit = parseInt(filters.limit) + 1"/>
        </div>
        <span class="ms-2">
            {{$t('tracking.per_page')}}
        </span>
        </div>
        <documents-details v-if="showDetails" @closeModal="showDetails = false" :details="documentDetails" :detailsLocal="selectedDocument" :types="types"/>
    </div>
</template>

<script>
    import Draggable from 'vuedraggable'
    import IconPlus from "../../../common/components/svg/IconPlus.vue";
    import IconMinus from "../../../common/components/svg/IconMinus.vue";
    import IconCheckCircle from '../../../common/components/svg/IconCheckCircle.vue'
    import WidgetStat from '../../layout/components/widgets/widget-stat.vue';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {documentMixin} from '../helpers/mixins/document-mixin'
    import DocumentsDetails from './modals/DocumentsDetails.vue';
import MultiSelectWithFilters from '../../../common/components/selects/MultiSelectWithFilters.vue';
    export default {
        name: "DocumentFollowupTable",
        components: { Draggable, IconPlus, IconMinus, IconCheckCircle, WidgetStat, DatePicker, DocumentsDetails, MultiSelectWithFilters },
        mixins: [documentMixin],
        computed:{
            salesFormat(){
                let sales = [];
                this.salesEmployee.forEach(sale => {
                    sales.push({...sale.entity, name: `${sale.entity.first_name} ${sale.entity.last_name}`})
                });
                return sales;
            }  
        }
    }
</script>

<style scoped>
    .table-responsive::v-deep{
        height: 55vh;
        text-align: center;

    }
    .table-responsive::v-deep > .table{
        vertical-align: middle;
        margin-bottom: 0;
    }
    .table-responsive::v-deep > .table > thead{
        position: sticky;
        top: 0px;
        z-index: 2;
    }
    .table-responsive::v-deep > .table > tbody > tr > td{
        padding: 4px;
    }
    .time-sla{
        border-radius: 20px;
        text-align: center;
        max-width: 100px;
        margin: auto;
    }
    .table-responsive::v-deep > .table > tbody > tr > td:first-child{
        font-weight: 500;
    }
    .time-sla-3.time-sla.animation{
        animation: blink 0.4s step-start infinite;
    }
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
    .mx-datepicker-range{
        width: 100%;
    }
    .input-limit{
        height: 35px;
        width: 45px;
        padding: 4px;
        text-align: center;
        font-size: 12px;
        background: transparent;
        border: 0;
    }
    .table-responsive::v-deep > .table > tbody > .b-table-empty-row{
        height: 47vh;
    }
    .multiselect::v-deep > .multiselect__tags{
        padding: 8px 40px 0 8px !important;
    }
    .container-actions{
        font-size: 13px;
        font-weight: 500;
    }
    .container-actions > span{
        max-width: 100px;
        /* overflow: hidden; */
        white-space: nowrap;
        font-weight: 500;
        /* text-overflow: ellipsis; */
    }
    .vue-custom-tooltip:after{
        padding: 3px 7px;
    }
    .btn-group::v-deep button:focus{
        box-shadow: none;
        border-color: transparent;
    }
    .btn-group::v-deep .btn-danger:focus{
        background-color: #ec4561;
    }
    .btn-group::v-deep .btn-success:focus{
        background-color: #02a499;
    }
    .init-tooltip-active{
        position: absolute;
        top: -11px;
        left: 32px;
        border-radius: 50%;
        fill: #179b04 !important;
        border: 2px solid #f8f8fa;
    }
    .btn-pressed{
        opacity: 0.4;
    }
</style>

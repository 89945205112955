import { http } from "@/common/services/web-backend.js";

export default {
  async getEmployees({commit}, params){
    try {
      const response = await http.get("employee/deliveries", {params: params});
      commit('SET_EMPLOYEES', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getTaskType({commit}){
    try {
      const response = await http.get("task/getTaskType");
      commit('SET_TASK_TYPE', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getIncidentType({commit}) {
    try {
      const response = await http.get("incidents/types");
      commit('SET_INCIDENT_TYPE', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getTaskMethod({commit}){
    try {
      const response = await http.get("task/getTaskMethod");
      commit('SET_TASK_METHOD', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getTasks({commit}){
    try {
      const response = await http.get("task", {params: {assign: 0,limit: 200}});
      commit('SET_TASKS', response.data.data.data)
    } catch (e) {
      throw e;
    }
  },
  async assingTasks(_, params){
    try {
      const response = await http.post("task/reAssignBatch", params);
      return response
    } catch (e) {
      throw e;
    }
  },
  async unAssign(_, id){
    try {
      const response = await http.post(`task/unAssign/${id}`);
      return response
    } catch (e) {
      throw e;
    }
  },
  async getAllTasks(_, filters){
    try {
      const response = await http.get("task", {params: filters} );
      // commit('SET_ALL_TASKS', response.data.data)
      return response.data.data
      
    } catch (e) {
      throw e;
    }
  },
  async getAllTaskIncidents({commit}, filters) {
    try {
      const response = await http.get("incidents/getTaskIncidents", {params: filters} );
      commit('SET_TASK_INCIDENTS', response.data.data.data)
      return response.data.data.data
      
    } catch (e) {
      throw e;
    }
  },
  async getTaskIncidents({commit}, filters) {
    try {
      const response = await http.get("incidents/getTaskIncidents", {params: filters} );
      commit('SET_SINGLE_TASK_INCIDENTS', response.data.data.data)
      return response.data.data.data
      
    } catch (e) {
      throw e;
    }
  },
  async reassingTask(_, params){
    try {
      const response = await http.post(`task/reAssign/${params.id}`, {employee_code: params.employee});
      return response
    } catch (e) {
      throw e;
    }
  },
  async deleteTask(_, code){
    try {
      const response = await http.delete(`general-messenger-service/${code}`);
      return response
    } catch (e) {
      throw e;
    }
  },
  async getTaskStatus({commit}){
    try {
      const response = await http.get(`task/getTaskStatus`);
      commit('SET_TASK_STATUS', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getTaskDetails({commit}, id){
    try {
      const response = await http.get(`task/${id}`);
      commit('SET_TASK_DETAILS', response.data.data)
    } catch (e) {
      throw e;
    }
  },
  async getSalesEmployee({commit}){
    try {
      const response = await http.get(`employee/salesEmployee`);
      commit('SET_SALES_EMPLOYEE', response.data.data)
    } catch (e) {
      throw e;
    }
  },
};

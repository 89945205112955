<template>
<div class="avatar-container d-flex align-items-center mb-2">
        <div class="avatar d-flex align-items-center justify-content-center" :style="[{background: getRandomColor}, {width: width + 'px'}, {height: width + 'px'}]" v-if="!customerImg || error" >
            <abbr>{{name.slice(0,1)+'.'+lastname.slice(0,1)}}</abbr>
        </div>
        <img :src="customerImg" alt="profile" class="avatar" :style="[{width: width + 'px'}, {height: width + 'px'}]" @error="error = true" v-else>
        <div class="d-flex flex-column ms-2 ps-1">
            <div class="d-flex align-items-center flex-wrap">
                <span class="subtitle-16 text-capitalize" v-if="!email">{{name | textFormat}} {{lastname | textFormat}}</span>
                <span class="subtitle-16" v-else>{{email}}</span>
                <div v-if="active" class="active d-flex align-items-center ms-3">
                    <icon-check-circle class="me-1" :color="'#66B266'"/> Activo
                </div>
                <div class="cursor-pointer ms-3" data-toggle="tooltip" data-placement="top" :title="$t('ecommerce.verified_buyer')" v-if="customerVerified">
                    <icon-check-circle :size="18" :color="'#02a499'" class="pb-1"/>
                    <span class="d-none d-sm-inline d-lg-none d-xl-inline color-dark-gray-200 ms-2 weight-200" v-if="showTextVerified">{{$t("ecommerce.verified_buyer")}}</span>
                </div>
            </div>
            <time v-if="date" class="d-flex d-xl-none color-dark-gray-300 weight-300 size-13" :class="{'mt-auto' : showStar}">{{ $d(new Date(date), 'alt_short') | textFormat }}</time>
            <!-- <star-rating :rating="rating" :starSize="22" :readOnly="true" :increment="0.1" :showRating="false" v-if="showStar"/> -->
            <star-rating :starSize="22" :rating="5" :readOnly="true" :showRating="false" v-if="showStar"/>
        </div>
        <time v-if="date" class="d-none d-xl-flex color-dark-gray-300 weight-300 ms-auto size-15" :class="{'mt-auto' : showStar}">{{ $d(new Date(date), 'alt_short') | textFormat }}</time>
    </div>
</template>

<script>
import IconCheckCircle from '../../../../common/components/svg/IconCheckCircle.vue';
export default {
    components: {IconCheckCircle },
    name: "Avatar",
    props:{
        name:{
            type: String
        },
        lastname:{
            type: String
        },
        rating:{
            type: Number
        },
        showStar: {
            type: Boolean,
            default: true
        },
        date:{
            type: [String, Date, Number]
        },
        customerVerified:{
            type: Boolean
        },
        customerImg:{
            type: String
        },
        email:{
            type: String
        },
        width:{
            type: Number,
            default: 45
        },
        showTextVerified:{
            type: Boolean,
            default: true
        },
        active:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        getRandomColor() {
            const min = Math.ceil(1);
            const max = Math.floor(6);
            let generator = Math.floor(Math.random() * (max - min + 1)) + min;
            switch (generator) {
                case 1:
                    return '#FA9696';
                case 2:
                    return '#8FC6F5';
                case 3:
                    return '#61BEB8';
                case 4:
                    return '#FCD058';
                case 5:
                    return '#9CC574';
                case 6:
                    return '#AC74C5';                     
                default:
                    break;
            }
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
    data() {
        return {
            error: false
        }
    },
}
</script>
<style scoped>
    .avatar{
        /* background: #FA9696; */
        /* width: 45px;
        height: 45px; */
        border-radius: 50%;
        color: #fff;
        object-fit: cover;
    }
    .active{
        color: #66b266;
        font-weight: 600;
    }
</style>

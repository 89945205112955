<template>
<ul class="timeline p-0" id="timeline">
    <li class="li" :class="[getState(action).complete ? 'complete' : '']" v-for="action in status" :key="action.id">
        <div class="status">
            <div class="circle d-flex align-items-center justify-content-center" :style="{background : getState(action).background_color}">
                <eva-icon name="checkmark" v-if="getState(action)" fill="white" width="18px"></eva-icon>
            </div>
            <div class="d-flex flex-column align-items-center mt-3">
                <h5 class="mb-2 text-nowrap title" :style="{color : getState(action).background_color}"> 
                    {{action.name}}
                </h5>
                <span class="size-16" :class="[getState(action).complete ? 'visible' : 'invisible', parseInt(getState(action).time_elapsed) > parseInt(getState(action).time_sla_sec) ? 'text-danger' :'text-success']">
                    <vue-custom-tooltip :label="`${getTimeComplete(getState(action).time_elapsed)} / ${getTimeComplete(getState(action).time_sla_sec)}`" :size="'is-small'"
                    :class="[parseInt(getState(action).time_elapsed) > parseInt(getState(action).time_sla_sec) ? 'bg-tooltip-danger' : 'bg-tooltip-success']">
                        <b>{{getTime(getState(action).time_elapsed)}} / {{getTime(getState(action).time_sla_sec)}}</b>
                    </vue-custom-tooltip>
                </span>
                <div class="date text-center size-13" :class="[getState(action).complete ? 'visible' : 'invisible']">
                    {{getState(action).complete ? getState(action).shortDate : 'N/A'}} 
                    <h6 class="weight-700 size-13">
                        {{getState(action).complete ? getState(action).hour : 'N/A'}}
                    </h6>
                </div>
            </div>
        </div>
    </li>
</ul> 
</template>

<script>
import moment from "moment";

export default {
    nmae: "TimeLine",
    props:{
        status:{
            type: Array
        },
        statusOrder:{
            type: Array
        },
        type: {
            type: Number
        }
    },
    methods: {
        getState(value){
            let filter = this.statusOrder.filter(action => action.id === value.id)
            if(filter[0]){
                return {...filter[0], shortDate: this.$d(new Date(filter[0].date_time)), hour: this.getHour(filter[0].date_time), complete: true}
            }else{
                return {background_color: '#e9ecef', complete: false}
            }
        },
        getHour(data){
            const date = new Date(data);
            const hour = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return hour
        }, 
        getTime(seconds){
            let negative = false
            if(seconds < 0){
                negative = true
                seconds = Math.abs(seconds)
            }
            // const formatted = moment.utc(seconds*1000).format('mm:ss');
            // console.log(formatted);
            let minutes = Math.floor(seconds / 60)
            let hour = Math.floor(minutes / 60)
            let days = Math.floor(hour / 24)
            if(days >= 1){
                return `${negative ? '-' :''}${days} d`
            }else if(hour >= 1){
                return `${negative ? '-' :''}${hour} h`
            }else if(minutes >= 1){
                return `${negative ? '-' :''}${minutes} m`
            }else{
                return `${negative ? '-' :''}${seconds || 0} s`
            }
        },
        getTimeComplete(seconds){
            let negative = false
            if(seconds < 0){
                negative = true
                seconds = Math.abs(seconds)
            }
            const formatted = moment.utc(seconds*1000).format('HH:mm:ss');
            var days = moment.duration(moment.utc(seconds*1000)).asDays();
            if(days >= 1){
                return `${negative ? '-' :''}${parseInt(days)}D ${formatted}`
            }else{
                return `${negative ? '-' :''}${formatted || 0}`
            }
        }
    },
}
</script>

<style scoped>
.timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.li {
    transition: all 200ms ease-in;
}

.timestamp {
    margin-bottom: 20px;
    /* padding: 0px 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
}

.status {
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    border-top: 3px solid #e9ecef;
    position: relative;
    transition: all 200ms ease-in;
    /*max-width: 140px;
    min-width: 100px;*/
}
.status h4 {
    font-weight: 600;
}
.circle {
    content: "";
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 25px;
    position: absolute;
    top: -14px;
    /* left: 39%; */
    transition: all 200ms ease-in;
}

.li.complete .status {
    border-top: 3px solid #08213b;
}
.li.complete .status:before {
    background-color: #66DC71;
    border: none;
    transition: all 200ms ease-in;
}
.li.complete .status h4 {
    /* color: #66DC71; */
}
.title{
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-device-width: 320px) and (max-device-width: 700px) {
    .timeline {
        list-style-type: none;
        display: block;
    }

    .li {
        transition: all 200ms ease-in;
        display: flex;
        width: inherit;
    }

    .timestamp {
        width: 100px;
    }

    .status:before {
        left: -8%;
        top: 30%;
        transition: all 200ms ease-in;
    }
}

.bg-tooltip-danger.vue-custom-tooltip::v-deep::after{
    background-color: #ec4561;
    box-shadow: none;
    font-weight: 600;
    line-height: 12px;
}
.bg-tooltip-danger.vue-custom-tooltip::v-deep::before{
    border-top-color: #ec4561;
}
.bg-tooltip-success.vue-custom-tooltip::v-deep::after{
    background-color: #02a499;
    box-shadow: none;
    font-weight: 600;
    line-height: 12px;
}
.bg-tooltip-success.vue-custom-tooltip::v-deep::before{
    border-top-color: #02a499;
}
</style>

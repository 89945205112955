<template>
    <div class="card" :class="classBackground">
        <div class="card-body text-white">
            <div class="d-flex">
                <h3 class="font-weight-semibold mb-0">{{ value }}   <i v-if="busy" class="icon-spinner2 spinner"></i></h3>
            </div>
            <div>
                {{ $t(title) }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrdersAnalytics",
        props: {
            classBackground: {
                type: Array
            },
            title: {
                type: String,
                required: true
            },
            value:{
                type: [Number, String]
            }
        },
        data(){
            return{
                busy: false
            }
        },
    }
</script>

<style scoped>

</style>

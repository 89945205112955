<template>
    <layout>
        <b-card no-body class="mt-2">
            <b-tabs card>
                <b-tab no-body :title="$t('tracking.actions')">
                    <b-table :items="localActions" :fields="fields" striped hover thead-class="bg-primary text-white" class="bg-white mt-1">
                        <template #cell(name)="data">
                            <b-form-input :id="data.item.name" v-model="data.item.name" placeholder="Enter name" required></b-form-input>
                        </template>
                        <template #cell(background_color)="data">
                            <color-picker v-model="data.item.background_color" :position="{left: 0, top: '40px'}"></color-picker>
                        </template>
                        <template #cell(actions)="data">
                            <div class="d-flex justify-content-center">
                                <eva-icon name="trash-2" class="cursor-pointer" @click.native="removeAction(data.index, data.item.id)" fill="#ec4561"></eva-icon>
                            </div>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-end mb-2 mx-2">
                        <b-button variant="success" class="me-3" @click="addAction">{{ $t('tracking.add') }}</b-button>
                        <b-button variant="primary" @click="saveActions">{{ $t('tracking.save') }}</b-button>
                    </div>
                </b-tab>
                <b-tab no-body :title="$t('tracking.order')">
                <h4 class="m-5">{{ $t('tracking.invoice').toUpperCase() }}</h4>
                    <time-line2 :status="cashActions" v-if="cashActions.length > 0" @delete="deleteToCash" :groupName="'cash-actions'" :key="cashComponent"/>
                    <h4 class="m-5">{{ $t('tracking.invoice_with_delivery').toUpperCase() }}</h4>
                    <time-line2 :status="creditActions" v-if="creditActions.length > 0" @delete="deleteToCredit" :groupName="'credit-actions'" :key="creditComponent"/>
                    <h4 class="m-5">{{ $t('tracking.transfer').toUpperCase() }}</h4>
                    <time-line2 :status="trasnferActions" v-if="trasnferActions.length > 0" @delete="deleteToTrasnfer" :groupName="'trasnfer-actions'" :key="trasnferComponent"/>
                    <div class="d-flex mb-2 mx-2">
                        <b-button variant="primary" class="ms-auto" @click="save">{{ $t('tracking.save') }}</b-button>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { mapGetters } from 'vuex';
import TimeLine2 from "../components/TimeLine2.vue";

    export default {
        name: "Maintenance",
        components:{
            Layout,
            TimeLine2
        },
        data(){
            return {
                localActions: [],
                drag: false,
                cashActions: [],
                creditActions: [],
                trasnferActions: [],
                cashComponent: 0,
                creditComponent: 1,
                trasnferComponent: 2
            }
        },
        watch: {
            'permissions'() {
                const modules = this.permissions?.modules?.find(data => data.name == 'Rastreo de estados')
                const maintnance = modules?.subModules?.find(data => data.name === 'Mantenimiento')
                // Verifica si se han cambiado los permisos de administrador
                if (!maintnance.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
            },
        },
        computed:{
            ...mapGetters({
                actions: "documentsFollowup/actions",
                types: "documentsFollowup/types",
                userData: "auth/user",
                permissions: "auth/permissions"
            }),
            fields(){
                return [
                    {label: this.$t('tracking.id'), key: 'id', sortable: true},
                    {label: this.$t('tracking.name'), key: 'name', sortable: false},
                    {label: this.$t('tracking.color'), key: 'background_color', sortable: false},
                    {label: this.$t('tracking.actions'), key: 'actions', sortable: false},
                ]
            },
            dragOptions() {
                return {
                    animation: 500,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods:{
            async saveActions(){
                this.$swal.fire({ 
                    title: this.$t('tracking.confirmation'), text: this.$t('tracking.save_confirmation'), 
                    icon: "info", showCancelButton: true, confirmButtonText: this.$t("tracking.confirm"), 
                    cancelButtonText: this.$t("tracking.cancel"),
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            await this.$store.dispatch('documentsFollowup/updateActions', {params: this.localActions})
                            await this.getActions()
                            this.cashComponent += 1
                            this.creditComponent += 1
                            this.trasnferComponent += 1
                            this.$swal.fire({ 
                                title: this.$t("tracking.saved_successfully"), text: this.$t("tracking.saved_changes_successfully"), 
                                icon: "success",
                            })
                        } catch (error) {
                            if(error.response?.data.message){
                                this.$swal.fire({
                                icon: 'error',
                                title: this.$t("tracking.error_saving_data"),
                                text: error.response.data.message,
                                })
                            }else{
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t("tracking.error_loading_data"),
                                    text: error.message,
                                })
                            }
                        }
                    }else{
                        return
                    }
                })
            },
            addAction(){
                this.localActions.push({has_delivery: false, name: '', background_color: '', type_documents: []})
            },
            removeAction(index, id){
                this.$swal.fire({ 
                    title: this.$t('tracking.confirm'), 
                    text: this.$t('tracking.delete_action_confirmation'), 
                    icon: "info", 
                    showCancelButton: true, 
                    confirmButtonText: this.$t('tracking.confirm'), 
                    cancelButtonText: this.$t('tracking.cancel'),
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            if(id){
                                await this.$store.dispatch('documentsFollowup/deleteAction', id)
                            }
                            this.localActions.splice(index, 1)
                            this.$swal.fire({ 
                                title: this.$t('tracking.deleted_successfully'), text: this.$t('tracking.deleted_action_successfully'), 
                                icon: "success",
                            })
                        } catch (error) {
                            if(error.response?.data.message){
                                this.$swal.fire({
                                icon: 'error',
                                title: this.$t('tracking.unexpected_error'),
                                text: error.response.data.message,
                                })
                            }else{
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('tracking.unexpected_error'),
                                    text: error.message,
                                })
                            }
                        }
                    }
                })
            },
            async save(){
                let actionsFinal = this.actions
                // console.log(actionsFinal);
                for (let i = 0; i < actionsFinal.length; i++) {
                    const action = actionsFinal[i];
                    action.type_documents = []
                    this.cashActions.map(el => {
                        if(el.id == action.id){
                            // console.log("hola");
                            action.type_documents.push({
                                id_sla: el.id,
                                id_type: 1,
                                name: this.$t('tracking.cash_invoice'),
                                order: el.order,
                            })
                        }
                    });
                    this.creditActions.map(el => {
                        console.log(el);
                        if(el.id == action.id){
                            // console.log("hola");
                            action.type_documents.push({
                                id_sla: el.order,
                                id_type: 2,
                                name: this.$t('tracking.credit_invoice'),
                                order: el.order,
                            })
                        }
                    });
                    this.trasnferActions.map(el => {
                        if(el.id == action.id){
                            // console.log("hola");
                            action.type_documents.push({
                                id_sla: el.order,
                                id_type: 3,
                                name: this.$t('tracking.transfer'),
                                order: el.order,
                            })
                        }
                    });
                }
                this.$swal.fire({ 
                    title: this.$t('tracking.confirmation'), text: this.$t('tracking.save_confirmation'), 
                    icon: "info", showCancelButton: true, confirmButtonText: this.$t("tracking.confirm"), 
                    cancelButtonText: this.$t("tracking.cancel"),
                }).then(async (result) => {
                    if(result.isConfirmed){
                        try {
                            await this.$store.dispatch('documentsFollowup/updateActions', {params: actionsFinal})
                            this.$swal.fire({ 
                                title: this.$t("tracking.saved_successfully"), text: this.$t("tracking.saved_changes_successfully"), 
                                icon: "success",
                            })
                        } catch (error) {
                            if(error.response?.data.message){
                                this.$swal.fire({
                                icon: 'error',
                                title: this.$t("tracking.error_saving_data"),
                                text: error.response.data.message,
                                })
                            }else{
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t("tracking.error_loading_data"),
                                    text: error.message,
                                })
                            }
                        }
                    }
                })
            },
            deleteToTrasnfer(value){
                let index = this.trasnferActions.findIndex(action => action.id == value)
                this.trasnferActions.splice(index, 1)
                this.trasnferComponent += 1
            },
            deleteToCash(value){
                let index = this.cashActions.findIndex(action => action.id == value)
                this.cashActions.splice(index, 1)
                this.cashComponent += 1
            },
            deleteToCredit(value){
                let index = this.creditActions.findIndex(action => action.id == value)
                this.creditActions.splice(index, 1)
                this.creditComponent += 1 
            },
            async getActions(){
                try {
                    // await this.$store.dispatch("tracking/getSLA")
                    await this.$store.dispatch("documentsFollowup/getActions")
                    // await this.$store.dispatch("tracking/getTypes")
                } catch (error) {
                    if(error.response?.data.message){
                        this.$swal.fire({
                        icon: 'error',
                        title: this.$t("tracking.error_loading_data"),
                        text: error.response.data.message,
                        })
                    }else{
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t("tracking.error_loading_data"),
                            text: error.message,
                        })
                    }
                }
                this.localActions = this.actions
                let cash = []
                let credit = []
                let trasnfer = []
                this.actions.forEach(action => {
                    action.type_documents.forEach(type =>{
                        if(type.id_type == 1){
                            cash.push({...action, order: type.order})
                        }else if(type.id_type == 2){
                            credit.push({...action, order: type.order})
                        }
                        else if(type.id_type == 3){
                            trasnfer.push({...action, order: type.order})
                        }
                    })
                });
                cash.push({name: 'add', $isDisabled: true, id: 0})
                credit.push({name: 'add', $isDisabled: true, id: 0})
                trasnfer.push({name: 'add',$isDisabled: true, id: 0})
                cash.sort(function(a, b) {
                    return a.order - b.order
                });
                credit.sort(function(a, b) {
                    return a.order - b.order
                });
                trasnfer.sort(function(a, b) {
                    return a.order - b.order
                });
                this.cashActions = cash
                this.creditActions = credit
                this.trasnferActions = trasnfer
            }
        },
        async created(){
            this.getPermissions()
            try {
                // await this.$store.dispatch("tracking/getSLA")
                await this.getActions()
                // await this.$store.dispatch("tracking/getTypes")
            } catch (error) {
                if(error.response?.data.message){
                    this.$swal.fire({
                    icon: 'error',
                    title: this.$t("tracking.error_loading_data"),
                    text: error.response.data.message,
                    })
                }else{
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t("tracking.error_loading_data"),
                        text: error.message,
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .b-table::v-deep{
        vertical-align: middle;
        text-align: center;
    }
    .custom-control::v-deep > input{
        border: none;
    }
</style>

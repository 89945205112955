<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 256 256" >
        <path d="M192.498,124.8c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8S183.22,124.8,192.498,124.8z
            M171.798,166.6c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8S162.52,166.6,171.798,166.6z
            M144.998,203.3h-18.9h-18.9c-11.5,0-18.7,9.5-18.7,21.4V254h12.9v-25.9c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5v-25.8
            c0-1.2,1-2,2-2c1.2,0,2,1,2,2v25.8h12.9v-29.1C163.998,212.8,156.698,203.3,144.998,203.3z M149.698,124.8
            c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8S140.42,124.8,149.698,124.8z M199.098,183.4
            c0,9.3,7.5,16.8,16.8,16.8s16.8-7.5,16.8-16.8s-7.5-16.8-16.8-16.8S199.098,174.1,199.098,183.4z M197.398,203.3
            c-11.5,0-18.7,9.5-18.7,21.4V254h12.9v-25.9c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5v-25.8c0-1.2,1-2,2-2c1.2,0,2,1,2,2v25.8h12.9
            v-29.1c0.2-12.1-7.1-21.6-18.7-21.6h-18.9h-19V203.3z M39.798,166.6c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8
            s-16.8-7.522-16.8-16.8S30.52,166.6,39.798,166.6z M14.798,253.9v-25.8c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5v-25.8
            c0-1.2,1-2,2-2c1.2,0,2,1,2,2v25.8h12.9v-29.1c0.2-12.1-7.1-21.6-18.7-21.6h-18.9h-18.9c-11.5,0-18.7,9.5-18.7,21.4v29.3
            L14.798,253.9L14.798,253.9z M109.298,183.4c0,9.3,7.5,16.8,16.8,16.8c9.3,0,16.8-7.5,16.8-16.8s-7.5-16.8-16.8-16.8
            S109.298,174.1,109.298,183.4z M61.298,124.8c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8
            S52.02,124.8,61.298,124.8z M106.698,124.8c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8
            S97.42,124.8,106.698,124.8z M84.098,166.6c9.278,0,16.8,7.522,16.8,16.8s-7.522,16.8-16.8,16.8s-16.8-7.522-16.8-16.8
            S74.82,166.6,84.098,166.6z M181,21.875l-7.808,32.874c-1.425,5.999-6.726,10.188-12.891,10.188h-47.393l1.865,7.771
            c0.715,2.982,3.358,5.065,6.424,5.065h46.967v6.625h-46.967c-6.142,0-11.433-4.171-12.867-10.144L93.796,13.69
            c-0.716-2.982-3.358-5.065-6.425-5.065H75V2h12.371c6.142,0,11.433,4.171,12.867,10.144l2.335,9.731H181z M114.191,98.477
            c0,5.26,4.264,9.523,9.523,9.523c5.26,0,9.523-4.264,9.523-9.523s-4.264-9.523-9.523-9.523
            C118.454,88.953,114.191,93.217,114.191,98.477z M148.972,98.477c0,5.26,4.264,9.523,9.523,9.523c5.26,0,9.523-4.264,9.523-9.523
            s-4.264-9.523-9.523-9.523C153.235,88.953,148.972,93.217,148.972,98.477z"/>
    </icon-base>
</template>

<script>
export default {
name: 'IconCustomers',
components: {
    IconBase: () => import('./IconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
        type: Boolean
    }
}
}
</script>


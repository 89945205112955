<template>
    <div class="dynamic-form-renderer-container overflow-auto">
        <b-form>
            <div v-for="item in fields" :key="item.id">
                <div v-if="item.field_type_id == 1">
                    <b-form-group :id="`input-group-${item.id}`" :label="item.label" :label-for="`input-${item.id}`" size="xs" :description="item.comment">
                        <b-form-input :value="item.data" :disabled="true" size="md" :title="item.comment"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="item.field_type_id == 2" class="mt-3">
                    <h3 :title="item.comment">{{ item.label }}</h3>
                    <ul v-for="selectItem in item.data" :key="selectItem.id">
                        <li>{{ selectItem.label }}</li>
                    </ul>
                </div>
                <div v-if="item.field_type_id == 3" class="mt-3">
                    <h4 :title="item.comment">{{ item.label }}</h4>
                    <!-- <b-row>
                        <swiper :options="swiperOption" style=" height: 500px; border-radius:10px;">
                            <swiper-slide :key="index" v-for="(url, index) in item.data">
                                <img v-lazy="url" width="100%" height="100%" style="object-fit: contain;">
                            </swiper-slide>
                            <div class="swiper-button-prev mr-5" slot="button-prev">
                                <icon-arrow :size="30" style="margin-right: -12px; transform: rotate(180deg);" :color="'var(--gray-1001)'"/>
                            </div>
                            <div class="swiper-button-next" slot="button-next">
                                <icon-arrow :size="30" style="margin-left: -12px" :color="'var(--gray-1001)'"/>
                            </div>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </b-row> -->

                    <div class="image-gallery-container">
                        <div v-for="(url, index) in item.data" class="image-gallery-item" :key="index">
                            <a :href="url" target="_blank">
                                <img :src="url">
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="item.field_type_id == 4" class="mt-3">
                    <h4>
                        {{ item.field_type }}
                    </h4>
                    <div class="image-gallery-container">
                        <div v-for="(url, index) in item.data" class="image-gallery-item" :key="index">
                            <a :href="url" target="_blank">
                                <img class="border border-dark" :src="url">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>

    export default {
        name: "DynamicFormRenderer",
        components: {
        },
        props: {
            fields: Array
        },
        data() {
            return {
                // swiperOption: {
                //     slidesPerView: 1,
                //     slidesPerColumn: 1,
                //     navigation: {
                //         nextEl: '.swiper-button-next',
                //         prevEl: '.swiper-button-prev'
                //     },
                //     pagination: {
                //         el: '.swiper-pagination',
                //         clickable: true,
                //         dynamicBullets: true
                //     }
                // }
            }
        }
    }
</script>

<style scoped>
.dynamic-form-renderer-container {
    max-width: 100%;
    max-height: 800px;
    margin: 0 auto;
}

.swiper-container::v-deep .swiper-button-prev::after, .swiper-container::v-deep .swiper-button-next::after{
    content: none;
}
.swiper-container::v-deep > .swiper-wrapper{
    align-items: center;
}

.image-gallery-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.image-gallery-item {
    width: 20%;
}

.image-gallery-container img {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
}

.form-input-text {
    width: 100%;
}

@media (min-width: 992px) { 
    .dynamic-form-renderer-container {
        min-width: 700px;
        width: 80%;
    }

    .form-input-text {
        width: 50%;
    }
}
</style>
<template>
  <div>
    <div class="accountbg" :style="{'background': 'url(' + require('@/common/assets/images/bg.jpg') + ')', 'background-size': 'cover', 'background-position': 'center'}"></div>
    <div class="wrapper-page account-page-full">
      <div class="card shadow-none">
      <vue-element-loading :active="loading" :is-full-screen="false"/>
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link to="/">
                    <img src="@/common/assets/images/logo-dark.png" height="22" alt="logo" />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">Welcome Back !</h4>
                <p class="text-muted text-center">Sign in to continue to Veltrix.</p>

                <form class="mt-4" action="#">
                  <div class="mb-3">
                    <label class="form-label" for="username">Username</label>
                    <input type="text" class="form-control" id="username" placeholder="Enter username" v-model="form.username"/>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="userpassword">Password</label>
                    <input type="password" class="form-control" id="userpassword" placeholder="Enter password" v-model="form.password"/>
                  </div>

                  <div class="mb-3 row">
                    <div class="col-sm-6">
                      <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="customControlInline">
                          <label class="form-check-label" for="customControlInline">Remember me</label>
                      </div>
                    </div>
                    <div class="col-sm-6 text-end">
                      <button class="btn btn-primary w-md" type="submit" @click.prevent="login">Log In</button>
                    </div>
                  </div>

                  <div class="form-group mt-2 mb-0 row">
                    <div class="col-12 mt-3">
                      <router-link to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Forgot your password?
                      </router-link>
                    </div>
                  </div>
                </form>

                <div class="mt-5 pt-4 text-center">
                  <p>
                    Don't have an account ?
                    <router-link to="/pages/register-2" class="fw-medium text-primary">Signup now</router-link>
                  </p>
                  <p>
                    ©
                    {{new Date().getFullYear()}}
                    Veltrix
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <verify-email :userAttributes="userAttributes" v-if="showVerifyEmail" @closeModal="showVerifyEmail = false" :user="form"/>
    <verifyMFA :userAttributes="userAttributes" v-if="showGenerateTOTPModal" :user="form" @closeModal="showGenerateTOTPModal = false" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VerifyEmail from '../components/modals/VerifyEmail.vue';
import VerifyMFA from '../components/modals/VerifyMFA.vue';
import { mapGetters } from "vuex";

export default {
  page: {
    title: "Login 2",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components:{
    VerifyEmail,
    VerifyMFA
  },
  computed:{
    ...mapGetters({
            userData: 'auth/user',
        }),
  },
  data() {
    return {
      form:{
        username: '',
        password: ''
      },
      loading: false,
      userAttributes: {},
      showGenerateTOTPModal: false,
      showVerifyEmail: false,
      mfaEmploye: null,
      userEmployee: null,
    }
  },
  methods:{
    async login(){
      try {
        this.loading = true
        
        const response = await this.$store.dispatch('auth/login', {form: this.form})
        if(response?.mfa_activated) return this.showGenerateTOTPModal = true

        if (response?.confirmEmail) {
          this.userAttributes = response.user
          this.showVerifyEmail = true
          return
        }

        if(response == 'isEmployee'){
          this.$swal.fire({
            icon: 'warning',
            title: 'Usuario no es empleado',
            html: '<span style="color: #9ba7ac;">Tienes una cuenta, pero no tienes permitido ingresar al sistema, contactate con el administrador para obtener acceso.</span>',
          })
        }
        this.$router.replace({path: '/'})
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text:  error?.response?.data?.message ?? error.message,
        })
      }finally{
          this.loading = false
      }
    },
  }
};
</script>

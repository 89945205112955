const Module = () => import("./Module.vue");
const CreateTask = () => import("./view/CreateTask.vue");
const Tasks = () => import("./view/Tasks.vue");
const Agents = () => import("./view/Agents.vue");
const AgentsERP = () => import("./view/AgentsERP.vue");
const IncidentSettingManager = () => import("./view/IncidentSettingManager.vue");
const TaskSettingManager = () => import("./view/TaskSettingManager.vue");
const DashboardTracking = () => import("./view/DashboardTracking.vue");

import i18n from "../../i18n";


const localData = JSON.parse(localStorage.getItem('userAcess'));
const modules = localData?.modules?.find(data => data.name == 'Delivery')

const task = modules?.subModules?.find(data => data.name === 'Tareas')
const configTask = modules?.subModules?.find(data => data.name === 'Config. de tarea')
const incidentSettings = modules?.subModules?.find(data => data.name === 'Config. de incidencias')
const tracking = modules?.subModules?.find(data => data.name === 'Rastreo')

const moduleRoute = {
  path: "/delivery",
  component: Module,
  name: "Delivery",
  children: [
    {
      path: "tasks",
      name: i18n.t("layout.tasks"),
      component: Tasks,
      meta: {
        icon: "icon-assing-task",
        search: task?.active,
        requiresAuth: true, 
        active: task?.active
      },
    },
    {
      path: "create-task",
      name: i18n.t("layout.create_tasks"),
      component: CreateTask,
      meta: {
        icon: "icon-create-task",
        search: configTask?.active,
        requiresAuth: true, 
        active: configTask?.active
      },
    },
    {
      path: "agents",
      component: Agents,
    },
    {
      path: "agents-erp",
      component: AgentsERP,
    },
    {
      path: "incident-settings",
      component: IncidentSettingManager,
      meta: {
        requiresAuth: true, 
        active: incidentSettings?.active
      },
    },
    {
      path: "task-settings",
      component: TaskSettingManager,
    },
    {
      path: "dashboard-tracking",
      component: DashboardTracking,
      meta: {
        requiresAuth: true, 
        active: tracking?.active
      },
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};

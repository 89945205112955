<template>
    <layout>
        <b-row class="mt-5">
            <b-col cols="6" class="mb-4">
                <label for="search">Buscar por nombre</label>
                <b-form-input v-model="search" placeholder="Buscar por nombre" id="search"></b-form-input>
            </b-col>
            <b-col cols="6" class="mb-4">
                <label for="search">Buscar por code</label>
                <b-form-input v-model="code" placeholder="Buscar por code" id="search"></b-form-input>
            </b-col>
            <b-col>
                <b-table :items="listEmployees" striped thead-class="green-bg bg-primary text-white" class="bg-white">
                </b-table>
                <h3>Explicacion (develop)</h3>
                <p>
                    Para agregar un agente a tookan debe estar registrado como empleado en el puesto de "MENSAJERO", el boton funciona para sincronizar una vez que sea "MENSAJERO". 
                </p>
            </b-col>
            <b-col cols="12" class="d-flex">
                <b-button variant="primary" @click="getDeliveries" class="ms-auto">
                    Sync Deliveries
                </b-button>
            </b-col>
        </b-row>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { mapGetters } from "vuex"
import { http as backend} from "@/common/services/web-backend.js";

export default {
    name:"AgentsERP",
    components:{
        Layout
    },
    data() {
        return {
            listEmployees: [],
            search: null,
            code: null
        }
    },
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
        }),
    },
    methods: {
        async getDeliveries(){
            try {
                this.loading = true;
                await backend.post('employee/sync-agents',)
                this.$swal.fire({ 
                    title: 'Syncronizacion exitosa', text: "Mensajeros sincronizados correctamente.", 
                    icon: "success",
                })
                await this.$store.dispatch('delivery/getEmployees')
            } catch (error) {
                console.log(error);
                this.$swal.fire({ 
                    title: 'Error con la transaccion', text: "Mensajeros no fueron sincronizados.", 
                    icon: "error",
                })
            }finally{
                this.loading = false;
            }
        },
    },
    watch: {
        search(val){
            let result = this.employees.filter(employee => (employee.first_name.toLowerCase() + employee.last_name.toLowerCase()).includes(val.toLowerCase()))
            this.listEmployees = result
        },
        code(val){
            let result = this.employees.filter(employee => employee.code.toString().toLowerCase().includes(val.toLowerCase()))
            this.listEmployees = result
        }
    },
    async created(){
        await this.$store.dispatch('delivery/getEmployees')
        this.listEmployees = this.employees
    }
}
</script>
<style scoped>
    .agents-list{
        list-style: none;
        font-size: 16px;
        padding: 0;
    }
</style>

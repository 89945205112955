<template>
    <main-modal @closeModal="$emit('closeModal')">
        <div class="d-flex flex-column justify-content-center py-2">
            <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                {{ this.error }}
            </div>
            <div v-else class="alert alert-warning color-light-yellow d-flex align-items-center border-0 py-2" role="warning">
                {{ $t("Valide su codigo TOTP") }} 
            </div>
                <form @submit.prevent="verify">
                <div>
                    <label for="code">{{$t('auth.code')}}</label>
                    <b-form-input id="input-verify" type="number" v-model="code" required> </b-form-input>
                </div>
                <div class="d-flex mt-3 ms-auto">
                    <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                        <b-button variant="primary" type="submit">{{$t('auth.confirm')}}</b-button>
                    </b-overlay>
                </div>
            </form>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import { AuthService } from "@/modules/auth/services/auth-service.js";
const authService = new AuthService();


export default {
    components:{
        // IconError,
        // IconCode,
        // MainButton,
        MainModal,
        // MainInput,
        // iconWarning
        // INSERT COMPONENT
    },
    name: "VerifyMFA",
    data(){
        return {
            code: '',
            error: false,
            mfaType: 'SOFTWARE_TOKEN_MFA',
            totp: null,
            loading: false
        }
    },
    props:{
        userAttributes: {
            type: Object
        },
        user:{
            type: Object
        },
    },
    methods:{
        focusInVerify(){
            setTimeout(() => { 
                let input = document.getElementById("input-verify")
                input.focus()
            }, 10);
        },
        onHidden() {
            // Return focus to the button once hidden
            this.$refs.button.focus()
        },
        async verify(){
            const totp = {
                username: this.user.username,
                totp: this.code
            }
            try {
                this.loading = true
                const sendCode = await authService.validateMFAIntranet(totp);
                if (sendCode.valid_totp) {
                    const response = await this.$store.dispatch('auth/employeeLogin', {username: this.user.username, password: this.user.password});
                    if(response == 'isEmployee'){
                        this.$swal.fire({
                            icon: 'warning',
                            title: 'Usuario no es empleado',
                            html: '<span style="color: #9ba7ac;">Tienes una cuenta, pero no tienes permitido ingresar al sistema, contactate con el administrador para obtener acceso.</span>',
                        })
                    }
                    this.$emit('closeModal');
                }
                this.$emit("closeModal")
            } catch (error) {
                this.error = error
                this.error = error.message
            }finally{
                this.loading = false
                if(!this.error){
                    console.log('success');
                }
            }
            // try {
            //     if(this.userAttributes?.challengeName !== 'SOFTWARE_TOKEN_MFA'){
            //         await Auth.verifyTotpToken(this.userAttributes, this.code)
            //     } else {
            //         const response = await Auth.confirmSignIn(this.userAttributes, this.code, 'SOFTWARE_TOKEN_MFA')
            //         this.$store.dispatch('auth/mfalogin', {username: this.user.username, password: this.user.password, response: response})
            //     }
            //     this.$emit("closeModal")
            // } catch (error) {
            //     this.error = error
            //     console.log(error);
            // }finally{
            //     if(!this.error){
            //         console.log('success');
            //     }
            // }
        },
    },
    created(){
        this.focusInVerify()
    }
}
</script>

<style>
</style>
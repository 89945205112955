import { http } from "@/common/services/web-backend.js";

export class AuthService {
  async login(){
    try {
      const response = await http.post('ecommerce/admin/login')
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  async getEmployeeMFA(getMFA){
      try {
          const getEmployeeMFAInfo = await http.post(`ecommerce/users/is-mfa-activated`, getMFA);
          const response = getEmployeeMFAInfo.data
          return response.data
      }catch (e) {
          if (e.response){
              throw e.response.data
          }else{
              throw e
          }
      }
  }

  async validateMFAIntranet(code){
      try {
          const postvalidateMFAIntranet = await http.post(`ecommerce/users/validate-google-2fa`, code);
          const response = postvalidateMFAIntranet.data
          return response.data
      }catch (e) {
          if (e.response){
              throw e.response.data
          }else{
              throw e
          }
      }
  }
}

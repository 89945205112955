import {http as nodeService} from "@/common/services/node-service.js";
import * as turf from "@turf/turf";


export const addressesMixin = {
    methods: {
        async getLimits(codplace){
        try{
            if (this.$refs.layers){
                this.$refs.layers.forEach(e=>{
                    console.log(e)
                    e.remove()
                })
                this.layers =[]
            }
    
            let data = await nodeService.get("stores/"+codplace+"/limits");
            data = data.data.data;

            await data.forEach( (limit) => {
                let coordinates = [];
                limit.limits.forEach((l, i) => {
                  coordinates[i] = [l.longitude, l.latitude];
                });
                let poly = turf.polygon([coordinates]);
                this.layers.push(poly);
            });
            return data
            }catch (e) {
                console.log(e)
            }
    
        },
    }
    

}

import { OrderService} from "../service/order-service";
const service = new OrderService();
import { http } from "../../../common/services/node-service";
import { StoreService} from "../service/stores-services";
const storeService = new StoreService();
export default {
  async getOrders(_, filters){
    try {
      const response = await service.getOrders(filters);
      return response
    } catch (error) {
      console.log(error);
    }
  },
  async getConfiguration({commit}) {
    try {
        const response = await http.get("configurations");
        this.settings = response.data.data
        commit("SET_GENERAL_SETTING", this.settings)
    } catch (error) {
        console.log(error);
    }
  },
  async getStatus({commit}){
    try {
      const response = await service.getStatus();
      commit("SET_STATUS", response)

    } catch (error) {
      console.log(error);
    }
  },
  async getStores({commit}){
    try {
      const response = await storeService.getStores();
      commit("SET_STORES", response)

    } catch (error) {
      console.log(error);
    }
  },
  async getStoreWithLimits({commit}){
    try {
      const response = await storeService.getStoreWithLimits();
      commit("SET_STORES_WITH_LIMITS", response)

    } catch (error) {
      console.log(error);
    }
  }
};

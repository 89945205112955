import { mapGetters } from "vuex";
import moment from "moment";

export const documentMixin = {
  data() {
    return {
      statData: [
        {
          title: "tracking.documents",
          image: require("@/common/assets/images/services-icon/01.png"),
          value: null,
          subText: 100,
          color: "info",
          backgroundMini: "sent",
        },
        {
          title: "tracking.very_late",
          image: "https://cdn-icons-png.flaticon.com/128/1407/1407089.png",
          value: null,
          subText: null,
          color: "danger",
          backgroundMini: "canceled",
        },
        {
          title: "tracking.late",
          image: "https://cdn-icons-png.flaticon.com/512/6314/6314804.png",
          value: null,
          subText: null,
          color: "warning",
          backgroundMini: "in_process",
        },
        {
          title: "tracking.on_time",
          image: "https://cdn-icons-png.flaticon.com/512/4474/4474370.png",
          value: "8",
          subText: null,
          color: "success",
          backgroundMini: "delivered",
        },
        {
          title: "Fue tarde",
          image: require("@/common/assets/images/services-icon/04.png"),
          value: "8",
          subText: "28",
          color: "orange",
          backgroundMini: "bg-on_held",
        },
        {
          title: "Example",
          image: require("@/common/assets/images/services-icon/04.png"),
          value: "0",
          subText: "0",
          color: "lime",
          backgroundMini: "delivered",
        },
      ],
      filters: {
        document: null,
        branch: [],
        type: [],
        state: [],
        time: [],
        seller: [],
        range: [],
        page: 1,
        limit: 50,
        follow_up_completed: null,
      },
      items: [],
      selected: {},
      showDetails: false,
      loading: false,
      fieldsTest: [],
      oldId: null,
      intervals: [],
      drag: false,
      json_fields: {
        Documento: "document",
        Tipo: "document_type",
        Cliente: "customer",
        Estado: "action_name",
        SLA: "sla_name",
        Sucursal: "branch_name",
        Tarde: "was_late",
      },
      times: [],
      defaultSeller: [],
      tasksWithTime:[],
      intervalTimer: null,
      isMounted: false
    };
  },
  computed: {
    ...mapGetters({
      stores: "ecommerce/stores",
      documents: "documentsFollowup/documents",
      documentDetails: "documentsFollowup/documentDetails",
      slas: "documentsFollowup/slas",
      actions: "documentsFollowup/actions",
      types: "documentsFollowup/types",
      documentSummary: "documentsFollowup/documentSummary",
      stopProcesses: "common/stopProcesses",
      salesEmployee: "delivery/salesEmployee",
      iframe: "documentsFollowup/iframe",
      userData: "auth/user",
      permissions: "auth/permissions"
    }),
    fields() {
      const columns = [
        { label: this.$t('tracking.document'), key: "document", sortable: true },
        { label:  this.$t('tracking.type'), key: "type", sortable: false },
        { label:  this.$t('tracking.customer'), key: "customer", sortable: false },
        { label:  this.$t('tracking.state'), key: "state", sortable: false },
        // Service Level Agreement
        { label:  "SLA", key: "state_sla", sortable: false },
        { label:  this.$t('tracking.time'), key: "time", sortable: false },
        // <-------------------->
        { label:  this.$t('tracking.branch'), key: "branch_name", sortable: false },
        { label:  this.$t('tracking.late'), key: "was_late", sortable: false },
        { label:  this.$t('tracking.actions'), key: "actions", sortable: false },
      ];
      this.fieldsTest = columns;
      return columns;
    },
    optionsOrderBy() {
      return [
        {
          caption: this.$t('tracking.incomplete'),
          state: this.filters.follow_up_completed == 0,
          value: 0,
          variant: "danger",
        },
        {
          caption: this.$t('tracking.complete'),
          state: this.filters.follow_up_completed == 1,
          value: 1,
          variant: "success",
        },
      ];
    },
    dragOptions() {
      return {
        animation: 500,
        group: "information",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragOptions2() {
      return {
        animation: 500,
        group: "tableDocuments",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    customMultiSelectTimer() {
      let tag = document.getElementById("timer");
      if (this.filters.time) {
        for (let i = 0; i < this.filters.time.length; i++) {
          const element = this.filters.time[i];
          setTimeout(() => {
            let tags = tag.querySelector(
              `.multiselect__tags-wrap span:nth-child(${i + 1})`
            );
            if(tags){
              tags.style.backgroundColor = element.background_color;
              tags.style.color = element.text;
            }
          }, 0);
        }
      }
    },
    customMultiSelectState() {
      let tag = document.getElementById("multiselect-state");
      for (let i = 0; i < this.filters.state.length; i++) {
        const element = this.filters.state[i];
        setTimeout(() => {
          let tags = tag.querySelector(
            `.multiselect__tags-wrap span:nth-child(${i + 1})`
          );
          tags.style.backgroundColor = element.background_color;
        }, 0);
        // tags.classList.add(`${element.background}`)
      }
    },
    async getDocuments(type) {
      clearInterval(this.intervalTimer)
      if (type == "refresh") {
        clearInterval(this.interval);
        this.interval = setInterval(async () => {
          await this.getDocuments();
        }, 30000);
      }
      try {
        this.loading = true;
        let branches = [];
        let actions = [];
        let types = [];
        let slas = [];
        let dates = [];
        let entities = this.filters.seller.map(item => item.code)

        if (this.filters.branch.length > 0) {
          this.filters.branch.forEach((element) => {
            branches.push(element.codplace);
          });
        }
        if (this.filters.state.length > 0) {
          this.customMultiSelectState();
          this.filters.state.forEach((element) => {
            actions.push(element.id);
          });
        }
        if (this.filters.type.length > 0) {
          this.filters.type.forEach((element) => {
            types.push(element.id);
          });
        }
        if (this.filters.time.length > 0) {
          this.customMultiSelectTimer();
          this.filters.time.forEach((element) => {
            slas.push(element.id);
          });
        }
        if (this.filters.range.length > 0) {
          this.filters.range.forEach((element) => {
            if (element)
              dates.push(new Date(element).toISOString().split("T")[0]);
          });
        }
        let filters = {
          branch_code: branches,
          action_id: actions,
          document_type: types,
          sla_status_id: slas,
          date_time: dates,
          entity_code: entities,
          limit: this.filters.limit,
          page: this.filters.page,
          document: this.filters.document,
          follow_up_completed: this.filters.follow_up_completed,
        };
        await this.$store.dispatch("documentsFollowup/getDocuments", filters);
        await this.$store.dispatch(
          "documentsFollowup/getSummaryDocuments",
          filters
        );
        // this.times = []
        // this.documents.documents.forEach(task => {
        //     this.startTimer(task)
        // });
        // await this.$store.dispatch("documentsFollowup/getChartsDocuments", {date: dates})
        this.documents.documents.forEach(task => {
          this.getTimeOfTask(task)
        });
        this.startTimer()
        // calculando porcentajes manuales
        let allLate = 0;
        let allVeryLate = 0;
        let allOnTime = 0;
        let allWasLate = 0;
        if (this.documentSummary.summary_documents.length > 0) {
          allLate = this.documentSummary.summary_documents[0].count;
          allVeryLate = this.documentSummary.summary_documents[1].count;
          allOnTime = this.documentSummary.summary_documents[2].count;
          allWasLate = this.documentSummary.total_was_late;
        }
        this.statData[0].value = this.documentSummary.total_documents;
        this.statData[1].value = allVeryLate;
        this.statData[1].subText = this.documentSummary.total_documents > 0 ? parseFloat((allVeryLate / this.documentSummary.total_documents) * 100).toFixed(2): 0;
        this.statData[2].value = allLate;
        this.statData[2].subText = this.documentSummary.total_documents > 0 ? parseFloat( (allLate / this.documentSummary.total_documents) * 100).toFixed(2): 0;
        this.statData[3].value = allOnTime;
        this.statData[3].subText =this.documentSummary.total_documents > 0 ? parseFloat((allOnTime / this.documentSummary.total_documents) * 100).toFixed(2): 0;
        this.statData[4].value = allWasLate;
        this.statData[4].subText = this.documentSummary.total_documents > 0 ? parseFloat((allWasLate / this.documentSummary.total_documents) * 100).toFixed(2): 0;
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          icon: "error",
          title: "HA OCURRIDO UN ERROR",
          text: error.response ? error.response.data.message : error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async getDetails(document) {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "documentsFollowup/getDocumentDetails",
          document.document
        );
        this.selectedDocument = document;
        this.showDetails = true;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "HA OCURRIDO UN ERROR",
          text: error.response ? error.response.message : "ERROR DEL BACK-END",
        });
      } finally {
        this.loading = false;
      }
    },
    filterByCompleted(value) {
      if (value == this.filters.follow_up_completed) {
        this.filters.follow_up_completed = null;
      } else {
        this.filters.follow_up_completed = value;
      }
    },
    getTimeOfTask(data){
      let startDate = moment(data.firts_date_time_start_of_process)
      let endDate = data.follow_up_completed ? moment(data.date_time).format() : moment() 
      let dtDiff = moment(endDate).diff(startDate);

      //express as a duration
      const diffDuration = moment.duration(dtDiff);
      let result = moment().startOf("day");
      result.add(diffDuration.hours(), 'hours')
      result.add(diffDuration.minutes(), 'minutes')
      result.add(diffDuration.seconds(), 'seconds')
      let days = diffDuration.days()
      this.tasksWithTime.push({id: data.id, time: result, days: days, completed: data.follow_up_completed})
    },
    startTimer(){
      clearInterval(this.intervalTimer)
      this.intervalTimer = setInterval(() => {
          this.tasksWithTime.forEach(task => {
              if(!task.completed)
              task.time.add(1, 'second');
              let span = document.getElementById(`document-time-${task.id}`)
              if(span){
                  span.innerHTML = `${task.days >= 1 ? `${task.days}D` : ''} ${task.time.format('HH:mm:ss')}`;
              }
          });
      }, 1000)
    },
    timeElapsed(seconds) {
      let negative = false;
      if (seconds < 0) {
        negative = true;
        seconds = Math.abs(seconds);
      }
      let minutes = Math.floor(seconds / 60);
      let hour = Math.floor(minutes / 60);
      let days = Math.floor(hour / 24);
      if (days >= 1) {
        return `${negative ? "-" : ""}${days}d`;
      } else if (hour >= 1) {
        return `${negative ? "-" : ""}${hour}h`;
      } else if (minutes >= 1) {
        return `${negative ? "-" : ""}${minutes}m`;
      } else {
        return `${negative ? "-" : ""}${seconds || 0}s`;
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        if(this.isMounted)
        await this.getDocuments();
      },
    },
    'permissions'() {
        const modules = this.permissions?.modules?.find(data => data.name == 'Rastreo de estados')
        const trackingDocument = modules?.subModules?.find(data => data.name === 'Seguimiento de documentos')
        // Verifica si se han cambiado los permisos de administrador
        if (!trackingDocument.active) {
            // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
            this.$router.push('/permission');
        }
    },
    stopProcesses(val) {
      if (val) {
        clearInterval(this.interval);
      }
    },

  },
  async mounted() {
    // this.fieldsTest = this.fields;
    try {
      if (this.iframe) {
        let form = {
          username: process.env.VUE_APP_USER,
          password: process.env.VUE_APP_PASSWORD,
        };
        await this.$store.dispatch("auth/login", form);
      }
      await this.$store.dispatch("documentsFollowup/getSLA");
      await this.$store.dispatch("documentsFollowup/getActions");
      await this.$store.dispatch("documentsFollowup/getTypes");
      await this.$store.dispatch('delivery/getSalesEmployee')

    } catch (error) {
      this.$swal.fire({
        icon: "error",
        title: "HA OCURRIDO UN ERROR",
        text: error.response ? error.response.message : "ERROR DEL BACK-END",
      });
    }
    this.isMounted = true
    this.filters.range = [new Date(), new Date()];
    this.filters.time = [
      {
        name: "Muy tarde",
        background_color: "#fce9e9",
        text: "#e84242",
        id: 3,
        updated_at: null,
        created_at: null,
      },
      {
        name: "Tarde",
        background_color: "#faebb9",
        text: "#d8a10a",
        id: 2,
        updated_at: null,
        created_at: null,
      },
      {
        name: "A Tiempo",
        background_color: "#ebf7df",
        text: "#348547",
        id: 1,
        updated_at: null,
        created_at: null,
      },
    ];
    this.filters.follow_up_completed = 0;
    setTimeout(() => {
      this.customMultiSelectTimer();
    }, 0);
    if (this.$route.path == "/documents-followup/iframe") {
      const { code } = this.$route.query;
      if (code) {
        const employee = this.salesFormat.find(item=>item.code == code)
        if(employee){
          this.filters.seller = [employee];
          this.defaultSeller = [employee];
        }
      }
    }
  },
  async created(){
    this.getPermissions()
    setTimeout(() => {
        this.showActive = false
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalTimer);
  },
};

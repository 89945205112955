<template>
    <layout>
        <el-tabs type="border-card" class="mt-4">
        
            <el-tab-pane label="Permissions">
                <el-row class="mb-3">
                    <el-col :span="6">
                        <label for="search" class="mb-1">Search by name or code</label>
                        <el-input v-model="search" size="small" placeholder="Type to search"/>
                    </el-col>
                </el-row>
                <el-table stripe :data="employeesFilter" tyle="width: 100%"  size="small" height="65vh" row-class-name="text-capitalize"
                    header-cell-class-name="bg-primary text-white font-weight-normal" :header-cell-style="{'font-weight': 500}" v-loading="loading">
                <el-table-column label="Codigo" prop="code"/>
                <el-table-column label="Nombre" prop="name"/>
                <el-table-column label="Apellido" prop="lastName"/>
                <el-table-column :label="permission.title" align="center" v-for="permission in permissions" :key="permission.key">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.permissions[permission.key]" @change="updatePermission(scope.row.code, permission.key, scope.row.permissions[permission.key])"/>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            <el-tab-pane label="Versions">
                <el-row :gutter="20">
                    <el-col :lg="8" :xl="6">
                        <el-card>
                            <div slot="header" class="clearfix">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5 class="mb-0 me-2">Android</h5>
                                        <el-tag type="success">{{ app.androidVersion }}</el-tag>
                                    </div>
                                    <el-popover placement="bottom" trigger="click">
                                        <div>
                                            <label for="new">Version</label>
                                            <div class="d-flex align-items-center">
                                                <el-input size="small" style="max-width: 200px" class="me-2" id="new" v-model="newVersion" @change="saveVersion('android')"/>
                                                <el-button size="small" @click="saveVersion('android')">Save</el-button>
                                            </div>
                                        </div>
                                        <el-button slot="reference" type="text">Add</el-button>
                                    </el-popover>
                                </div>
                            </div>
                            <el-table stripe :data="versions.android" tyle="width: 100%"  size="small" height="65vh" row-class-name="text-capitalize"
                            :header-cell-style="{'font-weight': 500}" v-loading="loading">
                                <el-table-column label="Version" prop="version" align="center">
                                    <template slot-scope="scope">
                                        <img src="https://www.svgrepo.com/show/223032/playstore.svg" width="20" class="me-2">
                                        <span>{{ scope.row.version }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Activo" prop="active" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.active" @change="updateVersionState('android')"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Acciones" prop="actions" align="center">
                                    <template slot-scope="scope">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <el-popconfirm title="Are you sure to delete this?" @confirm="deleteVersion('android', scope.row.version)">
                                                <i class="el-icon-delete cursor-pointer size-18 d-block" slot="reference"/>
                                            </el-popconfirm>
                                            <div style="width: 20px;">
                                                <i class="el-icon-star-on cursor-pointer size-24 ms-1 d-block" slot="reference" style="color:#E6A23C;"  v-if="scope.row.version == app.androidVersion"/>
                                                <el-popconfirm title="Do you want to make this the current version?" @confirm="putNewVersion('android', scope.row.version)" v-else>
                                                    <i class="el-icon-set-up cursor-pointer size-18 ms-2 d-block" slot="reference"/>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-col>
                    <el-col :lg="8" :xl="6">
                        <el-card>
                            <div slot="header" class="clearfix">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5 class="mb-0 me-2">IOS</h5>
                                        <el-tag>{{ app.iosVersion }}</el-tag>
                                    </div>
                                    <el-popover placement="bottom" trigger="click">
                                        <div>
                                            <label for="new">Version</label>
                                            <div class="d-flex align-items-center">
                                                <el-input size="small" style="max-width: 200px" class="me-2" id="new" v-model="newVersion" @change="saveVersion('ios')"/>
                                                <el-button size="small" @click="saveVersion('ios')">Save</el-button>
                                            </div>
                                        </div>
                                        <el-button slot="reference" type="text">Add</el-button>
                                    </el-popover>
                                </div>
                            </div>
                            <el-table stripe :data="versions.ios" tyle="width: 100%"  size="small" height="65vh" row-class-name="text-capitalize"
                                :header-cell-style="{'font-weight': 500}" v-loading="loading">
                                <el-table-column label="Version" prop="version" align="center">
                                    <template slot-scope="scope">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/App_Store_%28iOS%29.svg/640px-App_Store_%28iOS%29.svg.png" width="20" class="me-2">
                                        <span>{{ scope.row.version }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Activo" prop="active" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.active" @change="updateVersionState('ios')"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Acciones" prop="actions" align="center">
                                    <template slot-scope="scope">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <el-popconfirm title="Are you sure to delete this?" @confirm="deleteVersion('ios', scope.row.version)">
                                                <i class="el-icon-delete cursor-pointer size-18 d-block" slot="reference"/>
                                            </el-popconfirm>
                                            <div style="width: 20px;">
                                                <i class="el-icon-star-on cursor-pointer size-24 ms-1 d-block" slot="reference" style="color:#E6A23C;"  v-if="scope.row.version == app.iosVersion"/>
                                                <el-popconfirm title="Do you want to make this the current version?" @confirm="putNewVersion('ios', scope.row.version)" v-else>
                                                    <i class="el-icon-set-up cursor-pointer size-18 ms-2 d-block" slot="reference"/>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-col>
                    <el-col :lg="8" :xl="12">
                        <el-card>
                            <div>
                                <label for="url-android" class="mb-1">Play store URL</label>
                                <el-input v-model="app.urlAndroidApp" size="small" id="url-android"/>
                            </div>
                            <div class="mt-2">
                                <label for="url-ios" class="mb-1">App store URL</label>
                                <el-input v-model="app.urlIosApp" size="small" id="url-ios"/>
                            </div>
                            <div class="d-flex mt-4 justify-content-end">
                                <el-popconfirm title="Are you sure you want to cancel the changes?" @confirm="getAppConfig()">
                                    <el-button class="me-3" style="width:120px;" size="small" slot="reference">Cancel</el-button>
                                </el-popconfirm>
                                <el-popconfirm title="Are you sure you want to save the changes?" @confirm="saveURL">
                                    <el-button size="small" style="width:120px" type="primary" slot="reference">Save</el-button>
                                </el-popconfirm>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="Schedule">
                <el-row :gutter="20">
                    <el-col :xl="6" :lg="8" v-for="(day, ind) in schedule" :key="`day-${ind}`" class="mt-3">
                        <el-card>
                            <div slot="header" class="d-flex align-items-center justify-content-between">
                                <h6 class="mb-0 text-capitalize">{{day.title}}</h6>
                                <el-button class="p-0" type="text" @click="addSchedule(day.key)">Add</el-button>
                            </div>
                            <div class="grid-container">
                                <el-time-picker v-model="day.time[i]"  placeholder="Arbitrary time" class="me-2" size="small" v-for="(time, i) in day.time" :key="`monday-${i}`"
                                style="width: 130px;"/>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div class="d-flex flex-column align-items-end">
                    <div>
                        <el-checkbox v-model="scheduleActive">Active</el-checkbox>
                    </div>
                    <div class="d-flex mt-2">
                        <el-popconfirm title="Are you sure you want to cancel the changes?" @confirm="getAppConfig()">
                            <el-button class="me-3" style="width:120px;" size="small" slot="reference">Cancel</el-button>
                        </el-popconfirm>
                        <el-popconfirm title="Are you sure you want to save the changes?" @confirm="saveDeliverySettings">
                            <el-button type="primary" style="width:120px;" size="small" slot="reference">Save</el-button>
                        </el-popconfirm>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Configurations" class="mt-3">
                <el-row :gutter="20" style="max-width:600px">
                    <el-card>
                        <h4>Settings</h4>
                        <el-collapse>
                            <el-collapse-item title="Validate punch" name="1">
                                <el-checkbox label="validateFirstPunch" v-model="app.validateFirstPunch"/>
                            </el-collapse-item>
                        </el-collapse>

                        <div style="text-align: right; margin-top: 20px;">
                            <el-popconfirm title="Are you sure you want to save the changes?" @confirm="saveTabConfigurations">
                                <el-button type="primary" style="width:120px;" size="small" slot="reference">Save</el-button>
                            </el-popconfirm>
                        </div>
                    </el-card>
                </el-row>
            </el-tab-pane>

            <!-- <el-tab-pane label="Crear permiso" class="mt-3">
                <el-row :gutter="20" style="max-width:600px">
                    <el-col class="d-flex flex-column" span="12">
                        <label id="start" class="mb-1">Entidad</label>
                        <el-select v-model="value" placeholder="Persona" size="small">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col class="d-flex flex-column" span="12">
                        <label id="end" class="mb-1">Acompañantes</label>
                        <el-input placeholder="Please input" v-model="input" size="small"/>
                    </el-col>
                    <el-col class="d-flex flex-column mt-3" span="12">
                        <label id="start" class="mb-1">Inicio</label>
                        <el-date-picker size="small" v-model="value1" type="datetime" placeholder="Select date and time" id="start" class="w-100"/>
                    </el-col>
                    <el-col class="d-flex flex-column mt-3" span="12">
                        <label id="end" class="mb-1">Finaliza</label>
                        <el-date-picker size="small" v-model="value1" type="datetime" placeholder="Select date and time" id="end" class="w-100"/>
                    </el-col>
                    <el-col class="d-flex flex-column mt-3" span="24">
                        <label id="end" class="mb-1">Reason</label>
                        <el-input placeholder="Please input" v-model="input" size="small" type="textarea" :rows="4"/>
                    </el-col>
                    <el-col span="24" class="mt-4 d-flex justify-content-end">
                        <el-button size="small">Crear</el-button>
                    </el-col>
                </el-row>
            </el-tab-pane> -->
        </el-tabs>
    </layout>
</template>

<script>
    import Layout from "@/modules/template/layouts/main";
    import { http }  from "@/common/services/web-backend.js";
    import { db } from "@/firebase-config";
    import moment from "moment";
    import { mapGetters } from 'vuex';

    export default {
        name: 'Dashboard',
        data(){
            return{
                moment: moment,
                employees:[],
                search: '',
                loading: false,
                versions: {
                    android: [],
                    ios: [],
                },
                app: {
                    urlAndroidApp: '',
                    urlIosApp: '',
                    androidVersion: '',
                    iosVersion: '',
                    deliverySettings:{
                        isDayAllowed: false,
                        allowedTimings: null
                    },
                    validateFirstPunch: false,
                    versions: {
                        android: [],
                        ios: [],
                    }
                },
                newVersion: null,
                schedule: [],
                scheduleActive: false,
                permissions: [
                    {showModule:false, title:'Mostrar modulo', key:'showModule'},
                    {createVisit:false, title:'Crear visita', key:'createVisit'},
                    {updateVisit:false, title:'Actualizar visita', key:'updateVisit'},
                    {disableVisit:false, title:'Desactivar visita', key:'disableVisit'},
                    {acceptVisit:false, title:'Aceptar visita', key:'acceptVisit'},
                    {createEntity:false, title:'Crear entidad', key:'createEntity'},
                    {updateEntity:false, title:'Actualizar entidad', key:'updateEntity'},
                    {disableEntity:false, title:'Desactivar entidad', key:'disableEntity'},
                    {shareQr:false, title:'Compartir Qr', key:'shareQr'},
                    {showPermission:false, title:'Ver permiso', key:'showPermission'},
                ]
                // {deleteVisit:false, title:'', key:'deleteVisit'},
                // {deleteEntity:false, title:'', key:'deleteEntity'},
            }
        },
        components: {
            Layout
        },
        computed:{
            ...mapGetters({
                userData: "auth/user",
                accessPermissions: "auth/permissions"
            }),
            employeesFilter(){
                if(!this.search){
                    return this.employees;
                }
                return this.employees.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase())
                || data.lastName.toLowerCase().includes(this.search.toLowerCase()) || data.code.toLowerCase().includes(this.search.toLowerCase()));
            }
        },
        watch:{
            'accessPermissions'() {
                const modules = this.accessPermissions?.modules?.find(data => data.name == 'App')
                const visitDashboard = modules?.subModules?.find(data => data.name === 'Dashboard')
                    // Verifica si se han cambiado los permisos de administrador
                    if (!visitDashboard.active) {
                        // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                        this.$router.push('/permission');
                    }
            },
        },
        methods:{
            async getEmployees(){
                try {
                    this.loading = true;
                    const {data} = await http.get('/employee?limit=1000');
                    const permissionsRef = db.ref(`permissions`);
                    let permissions = [];
                    await permissionsRef.once('value', (snapshot) => {
                        
                        const employees = snapshot.val();
                        if(!employees) return;

                        const deliveriesFromObj = Object.entries(employees).map(([key, value])=> ({...value, code: key}));
                        permissions = deliveriesFromObj;
                    })  
                    if(data.data.data.length > 0){
                        data.data.data.forEach(employee => {
                            const permission = permissions.find(e=> e.code == employee.entity.code);
                            this.employees.push({
                                permissions: permission ?? {
                                    showModule:false,
                                    createVisit:false,
                                    updateVisit:false,
                                    deleteVisit:false,
                                    disableVisit:false,
                                    acceptVisit:false,
                                    createEntity:false,
                                    updateEntity:false,
                                    deleteEntity:false,
                                    disableEntity:false,
                                    shareQr:false,
                                    showPermission:false,
                                },
                                name: employee.entity.first_name.toLowerCase(),
                                lastName: employee.entity.last_name.toLowerCase(),
                                code: employee.entity.code
                            })
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally{
                    this.loading = false;
                }
            },
            async updatePermission(code, permission, value){
                try {
                    const permissionsRef = db.ref(`permissions/${code}`);
                    await permissionsRef.update({[permission]: value})
                    
                } catch (error) {
                    console.log(error);
                }
            },
            async getAppConfig(){
                try {
                    const appRef = db.ref(`app`);
                    await appRef.once('value', (snapshot) => {
                        const app = snapshot.val();
                        this.versions = app?.versions ?? this.app.versions;
                        if(app?.deliverySettings){
                            const convertedData = app.deliverySettings.allowedTimings.map(item => {
                                const dayOfWeek = Object.keys(item)[0];
                                const timeArray = item[dayOfWeek].map(time => {
                                    const hour = time.hour.toString().padStart(2, '0');
                                    const minute = time.minute.toString().padStart(2, '0');
                                    const date = moment().set({
                                        hour: hour,
                                        minute: minute,
                                        second: 0,
                                        millisecond: 0
                                    });
                                    return date;
                                });
                                
                                return {
                                    "time": timeArray,
                                    "title": dayOfWeek,
                                    "key": dayOfWeek.toLowerCase()
                                };
                            });
                            this.schedule = convertedData;
                            this.scheduleActive = app.deliverySettings.isDayAllowed;
                        }else{
                            this.scheduleActive = false;
                            this.schedule =  [
                                { time: [], title: "Monday", key: "monday" },
                                { time: [], title: "Tuesday", key: "tuesday" },
                                { time: [], title: "Wednesday", key: "wednesday" },
                                { time: [], title: "Thursday", key: "thursday" },
                                { time: [], title: "Friday", key: "friday" },
                                { time: [], title: "Saturday", key: "saturday" },
                                { time: [], title: "Sunday", key: "sunday" }
                            ]
                        }
                        if(!app) return;
                        this.app = app;
                    })  
                } catch (error) {
                    console.error(error);
                } finally{
                    this.loading = false;
                }
            },
            async updateVersionState(platform){
                try {
                    const appRef = db.ref(`app/versions/${platform}`);
                    await appRef.update(this.versions[platform]);
                } catch (error) {
                    console.log(error);
                }
            },
            async saveVersion(platform, validate = true){
                const version = this.newVersion.trim();
                const index = this.versions[platform].findIndex(v=> v.version == version);
                if(index != -1){
                    if(validate)
                    this.$notify.error({
                        title: 'Error',
                        message: 'This version already exists.'
                    });
                    return;
                }
                this.versions[platform].push({version:version, active:false})
                await this.updateVersionState(platform);
                this.newVersion = null;
            },
            async putNewVersion(platform, version){
                const appRef = db.ref(`app/${platform}Version`);
                await appRef.set(version);
                this.app[`${platform}Version`] = version;
                this.$notify.success({
                    title: 'Success',
                    message: 'Current version has changed.'
                });
            },
            async deleteVersion(platform, version){
                const index = this.versions[platform].findIndex(v=> v.version == version);
                if(index != -1){
                    this.versions[platform].splice(index, 1);
                    const appRef = db.ref(`app/versions/${platform}/${index}`);
                    await appRef.remove();
                }
            },
            addSchedule(day){
                const index = this.schedule.findIndex(e=>e.key == day);
                console.log(index)
                this.schedule[index].time.push(Date());
                this.schedule[index].time.push(Date());
            },
            async saveDeliverySettings(){
                try {
                    
                    const convertedData = this.schedule.map(item => {
                        const timeArray = item.time.map(timeString => {
                            const time = new Date(timeString);
                            return {
                                "hour": time.getHours(),
                                "minute": time.getMinutes()
                            };
                        });
                        
                        return {
                            [item.key]: timeArray
                        };
                    });
    
                    const data = {
                        allowedTimings: convertedData,
                        isDayAllowed: this.scheduleActive
                    }
                    const settingsRef = db.ref(`app/deliverySettings`);
                    await settingsRef.update(data);
                    this.$notify.success({
                        title: 'Success',
                        message: 'Changes saved successfully.'
                    });
                } catch (error) {
                    this.$notify.error({
                        title: 'Error',
                        message: error.message,
                    });
                }
            },
            async saveTabConfigurations(){
                try {
                    const appRef = db.ref(`app`);
                    await appRef.update({
                        validateFirstPunch: this.app.validateFirstPunch,
                    });
                } catch (error) {
                    console.log(error);
                }
            },
            async saveURL(){
                try {
                    const appRef = db.ref(`app`);
                    await appRef.update({
                        urlAndroidApp: this.app.urlAndroidApp,
                        urlIosApp: this.app.urlIosApp,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        created(){
            this.getPermissions()
            this.getEmployees();
            this.getAppConfig()
        }
    }
</script>

<style scoped>
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px; /* Espacio entre los divs */
    }

</style>
<template>
    <layout>
        <!-- <div class="ms-auto mt-2 d-flex align-items-center" v-if="false" :class="[tabIndex == 0 ? '' :'invisible']">
            <b-button class="me-3 ms-auto"  variant="primary" @click="save = !save">
                {{$t('common.save')}}
                <eva-icon name="save" fill="#fff" width="20"></eva-icon>
            </b-button>
            <b-button variant="danger" class="ml-1" @click="cancel = !cancel">
                {{$t('common.cancel')}}
                <eva-icon name="alert-circle" fill="#fff" width="20"></eva-icon>
            </b-button>    
        </div> -->
        <div class="position-relative h-100">
            <div class="d-flex position-absolute" style="right: 11px;top: 4px;z-index: 300;" v-if="showActionButtons">
                <el-button size="small" @click="setAccion('save')" style="width: 150px;" type="primary">{{$t('common.save')}}</el-button>
                <el-button size="small" @click="setAccion('cancel')" style="width: 150px;" type="danger">{{$t('common.cancel')}}</el-button>
            </div>
            <el-tabs type="border-card" v-model="tabIndex" class="h-100" style="background-color: #f8f8fa;">
                <el-tab-pane :label="$t('delivery.assign_tasks')" lazy>
                    <assign-tasks :save="tabsOption.assign.save" :cancel="tabsOption.assign.cancel"/>
                </el-tab-pane>
                <el-tab-pane :label="$t('delivery.task_history')">
                    <task-history/>
                </el-tab-pane>
                <el-tab-pane :label="$t('delivery.create_tasks')" lazy>
                    <create-task  :save="tabsOption.create.save" :cancel="tabsOption.create.cancel"/>
                </el-tab-pane>
                <el-tab-pane :label="$t('delivery.history_for_messenger')" disabled>Task</el-tab-pane>
                <el-tab-pane :label="$t('delivery.configurate_state')">
                    <state-maintenance/>
                </el-tab-pane>
                <el-tab-pane :label="$t('delivery.manage_incidents')">
                    <incident-manager/>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- <b-tabs content-class="mt-2" class="position-relative mt-2" v-model="tabIndex" card>
            <b-tab :title="$t('delivery.assign_tasks')">
                <assign-tasks :save="save" :cancel="cancel"/>
            </b-tab>
            <b-tab :title="$t('delivery.task_history')">
                <task-history/>
            </b-tab>
            <b-tab :title="$t('delivery.create_tasks')">
                <create-task v-if="tabIndex == 2"/>
            </b-tab>
            <b-tab :title="$t('delivery.history_for_messenger')" disabled></b-tab>
            <b-tab :title="$t('delivery.configurate_state')">
                <state-maintenance/>
            </b-tab>            
            <b-tab :title="$t('delivery.manage_incidents')">
                <incident-manager></incident-manager>
            </b-tab>
            <template #tabs-end v-if="tabIndex == 0">
                <div class="ms-auto d-flex align-items-center position-absolute" style="right: 10px;top: 6px;">
                    <b-button class="me-3 ms-auto"  variant="primary" @click="save = !save">
                        {{$t('common.save')}}
                        <eva-icon name="save" fill="#fff" width="20"></eva-icon>
                    </b-button>
                    <b-button variant="danger" class="ml-1" @click="cancel = !cancel">
                        {{$t('common.cancel')}}
                        <eva-icon name="alert-circle" fill="#fff" width="20"></eva-icon>
                    </b-button>    
                </div>
            </template> 
        </b-tabs> -->
    </layout>
</template>
<script>
    import Layout from "@/modules/template/layouts/main";
    import AssignTasks from '../components/tasks/assign/AssignTasks.vue';
    import TaskHistory from '../components/tasks/TaskHistory.vue';
    import StateMaintenance from '../components/tasks/StateMaintenance.vue';
    import IncidentManager from '../components/tasks/IncidentManager.vue'
    import CreateTask from './CreateTask.vue';
    import { mapGetters } from 'vuex';


    export default {
        name: 'Tasks',
        data(){
            return {
                employee_id: null,
                tabsOption:{
                    assign:{
                        save: false,
                        cancel: false,
                    },
                    create:{
                        save: false,
                        cancel: false,
                    }
                },
                save: false,
                cancel: false,
                tabIndex: '0',
            }
        },
        computed:{
            ...mapGetters({
                userData: "auth/user",
                permissions: "auth/permissions"
            }),
            showActionButtons(){
                switch (this.tabIndex) {
                    case '0':
                        return true;
                    case '2':
                        return true;
                    default:
                        return false;
                }
            },
        },
        watch:{
            'permissions'() {
                const modules = this.permissions?.modules?.find(data => data.name == 'Delivery')
                const task = modules?.subModules?.find(data => data.name === 'Tareas')
                // Verifica si se han cambiado los permisos de administrador
                if (!task.active) {
                    // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                    this.$router.push('/permission');
                }
            },
        },
        components:{
            Layout,
            AssignTasks,
            TaskHistory,
            StateMaintenance,
            IncidentManager,
            CreateTask
        },
        methods:{
            setAccion(action){
                switch (this.tabIndex) {
                    case '0':
                        this.tabsOption.assign[action] = !this.tabsOption.assign[action];
                    break;
    
                    case '2':
                        this.tabsOption.create[action] = !this.tabsOption.create[action];
                    break;
                    
                    default:
                        break;
                }
            }
        },
        mounted(){
            const content = document.querySelector(".page-content");
            const container = document.querySelector(".container-fluid");
            content.style.height = '100vh'
            content.classList.add('ps-0')
            content.classList.add('pe-0')
            container.classList.add('ps-0')
            container.classList.add('pe-0')
            container.classList = 'h-100'
        },
        beforeDestroy(){
            this.$store.commit('layout/SET_IS_MAP', false);
            const content = document.querySelector(".page-content");
            const container = document.querySelector(".container-fluid");
            content.classList.remove('ps-0')
            content.classList.remove('pe-0')
            container.classList.remove('ps-0')
            container.classList.remove('pe-0')
        },
        async created(){
            this.getPermissions()
            this.$store.commit('layout/SET_IS_MAP', true);
            try {
                await this.$store.dispatch('delivery/getEmployees');
                await this.$store.dispatch('delivery/getTaskType');
                await this.$store.dispatch('delivery/getTaskMethod');
                await this.$store.dispatch('delivery/getTaskStatus');
                await this.$store.dispatch('delivery/getSalesEmployee');
                await this.$store.dispatch("documentsFollowup/getTypes");
                await this.$store.dispatch('delivery/getIncidentType');
            } catch (error) {
                console.log(error)
            }
        }
    }
</script>

<style scoped>
.tabs::v-deep > .tab-content > .tab-pane{
    padding: 0;
}
.el-tabs::v-deep .el-tabs__content{
    overflow: auto;
    height: calc(100% - 39px);
}
.el-tabs::v-deep .el-tabs__header{
    padding-right: 337px;
}
.el-tabs::v-deep .el-tab-pane{
    height: 100%;
}
</style>